const win = window as any;
export const natsUrl: string = win.NATS_URL || import.meta.env.VITE_NATS_URL;
export const authAPIUrl: string =
  win.AUTH_API_URL || import.meta.env.VITE_AUTH_API_URL;
export const piserverAPIUrl: string =
  win.PISERVER_API_URL || import.meta.env.VITE_PISERVER_API_URL;
export const adminUrl: string = win.ADMIN_URL || import.meta.env.VITE_ADMIN_URL;
export const isCloud: boolean =
  "IS_CLOUD" in window
    ? win.IS_CLOUD === "true"
    : import.meta.env.VITE_IS_CLOUD === "true";

export const sentryDsn: string =
  win.SENTRY_DSN || import.meta.env.VITE_SENTRY_DSN;
export const gaKey: string = win.GA_KEY || import.meta.env.VITE_GA_KEY;
export const featureProxyUrl: string =
  win.FEATURE_PROXY_URL || import.meta.env.VITE_FEATURE_PROXY_URL;
export const featureProxySecret: string =
  win.FEATURE_PROXY_SECRET || import.meta.env.VITE_FEATURE_PROXY_SECRET;
export const environment: string =
  win.ENVIRONMENT || import.meta.env.VITE_ENVIRONMENT;
export const intercomId: string =
  win.INTERCOM_ID || import.meta.env.VITE_INTERCOM_ID;
export const intercomApi: string =
  win.INTERCOM_API || import.meta.env.VITE_INTERCOM_API;
export const loginTimeout: number =
  win.LOGIN_TIMEOUT || import.meta.env.VITE_LOGIN_TIMEOUT || 5000;
export const build = {
  id: win.BUILD_ID || import.meta.env.VITE_BUILD_ID,
  hash: win.BUILD_HASH || import.meta.env.VITE_BUILD_HASH,
  time: new Date(win.BUILD_TIME || import.meta.env.VITE_BUILD_TIME),
};
export const amplitudeApiKey: string =
  win.AMPLITUDE_API_KEY || import.meta.env.VITE_AMPLITUDE_API_KEY;
export const amplitudeDebugMode: boolean =
  win.AMPLITUDE_DEBUG_MODE ||
  import.meta.env.VITE_AMPLITUDE_DEBUG_MODE ||
  false;

export function getConfig() {
  return {
    natsUrl,
    authAPIUrl,
    piserverAPIUrl,
    adminUrl,
    isCloud,
    sentryDsn,
    gaKey,
    environment,
    intercomId,
    intercomApi,
    build,
    amplitudeApiKey,
  };
}
