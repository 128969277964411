<script lang="ts" setup>
import { computed } from "vue";

import { linkActions } from "@/action/linkActions";
import { cardKey } from "@/components/card/injectKeys";
import StickyLinkModal from "@/components/modal/StickyLinkModal.vue";
import { CardLink } from "@/model";
import { useLinkStore } from "@/store/link";
import { useModalStore } from "@/store/modal";
import { injectStrict } from "@/utils/context";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";
import StickyLinks from "../StickyLinks/StickyLinks.vue";

const card = injectStrict(cardKey);

const links = computed(() => {
  return useLinkStore().getCardLinks(card);
});

const handleAddLinks = () => {
  return useModalStore().open(StickyLinkModal, {
    attrs: { cardIds: [card.id] },
  });
};

const handleUnlink = (link: CardLink) => {
  linkActions.remove("card", { linkId: link.id });
};
</script>

<template>
  <AttributeChip
    :dropdown-props="{ width: 'sm' }"
    icon="thin/link"
    text-placeholder="0"
    :text="links.length ? links.length.toString() : undefined"
  >
    <StickyLinks :links="links" @add="handleAddLinks" @unlink="handleUnlink" />
  </AttributeChip>
</template>
