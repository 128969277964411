<template>
  <div class="home-header">
    <slot name="back" />
    <div v-if="$slots.title" class="title">
      <slot name="title" />
    </div>
    <div v-if="$slots.subtitle" class="subtitle">
      <slot name="subtitle" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class HomeHeader extends Vue {}
</script>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.home-header {
  position: relative;
  padding-bottom: 30px;

  .title h1 {
    font-size: 24px;
    font-weight: font.$weight-black;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .subtitle h2 {
    font-size: 16px;
    font-weight: font.$weight-medium;
    color: colors-old.$text-secondary-color;
  }

  button.base-button {
    margin-left: -37px;
  }
}
</style>
