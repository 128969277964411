import { reactive } from "vue";

import * as Environment from "@/Environment";
import { userCacheTimeout } from "@/Settings";
import { getAlmType } from "@/backend/Backend";
import { CancelError } from "@/backend/CancelError";
import { ServerAuthUser, readableAlmType } from "@/backend/serverModel";
import { AuthUser, BackendUser, IdMap, User } from "@/model";
import { captureMessage } from "@/sentry";
import color from "@/styles/color.module.scss";
import { colorFromNumber } from "@/utils/color";
import { hash } from "@/utils/general";

import { createApiClient } from "./api.config";

const apiClient = createApiClient(Environment.authAPIUrl);

type CacheUser = AuthUser & { timestamp: number };
type Users = IdMap<CacheUser>;
type UserIdentifier = { id: string } | User;

let users = load();
const loading: IdMap<Promise<AuthUser>> = {};

const backendUser: CacheUser = {
  id: "backend",
  name: "System",
  email: "",
  color: color.menu,
  timestamp: 0,
  preferredLanguage: "en",
};

export function isBackendUserId(userId: string) {
  return userId === backendUser.id;
}

export function loadUserImmediate(
  user: UserIdentifier & { name?: string },
  mapBackendToAlm?: boolean,
): AuthUser {
  const res = reactive(unknownUser(user));
  loadUser(user, { mapBackendToAlm }).then((loaded) => {
    res.name = loaded.name;
    res.email = loaded.email;
    res.imageUrl = loaded.imageUrl;
    res.color = loaded.color;
    res.preferredLanguage = loaded.preferredLanguage;
    res.hash = loaded.hash;
    if ("iconName" in loaded) {
      (res as BackendUser).iconName = loaded.iconName;
    }
  });
  return res;
}

export async function loadUser(
  user: UserIdentifier,
  options?: { useCache?: boolean; mapBackendToAlm?: boolean },
): Promise<AuthUser> {
  const useCache = options?.useCache ?? true;

  if (!user.id) {
    return add(unknownUser(user));
  }
  if (isBackendUserId(user.id)) {
    return options?.mapBackendToAlm ? almUser() : backendUser;
  }
  const cached = users[user.id];
  if (useCache && cached && Date.now() - cached.timestamp < userCacheTimeout) {
    return cached;
  }
  if (user.id in loading) {
    return loading[user.id];
  }
  try {
    return await (loading[user.id] = getUser(user));
  } finally {
    delete loading[user.id];
  }
}

export function clearUserCache() {
  users = {};
  save(users);
}

async function getUser(user: UserIdentifier): Promise<AuthUser> {
  try {
    const res = await apiClient.get("/user/" + user.id);
    if (res.data.success) {
      return add(mapUser(res.data.data));
    }
    captureMessage(`Could not get user: '${user.id}'`, {
      response: { result: res },
    });
  } catch (e: any) {
    if (e instanceof CancelError) {
      // most likely refreshing the token failed
      // don't log as we are already redirected to logout
      throw e;
    }
    captureMessage(`Could not get user: '${user.id}'`, {
      response: { message: e.message },
    });
  }
  return add(unknownUser(user));
}

function mapUser(user: ServerAuthUser): CacheUser {
  const name = userName(user);
  return {
    id: user.id,
    name,
    email: user.email,
    imageUrl: user.image_url,
    color: user.color || colorFromNumber(hash(name)),
    timestamp: 0,
    preferredLanguage: user.preferred_language,
    hash: user.hash,
  };
}

function add(user: CacheUser) {
  user.timestamp = Date.now();
  users[user.id] = user;
  save(users);
  return user;
}

export function unknownUser(
  user: { id: string } & Partial<CacheUser> = { id: "" },
): CacheUser {
  return {
    name: userName(user),
    email: "",
    color: color.menu,
    timestamp: 0,
    preferredLanguage: "en",
    ...user,
  };
}

function userName(user: { name?: string; email?: string }) {
  return user.name || user.email?.substring(0, user.email.indexOf("@")) || "";
}

export function isUnknownUser(user: AuthUser | null) {
  return !user || !user.email;
}

function load(): Users {
  try {
    return JSON.parse(localStorage.getItem("users") || "{}");
  } catch (e) {
    return {};
  }
}

function save(userObj: Users) {
  localStorage.setItem("users", JSON.stringify(userObj));
}

/**
 * Generates a user representing the session's ALM tool
 * (since a session can only have one ALM, we know any
 * 'backend' events are coming from that ALM)
 */
function almUser(): AuthUser {
  const user = { ...backendUser };
  const almType = getAlmType();

  if (almType) {
    switch (almType) {
      case "ac":
        (user as BackendUser).iconName = "ac-color";
        break;
      case "ado":
        (user as BackendUser).iconName = "ado-color";
        break;
      case "jira":
        (user as BackendUser).iconName = "jira-color";
        break;
    }

    user.name = readableAlmType(almType);
  }

  return user;
}
