import { Drag, registerDrag } from "@/Gestures";
import { boardActions } from "@/action/boardActions";
import { cardActions } from "@/action/cardActions";
import { linkActions } from "@/action/linkActions";
import { sender } from "@/backend/Sender";
import { sendCardMove } from "@/components/card/animator";
import {
  boardSize,
  boardToRelative,
  boardToWindow,
  boardToWindowSimple,
  windowToBoard,
  windowToBoardSimple,
  windowToRelative,
} from "@/math/coordinate-systems";
import {
  BoardCoordinate,
  Rectangle,
  WindowCoordinate,
  boardCoord,
  insideRectangle,
  plus,
  windowCoord,
} from "@/math/coordinates";
import { sendCardAction } from "@/mixins/EventBusUser";
import { Card } from "@/model";
import { useAppSizeStore } from "@/store/appSize";
import { useBoardStore } from "@/store/board";
import { useDraggingStore } from "@/store/dragging";
import { useSelectionStore } from "@/store/selection";
import { useZoomStore } from "@/store/zoom";

interface CardDrag {
  origin: WindowCoordinate;
  boardSize: Rectangle<WindowCoordinate>;
  insideBoard: boolean;
  selectedCards: CardInfo[];
}

interface CardInfo {
  id: string;
  el: HTMLElement;
  offset: WindowCoordinate;
}

export function registerCardDrag(
  card: Card,
  onDragEnd: () => void,
  e: PointerEvent,
) {
  registerDrag<CardDrag>({}, e, {
    start(drag) {
      const relativePointerPos = () =>
        windowToRelative(windowCoord(e.clientX, e.clientY));

      if (drag.type === "link") {
        drag.el = drag.imageEl = drag.target;
        drag.origin = windowCoord(drag.el.offsetLeft, drag.el.offsetTop);
        drag.start = windowCoord(
          0,
          0.13 *
            useBoardStore().currentBoard().cardSize.y *
            useAppSizeStore().appSize.height *
            useAppSizeStore().appSize.zoom,
        );
        drag.el.style.display = "none";
        useDraggingStore().startLinkDragging(
          card.id,
          drag.pointerId,
          relativePointerPos(),
        );
      } else {
        const windowPos = boardToWindow(
          boardCoord(drag.el.offsetLeft, drag.el.offsetTop),
        );
        drag.start = plus(drag.start, windowPos);
        drag.boardSize = boardSize();
        drag.insideBoard = insideRectangle(windowPos, drag.boardSize);
        const selected = useBoardStore().selectedStickyIds;
        const availableSelectedCards =
          !useSelectionStore().singleCard &&
          selected.length > 0 &&
          useBoardStore().isStickySelected(card)
            ? selected.map((id) => selectedCardInfo(id, drag))
            : [selectedCardInfo(card.id, drag)];
        drag.selectedCards = availableSelectedCards.filter(
          (card) => card !== null,
        ) as CardInfo[];
        for (const card of drag.selectedCards) {
          boardActions.cardToFront("internal", card.id);
          sender.startAlterCard(useBoardStore().currentBoard().id, card.id);

          useDraggingStore().startCardDragging(
            card.id,
            undefined,
            relativePointerPos(),
          );
        }
        cardActions.setPosition(
          "board",
          drag.selectedCards.map((c) => c.id),
        );
      }
      return true;
    },
    move(drag) {
      if (drag.origin) {
        const limitCoords = drag.type === "link" ? "none" : "output";
        useDraggingStore().dragLink(
          drag.pointerId,
          boardToRelative(windowToBoard(drag.pos, limitCoords)),
        );
        return drag.pos;
      } else {
        if (!drag.insideBoard && insideRectangle(drag.pos, drag.boardSize)) {
          drag.insideBoard = true;
        }
        return doWithSelected(drag, dragCard);
      }
    },
    stop(drag) {
      if (drag.origin) {
        linkActions.addByDrag("dragDrop", {
          dragId: drag.pointerId,
          id: card.id,
          el: drag.el,
        });
        return { ...drag.origin };
      } else {
        const pos = doWithSelected(drag, endDragCard);
        onDragEnd();
        return pos;
      }
    },
  });

  function selectedCardInfo(
    cardId: string,
    drag: Drag<CardDrag>,
  ): CardInfo | null {
    const el = document.getElementById(cardId);
    if (el) {
      return { id: cardId, el, offset: cardOffset(el, drag) };
    }
    return null;
  }

  function doWithSelected(
    drag: Drag<any> & CardDrag,
    action: (card: CardInfo, coordinate: BoardCoordinate) => void,
  ): BoardCoordinate {
    const pos = windowToBoard(
      drag.pos,
      drag.insideBoard ? "output" : "input",
      drag.selectedCards,
    );
    for (const card of drag.selectedCards) {
      action(card, cardCoordinate(card, pos));
    }
    return pos;
  }

  function dragCard(card: CardInfo, coordinate: BoardCoordinate) {
    sendCardAction(card.id, {
      action: "drag",
      x: coordinate.x,
      y: coordinate.y,
    });
    const pos = boardToRelative(coordinate);
    sendCardMove(card.id, useBoardStore().currentBoard().id, pos);
    useDraggingStore().dragCard(card.id, pos);
  }

  function endDragCard(card: CardInfo, coordinate: BoardCoordinate) {
    sendCardAction(card.id, { action: "dragEnd" });
    const pos = boardToRelative(coordinate);
    sendCardMove(card.id, useBoardStore().currentBoard().id, pos);
    if (useDraggingStore().dragging[card.id].fromPalette) {
      setTimeout(() => {
        useZoomStore().zoomStickyNote(card.id, true);
        sendCardAction(card.id, { action: "zoom", zoom: true });
      }, 100);
    }
    useDraggingStore().endCardDragging(card.id, pos);
  }
}

function cardOffset(el: HTMLElement, drag: Drag<CardDrag>) {
  return boardToWindowSimple(
    boardCoord(
      el.offsetLeft - drag.el.offsetLeft,
      el.offsetTop - drag.el.offsetTop,
    ),
  );
}

function cardCoordinate(card: CardInfo, pos: BoardCoordinate) {
  return plus(pos, windowToBoardSimple(card.offset));
}
