import { defineStore } from "pinia";

export type Tool = "move" | "line";

interface DrawState {
  active: boolean;
  tool: Tool;
}

export const useDrawStore = defineStore("draw", {
  state: (): DrawState => ({
    active: false,
    tool: "move",
  }),
  actions: {
    toggleActive() {
      this.active = !this.active;
    },
  },
});
