<script setup lang="ts">
import { computed, ref } from "vue";

import { navigationActions } from "@/action/navigationActions";
import HomeHeader from "@/components/HomeHeader.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import LoadComponent from "@/components/modal/components/LoadComponent.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { Team } from "@/model";
import { navigateBack, navigateForward } from "@/router/navigation";
import { useArtStore } from "@/store/art";
import { useModalStore } from "@/store/modal";
import { useSessionStore } from "@/store/session";
import { useTeamStore } from "@/store/team";
import { teamChange } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

const props = defineProps<{ asModal?: boolean }>();

const loading = ref(false);

const teams = computed(() => useTeamStore().teamsInCurrentArt);
const selectedSessionName = computed(
  () => useSessionStore().session.selected?.name,
);
const currentArt = computed(() => useArtStore().currentArt);

function back() {
  navigateBack();
}

function select(team: Team) {
  if (loading.value) {
    // this is to avoid loading multiple sessions in parallel which can cause troubles
    // would be nice to solve the problem with a lock around session loading
    // but when loading is in progress and then another request comes into the router, the original request is always canceled
    return;
  }
  if (!props.asModal) {
    loading.value = true;
  }

  // without timeout, the spinner is not shown, also $nextTick is not working, why?
  setTimeout(() => {
    if (props.asModal) {
      trackEvent(teamChange("menu-sidebar"));
      navigationActions.changeTeam("modal", team);
      useModalStore().hide();
    } else {
      navigationActions.selectTeam("mainMenu", team);
      navigateForward();
    }
  }, 30);
}

function isSelectedTeam(team: Team) {
  return useTeamStore().selectedTeam?.id === team.id;
}
</script>

<template>
  <div>
    <LoadComponent v-if="loading" />
    <div class="home-overview team-page">
      <HomeHeader v-if="!asModal">
        <template #back>
          <BaseButton
            variant="ghost"
            color="grey"
            icon-before="arrow/left"
            @click="back"
          >
            {{
              currentArt.name
                ? $t("teamPage.backToARTs")
                : $t("page.backToSessions")
            }}
          </BaseButton>
        </template>
        <template #title>
          <span v-if="currentArt.name">
            <h1>{{ currentArt.name }}</h1>
            <h4 class="art">{{ selectedSessionName }}</h4>
          </span>
          <span v-else>
            <h1>{{ selectedSessionName }}</h1>
          </span>
        </template>
        <template #subtitle>
          <h2>{{ $t("teamPage.selectTeam") }}</h2>
        </template>
      </HomeHeader>

      <hr v-if="!asModal" class="seperator" />
      <div
        :class="{ scrollable: !asModal }"
        class="scrollable-relative scrollable-content"
      >
        <div
          v-for="team in teams"
          :key="team.id"
          class="item"
          :class="{ selected: isSelectedTeam(team) }"
          @click="select(team)"
        >
          <div class="name-wrapper">
            <h3 class="name">{{ team.name }}</h3>
          </div>
          <SvgIcon name="arrow/right" size="20" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/team-art-list" as *;
@use "@/styles/mixins" as *;

.team-page {
  @include two-line-ellipsis-text;

  .art {
    font-weight: font.$weight-normal;
    color: colors-old.$text-secondary-color;
    margin-top: 5px;
  }
}
</style>
