<script lang="ts" setup>
import BaseList from "@/components-ng/BaseList/BaseList.vue";
import BaseListFooter from "@/components-ng/BaseList/components/BaseListFooter/BaseListFooter.vue";
import BaseListItem from "@/components-ng/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListItems from "@/components-ng/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components-ng/BaseList/components/BaseListTitle/BaseListTitle.vue";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import TypeDot from "@/components/TypeDot.vue";
import { CardLink } from "@/model";
import { injectStrict } from "@/utils/context";

const props = defineProps<{ links: CardLink[] }>();
const emit = defineEmits<{
  select: [link: CardLink];
  add: [];
  unlink: [link: CardLink];
}>();

const dropdown = injectStrict(dropdownKey);

const handleSelect = (link: CardLink) => {
  emit("select", link);
  dropdown.close();
};

const handleAdd = () => {
  emit("add");
  dropdown.close();
};

const handleUnlink = (link: CardLink) => {
  emit("unlink", link);
  dropdown.close();
};
</script>

<template>
  <BaseList class="sticky-links">
    <BaseListTitle>
      {{ $t("general.links") }}
    </BaseListTitle>
    <BaseListItems>
      <div v-if="!links.length" class="empty">
        <div class="content">
          <SvgIcon size="20" name="link" />
          <span>{{ $t("general.noLinkedStickies") }}</span>
        </div>
      </div>
      <template v-else>
        <BaseListItem
          v-for="(link, id) in props.links"
          :key="id"
          @click="handleSelect(link)"
        >
          <template #before>
            <TypeDot
              v-if="link.type === 'sticky'"
              size="medium"
              :color="link.color"
            />
          </template>
          <span> {{ link.text }}</span>
          <template #after>
            <SvgIcon name="unlink" @click="handleUnlink(link)" />
          </template>
        </BaseListItem>
      </template>
    </BaseListItems>
    <BaseListFooter :label="$t('objectivesModal.add')" @click="handleAdd" />
  </BaseList>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.sticky-links {
  font-size: 12px;

  .empty {
    color: colors-old.$placeholder-color;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      display: flex;
      align-items: center;
      height: 20px;
      white-space: nowrap;
      gap: 8px;
    }
  }
}
</style>
