<template>
  <div class="settings-page">
    <p class="title">{{ $t("settings.generalTitle") }}</p>
    <div class="row">
      <div class="left">
        <p class="subtitle">{{ $t("settings.mouseWheelTitle") }}</p>
        <p class="description">
          {{ $t("settings.mouseWheelDescription") }}
        </p>
      </div>
      <div class="right">
        <base-switch v-model="settings.wheelZoom" />
      </div>
    </div>
    <p class="title">{{ $t("settings.stickyNotesTitle") }}</p>
    <div class="row">
      <div class="left">
        <p class="subtitle">{{ $t("settings.fontType") }}</p>
        <p class="description">
          {{ $t("settings.stickyNotesDescription") }}
        </p>
      </div>
      <div class="right">
        <settings-font-dropdown v-model="settings.stickyFont" />
      </div>
    </div>
    <div class="row">
      <div class="left">
        <p class="subtitle">{{ $t("settings.scaleUpStickyFont") }}</p>
        <p class="description">
          {{ $t("settings.fontScaleDescription") }}
        </p>
      </div>
      <div class="right">
        <base-switch v-model="settings.textScale" />
      </div>
    </div>
    <p class="title">{{ $t("settings.boardsTitle") }}</p>
    <div class="row">
      <div class="left">
        <p class="subtitle">{{ $t("settings.loadWarningTitle") }}</p>
        <p class="description">
          {{ $t("settings.loadWarningDescription") }}
        </p>
      </div>
      <div class="right screenshot">
        <percentage-input v-model.number="settings.loadWarn" :min-value="50" />
      </div>
    </div>
    <div class="row">
      <div class="left">
        <p class="subtitle">
          {{ $t("settings.overloadOnProgramBoard") }}
        </p>
        <p class="description">
          {{ $t("settings.markIterationsWithOverload") }}
        </p>
      </div>
      <div class="right">
        <base-switch v-model="settings.overload" />
      </div>
    </div>
    <div class="row">
      <div class="left">
        <p class="subtitle">
          {{ $t("settings.linkLinesSubtitle") }}
        </p>
        <p class="description">
          {{ $t("settings.linkLinesDescription") }}
        </p>
      </div>
      <div class="right">
        <base-switch v-model="settings.permanentTeamLinks" />
      </div>
    </div>
    <div class="row">
      <div class="left">
        <p class="subtitle">
          {{ $t("settings.linkProgramLinesSubtitle") }}
        </p>
        <p class="description">
          {{ $t("settings.linkLinesDescription") }}
        </p>
      </div>
      <div class="right">
        <base-switch v-model="settings.permanentProgramLinks" />
      </div>
    </div>
    <div class="row">
      <div class="left">
        <p class="subtitle">{{ $t("settings.lineupDistanceTitle") }}</p>
        <p class="description">
          {{ $t("settings.lineupDistanceDescription") }}
        </p>
      </div>
      <div class="right screenshot">
        <percentage-input
          v-model.number="settings.initialLineupDistance"
          :min-value="50"
          :max-value="150"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import SettingsFontDropdown from "@/components/SettingsFontDropdown.vue";
import BaseSwitch from "@/components/input/BaseSwitch.vue";
import PercentageInput from "@/components/input/PercentageInput.vue";
import { navigateBack } from "@/router/navigation";
import { useClientSettingsStore } from "@/store/clientSettings";

@Options({
  components: {
    PercentageInput,
    SettingsFontDropdown,
    BaseSwitch,
  },
})
export default class SettingsContent extends Vue {
  @Prop({ type: Boolean, default: true }) readonly showBackBtn!: boolean;
  settings = useClientSettingsStore();

  back() {
    navigateBack();
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.settings-page {
  .row {
    width: 100%;
    text-align: left;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    font-weight: font.$weight-extra-bold;
    font-size: 20px;
  }

  .subtitle {
    font-size: 16px;
    margin-bottom: -5px;
  }

  .description {
    color: colors-old.$text-secondary-color;
    font-weight: font.$weight-normal;
  }

  h3 {
    text-align: left;
  }

  .text {
    width: 10%;

    input {
      text-align: right;
    }
  }

  .right {
    padding: 0.5em 0;
    float: right;

    &.screenshot {
      padding: 0;

      input {
        text-align: right;
        padding: 0;
        width: 5em;
      }
    }
  }
}
</style>
