import {
  Rectangle,
  RelativeCoordinate,
  relativeCoord,
} from "@/math/coordinates";
import { Board, BoardCard, Shape } from "@/model";

export interface BoardLocation {
  name: string;
  bounds: Rectangle<RelativeCoordinate>;
  shapes: Shape[];

  matches(coord: RelativeCoordinate): boolean;

  index(): number[];
}

export const fullBoardLocation: BoardLocation = {
  name: "",
  bounds: { p0: relativeCoord(0, 0), p1: relativeCoord(1, 1) },
  shapes: [],
  matches() {
    return true;
  },
  index() {
    return [];
  },
};

export function cardsInLocation(board: Board, loc: BoardLocation): BoardCard[] {
  const cards: BoardCard[] = [];
  for (const id in board.cards) {
    if (loc.matches(board.cards[id].meta.pos)) {
      cards.push(board.cards[id]);
    }
  }
  return cards;
}
