<script setup lang="ts">
import { computed } from "vue";

import UserAvatar from "@/components/UserAvatar.vue";
import { AuthUser } from "@/model";

import Duration from "../Duration";

const props = defineProps<{
  duration: Duration;
  user?: AuthUser;
  name: string;
  paused: boolean;
}>();
const userName = computed(() => props.user?.name);
</script>

<template>
  <div class="active-timer">
    <div>
      <div class="time">
        <template v-if="duration.hasHours"
          >{{
            $t("timer.longDuration", {
              h: duration.hourStr,
              m: duration.minuteStr,
              s: duration.secondStr,
            })
          }}
        </template>
        <template v-else
          >{{
            $t("timer.shortDuration", {
              m: duration.minuteStr,
              s: duration.secondStr,
            })
          }}
        </template>
      </div>
      <div class="name" :class="{ paused }">
        {{ name }}
      </div>
      <div class="user">
        <UserAvatar :user="user" class="small" />
        <span>{{ userName }}</span>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.active-timer {
  display: flex;
  align-items: center;

  & > img {
    flex: 0 0 2.5em;
    height: 2em;
    cursor: pointer;
  }

  & > div {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    overflow: hidden;

    .time {
      font-weight: font.$weight-bold;
      color: colors-old.$text-secondary-color;
      margin-bottom: 2px;
      font-size: 14px;
    }

    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: colors-old.$text-secondary-color;
      font-size: 12px;
    }

    .user {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 0.5em;
      color: colors-old.$text-secondary-color;
      font-size: 75%;

      .user-avatar {
        .avatar {
          width: 18px;
          height: 18px;

          span {
            font-size: 10px;
          }
        }
      }
    }
  }

  .paused {
    color: colors-old.$text-secondary-color;
  }
}
</style>
