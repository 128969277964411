{
  "action.addSticky": "Add Sticky Note",
  "action.alignHorizontal": "Align horizontally",
  "action.alignVertical": "Align vertically",
  "action.backlogBoard": "ART Backlog Board",
  "action.canvases": "Canvases",
  "action.deleteSticky": "Delete Sticky Note",
  "action.deleteSticky.description": "Are you sure you want to delete this sticky note? This action cannot be undone.",
  "action.distributeHorizontal": "Distribute horizontally",
  "action.distributeVertical": "Distribute vertically",
  "action.duplicate": "Duplicate",
  "action.executionMode": "Execution Mode",
  "action.facilitation": "Facilitation",
  "action.lineTool": "Draw lines",
  "action.lineup": "Line up in a grid",
  "action.linkMultiple": "Link",
  "action.messenger": "Messenger",
  "action.mirrorMultiple": "Mirror",
  "action.moveSticky": "Move Sticky Note",
  "action.moveTool": "Move",
  "action.next": "Next",
  "action.objectivesBoard": "ART PI Objectives",
  "action.paste": "Paste",
  "action.planMode": "Plan Mode",
  "action.pointer": "Pointer",
  "action.previous": "Previous",
  "action.programBoard": "ART Planning Board",
  "action.programMetrics": "ART Metrics",
  "action.removeSticky": "Remove Sticky Note",
  "action.removeSticky.description": "Are you sure you want to remove this sticky note? It will only be removed on this board.",
  "action.riskBoard": "ART PI Risks",
  "action.scaleStickies": "Scale stickies",
  "action.search": "Search",
  "action.searchArts": "Search ARTs",
  "action.searchTeams": "Search Teams",
  "action.selectStickies": "Select Stickies",
  "action.shiftMenu": "Shift navigation to the other side",
  "action.showGPT": "Explain me this objective business friendly",
  "action.showOverview": "Show overview",
  "action.solutionBacklogBoard": "Solution Backlog Board",
  "action.solutionBoard": "Solution Planning Board",
  "action.team": "Team",
  "action.teamBoard": "Team Board",
  "action.teamMetrics": "Team Metrics",
  "action.teamObjectives": "Objectives",
  "action.timer": "Timer",
  "action.undoSelectionAction": "Undo {action} {count} stickies",
  "actionMenu.dependentOn": "Dependent on",
  "actionMenu.mirrorSticky.mirror": "Mirror",
  "actionMenu.mirrorSticky.mirrorSticky": "Mirror Sticky Note",
  "actionMenu.mirrorSticky.mirrored": "Mirrored",
  "actions.art": "ART",
  "actions.currentStatusMessage": "Currently the status can only be changed in your ALM tool",
  "actions.deleteSticky": "Delete this sticky note",
  "actions.linkedStickies": "linked stickies",
  "actions.mirrorProgramBoard": "Mirror to ART planning board",
  "actions.mirroredStickies": "Mirrored stickies can not be moved to another team.",
  "actions.noDependencyTypeChange": "You cannot change the type of a dependency sticky note. Delete it and create a new one.",
  "actions.noMirroring": "This sticky note can not be mirrored to any other board.",
  "actions.onlyRiskWarning": "Only risk stickies can be moved.",
  "actions.roamRisk": "Roam that risk",
  "actions.selectArt": "Select an ART",
  "actions.selectBoard": "Select a board",
  "actions.selectProject": "Select a project",
  "actions.selectStatus": "Select a status",
  "actions.selectSticker": "Select sticker",
  "actions.selectStickyType": "Select a sticky note type",
  "actions.selectTeam": "Select a team",
  "actions.selectTeamBoard": "Select a team board",
  "actions.setStoryPoints": "Set story points",
  "actions.setWsjf": "Set WSJF value",
  "actions.stickyDeleteWarning": "This board does not allow deleting ALM stickies.",
  "actions.stickyMirrorMessage": "This sticky note is mirrored from the {originName}. You can only change the type there.",
  "actions.wsjf": "WSJF",
  "actions.wsjfWarning": "The WSJF value cannot be changed.",
  "activity.title": "Sticky Note Activity",
  "almSync.tooltip": "Connection to the ALM tool failed. Click to get more information and ability to fix it.",
  "almsync.disconnected": "{almType} Disconnected",
  "almsync.resync": "Resyncing",
  "almsync.stopped": "{almType} Sync Stopped",
  "appZoom.zoom": "Zoom",
  "artPage.select": "Select an ART",
  "background.agenda": "Agenda",
  "background.businessModelCanvasAdaptionCCLicense": "{canvasName} is adapted from The Business Model Canvas (https://www.strategyzer.com/canvas/business-model-canvas) and is licensed under the Creative Commons Attribution-Share Alike 3.0 Un-ported License.",
  "background.by": "by {name}",
  "background.createdCredits": "created with passion in",
  "background.day": "Day {day}",
  "background.risks": "Risks",
  "background.teamNr": "Team {0}",
  "background.timeZone": "Time Zone {timeZoneNumber}",
  "backgroundArtTracks.accelerate": "Accelerate",
  "backgroundArtTracks.aftercare": "Aftercare",
  "backgroundArtTracks.artTracks": "ART Tracks",
  "backgroundArtTracks.firstPi": "First PI",
  "backgroundArtTracks.identify": "Identify",
  "backgroundArtTracks.inputFunnel": "Input Funnel",
  "backgroundArtTracks.launch": "Launch",
  "backgroundArtTracks.preparation": "Preparation",
  "backgroundArtTracks.secondPi": "Second PI",
  "backgroundArtTracks.stations": "Stations",
  "backgroundArtTracks.transformation": "Transformation Backlog",
  "backgroundBusinessModel.businessModelCanvas": "Business Model Canvas",
  "backgroundBusinessModel.businessModelCanvasNote": "The Business model canvas is created by strategyzer ({url}) and licensed under the Creative Commons Attribution-Share Alike 3.0 Unsupported license.",
  "backgroundBusinessModel.channels": "Channels",
  "backgroundBusinessModel.costStructure": "Cost Structure",
  "backgroundBusinessModel.customerRelationships": "Customer Relationships",
  "backgroundBusinessModel.customerSegments": "Customer Segments",
  "backgroundBusinessModel.keyActivities": "Key Activities",
  "backgroundBusinessModel.keyPartners": "Key Partners",
  "backgroundBusinessModel.keyResources": "Key Resources",
  "backgroundBusinessModel.revenueStreams": "Revenue Streams",
  "backgroundBusinessModel.valuePropositions": "Value Propositions",
  "backgroundBusinessModel.viewCopy": "To view a copy of this license, visit {url}.",
  "backgroundChecklist.checklist": "Checklist",
  "backgroundConfidenceVoting.confidenceVote": "Confidence Vote - Fist of Five",
  "backgroundCustomerJourney.customerBackStory": "customer back-story",
  "backgroundCustomerJourney.customerJourneyMap": "Customer Journey Map",
  "backgroundCustomerJourney.opportunityImprovePotential": "Opportunities / Improvement / Potential",
  "backgroundCustomerJourney.painPoints": "Pain Points",
  "backgroundCustomerJourney.persona": "Persona",
  "backgroundCustomerJourney.thinkingAndFeeling": "Thinking & Feeling",
  "backgroundEarlyToMature.early": "Early",
  "backgroundEarlyToMature.established": "Established",
  "backgroundEarlyToMature.failing": "Failing",
  "backgroundEarlyToMature.mature": "Mature",
  "backgroundEarlyToMature.successful": "Successful",
  "backgroundEarlyToMature.working": "Working",
  "backgroundEffortMatrix.costOfDelay": "Cost of Delay",
  "backgroundEffortMatrix.costOfDelayEffortMatrix": "Cost of Delay x Effort Matrix",
  "backgroundEffortMatrix.effortDuration": "Effort (Duration)",
  "backgroundEmpathyRetro.actions": "Actions",
  "backgroundEmpathyRetro.appreciations": "Appreciations",
  "backgroundEmpathyRetro.puzzles": "Puzzles",
  "backgroundEmpathyRetro.wishes": "Wishes",
  "backgroundGoldenCircle.goldenCircle": "Golden Circle",
  "backgroundGoldenCircle.how": "How",
  "backgroundGoldenCircle.what": "What",
  "backgroundGoldenCircle.why": "Why",
  "backgroundImpactEffortMatrix.effort": "Effort",
  "backgroundImpactEffortMatrix.fillInJobs": "Fill In Jobs",
  "backgroundImpactEffortMatrix.high": "high",
  "backgroundImpactEffortMatrix.impact": "Impact",
  "backgroundImpactEffortMatrix.impactEffortMatrix": "Impact Effort Matrix",
  "backgroundImpactEffortMatrix.low": "low",
  "backgroundImpactEffortMatrix.majorProjects": "Major Projects",
  "backgroundImpactEffortMatrix.notWorthIt": "Not Worth It",
  "backgroundImpactEffortMatrix.quickWins": "Quick Wins!",
  "backgroundIshikawa.cause": "Cause",
  "backgroundIshikawa.effect": "Effect",
  "backgroundIshikawa.ishikawaDiagram": "Ishikawa Diagram",
  "backgroundKeyTopics.keyTopics": "Key Topics",
  "backgroundKeyTopics.topicNr": "Topic {0}",
  "backgroundLeanCanvas.channels": "Channels",
  "backgroundLeanCanvas.channelsList": "List your path to customers (inbound or outbound).",
  "backgroundLeanCanvas.costStructure": "Cost Structure",
  "backgroundLeanCanvas.costStructureList": "List your fixed and variable costs.",
  "backgroundLeanCanvas.customerSegments": "Customer Segments",
  "backgroundLeanCanvas.customerSegmentsList": "List your target customers and users.",
  "backgroundLeanCanvas.customerView": "Customer View",
  "backgroundLeanCanvas.earlyAdopters": "Early Adopters",
  "backgroundLeanCanvas.earlyAdoptersList": "List the characteristics of your ideal customers.",
  "backgroundLeanCanvas.existingAlternatives": "Existing Alternatives",
  "backgroundLeanCanvas.existingAlternativesList": "List how these problems are solved today.",
  "backgroundLeanCanvas.highlevelConcept": "High-Level Concept",
  "backgroundLeanCanvas.highlevelConceptList": "List your X for Y analogy e.g. YouTube = Flickr for videos.",
  "backgroundLeanCanvas.keyMetrics": "Key Metrics",
  "backgroundLeanCanvas.keyMetricsList": "List the key numbers that tell you how your business is doing.",
  "backgroundLeanCanvas.leanCanvas": "Lean Canvas",
  "backgroundLeanCanvas.marketView": "Market View",
  "backgroundLeanCanvas.problem": "Problem",
  "backgroundLeanCanvas.problemsList": "List your top 1-3 problems.",
  "backgroundLeanCanvas.productView": "Product View",
  "backgroundLeanCanvas.revenueStreams": "RevenueStreams",
  "backgroundLeanCanvas.revenueStreamsList": "List your sources of revenue.",
  "backgroundLeanCanvas.solution": "Solution",
  "backgroundLeanCanvas.solutionsList": "Outline a possible solution for each problem.",
  "backgroundLeanCanvas.unfairAdvantage": "Unfair Advantage",
  "backgroundLeanCanvas.unfairAdvantageList": "Something that cannot easily be bought or copied.",
  "backgroundLeanCanvas.uniqueValueProposition": "Unique Value Proposition",
  "backgroundLeanCanvas.uniqueValuePropositionList": "Single, clear, compelling message that states why you are different and worth paying attention.",
  "backgroundParking.p": "P",
  "backgroundPiplanningProduct.art": "ART",
  "backgroundPiplanningProduct.collaborate": "Collaborate",
  "backgroundPiplanningProduct.execute": "Execute & Track",
  "backgroundPiplanningProduct.facilitate": "Facilitate",
  "backgroundPiplanningProduct.management": "Product Management & Forecast",
  "backgroundPiplanningProduct.plan": "Plan",
  "backgroundPiplanningProduct.portfolio": "Portfolio",
  "backgroundPiplanningProduct.solution": "Solution",
  "backgroundPiplanningProduct.team": "Team",
  "backgroundPiplanningProduct.title": "piplanning.io product",
  "backgroundPlusMinusDelta.delta": "Delta",
  "backgroundPlusMinusDelta.minus": "Minus",
  "backgroundPlusMinusDelta.plus": "Plus",
  "backgroundPortfolio.budget": "Budget",
  "backgroundPortfolio.channels": "Channels",
  "backgroundPortfolio.costStructure": "Cost Structure",
  "backgroundPortfolio.customer": "Customer",
  "backgroundPortfolio.customers": "Customers",
  "backgroundPortfolio.keyActivities": "Key Activities",
  "backgroundPortfolio.keyPartners": "Key Partners",
  "backgroundPortfolio.keyResources": "Key Resources",
  "backgroundPortfolio.kpiRevenue": "KPI / Revenue",
  "backgroundPortfolio.portfolioCanvas": "Portfolio Canvas",
  "backgroundPortfolio.relationships": "Relationships",
  "backgroundPortfolio.revenueStreams": "Revenue Streams",
  "backgroundPortfolio.solutions": "Solutions",
  "backgroundPortfolio.valuePropositions": "Value Propositions",
  "backgroundPortfolio.valueStreams": "Value Streams",
  "backgroundPortfolioRoadmap.firstHalfYear": "H1",
  "backgroundPortfolioRoadmap.milestones": "Milestones",
  "backgroundPortfolioRoadmap.portfolioRoadmap": "Portfolio Roadmap",
  "backgroundPortfolioRoadmap.quarter": "Q{0}",
  "backgroundPortfolioRoadmap.secondHalfYear": "H2",
  "backgroundPortfolioRoadmap.year": "Y{0}",
  "backgroundProblemSolving.decisions": "Decisions",
  "backgroundProblemSolving.measures": "Measures",
  "backgroundProblemSolving.possibleSolutions": "Possible Solutions",
  "backgroundProblemSolving.problems": "Problems",
  "backgroundRetro.continueDoing": "Continue Doing",
  "backgroundRetro.retrospective": "Retrospective",
  "backgroundRetro.startDoing": "Start Doing",
  "backgroundRetro.stopDoing": "Stop Doing",
  "backgroundRoamKanban.accepted": "Accepted",
  "backgroundRoamKanban.inProgress": "In Progress",
  "backgroundRoamKanban.mitigated": "Mitigated",
  "backgroundRoamKanban.owned": "Owned",
  "backgroundRoamKanban.ready": "Ready",
  "backgroundRoamKanban.resolved": "Resolved",
  "backgroundRoamKanban.review": "Review",
  "backgroundRoamKanban.riskBacklog": "Risk Backlog",
  "backgroundRoamKanban.roamKanban": "ROAM | Kanban",
  "backgroundRootCause.adapted": "Adapted from {from}",
  "backgroundRootCause.biggest": "#{item} Biggest Root-Cause",
  "backgroundRootCause.environment": "Environment",
  "backgroundRootCause.improvement": "#{item} Identify Improvement Backlog Items",
  "backgroundRootCause.people": "People",
  "backgroundRootCause.problem": "#{item} Original Problem",
  "backgroundRootCause.process": "Process",
  "backgroundRootCause.program": "Program",
  "backgroundRootCause.restate": "#{item} Restate Problem",
  "backgroundRootCause.rootCause": "#{item} Root-Cause Analysis",
  "backgroundRootCause.solutions": "#{item} Brainstorm Solutions",
  "backgroundRootCause.title": "Root-Cause Analysis",
  "backgroundRootCause.tools": "Tools",
  "backgroundSos.access": "Do you have access to the team members and stakeholders you need?",
  "backgroundSos.allOk": "Are all your NOs above YES now?",
  "backgroundSos.architecture": "Do you understand the architectural context, and who to go to for questions?",
  "backgroundSos.capacity": "Have you finished allocating capacity (if necessary) to maintenance in your Iteration?",
  "backgroundSos.completeDraft": "Do you anticipate completing your draft plan?",
  "backgroundSos.completePlan": "Do you anticipate completing your final plan?",
  "backgroundSos.day1": "SoS Radiator Day 1",
  "backgroundSos.day2": "SoS Radiator Day 2",
  "backgroundSos.devops": "Do you understand the role of the System Team and DevOps, and who to go to for questions?",
  "backgroundSos.discussPO": "Are you discussing trade-offs and conflicting priorities with your business owners?",
  "backgroundSos.discussSM": "Is there anything you need to discuss with other Scrum Masters? If so, stay for the 'Meet After'",
  "backgroundSos.draftReady": "Draft Plan Readiness",
  "backgroundSos.estimateStories": "Are you identifying and estimating stories?",
  "backgroundSos.feedback": "Do you understand how the management team feedback affects your team?",
  "backgroundSos.finalReady": "Final Plan Readiness",
  "backgroundSos.finalizeObjectives": "Are your PI Objectives, Uncommitted Objectives, and business value finalized?",
  "backgroundSos.getStarted": "Getting Started",
  "backgroundSos.hardDates": "Have hard dates been identified and on the ART planning board?",
  "backgroundSos.havePOSM": "Do you have a Product Owner and Scrum Master?",
  "backgroundSos.identifyCapacity": "Have you identified the capacity for each Iteration in your PI?",
  "backgroundSos.identifyDependencies": "Have you identified any dependencies?",
  "backgroundSos.identifyHardDates": "Are you confident you have identified all hard dates based on the latest feedback and represented them on the ART planning board?",
  "backgroundSos.iterationStory": "Is Iteration story level planning in process?",
  "backgroundSos.iterations": "Have you planned all Iterations for your PI?",
  "backgroundSos.knowTeam": "Do you know who your team is for the whole PI?",
  "backgroundSos.latestRisks": "Are you confident you have identified your ART PI risks based on the latest feedback?",
  "backgroundSos.mitigateRisks": "Are your team risks mitigated?",
  "backgroundSos.moreCapacity": "Did you consider whether you need to allocate capacity to maintenance in your Iterations?",
  "backgroundSos.objectives": "Have you begun writing your PI Objectives?",
  "backgroundSos.objectivesProgress": "Objectives Progress",
  "backgroundSos.planningProgress": "Planning Progress",
  "backgroundSos.presentPlan": "Are you ready to present your final plan?",
  "backgroundSos.prioritizeObjectives": "Have your business owners begun prioritizing your PI Objectives and Uncommitted Objectives?",
  "backgroundSos.progressCheckin": "Progress Check-In",
  "backgroundSos.release": "Have you confirmed whether or not there is an opportunity to release before the end of the PI?",
  "backgroundSos.remainingDependencies": "Are you confident you have identified remaining dependencies based on the latest feedback and have you represented them on the ART planning board?",
  "backgroundSos.remainingRisks": "Have you identified remaining team and ART PI risks?",
  "backgroundSos.removeBlockings": "Have all blocking issues which are impacting planning been removed?",
  "backgroundSos.replanIterations": "Have you replanned all Iterations in the PI as necessary?",
  "backgroundSos.representDependencies": "Are your dependencies represented on the ART planning board?",
  "backgroundSos.requirements": "Do you understand the planning requirements?",
  "backgroundSos.resolveDependencies": "Have you begun resolving dependencies with other teams?",
  "backgroundSos.reviewRisks": "Are your ART PI risks ready for review ('ROAM'ing)?",
  "backgroundSos.revisedObjectives": "Are your revised PI Objectives and Uncommitted Objectives clear, legible, succinct, and more measurable?",
  "backgroundSos.risks": "Are you identifying team and ART PI risks?",
  "backgroundSos.setup": "Is your working space setup?",
  "backgroundSos.shared": "Do you understand which resources are shared (e.g. UX, Training and Documentation) and who to go to for questions?",
  "backgroundSos.smartObjectives": "Are your PI Objectives and Uncommitted Objectives for the PI clear, legible, succinct, and measurable (as per SMART Objectives)?",
  "backgroundSos.stories": "Have you identified most of your stories?",
  "backgroundSos.vision": "Do you understand (and can you find) the vision that drives your backlog",
  "backgroundSosSync.capacity": "Have you defined the capacity for each iteration in the PI?",
  "backgroundSosSync.dependencies": "Have you begun resolving dependencies with other teams?",
  "backgroundSosSync.discussBO": "Are you discussing trade-offs and conflicting priorities with your Business Owners?",
  "backgroundSosSync.discussSM": "Is there anything you need to discuss with other Scrum Masters? If so, stay for the meet-after.",
  "backgroundSosSync.objectives": "Will you be ready to start writing PI Objectives in the next 15 minutes?",
  "backgroundSosSync.risks": "Have you identified any ART PI risks?",
  "backgroundSosSync.stories": "Have you identified most of the Stories for the first two iterations and begun estimating?",
  "backgroundSosSync.title": "Scrum of Scrum Sync Questions",
  "backgroundStarfish.keep": "Keep",
  "backgroundStarfish.less": "Less",
  "backgroundStarfish.more": "More",
  "backgroundStarfish.start": "Start",
  "backgroundStarfish.stop": "Stop",
  "backgroundSwot.opportunities": "Opportunities",
  "backgroundSwot.strengths": "Strengths",
  "backgroundSwot.swotAnalysis": "SWOT Analysis",
  "backgroundSwot.threats": "Threats",
  "backgroundSwot.weaknesses": "Weaknesses",
  "backgroundSwotTows.eval": "SWOT / TOWS Analysis (Evaluation)",
  "backgroundSwotTows.feasibility": "Feasibility",
  "backgroundSwotTows.impact": "Impact",
  "backgroundSwotTows.notes": "Notes",
  "backgroundSwotTows.opportunities": "External Opportunities",
  "backgroundSwotTows.probability": "Probability",
  "backgroundSwotTows.so": "SO",
  "backgroundSwotTows.soNote": "SO: How can your strengths be used to exploit and maximize opportunities?",
  "backgroundSwotTows.st": "ST",
  "backgroundSwotTows.stNote": "ST: How can you apply your strengths to overcome present and potential threats?",
  "backgroundSwotTows.strengths": "Internal Strengths",
  "backgroundSwotTows.threats": "External Threats",
  "backgroundSwotTows.title": "SWOT / TOWS Analysis",
  "backgroundSwotTows.weaknesses": "Internal Weaknesses",
  "backgroundSwotTows.wo": "WO",
  "backgroundSwotTows.woNote": "WO: How can your opportunities be leveraged to overcome weaknesses",
  "backgroundSwotTows.wt": "WT",
  "backgroundSwotTows.wtNote": "WT: How can you minimize weaknesses and avoid threats?",
  "backgroundTodoProgressDone.done": "Done",
  "backgroundTodoProgressDone.inProgress": "In Progress",
  "backgroundTodoProgressDone.todo": "To Do",
  "backgroundUserStoryMap.highlevelActivities": "High level activities",
  "backgroundUserStoryMap.releaseXy": "Release x.y",
  "backgroundUserStoryMap.userStoryMap": "User Story Map",
  "backgroundValueStream.adapted": "Value Stream Canvas is adapted from The Development Value Stream Canvas provided by Scaled Agile, Inc. (https://www.scaledagileframework.com/value-streams/)",
  "backgroundValueStream.budget": "Budget",
  "backgroundValueStream.channels": "Channels",
  "backgroundValueStream.customerRelationships": "Customer Relationships",
  "backgroundValueStream.customerSegments": "Customer Segments",
  "backgroundValueStream.economy": "Economic Framework",
  "backgroundValueStream.for": "For...",
  "backgroundValueStream.isA": "Is a...",
  "backgroundValueStream.operational": "Operational Value Stream Supported",
  "backgroundValueStream.ourSolution": "Our Solution...",
  "backgroundValueStream.people": "People and Locations",
  "backgroundValueStream.proposition": "Value Proposition",
  "backgroundValueStream.revenue": "KPI / Revenue",
  "backgroundValueStream.roles": "Key Roles",
  "backgroundValueStream.solutionContext": "Solution Context",
  "backgroundValueStream.solutions": "Solutions",
  "backgroundValueStream.suppliers": "ARTs and Suppliers",
  "backgroundValueStream.that": "That...",
  "backgroundValueStream.the": "The...",
  "backgroundValueStream.title": "Value Stream Canvas",
  "backgroundValueStream.unlike": "Unlike...",
  "backgroundValueStream.who": "Who...",
  "backgroundValueStreamIdent.customerValue": "Customer Value",
  "backgroundValueStreamIdent.developmentValue": "Development Value Streams",
  "backgroundValueStreamIdent.operationalValue": "Operational Value Stream",
  "backgroundValueStreamIdent.people": "People",
  "backgroundValueStreamIdent.systems": "Systems",
  "backgroundValueStreamIdent.valueStreamIdentification": "Value Stream Identification",
  "backgroundWelcome.followGuide": "Follow the numbers that guide you through piplanning.io",
  "backgroundWelcome.learnMaster": "Learn more and master piplanning.io",
  "backgroundWelcome.startTour": "Click the link on the sticky note to start the tour",
  "backgroundWorkingAgreement.codeOfConduct": "Code of Conduct",
  "backgroundWorkingAgreement.communicationChannels": "Communication Channels",
  "backgroundWorkingAgreement.linkTypes": "Link Types",
  "backgroundWorkingAgreement.stickers": "Stickers",
  "backgroundWorkingAgreement.stickyTypes": "Sticky Note Types",
  "backgroundWorkingAgreement.workflows": "Workflows",
  "backgroundWorkingAgreement.workingAgreement": "Working Agreement",
  "backgroundWorldMap.adisAbaba": "Adis Ababa",
  "backgroundWorldMap.algiers": "Algiers",
  "backgroundWorldMap.anchorage": "Anchorage",
  "backgroundWorldMap.astana": "Astana",
  "backgroundWorldMap.auckland": "Auckland",
  "backgroundWorldMap.bangkok": "Bangkok",
  "backgroundWorldMap.beijing": "Beijing",
  "backgroundWorldMap.bogota": "Bogota",
  "backgroundWorldMap.brasilia": "Brasilia",
  "backgroundWorldMap.buenosAires": "Buenos Aires",
  "backgroundWorldMap.cairo": "Cairo",
  "backgroundWorldMap.capeTown": "Cape Town",
  "backgroundWorldMap.caracas": "Caracas",
  "backgroundWorldMap.chicago": "Chicago",
  "backgroundWorldMap.dallas": "Dallas",
  "backgroundWorldMap.denver": "Denver",
  "backgroundWorldMap.edmonton": "Edmonton",
  "backgroundWorldMap.hoChiMinhCity": "Ho Chi Minh City",
  "backgroundWorldMap.hongKong": "Hong Kong",
  "backgroundWorldMap.irkutsk": "Irkutsk",
  "backgroundWorldMap.istanbul": "Istanbul",
  "backgroundWorldMap.jakarta": "Jakarta",
  "backgroundWorldMap.johannesburg": "Johannesburg",
  "backgroundWorldMap.khartoum": "Khartoum",
  "backgroundWorldMap.kinshasa": "Kinshasa",
  "backgroundWorldMap.kisangani": "Kisangani",
  "backgroundWorldMap.lagos": "Lagos",
  "backgroundWorldMap.lima": "Lima",
  "backgroundWorldMap.london": "London",
  "backgroundWorldMap.losAngeles": "Los Angeles",
  "backgroundWorldMap.madrid": "Madrid",
  "backgroundWorldMap.magadan": "Magadan",
  "backgroundWorldMap.manaus": "Manaus",
  "backgroundWorldMap.melbourne": "Melbourne",
  "backgroundWorldMap.mexicoCity": "Mexico City",
  "backgroundWorldMap.moscow": "Moscow",
  "backgroundWorldMap.nairobi": "Nairobi",
  "backgroundWorldMap.newDelhi": "New Delhi",
  "backgroundWorldMap.newYork": "New York",
  "backgroundWorldMap.novosibirsk": "Novosibirsk",
  "backgroundWorldMap.paris": "Paris",
  "backgroundWorldMap.perth": "Perth",
  "backgroundWorldMap.rioDeJaneiro": "Rio De Janeiro",
  "backgroundWorldMap.riyadh": "Riyadh",
  "backgroundWorldMap.rome": "Rome",
  "backgroundWorldMap.saintPetersburg": "Saint Petersburg",
  "backgroundWorldMap.samara": "Samara",
  "backgroundWorldMap.santiago": "Santiago",
  "backgroundWorldMap.saoPaulo": "Sao Paulo",
  "backgroundWorldMap.seoul": "Seoul",
  "backgroundWorldMap.shanghai": "Shanghai",
  "backgroundWorldMap.singapore": "Singapore",
  "backgroundWorldMap.sydney": "Sydney",
  "backgroundWorldMap.taipei": "Taipei",
  "backgroundWorldMap.tehran": "Tehran",
  "backgroundWorldMap.tokyo": "Tokyo",
  "backgroundWorldMap.toronto": "Toronto",
  "backgroundWorldMap.vancouver": "Vancouver",
  "backgroundWorldMap.washington": "Washington, D.C.",
  "backgroundWorldMap.winnipeg": "Winnipeg",
  "backgroundWorldMap.yekaterinburg": "Yekaterinburg",
  "backlogBoard.confirmManualUpdate": "Do you want to manually update the stickies on this board with the state of {almName}?",
  "backlogBoard.programBacklog": "ART Backlog",
  "backlogBoard.solutionBacklog": "Solution Backlog",
  "backlogBoard.stickyOverwrite": "Stickies with ALM-Key will be overwritten by the state of {almName}",
  "backlogSync.failedSync": "Could not synchronize the backlog. Please try again. {errorMessage}",
  "backlogSync.timeout": "Synchronizing takes a long time. Continuing in the background.",
  "board.backlog": "ART Backlog Board",
  "board.backlog.long": "ART Backlog {art}",
  "board.flex": "Collaboration Canvas",
  "board.flex.long": "Collaboration Canvas {name}",
  "board.objectives": "ART PI Objectives",
  "board.objectives.long": "Objectives {art}",
  "board.program": "ART Planning Board",
  "board.program.long": "ART Planning {art}",
  "board.risk": "ART PI Risks",
  "board.risk.long": "ART PI Risk {art}",
  "board.solution": "Solution Planning Board",
  "board.solution.long": "Solution",
  "board.solutionBacklog": "Solution Backlog Board",
  "board.solutionBacklog.long": "Solution Backlog",
  "board.team": "Team Board",
  "board.team.long": "Team {team}",
  "boardBase.arrangeStickies": "Arrange stickies",
  "boardBase.arrangeStickyPrompt": "Do you really want to arrange the stickies in {boardLocation}?",
  "boardBase.reorderBlurb": "This will reorder the stickies into a grid formation. This cannot be undone!",
  "boardUsers.activeMembers": "Active Members",
  "boardUsers.moreUsers": "+{more}",
  "boardUsers.unknown": "unknown",
  "cardAction.activity": "Activity",
  "cardAction.art": "ART",
  "cardAction.delete": "Delete",
  "cardAction.depend": "Depend",
  "cardAction.link": "Link",
  "cardAction.mirror": "Mirror",
  "cardAction.move": "Move",
  "cardAction.program": "Mirror to Planning board",
  "cardAction.project": "Project",
  "cardAction.remove": "Remove",
  "cardAction.team": "Team",
  "cardLabelsOverlay.unlink": "unlink",
  "cardPointsOverlay.setOwnValue": "SET OWN VALUE",
  "connectionStatus.notWorking": "Network connection failed. Trying to reconnect...",
  "connectionStatus.refreshPage": "Refresh page",
  "contextMenu.createNew": "Create new sticky note",
  "contextMenu.drawModeDone": "Done",
  "contextMenu.drawModeOff": "Leave board configuration",
  "contextMenu.drawModeOn": "Configure board",
  "contextMenu.general": "General",
  "contextMenu.saveBoardImage": "Save board as image",
  "contextMenu.selectedStickyCount": "Selected Stickies ({selectedCount})",
  "contextMenu.synchronizeBacklog": "Synchronize Backlog Board",
  "contextMenu.synchronizeIteration": "Synchronize iteration",
  "contextMenu.zoomToRegion": "Zoom to region",
  "date.ago": "{time} ago",
  "date.hour": "1 hour | {count} hours",
  "date.in": "in {time}",
  "date.minute": "1 minute | {count} minutes",
  "date.now": "Just now",
  "date.range": "{from} - {to}",
  "date.today": "Today",
  "date.tomorrow": "Tomorrow",
  "date.yesterday": "Yesterday",
  "dependency.linked": "Linked",
  "dependency.notLinked": "Not Linked",
  "dependency.stickies": "Dependency Stickies",
  "dependencyTeamFilter.incoming": "Incoming",
  "dependencyTeamFilter.incomingAndOutgoing": "Incoming & Outgoing",
  "dependencyTeamFilter.mutual": "Mutual Links",
  "dependencyTeamFilter.outgoing": "Outgoing",
  "flagPicker.noSticker": "No sticker",
  "flexBoardElem.confirm": "Are you sure you want to delete this canvas and all sticky notes on it?",
  "flexBoardElem.delete": "DELETE",
  "flexModal.addCanvas": "Add Canvas",
  "flexModal.addCategory": "Add Category",
  "flexModal.all": "All",
  "flexModal.chooseCanvas": "Choose a canvas type to add",
  "flexModal.cockpit": "piplanning cockpit",
  "flexModal.collaborationCanvas": "Collaboration Canvases",
  "flexModal.defaultCanvasName": "Board name",
  "flexModal.deleteCategoryDialog": "Are you sure you want to delete this category? \n\n The canvases in it will still be available in the 'All' category.",
  "flexModal.dragCanvases": "Drag canvases onto Categories to organize them.",
  "flexModal.manage": "manage",
  "flexModal.newCategory": "New Category",
  "flexModal.noCanvasAsk": "There are no canvas types configured. Ask your RTE to configure them.",
  "flexModal.noCanvasConfigure": "There are no canvas types configured. Go to the {cockpit} to configure them.",
  "flexModal.noCanvasConfiguredMessage": "There are no canvases configured. Click on the add button to create a new one.",
  "general.arts": "ARTs",
  "general.cancel": "Cancel",
  "general.capacity": "Capacity",
  "general.clear": "Clear",
  "general.close": "Close",
  "general.delete": "Delete",
  "general.iterations": "Iterations",
  "general.links": "Links",
  "general.namedArt": "ART {name}",
  "general.namedIteration": "Iteration {name}",
  "general.namedTeam": "Team {name}",
  "general.noArt": "No ART",
  "general.noLinkedStickies": "No linked stickies",
  "general.noTeam": "No team",
  "general.objectives": "Objectives",
  "general.partOfTotal": "{part}/{total}",
  "general.percent": "{value}%",
  "general.selectAnotherArt": "Select another ART",
  "general.statusClasses": "Status Classes",
  "general.stickers": "Stickers",
  "general.stickyTypes": "Sticky Note Types",
  "general.teamDependencies": "Team dependencies",
  "general.teams": "Teams",
  "general.text": "Text",
  "globalError.informAdmin": "If the problem persists, please inform your administrator.",
  "globalError.somethingWrong": "Something went wrong.",
  "globalError.technicalDetails": "Technical details",
  "globalError.tryReload": "Try to {reload} the page.",
  "globalError.tryReload.reload": "reload",
  "gptPanel.title": "GPT Panel Title",
  "inactiveTimer.description": "Description",
  "inactiveTimer.duration": "Duration",
  "inactiveTimer.startTimer": "Start timer",
  "iterationSync.confirmManualUpdate": "Do you want to manually update the stickies in this iteration with the state of {almName}?",
  "iterationSync.failedSync": "Failed to sync iteration: {errorMessage}",
  "iterationSync.stickyOverwrite": "Stickies with ALM-key will be overwritten by the state of {almName}.",
  "iterationSync.updateStickies": "Update Stickies",
  "key.alt": "Alt",
  "key.backspace": "Backspace",
  "key.ctrl": "Ctrl",
  "key.enter": "Enter",
  "key.escape": "Esc",
  "key.shift": "Shift",
  "key.space": "Space",
  "license.expired": "Licenses have expired. Notify RTE",
  "license.expiredInDays": "Licenses expire in one day. Notify RTE | Licenses expire in {count} days. Notify RTE",
  "linkModal.addLink": "Add link",
  "linkModal.bv": "BV {bv}",
  "linkModal.empty": "Nothing to link",
  "linkModal.noResult": "No sticky note found",
  "linkModal.removeLink": "Remove link",
  "linkModal.stickies": "Stickies",
  "linkModal.title": "Link this Sticky Note | Link these Sticky Notes",
  "linkState.criticalLink.count": "1 Critical Link | {count} Critical Links",
  "linkState.criticalLinks": "Critical Links",
  "linkState.riskyLink.count": "1 Risky Link | {count} Risky Links",
  "linkState.riskyLinks": "Risky Links",
  "linkState.title": "Link states",
  "loadCapacityChart.load": "Load",
  "loadComponent.loading": "Loading",
  "loginPage.notLogin": "Could not login. Please try again.",
  "loginPageEmail.continue": "Continue",
  "loginPageEmail.email": "Email",
  "loginPageEmail.login": "Log in",
  "loginPageEmail.noAccount": "Don't have an account? {register}",
  "loginPageEmail.noAccount.register": "Register company.",
  "loginPageEmail.useWorkEmail": "Use your work email to log in",
  "loginPageTenants.changeEmail": "Change email",
  "loginPageTenants.selectOrganization": "Select an organization",
  "loginPageTenants.selectOrganizationsAssociated": "Organizations associated with {email}",
  "message.generateImage": "Generating image...",
  "message.keepBarPressed": "Keep space bar pressed while hovering over stickies to magnify",
  "message.lineTool": "Click & Drag to create lines on your board. You can snap the lines to the board's area lines and to lines you draw.",
  "message.onlyRTEAdminAllowed": "Only the admin user / RTE is allowed to change the sticky note size for the Planning Boards.",
  "message.selectStickyHint": "Keep Shift key pressed while hovering over stickies to select them",
  "message.singleCardMode": "While Control key is pressed single stickies can be dragged",
  "metricsModal.allIterations": "All iterations",
  "metricsModal.allTeams": "All Teams",
  "metricsModal.capacityAllocation": "Capacity Allocation",
  "metricsModal.loadVsCapacity": "Load vs. Capacity",
  "mirrorModal.title": "Mirror stickies to board",
  "mobileInfo.askIfOnDesktop": "Are you on desktop instead?",
  "mobileInfo.checkVideo": "Check out the video below to see why it is designed for desktop only",
  "mobileInfo.chooseDevice": "Choose your device",
  "mobileInfo.loginBlurb": "Click here to log in",
  "mobileInfo.loginFromDesktop": "Log in from your desktop device",
  "mobileInfo.onDesktop": "I'm on Desktop",
  "mobileInfo.onMobile": "I'm on Mobile",
  "mobileInfo.userExperienceBlurb": "For the best user experience choose the device that you are currently using",
  "modal.capacityValue": "Capacity: {capacity}",
  "modal.loadValue": "Load: {number}",
  "mouse.doubleClick": "Double click",
  "mouse.longClick": "Long click",
  "objective.markAsCommitted": "Mark as committed",
  "objective.markAsUncommitted": "Mark as uncommitted",
  "objectiveLinkModal.title": "Linked sticky notes",
  "objectives.actualValue": "Actual Value",
  "objectives.add": "Objective",
  "objectives.businessValue": "Business Value",
  "objectives.bv": "BV",
  "objectives.committed": "Committed",
  "objectives.count": "1 Objective | {count} Objectives",
  "objectives.description.placeholder": "Add a description",
  "objectives.hideDescriptionButton": "Hide Description",
  "objectives.none": "No objectives yet",
  "objectives.programObjectives": "ART PI Objectives",
  "objectives.showDescriptionButton": "Show Description",
  "objectives.title.placeholder": "Title",
  "objectives.uncommitted": "Uncommitted",
  "objectivesBoard.objectives": "{name} PI Objectives",
  "objectivesModal.add": "Add",
  "objectivesModal.linkedStickies": "Linked stickies",
  "objectivesModal.linkedStickiesNumber": "1 linked sticky note | {count} linked sticky notes",
  "overviewModal.title": "Overview",
  "page.art": "Select Art",
  "page.backToSessions": "Back to sessions",
  "page.login": "Login",
  "page.session": "Select Session",
  "page.team": "Select Team",
  "planReadout.title": "Plan Readout",
  "planReadoutModal.listFooter.objectives": "Use {icons} to skip through objectives",
  "planReadoutModal.listFooter.risks": "Use {icons} to skip through risks",
  "planReadoutModal.objectives.businessValue": "Business Value: {num}",
  "planReadoutModal.objectives.indexPlaceholder": "#{index}",
  "planReadoutModal.objectives.noDescription": "No description",
  "planReadoutModal.objectives.title": "{teamName} Objectives",
  "planReadoutModal.risks": "Risks",
  "planReadoutModal.risks.count": "1 Risk | {count} Risks",
  "planReadoutModal.risks.title": "{teamName} Risks",
  "programBoard.inPlanning": "In Planning",
  "programBoard.milestoneEvents": "Milestones Events",
  "programBoard.otherArts": "Other ARTs",
  "reaction.with": "Reacted with {reaction}",
  "risk.accepted": "Accepted",
  "risk.accepted.short": "A",
  "risk.mitigated": "Mitigated",
  "risk.mitigated.short": "M",
  "risk.owned": "Owned",
  "risk.owned.short": "O",
  "risk.resolved": "Resolved",
  "risk.resolved.short": "R",
  "risk.roam": "ROAM",
  "risk.unRoam": "unROAM",
  "risk.unRoam.short": " ",
  "riskBoardLocation.submissions": "Risk Submissions",
  "searchItem.closeSearch": "Close Search",
  "searchItem.hideSearch": "Keep Filters & Close",
  "searchResults.noResults": "No results found",
  "searchResults.resultsBoards": "Matching results on other boards",
  "searchResultsTs.results": "1 result | {count} results",
  "searchResultsTs.stickies": "1 sticky note | {count} sticky notes",
  "sessionPage.authid": "Authid: {authid}",
  "sessionPage.licenseExpired": "Your company licenses have expired. Please reach out to your administrator.",
  "sessionPage.piPlannings": "{safe} PIs",
  "sessionPage.select": "Select an interval, for planning and execution",
  "sessionPage.showArchived": "Show archived sessions",
  "settings.boardsTitle": "Boards",
  "settings.fontScaleDescription": "The font size of the text on sticky notes will scale up as much as possible",
  "settings.fontType": "Font type used for sticky note text",
  "settings.generalTitle": "General",
  "settings.lineupDistanceDescription": "When using the line up multiple stickies function, stickies will have this distance from each other.",
  "settings.lineupDistanceTitle": "Sticky note line up distance (%)",
  "settings.linkLinesDescription": "Normally, they are only shown for active / highlighted stickies",
  "settings.linkLinesSubtitle": "Show link lines permanently on team / canvases / risk board",
  "settings.linkProgramLinesSubtitle": "Show link lines permanently on ART Planning Board",
  "settings.loadWarningDescription": "Set the load warning percentage to mark the iteration when the load is more than the given percentage of the capacity",
  "settings.loadWarningTitle": "Load warning percentage (%)",
  "settings.markIterationsWithOverload": "Mark iterations with overload on the ART Planning Board",
  "settings.mouseWheelDescription": "Zoom with mouse wheel without pressing the control key",
  "settings.mouseWheelTitle": "Mouse Wheel Zooming",
  "settings.overloadOnProgramBoard": "Overload on ART Planning Board",
  "settings.scaleUpStickyFont": "Scale up sticky note text font",
  "settings.settings": "Settings",
  "settings.stickyNotesDescription": "You can add handwriting feeling to your stickies! ☺",
  "settings.stickyNotesTitle": "Sticky Notes",
  "shortcutsModal.actionHoverOverSticky": "{action} hover over sticky note",
  "shortcutsModal.actionOnBoard": "{action} on board",
  "shortcutsModal.actionOnRegion": "{action} on region",
  "shortcutsModal.actionOnSticky": "{action} on sticky note",
  "shortcutsModal.addingProgramBoardLink": "Adding ART Planning Board link:",
  "shortcutsModal.addingRiskBoardLink": "Adding Risk Board link:",
  "shortcutsModal.addingTeamBoardLink": "Adding Team Board link:",
  "shortcutsModal.alternative": "{action1} or {action2}",
  "shortcutsModal.backToLastBoard": "Back to last Board:",
  "shortcutsModal.backlogBoard": "Backlog Board:",
  "shortcutsModal.canvasName": "canvas-name",
  "shortcutsModal.closeMenu": "Close menu on all stickies:",
  "shortcutsModal.collaborationCanvas": "Collaboration Canvas:",
  "shortcutsModal.detailsOfBoardRegion": "Details of a board region:",
  "shortcutsModal.dragAroundBoard": "Drag around the board",
  "shortcutsModal.duplicateSticky": "Duplicate sticky note",
  "shortcutsModal.emojiId": "emoji-id",
  "shortcutsModal.emojis": "Emoji (format):",
  "shortcutsModal.exit": "Exit:",
  "shortcutsModal.focusSticky": "Focus sticky note:",
  "shortcutsModal.help.title": "Help",
  "shortcutsModal.highlightSticky": "Highlight sticky note and links:",
  "shortcutsModal.link": "Adding URL link:",
  "shortcutsModal.linkArtLink": "Adding ART PI Objectives link:",
  "shortcutsModal.linkToBacklogBoard": "Adding Backlog Board link:",
  "shortcutsModal.linkToCanvas": "Adding Canvas link:",
  "shortcutsModal.magnifierMode": "Magnifier mode:",
  "shortcutsModal.moreGestures": "More gestures",
  "shortcutsModal.mouseGestures": "Mouse gestures",
  "shortcutsModal.newLine": "New line:",
  "shortcutsModal.newSticky": "New sticky note:",
  "shortcutsModal.newStickyObjective": "New sticky note / objective:",
  "shortcutsModal.nextCanvas": "Previous / Next Canvas",
  "shortcutsModal.objectivesBoard": "ART PI Objectives:",
  "shortcutsModal.pointer": "Pointer  ",
  "shortcutsModal.pointerMode": "Pointer mode",
  "shortcutsModal.pressing": "Pressing {key}",
  "shortcutsModal.previousBoard": "Previous / Next Team Board:",
  "shortcutsModal.programBoard": "ART Planning Board:",
  "shortcutsModal.riskBoard": "Risk Board:",
  "shortcutsModal.screenshot": "Screenshot",
  "shortcutsModal.scroll": "Scroll:",
  "shortcutsModal.search": "Search:",
  "shortcutsModal.searchBySticker": "Search by sticker",
  "shortcutsModal.searchByStickyType": "Search by sticky note type:",
  "shortcutsModal.searchLast": "Previous:",
  "shortcutsModal.searchNext": "Next:",
  "shortcutsModal.selectMultiple": "Select multiple stickies:",
  "shortcutsModal.settings": "Settings:",
  "shortcutsModal.showStickyProgress": "Show / Hide sticky note progress:",
  "shortcutsModal.tab.board": "Board",
  "shortcutsModal.tab.navigation": "Navigation",
  "shortcutsModal.tab.search": "Search",
  "shortcutsModal.tab.stickies": "Sticky Notes",
  "shortcutsModal.tab.tools": "Tools",
  "shortcutsModal.teamBoard": "Team Board:",
  "shortcutsModal.teamName": "team-name",
  "shortcutsModal.textStarting": "Any URL starting with",
  "shortcutsModal.timer": "Timer",
  "statusDistribution.emptyIteration": "No work items in this iteration.",
  "statusDistribution.emptyObjective": "No work items are linked to this objective.",
  "statusDistribution.emptySticky": "No work items are linked to this sticky note.",
  "statusDistribution.iteration": "Day {day} of {total} in iteration",
  "statusDistribution.iteration.ended": "Iteration has ended",
  "statusDistribution.iteration.notStarted": "Iteration has not started",
  "statusDistribution.status": "Status",
  "statusDistribution.storyPoints": "Story Points",
  "statusDistribution.today": "Today: {today}",
  "statusDistribution.workItems": "Work items",
  "stickersItem.selected": "Selected",
  "stickyChange.addReaction": "{user} reacted to this sticky note",
  "stickyChange.created": "{user} created this sticky note",
  "stickyChange.deleted": "{user} deleted this sticky note",
  "stickyChange.dependsOn": "{user} updated the dependent-on team",
  "stickyChange.dependsOn.new": "{user} set the dependent-on team",
  "stickyChange.empty": "This sticky note's activities are not available.",
  "stickyChange.flag": "{user} changed the sticker",
  "stickyChange.flag.new": "{user} set the sticker",
  "stickyChange.incomplete": "Older activities are not available.",
  "stickyChange.iteration": "{user} changed the iteration",
  "stickyChange.iteration.new": "{user} set the iteration",
  "stickyChange.link": "{user} added a link",
  "stickyChange.linkedStickyNotFound": "The linked sticky note could not be found - it may have been deleted.",
  "stickyChange.mirrored": "{user} mirrored this sticky note",
  "stickyChange.removeReaction": "{user} removed the reaction",
  "stickyChange.risk": "{user} ROAMed this sticky note",
  "stickyChange.risk.new": "{user} ROAMed this sticky note",
  "stickyChange.stickyType": "{user} changed the sticky note type",
  "stickyChange.stickyType.new": "{user} set the sticky note type",
  "stickyChange.storyPoints": "{user} changed the story points",
  "stickyChange.storyPoints.new": "{user} set the story points",
  "stickyChange.summary": "{user} changed the summary",
  "stickyChange.summary.new": "{user} added the summary",
  "stickyChange.team": "{user} changed the team",
  "stickyChange.team.new": "{user} set the team",
  "stickyChange.unknownUser": "Unknown User",
  "stickyChange.unlink": "{user} removed a link",
  "stickyChange.unmirrored": "{user} removed this sticky note from",
  "stickyChange.updated": "{user} changed this sticky note",
  "stickyChange.wsjf": "{user} changed the WSJF value",
  "stickyChange.wsjf.new": "{user} set the WSJF value",
  "stickyStatus.status": "Status",
  "stickyType.unknown": "Unknown",
  "teamBoardLocation.risks": "Risks",
  "teamModal.title": "Assign stickies to team",
  "teamObjectives.teamObjectives": "PI Objectives",
  "teamObjectivesModal.objectives": "{teamName} Objectives",
  "teamPage.backToARTs": "Back to ARTs",
  "teamPage.selectTeam": "Select a team",
  "textarea.lengthIndicator": "{textLength} / {maxLength}",
  "textarea.seeLess": "See less",
  "textarea.seeMore": "See more",
  "timer.add": "+ Add",
  "timer.addMinute": "+{m}m",
  "timer.longDuration": "{h}h {m}m {s}s",
  "timer.missingDescription": "Please set a description",
  "timer.missingDuration": "Please set a duration",
  "timer.nameLabel": "Name",
  "timer.shortDuration": "{m}m {s}s",
  "timer.timerMessage": "Time is up!",
  "timer.title": "Timer",
  "userAction.boardSettings": "Board settings",
  "userAction.documentation": "Documentation",
  "userAction.gettingStarted": "Getting started in 20 minutes",
  "userAction.gotoProfileSync": "Go to profile",
  "userAction.keyboardShortcuts": "Keyboard shortcuts",
  "userAction.logOut": "Log Out",
  "userAction.mainMenu": "Main Menu",
  "userAction.profile": "Profile",
  "userAction.releaseNotes": "Release notes",
  "userAction.rteCockpit": "RTE Cockpit",
  "userAction.tips": "Tips for this canvas",
  "userAction.userNotSynced": "Your piplanning.io account is currently not linked with your {almType} account. Go to your profile to connect them.",
  "userAgreement.base": "By continuing, I agree to Scaled Agile, Inc's {termsOfService} and {privacyPolicy}.",
  "userAgreement.privacyPolicy": "Privacy Policy",
  "userAgreement.termsOfService": "Terms of Service",
  "version.info": "Version: {id} {hash} {time} UTC"
}
