<template>
  <div class="avatar">
    <slot>
      <span v-if="text" :style="{ backgroundColor: color }">{{ text }}</span>
    </slot>
  </div>
</template>

<script lang="ts">
export default {
  name: "AvatarComponent",
  props: {
    text: {
      type: String,
      default: "",
      validator: (value: string) => {
        return value.length <= 2;
      },
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  display: inline-flex;

  :deep(img, span) {
    border-radius: 50%;
    height: 48px;
    width: 48px;
  }

  :deep(span) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
  }
}
</style>
