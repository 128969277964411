<script setup lang="ts">
import { useBoardStore } from "@/store/board";

import { ItemKey, SearchResultGroupItem } from "./SearchResult";
import SearchResultsItem from "./SearchResultsItem.vue";

const emit = defineEmits<{
  select: [item: ItemKey];
  pointerenter: [item: SearchResultGroupItem];
  pointerleave: [item: SearchResultGroupItem];
}>();

const props = defineProps<{
  results: SearchResultGroupItem[];
  selected?: ItemKey;
  current: ItemKey | null;
  boardId: string;
}>();

function isCurrentBoard(boardId: string) {
  return useBoardStore().boardId() === boardId;
}

function isSelected(item: SearchResultGroupItem) {
  return (
    props.selected &&
    item.id === props.selected.id &&
    item.teamId === props.selected.teamId
  );
}

function isHighlighted(item: SearchResultGroupItem) {
  return (
    props.current &&
    item.id === props.current.id &&
    item.teamId === props.current.teamId
  );
}

function select(item: SearchResultGroupItem) {
  const otherBoardId = isCurrentBoard(props.boardId)
    ? undefined
    : props.boardId;
  emit("select", {
    id: item.id,
    boardId: otherBoardId,
    teamId: item.teamId,
  });
}
</script>

<template>
  <div>
    <SearchResultsItem
      v-for="item in results"
      :id="`result-${item.id}`"
      :key="item.id"
      :class="{
        selected: isSelected(item),
        current: isHighlighted(item),
      }"
      :item="item"
      @click.stop="select(item)"
      @pointerenter="emit('pointerenter', item)"
      @pointerleave="emit('pointerleave', item)"
    >
    </SearchResultsItem>
  </div>
</template>
