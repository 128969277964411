import { Color } from "@/baseTypes";
import { PieData } from "@/components/PieChart";
import { Board } from "@/model";

export interface CapacityAllocation {
  total: number;
  values: PieData[];
}

export function calcCapacityAllocation(
  boards: Board[],
  iteration?: number,
): CapacityAllocation {
  const loads: { [id: string]: { value: number; color: Color } } = {};
  let total = 0;
  for (const board of boards) {
    for (const id in board.cards) {
      const card = board.cards[id].data;
      if (iteration === undefined || card.iterationId === iteration) {
        const type = card.type;
        if (!loads[type.name]) {
          loads[type.name] = { value: 0, color: type.color };
        }
        const value = card.points;
        loads[type.name].value += value;
        total += value;
      }
    }
  }
  const data = [];
  for (const id in loads) {
    data.push({ name: id, value: loads[id].value, color: loads[id].color });
  }
  data.sort((a, b) => b.value - a.value);
  return { total, values: data };
}
