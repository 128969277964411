<script setup lang="ts">
import { computed } from "vue";

import { maxVisibleUsersOnBoard } from "@/Settings";
import UserAvatar from "@/components/UserAvatar.vue";
import MenuItemDelimiter from "@/components/menu/components/MenuItemDelimiter.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import { useUserStore } from "@/store/user";
import { useUsersOnBoardStore } from "@/store/usersOnBoard";

const allUsers = computed(() => useUsersOnBoardStore().usersOnBoard);
const users = computed(() =>
  allUsers.value
    .filter((user) => user.id !== useUserStore().technicalUser.id)
    .slice(0, maxVisibleUsersOnBoard),
);
const more = computed(() => allUsers.value.length - maxVisibleUsersOnBoard);
</script>

<template>
  <div v-if="users.length > 0" id="board-users" data-no-screenshot>
    <BaseTooltip position="bottom" class="tooltip" interactive>
      <div data-testid="board-users-preview" class="users-preview">
        <UserAvatar
          v-for="user in users"
          :key="user.id"
          user-color
          :user="user"
          class="small"
        />
        <span v-if="more > 0" class="more">
          {{ $t("boardUsers.moreUsers", { more }) }}
        </span>
      </div>
      <template #content>
        <div class="users-list">
          <h3>{{ $t("boardUsers.activeMembers") }}</h3>
          <ul class="scrollable scrollable-relative">
            <li v-for="user in allUsers" :key="user.id">
              <UserAvatar user-color :user="user" class="small" />
              {{ user.name || $t("boardUsers.unknown") }}
            </li>
          </ul>
        </div>
      </template>
    </BaseTooltip>
    <MenuItemDelimiter />
  </div>
</template>

<style lang="scss">
@use "@/styles/tooltip-users-list";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

#board-users {
  display: flex;
  flex-direction: row;
  align-items: center;

  .tooltip {
    display: flex;
    height: 40px;
  }

  .avatar,
  .more {
    margin-left: -7px;
    outline: 2px solid var(--back-color);
  }

  .user-avatar:first-child .avatar {
    margin-left: 0;
  }

  .more {
    border-radius: 100%;
    background-color: colors-old.$text-secondary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    color: colors-old.$back-color;
    width: 24px;
    height: 24px;
    font-size: 12px;
  }

  .users-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .user-avatar {
      &.small {
        .avatar {
          span,
          img {
            width: 24px;
            height: 24px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
