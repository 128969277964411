import { Directive } from "vue";

/**
 * Autofocuses to the element on mount and sets tabindex=0
 */
export function autofocus(): Directive<HTMLElement> {
  return {
    mounted(el) {
      el.tabIndex = el.tabIndex ?? 0;
      el.focus();
    },
  };
}
