<script setup lang="ts">
import CardRow from "@/components/CardRow.vue";
import { LinkableCard } from "@/components/modal/stickyLinkStickies";
import IconButton from "@/components/ui/IconButton/IconButton.vue";

defineProps<{
  linkableCard: LinkableCard;
  resetButton?: boolean;
  showTeamName?: boolean;
  showStatus?: boolean;
  showActions?: boolean;
}>();
const emit = defineEmits<{
  click: [event: Event];
  setLinkedState: [linked: boolean];
}>();

function click() {
  emit("setLinkedState", true);
}
</script>

<template>
  <div class="linkable-card-row" @click="emit('click', $event)">
    <CardRow
      :card="linkableCard"
      :show-team-name="showTeamName"
      :show-status="showStatus"
    />
    <div v-if="showActions">
      <IconButton
        v-if="linkableCard.linked"
        variant="filled"
        color="primary"
        icon="unlink"
        :tooltip="$t('linkModal.removeLink')"
        @click="click"
      />
      <IconButton
        v-else
        icon="link"
        :tooltip="$t('linkModal.addLink')"
        @click="click"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.linkable-card-row {
  display: flex;
  justify-content: space-between;
  gap: 1.125rem;
  width: 100%;
  align-items: center;
  cursor: pointer;
}
</style>
