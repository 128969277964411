<script setup lang="ts">
import { computed, onMounted } from "vue";

import RevealHeightTransition from "@/components-ng/transitions/RevealHeightTransition.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import TypeDot from "@/components/TypeDot.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import SidePanelRight from "@/components/menu/SidePanelRight.vue";
import { StickyChange } from "@/model";
import { useActivityStore } from "@/store/activity";
import { useBoardStore } from "@/store/board";
import { mirrorOriginId } from "@/store/card";
import {
  formatTimestampFriendly,
  relativeDate,
  relativeTime,
} from "@/utils/dateFormat";

import BaseTooltip from "../ui/BaseTooltip/BaseTooltip.vue";
import { stickyChanges } from "./stickyChanges";

const open = computed(() => useActivityStore().active);
const card = computed(() => useActivityStore().card);

onMounted(() =>
  // Close this panel when the Card loses focus
  useBoardStore().$subscribe((_mutation, state) => {
    if (open.value && state.activeCardId !== card.value?.id) {
      close();
    }
  }),
);

function close() {
  useActivityStore().inactivate();
}

function action(change: StickyChange) {
  switch (change.kind) {
    case "create":
      return /*$t*/ "stickyChange.created";
    case "mirror":
      return /*$t*/ "stickyChange.mirrored";
    case "unmirror":
      return /*$t*/ "stickyChange.unmirrored";
    case "link":
      return /*$t*/ "stickyChange.link";
    case "unlink":
      return /*$t*/ "stickyChange.unlink";
    case "update":
      // Handle the special case where the dependent-on team was set -- here,
      // the sticky is automatically flagged at the same time, so there are
      // multiple updates, but we want to use the dependent-on-team label
      // so it's clear to the user what happened
      if (change.fields.find((field) => field.name === "dependTeamId")) {
        return /*$t*/ stickyChanges["dependTeamId"].keyNew;
      }
      if (change.fields.length > 1) {
        return /*$t*/ "stickyChange.updated";
      }
      if (change.fields[0].name === "reactions") {
        return change.fields[0].new
          ? /*$t*/ "stickyChange.addReaction"
          : /*$t*/ "stickyChange.removeReaction";
      }
      // If the value was previously undefined, use a slightly different label
      if ((change.fields[0].old ?? null) === null) {
        return stickyChanges[change.fields[0].name].keyNew;
      }
      return stickyChanges[change.fields[0].name].key;
    case "delete":
      return /*$t*/ "stickyChange.deleted";
  }
}
</script>

<template>
  <div class="activity-panel" @scroll.stop>
    <transition>
      <SidePanelRight
        v-if="open"
        icon="action-menu/activity"
        :title="$t('activity.title')"
        @close="close"
      >
        <template v-if="card" #sub-title>
          <TypeDot size="medium" :color="card.type.color" />
          <span class="text">{{ card.text }}</span>
          <SvgIcon v-if="!!mirrorOriginId(card)" name="mirror" size="20" />
        </template>
        <div
          v-for="entry in useActivityStore().stickyChangesByDay"
          :key="entry.date.getTime()"
          class="group"
        >
          <span class="day">{{ relativeDate(entry.date) }}</span>
          <div
            v-for="change in entry.stickyChanges"
            :key="change.timestamp.getTime()"
            class="sticky-change"
            :class="{ action: change.expandable }"
            @click="change.expandable && (change.expanded = !change.expanded)"
          >
            <UserAvatar :user="change.user" user-color class="small smaller" />
            <div class="change-content">
              <div class="desc">
                <i18n-t :keypath="action(change)" scope="global" tag="span">
                  <template #user>
                    <span class="user">{{ change.user.name }}</span>
                  </template>
                </i18n-t>
              </div>
              <div>
                <BaseTooltip position="bottom" tag="span">
                  <span class="timestamp">
                    {{ relativeTime(change.timestamp) }}
                  </span>
                  <template #content>
                    {{ formatTimestampFriendly(change.timestamp) }}
                  </template>
                </BaseTooltip>
              </div>
              <RevealHeightTransition>
                <div v-if="change.expanded" class="change-details" @click.stop>
                  <div
                    v-for="field in change.fields"
                    :key="field.name"
                    class="field"
                  >
                    <Component
                      :is="stickyChanges[field.name].component"
                      :field="field"
                    />
                  </div>
                </div>
              </RevealHeightTransition>
            </div>
            <SvgIcon
              v-if="change.expandable"
              name="arrow/down"
              size="16"
              class="toggle-icon"
              :class="{ expanded: change.expanded }"
            />
          </div>
        </div>
        <div v-if="useActivityStore().stickyChanges.length === 0" class="group">
          {{ $t("stickyChange.empty") }}
        </div>
        <div v-else-if="useActivityStore().incomplete" class="group">
          {{ $t("stickyChange.incomplete") }}
        </div>
      </SidePanelRight>
    </transition>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.activity-panel {
  .side-panel-right .panel-icon {
    color: colors-old.$text-subtle-color;
  }

  .text {
    color: colors-old.$text-subtle-color;
    font-weight: font.$weight-medium;
    overflow-wrap: anywhere;
  }

  .group {
    padding: 0 1em;
    margin-top: 2.5em;
    margin-bottom: 1.5em;

    .day {
      font-weight: font.$weight-bold;
    }

    .sticky-change {
      margin-top: 1.5em;
      display: flex;
      gap: 8px;

      .change-content {
        min-width: 0;
        flex-grow: 1;

        .desc {
          color: colors-old.$text-subtle-color;
          font-size: font.$size-normal;

          .user {
            color: colors-old.$text-primary-color;
            font-weight: font.$weight-semi-bold;
          }
        }

        .timestamp {
          display: inline-block;
          padding-top: 4px;
          color: colors-old.$text-subtle-color;
        }

        .change-details {
          cursor: auto;
          user-select: text;
        }

        .field {
          display: flex;
          width: 100%;

          .change-value {
            background-color: colors-old.$light-background-color;
            padding: 4px;
            border-radius: 4px;
            min-width: 0;
          }

          .change {
            margin: 0.5em 0;
            min-width: 0;

            &.horizontal {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              gap: 0.5em;
            }

            .old {
              color: colors-old.$text-subtle-color;
            }

            &.change-value {
              padding: 12px;
            }

            .change-value {
              display: inline-flex;
              align-items: center;
              gap: 0.5em;
            }
          }
        }
      }

      .toggle-icon {
        color: colors-old.$text-subtle-color;
        flex-shrink: 0;
        transform: rotate(0deg);
        transition: transform 0.25s;

        &.expanded {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
</style>
