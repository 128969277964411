import { defineStore } from "pinia";

type MenuPosition = "right" | "left";

interface State {
  defaultPosition: MenuPosition;
  position: MenuPosition;
  disabledShiftButton: boolean;
}

export function togglePosition(position: string) {
  return position === "left" ? "right" : "left";
}

export const useBoardMenuStore = defineStore("boardMenu", {
  state: (): State => {
    return {
      defaultPosition: "left",
      position: "left",
      disabledShiftButton: false,
    };
  },
  actions: {
    toggleMenuPosition() {
      this.position = togglePosition(this.position);
      this.defaultPosition = togglePosition(this.defaultPosition);
    },
    setMenuPosition(position: MenuPosition | "default") {
      if (position === "default") {
        this.position = this.defaultPosition;
        this.disabledShiftButton = false;
      } else {
        this.position = position;
        this.disabledShiftButton = true;
      }
    },
  },
});
