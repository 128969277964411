<script setup lang="ts">
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { FieldChange } from "@/model";
import { useIterationStore } from "@/store/iteration";

defineProps<{ field: FieldChange }>();

function iterationName(id?: number) {
  return id !== null
    ? useIterationStore().iterations.find((iter) => iter.id === id)!.name
    : "";
}
</script>

<template>
  <div class="change horizontal">
    <template v-if="field.old !== null">
      <span class="change-value">
        <SvgIcon name="iteration" size="20" />
        {{ iterationName(field.old) }}
      </span>
      <SvgIcon name="arrow/right-thin" size="20" />
    </template>
    <template v-if="field.new !== null">
      <span class="change-value">
        <SvgIcon name="iteration" size="20" />
        {{ iterationName(field.new) }}
      </span>
    </template>
  </div>
</template>
