<template>
  <div class="overlay message input" @click.stop="emit">
    {{ message }}
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({ emits: ["select"] })
export default class CardMessageOverlay extends Vue {
  @Prop(String) readonly message!: string;

  emit() {
    this.$emit("select");
  }
}
</script>

<style lang="scss">
.overlay.message {
  overflow: hidden;
  font-size: 200%;
  padding: 1em;
}
</style>
