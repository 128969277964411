// Execute `action` `executionsPerPeriod` times during `period` milliseconds.
// If the sum of the returned values is bigger than `maxSumPerPeriod` the execution frequency is reduced.
export class Throttle {
  private values: Array<number>;
  private index = 0;
  private total = 0;
  private wait = 0;

  constructor(
    private period: number,
    private executionsPerPeriod: number,
    private maxSumPerPeriod: number,
    private action: () => number,
  ) {
    this.values = new Array<number>(executionsPerPeriod);
    this.run();
  }

  run() {
    this.total -= this.values[this.index] || 0;
    let value = 0;
    if (this.wait > 0) {
      this.wait--;
    } else {
      value = this.action();
      this.wait = Math.floor((this.total + value) / this.maxSumPerPeriod);
    }
    this.total += this.values[this.index] = value;
    this.index = (this.index + 1) % this.executionsPerPeriod;
    setTimeout(() => this.run(), this.period / this.executionsPerPeriod);
  }
}
