<script setup lang="ts">
import { computed } from "vue";

import { boardActions } from "@/action/boardActions";
import { cardsInLocation } from "@/components/BoardLocation";
import ConfirmModal from "@/components/modal/ConfirmModal.vue";
import { useBoardStore } from "@/store/board";
import { useModalStore } from "@/store/modal";

const props = defineProps<{ boardId: string; location: string[] }>();

const loc = computed(() =>
  useBoardStore().boardLocationFromIndex(props.location),
);

function arrange() {
  useModalStore().hide();
  const cards = cardsInLocation(useBoardStore().currentBoard(), loc.value);
  boardActions.arrange(
    "contextMenu",
    useBoardStore().boardById(props.boardId),
    cards,
    loc.value.bounds,
  );
}
</script>

<template>
  <ConfirmModal
    :height="420"
    :question="$t('boardBase.arrangeStickyPrompt', { boardLocation: loc.name })"
    :subtext="$t('boardBase.reorderBlurb')"
    :action-label="$t('boardBase.arrangeStickies')"
    :state="{ status: 'arrange' }"
    :action="arrange"
  />
</template>
