<script setup lang="ts">
import StatusDot from "@/components/StatusDot.vue";
import TypeDot from "@/components/TypeDot.vue";
import BaseFlag from "@/components/card/BaseFlag.vue";
import CardFlag from "@/components/card/CardFlag";

import { SearchResultGroupItem } from "./SearchResult";

defineProps<{ item?: SearchResultGroupItem; showStatus?: boolean }>();

function initCardFlag(flag: string): CardFlag {
  return CardFlag.fromFlagString(flag);
}
</script>

<template>
  <div v-if="item" class="result-card item">
    <div>
      <TypeDot size="large" :color="item.color" />
    </div>
    <div class="result-text">
      <div class="main">{{ item.text }}</div>
      <div class="details">
        <div v-if="item.teamName">{{ item.teamName }}</div>
        <div class="shrinkable">{{ item.type }}</div>
        <div v-if="item.almId">{{ item.almId }}</div>
        <div v-if="item.flag">
          <div class="flag-container">
            <BaseFlag :flag="initCardFlag(item.flag)" />
          </div>
        </div>
        <div
          v-if="showStatus && item.status"
          class="status-dot-container"
          data-testid="status"
        >
          <StatusDot :status-class="item.status.statusClass" radar-type />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.result-text {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  overflow: hidden;

  .main {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: initial;
    font-size: font.$size-normal;
  }

  .details {
    display: flex;
    color: colors-old.$text-secondary-color;
    white-space: nowrap;
    align-items: center;
    gap: 0.125rem;
    vertical-align: center;
    height: 1rem;
    font-size: font.$size-small;

    .shrinkable {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    div:not(:last-child)::after,
    span:not(:last-child)::after {
      content: "\00a0|\00a0";
      color: colors-old.$divider-color;
      padding-left: 0.125rem;
    }

    .flag-container {
      height: 1rem;
      width: 1rem;
      display: inline-block;
      vertical-align: bottom;
    }

    .flag-icon {
      height: 1rem;
      width: 1rem;
      padding: 0;
    }

    & > div {
      display: flex;
      align-items: center;
    }

    .status-dot-container {
      align-content: center;
      height: 1rem;
      width: 1rem;
    }
  }
}

.result-card {
  display: flex;
  align-items: center;
  padding: 10px 12px;

  &.current {
    background-color: colors-old.$light-background-color;

    .result-text {
      .details {
        div:not(:last-child)::after,
        span:not(:last-child)::after {
          content: "\00a0|\00a0";
          color: colors-old.$text-secondary-color;
        }
      }
    }
  }

  .card-icon {
    width: 2rem;
    height: 2rem;
  }
}
</style>
