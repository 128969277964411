import { noop } from "lodash-es";
import { nextTick } from "vue";

import { namedKey } from "@/Shortcuts";
import { action, defineActions } from "@/action/actions";
import { boardActions } from "@/action/boardActions";
import { almSync, getStickyChanges } from "@/backend/Backend";
import { sender } from "@/backend/Sender";
import { AlmSourceId, RiskType } from "@/baseTypes";
import CardCreationMenu from "@/components/card/CardCreationMenu.vue";
import CardFlag from "@/components/card/CardFlag";
import { findFreePosition } from "@/layout";
import { relativeToWindow, windowToRelative } from "@/math/coordinate-systems";
import {
  RelativeCoordinate,
  WindowCoordinate,
  centerCoord,
  minus,
  plus,
  scrollCoord,
  times,
} from "@/math/coordinates";
import { sendCardAction } from "@/mixins/EventBusUser";
import { Board, BoardCard, Reaction, StickyType } from "@/model";
import { useActivityStore } from "@/store/activity";
import { useAlmItemTypeStore } from "@/store/almItemType";
import { useBoardStore } from "@/store/board";
import { CardEvent, mirrorOriginId, useCardStore } from "@/store/card";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useContextMenuStore } from "@/store/contextMenu";
import { useSelectionStore } from "@/store/selection";
import { NO_TEAM_ID, useTeamStore } from "@/store/team";
import { useUserStore } from "@/store/user";
import { useZoomStore } from "@/store/zoom";
import { removeNonPrintable } from "@/utils/general";

export const cardActions = defineActions("card", {
  add: action(startAdd, { shortcut: namedKey("KeyN") }),
  togglePalette: action(noop, {
    icon: "add-sticky",
    name: /*$t*/ "action.addSticky",
    shortcut: namedKey("KeyN"),
  }),
  copy: action(noop),
  paste: action((data: Partial<CardCreateProps>) => startAdd(data)),
  pasteMultiple: action((data: CardCreateProps[]) => addMultiple(data), {
    name: /*$t*/ "action.paste",
  }),
  pasteText: action((text: string) => startAdd({ text })),
  duplicate: action(
    async () => {
      const cards = useBoardStore().selectedOrActiveCards;
      const cardSize = useBoardStore().currentBoard().cardSize;
      const step = times(cardSize, 0.4);
      if (cards.length === 1) {
        startAdd(cardCreateProps(cards[0], step, { findFreePos: true }));
      } else {
        const ids = await addMultiple(
          cards.map((card) => cardCreateProps(card, step)),
        );
        useSelectionStore().duplicated(ids);
      }
    },
    {
      name: /*$t*/ "action.duplicate",
      shortcut: namedKey("KeyD", { modifiers: ["altCtrl"] }),
    },
  ),
  delete: action((id: string, boardId: string) => {
    if (useCardStore().delete({ id, boardId })) {
      sender.deleteCard(boardId, id);
    }
  }),

  mirror: action((id: string, teamId: string | null, board: Board) => {
    if (!teamId && board.type === "team") {
      cardActions.setTeam("internal", id, board.team.id);
    }
    return sender.mirror(id, board.id);
  }),

  move: action((id: string, teamId: string) => sender.move(id, teamId)),

  toProgram: action((id: string) =>
    sender.mirror(id, useBoardStore().boardByType("program").id),
  ),

  toSolution: action((id: string) =>
    sender.mirror(id, useBoardStore().boardByType("solution").id),
  ),

  toRisk: action((id: string, teamId: string) => {
    const boardId = useBoardStore().boardByType("risk", {
      artId: useTeamStore().team?.current?.artId,
    }).id;
    const card = useCardStore().cards[id];
    sender.toRisk(id, boardId, card.text, card.type.id, teamId);
  }),

  toggleReaction: action((cardId: string, reaction: Reaction) => {
    if (useCardStore().hasCurrentUserReaction(cardId, reaction)) {
      sender.removeReaction(cardId, reaction);
    } else {
      sender.addReaction(cardId, reaction);
    }
  }),

  startAlter: action((id: string) =>
    sender.startAlterCard(useBoardStore().currentBoard().id, id),
  ),

  stopAlter: action((id: string) => {
    const card = useBoardStore().currentBoard().cards[id];
    // could have just been deleted
    // don't stop alter when card is selected
    if (card && !useBoardStore().board?.selected[id]) {
      sender.stopAlterCard(useBoardStore().currentBoard().id, id, {
        type: card.data.type,
        x: card.meta.pos.x,
        y: card.meta.pos.y,
      });
    }
  }),

  setAlmSource: action((id: string, almSourceId: AlmSourceId | null) => {
    useCardStore().setAlmSource({ id, almSourceId });
    sender.alterCard(useBoardStore().currentBoard().id, id, { almSourceId });
  }),

  setPriority: action((id: string, priority: number, priorities?: boolean) => {
    const { use, priority: prio } = useCardStore().setPriority({
      id,
      priority,
      priorities,
    });
    sender.alterCard(
      useBoardStore().currentBoard().id,
      id,
      { id, priority: prio },
      use,
    );
  }),

  setPoints: action((id: string, points: number) => {
    useCardStore().setPoints({ id, points });
    sender.alterCard(useBoardStore().currentBoard().id, id, { points });
  }),

  setFlag: action((id: string, flagType: CardFlag) => {
    useCardStore().setFlag({ id, flagType });
    sender.alterCard(useBoardStore().currentBoard().id, id, { flagType });
  }),

  setRisk: action((id: string, risk: RiskType) => {
    useCardStore().setRisk({ id, risk });
    sender.stopAlterCard(useBoardStore().currentBoard().id, id, { risk });
  }),

  setText: action(
    (id: string, text: string) => {
      useCardStore().setText({ id, text });
      sender.alterCard(useBoardStore().currentBoard().id, id, { text });
    },
    {
      history: {
        merge: true,
        saveState: (id) => ({
          id,
          text: useCardStore().cards[id].text,
        }),
      },
    },
  ),

  setIteration: action((id: string, iterationId: number | null) => {
    if (useCardStore().setIteration({ id, iterationId })) {
      sender.alterCard(useBoardStore().currentBoard().id, id, { iterationId });
    }
  }),

  setTeam: action((id: string, teamId: string | null) => {
    useCardStore().setTeam({ id, teamId });
    sender.alterCard(useBoardStore().currentBoard().id, id, { teamId });
  }),

  setTeamAction: action((id: string, teamId: string) => {
    if (teamId === NO_TEAM_ID) {
      cardActions.setTeam("internal", id, null);
      cardActions.setIteration("internal", id, null);
    } else {
      cardActions.setTeam("internal", id, teamId);
    }
  }),

  setDepend: action((id: string, teamId: string) => {
    const { board, card } = useCardStore().setDependAction({ id, teamId });
    sender.mirror(id, board.id, {
      flagType: card.flagType,
      precondTeam: card.precondTeam,
      dependTeam: card.dependTeam,
    });
    sender.alterCard(useBoardStore().currentBoard().id, id, {
      flagType: card.flagType,
      precondTeam: card.precondTeam,
      dependTeam: card.dependTeam,
    });
  }),

  setArt: action((id: string, artId: string | null) => {
    useCardStore().setArt({ id, artId });
    sender.alterCard(useBoardStore().currentBoard().id, id, { artId });
  }),

  setType: action((id: string, type: string, boardId?: string) => {
    const { board, props, priorities } = useCardStore().setType({
      id,
      type,
      boardId,
    });
    sender.alterCard(board.id, id, props, priorities);
  }),

  setStatus: action(
    (board: Board, cardId: string, statusName: string, transition?: string) => {
      const status = useCardStore().setStatus(
        board,
        cardId,
        statusName,
        transition,
      );
      sender.alterCard(board.id, cardId, { status, transition });
    },
  ),

  // the actual moving is done continuously by mouse dragging
  // we need this here to track the action and to save the state for undo
  setPosition: action((_: string[]) => {}, {
    history: {
      saveState: (ids) =>
        ids.map((id) => ({
          id,
          pos: { ...useBoardStore().currentBoard().cards[id].meta.pos },
        })),
    },
  }),

  toggleActivity: action(async (id: string) => {
    useActivityStore().toggle(id);
    if (useActivityStore().active) {
      const card = useCardStore().cards[id];
      useActivityStore().setStickyChanges(
        await getStickyChanges(mirrorOriginId(card) || id),
      );
    }
  }),
});

export interface CardCreateProps {
  type: StickyType;
  pos: WindowCoordinate;
  text?: string;
  almSourceId?: AlmSourceId;
}

async function startAdd(props: Partial<CardCreateProps> = {}, focus = true) {
  if (!useUserStore().isAllowed("edit")) {
    return;
  }
  if (!props.pos) {
    props.pos = centerCoord();
  }
  if (!props.type) {
    const defaultTypeId = useClientSettingsStore().defaultStickyTypeId;
    if (defaultTypeId) {
      const defaultType = useBoardStore().originStickyTypes.find(
        (type) => type.id === defaultTypeId,
      );
      if (defaultType) {
        props.type = defaultType;
      }
    }
  }
  if (props.type && !almSourceNeeded(props.almSourceId)) {
    return add(props as CardCreateProps, focus);
  }
  useContextMenuStore().open(
    CardCreationMenu,
    { position: props.pos, card: props },
    {
      select: (type: StickyType, almSourceId?: AlmSourceId) =>
        add({ ...props, type, almSourceId } as CardCreateProps, focus),
    },
  );
}

function almSourceNeeded(almSourceId?: AlmSourceId) {
  const board = useBoardStore().currentBoard();
  return board.almSources.length > 1 && almSourceId === undefined;
}

async function add(props: CardCreateProps, focus = true) {
  const board = useBoardStore().currentBoard();
  props = sanitize(props);
  const almSourceId = props.almSourceId ?? board.almSources[0]?.id;
  const status = useAlmItemTypeStore().calcStatus(
    undefined,
    props.type,
    board.type === "team" ? board.team.id : undefined,
    board.artId,
    almSourceId,
  );
  const ev: CardEvent = {
    id: "",
    boardId: board.id,
    pos: windowToRelative(props.pos),
    text: props.text,
    type: props.type,
    status,
    priority: 0,
    iterationId: null,
    teamId: null,
    zIndex: 0,
    flagType: CardFlag.emptyFlag(),
    objectives: [],
    almSourceId,
    artId: board.type === "backlog" ? board.artId : null,
  };
  useBoardStore().initCard(ev);
  ev.id = await sender.addCard(board.id, { ...ev, ...ev.pos });
  useCardStore().add(ev);
  if (focus) {
    await nextTick();
    useZoomStore().zoomStickyNote(ev.id, true);
    sendCardAction(ev.id, { action: "zoom", zoom: true });
  }
  return ev.id;
}

function sanitize(event: CardCreateProps): CardCreateProps {
  const newlines = !almSync();
  if (event.text && !newlines) {
    event.text = removeNonPrintable(event.text);
  }
  return event;
}

async function addMultiple(cards: CardCreateProps[]) {
  boardActions.clearCardSelection("internal");
  return await Promise.all(
    cards.map(async (card) => {
      const id = await add(card, false);
      useBoardStore().selectCard(id);
      return id;
    }),
  );
}

function cardCreateProps(
  card: BoardCard,
  step: RelativeCoordinate,
  options?: { findFreePos?: boolean },
): CardCreateProps {
  return {
    text: card.data.text,
    type: card.data.type,
    pos: minus(
      relativeToWindow(
        options?.findFreePos
          ? findFreePosition(card.meta.pos, step)
          : plus(card.meta.pos, step),
      ),
      scrollCoord(),
    ),
    almSourceId: card.data.almSourceId ?? undefined,
  };
}
