import { defineStore } from "pinia";

import { i18n } from "@/i18n";
import { Team, emptySelectList } from "@/model";
import { useArtStore } from "@/store/art";

export const NO_TEAM_ID = "";

export const useTeamStore = defineStore("team", {
  state: () => ({ team: emptySelectList<Team>() }),
  getters: {
    teams: (state) => state.team.list,
    currentTeam: (state) => state.team.current,
    selectedTeam: (state) => state.team.selected,
    findTeam() {
      return (team: { id?: string; name?: string }) => {
        if (team.id) {
          return this.teams.find((t) => t.id === team.id);
        }
        if (team.name) {
          return this.teams.find((t) => t.name === team.name);
        }
      };
    },
    teamsInArt(state) {
      return (artId: string): Team[] => {
        if (!state.team || !this.teams) {
          return [];
        }
        // REN-9638 backward compatibility fix
        if (artId === "") {
          return this.teams;
        }
        return this.teams.filter((team) => team.artId === artId);
      };
    },
    teamsInCurrentArt(): Team[] {
      const artId = useArtStore().currentArt.id;
      return artId ? this.teamsInArt(artId) : this.teams;
    },
    noTeam: () => ({ id: NO_TEAM_ID, name: i18n.global.t("general.noTeam") }),
  },
  actions: {
    setTeams(e: { teams: Team[] }) {
      this.team.list = e.teams;
    },
    selectTeam(e: Team) {
      this.team.selected = e;
    },
    setTeam(team: Team) {
      this.team.current = this.team.selected = team;
    },
  },
});
