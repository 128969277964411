import { action, defineActions } from "@/action/actions";
import { sender } from "@/backend/Sender";
import { BoardData, FlexType } from "@/model";
import { useBoardStore } from "@/store/board";

export const flexActions = defineActions("flex", {
  add: action((type: FlexType, name: string) => {
    return sender
      .addBoard(type.id, name)
      .then((id) => useBoardStore().addFlexBoard(id, name, type));
  }),
  update: action((id: string, name: string) => {
    sender.updateBoard(id, name);
    (useBoardStore().boardById(id) as BoardData<"flex">).name = name;
  }),
  delete: action((id: string) => {
    sender.deleteBoard(id);
    delete useBoardStore().boards[id];
  }),
});
