<script setup lang="ts">
import { cardActions } from "@/action/cardActions";
import { boardKey, cardKey } from "@/components/card/injectKeys";
import StickyLinkModal from "@/components/modal/StickyLinkModal.vue";
import { Card } from "@/model";
import { useModalStore } from "@/store/modal";
import { injectStrict } from "@/utils/context";

import * as actions from "./actions";
import { type ActionMenuAction } from "./actions";
import ActionMenuItem from "./components/ActionMenuItem/ActionMenuItem.vue";
import ChangeArt from "./components/ChangeArt/ChangeArt.vue";
import ChangeProject from "./components/ChangeProject/ChangeProject.vue";
import DeleteSticky from "./components/DeleteSticky/DeleteSticky.vue";
import DependOnSticky from "./components/DependOnSticky/DependOnSticky.vue";
import MirrorSticky from "./components/MirrorSticky/MirrorSticky.vue";
import MoveSticky from "./components/MoveSticky/MoveSticky.vue";
import TeamDropdown from "./components/TeamDropdown/TeamDropdown.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

/**
 *  Determines if specific action should be visible in the action menu
 * @param action
 */
const isActionVisible = (action: ActionMenuAction): boolean => {
  if (!card || !board) return false;
  return actions.getActionsByBoardType(card, board).includes(action);
};

const isDeleteActionVisible = () => {
  return (
    isActionVisible("delete") &&
    !actions.cardWasCreatedOnDifferentBoard(card, board)
  );
};

const handleLinkModalOpen = (card: Card) => {
  return useModalStore().open(StickyLinkModal, {
    attrs: { cardIds: [card.id] },
  });
};
</script>

<template>
  <div class="action-menu" data-ignore-click>
    <ActionMenuItem
      v-if="isDeleteActionVisible() && actions.isStickyDeletable(card, board)"
      :tooltip-text="$t('cardAction.delete')"
      icon="action-menu/delete"
    >
      <template #dropdown>
        <DeleteSticky mode="delete" />
      </template>
    </ActionMenuItem>

    <ActionMenuItem
      v-if="!isDeleteActionVisible()"
      icon="action-menu/trash-old"
      :tooltip-text="$t('cardAction.remove')"
    >
      <template #dropdown>
        <DeleteSticky mode="remove" />
      </template>
    </ActionMenuItem>

    <ActionMenuItem
      v-if="isActionVisible('art')"
      icon="action-menu/art-old"
      :tooltip-text="$t('cardAction.art')"
    >
      <template #dropdown>
        <ChangeArt />
      </template>
    </ActionMenuItem>

    <ActionMenuItem
      v-if="isActionVisible('link')"
      icon="action-menu/link-old"
      :tooltip-text="$t('cardAction.link')"
      @click="() => handleLinkModalOpen(card)"
    />

    <ActionMenuItem
      v-if="isActionVisible('mirror') && actions.isStickyMirrorable(card)"
      :tooltip-text="$t('cardAction.mirror')"
      icon="action-menu/menu-old"
    >
      <template #dropdown>
        <MirrorSticky />
      </template>
    </ActionMenuItem>

    <ActionMenuItem
      v-if="isActionVisible('move') && actions.isStickyMovable(card, board)"
      :tooltip-text="$t('cardAction.move')"
      icon="action-menu/move-old"
    >
      <template #dropdown>
        <MoveSticky />
      </template>
    </ActionMenuItem>

    <ActionMenuItem
      v-if="isActionVisible('program')"
      icon="action-menu/planning-old"
      :tooltip-text="$t('cardAction.program')"
    >
      <template #dropdown>
        <MirrorSticky />
      </template>
    </ActionMenuItem>

    <ActionMenuItem
      v-if="
        isActionVisible('almSource') &&
        !actions.cardWasCreatedOnDifferentBoard(card, board) &&
        board.almSources.length > 1
      "
      icon="action-menu/project-old"
      :tooltip-text="$t('cardAction.project')"
    >
      <template #dropdown>
        <ChangeProject />
      </template>
    </ActionMenuItem>

    <ActionMenuItem
      v-if="isActionVisible('team')"
      icon="action-menu/team-old"
      :tooltip-text="$t('cardAction.team')"
    >
      <template #dropdown>
        <TeamDropdown />
      </template>
    </ActionMenuItem>

    <ActionMenuItem
      v-if="isActionVisible('depend')"
      icon="action-menu/team-old"
      :tooltip-text="$t('cardAction.depend')"
    >
      <template #dropdown>
        <DependOnSticky />
      </template>
    </ActionMenuItem>

    <ActionMenuItem
      v-if="isActionVisible('activity')"
      :tooltip-text="$t('cardAction.activity')"
      icon="action-menu/activity"
      @click="cardActions.toggleActivity('card', card.id)"
    />
  </div>
</template>

<style scoped lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.action-menu {
  background-color: colors-old.$menu-color;
  color: colors-old.$back-color;
  display: inline-flex;
  border-radius: 10px;

  > :not(:last-child) {
    border-right: 1px solid rgba(colors-old.$back-color, 0.1);
  }
}
</style>
