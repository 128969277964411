<script lang="ts" setup>
import { computed } from "vue";

import { MarkMode, isFullyFaded } from "@/model";

const props = defineProps<{ mark: MarkMode }>();

const classes = computed(() => ({
  lowlight: isFullyFaded(props.mark),
}));
</script>
<template>
  <div class="sticky-note-filter" :class="classes" />
</template>

<style lang="scss" scoped>
@use "@/styles/colors";
@use "@/styles/z-index";

.sticky-note-filter {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;

  &.lowlight {
    background-color: colors.$lowlight-color;
    z-index: z-index.$board;
  }
}
</style>
