import { type FocusTrap, Options, createFocusTrap } from "focus-trap";
import { Directive, Ref, ref } from "vue";

/**
 * Traps the focus inside an element. Usage: `v-focus-trap`
 *
 * By default, the trap is removed when the user clicks outiside of the trapped element.
 * This can be disabled by passing `{ disableOnOutsideClick: false }` as a value.
 */
export function focusTrap(): Directive<
  HTMLElement,
  Options & { disableOnOutsideClick?: boolean }
> {
  const focusTrap: Ref<FocusTrap | null> = ref(null);
  const trappedEl = ref<HTMLElement | null>(null);

  // remove the trap when clicking outside of the trapped element
  const handleOutsideClick = (event: MouseEvent) => {
    if (!trappedEl.value) return;

    const target = event.target as HTMLElement;
    const isChild = isChildOf(target, trappedEl.value);

    if (!isChild) {
      focusTrap.value?.deactivate();
      document.removeEventListener("click", handleOutsideClick);
    }
  };

  return {
    mounted(el, { value }) {
      if (focusTrap.value) return;

      const disableOnOutsideClick = value?.disableOnOutsideClick ?? true;
      if (disableOnOutsideClick) {
        trappedEl.value = el;
        document.addEventListener("mousedown", handleOutsideClick, {
          capture: true,
        });
      }

      focusTrap.value = createFocusTrap(el, {
        initialFocus: false,
        ...(value || {}),
      });
      focusTrap.value?.activate();
    },
    unmounted() {
      focusTrap.value?.deactivate();
      focusTrap.value = null;
    },
  };
}

function isChildOf(child: Node, parent: Node): boolean {
  return parent.contains(child);
}
