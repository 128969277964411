<template>
  <div class="base-list-items scrollable">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/utils";

.base-list-items {
  position: relative;
  flex-grow: 1;
  padding: 2px 4px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow-y: auto;
  max-height: 100%;
}
</style>
