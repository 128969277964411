import { action, defineActions } from "@/action/actions";
import { sender } from "@/backend/Sender";
import { Shape } from "@/model";
import { useBoardStore } from "@/store/board";
import { useDrawStore } from "@/store/draw";

export const drawActions = defineActions("draw", {
  moveTool: action(() => (useDrawStore().tool = "move"), {
    name: /*$t*/ "action.moveTool",
    icon: "draw/pointer",
  }),
  lineTool: action(() => (useDrawStore().tool = "line"), {
    name: /*$t*/ "action.lineTool",
    icon: "draw/line",
  }),
  addShape: action(async (shape: Shape) => {
    const boardId = useBoardStore().currentBoard().id;
    shape.id = await sender.addShape(boardId, shape);
    return useBoardStore().addShape(boardId, shape);
  }),
  editShape: action((shape: Shape) => {
    const boardId = useBoardStore().currentBoard().id;
    useBoardStore().editShape(boardId, shape);
    sender.editShape(boardId, shape);
  }),
  removeShape: action((id: string) => {
    const boardId = useBoardStore().currentBoard().id;
    useBoardStore().removeShape(boardId, id);
    sender.removeShape(boardId, id);
  }),
});
