import { Color } from "@/baseTypes";
import color from "@/styles/color.module.scss";
import { colorPalette } from "@/utils/colorPalette";
import { toHex } from "@/utils/general";

export const black: Color = [0, 0, 0, 1];
export const white: Color = [1, 1, 1, 1];

export function toCssColor(c: Color): string {
  const hex = (v: number) => toHex(Math.round(v), 2);
  return (
    "#" + hex(c[0] * 255) + hex(c[1] * 255) + hex(c[2] * 255) + hex(c[3] * 255)
  );
}

export function colorFromNumber(h: number): string {
  // omit grays and the first shade of every color
  const a = Math.abs(h);
  const hue = a % 6;
  const shade = (Math.floor(a / 6) % 3) + 1;
  return colorPalette[hue * 4 + shade].color;
}

export function isDarkColor(c: Color): boolean {
  return brightness(c) < 0.55;
}

export function contrastCssColor(c: Color): string {
  return isDarkColor(c) ? color.back : color.textPrimary;
}

export function shadeColor(c: Color): Color {
  return isDarkColor(c)
    ? interpolate(c, white, 0.25)
    : interpolate(c, black, 0.25);
}

function brightness(c: Color): number {
  return c[0] * 0.299 + c[1] * 0.587 + c[2] * 0.114;
}

function interpolate(c: Color, d: Color, f: number): Color {
  return [
    c[0] + (d[0] - c[0]) * f,
    c[1] + (d[1] - c[1]) * f,
    c[2] + (d[2] - c[2]) * f,
    c[3],
  ];
}
