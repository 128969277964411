<template>
  <div :id="board.id" class="board board-team">
    <load-component v-if="!board.loaded" is-board />
    <link-layer :board="board" :color="linkColor" :show-errors="true" />
    <div class="backdrop" @dblclick="overview(eventLocation($event))">
      <team-iteration
        v-for="i in columns"
        :key="i"
        :index="i - 1"
        :read-only="readOnly"
        class="field"
        style="height: 50%"
        :style="{ left: (i - 1) * fieldWidth + 'px', width: fieldWidth + 'px' }"
        @metrics="metricsAction"
      />
      <div
        class="field team-objectives"
        style="height: 50%; top: 50%"
        :style="{ width: fieldWidth + 'px' }"
      >
        <team-objectives />
      </div>
      <div
        class="field risks"
        style="height: 50%; top: 50%"
        :style="{ left: fieldWidth + 'px', width: fieldWidth + 'px' }"
      >
        <h3 class="header">
          <SvgIcon name="risks" size="1.25em" />
          <span>{{ $t("teamBoardLocation.risks") }}</span>
        </h3>
      </div>
      <team-iteration
        v-for="i in iterations.length - columns"
        :key="i + columns"
        :index="i - 1 + columns"
        :read-only="readOnly"
        class="field"
        style="height: 50%; top: 50%"
        :style="{ left: (i + 1) * fieldWidth + 'px', width: fieldWidth + 'px' }"
        @metrics="metricsAction"
      />
    </div>
    <template v-if="isNewStickyNoteEnabled">
      <StickyNote
        v-for="card in board.cards"
        :key="card.data.id"
        :card="card.data"
        :board="board"
        :meta="card.meta"
        :board-size="boardSize"
      />
    </template>
    <template v-else>
      <card
        v-for="card in board.cards"
        :key="card.data.id"
        :draggable="!readOnly"
        :card="card.data"
        :meta="card.meta"
        :color="card.data.type.color"
        :link-color="linkColor"
        :height="board.cardSize.y * height"
        :width="board.cardSize.x * width"
        :board="board"
        :board-width="width"
        :board-height="height"
        :actions="actions(card.data)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";

import { ActionSource } from "@/action/actions";
import { toggleActions } from "@/action/toggleActions";
import { normalLinkColors } from "@/colors";
import StickyNote from "@/components-ng/StickyNote/StickyNote.vue";
import FluidBoard, {
  ContextInfo,
  LocatedContextActionEvent,
} from "@/components/FluidBoard";
import LinkLayer from "@/components/LinkLayer.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { TeamBoardLocation } from "@/components/TeamBoardLocation";
import TeamIteration from "@/components/TeamIteration.vue";
import { ActionType } from "@/components/card/actions";
import ConfirmSyncIterationModal from "@/components/modal/ConfirmSyncIterationModal.vue";
import MetricsModal from "@/components/modal/MetricsModal.vue";
import LoadComponent from "@/components/modal/components/LoadComponent.vue";
import TeamObjectives from "@/components/objectives/TeamObjectives.vue";
import { isFeatureEnabled } from "@/feature";
import { relativeClientCoord } from "@/math/coordinate-systems";
import { RelativeCoordinate } from "@/math/coordinates";
import ScrollSupport from "@/mixins/ScrollSupport";
import { BoardIteration, Card } from "@/model";
import { useBoardStore } from "@/store/board";
import { useConnectionStore } from "@/store/connection";
import { useIterationStore } from "@/store/iteration";
import { useModalStore } from "@/store/modal";

@Options({
  components: {
    SvgIcon,
    TeamObjectives,
    TeamIteration,
    LinkLayer,
    LoadComponent,
    StickyNote,
  },
})
export default class TeamBoard extends mixins(FluidBoard, ScrollSupport) {
  linkColor = normalLinkColors.team;
  baseActions: ActionType[] = [
    "delete",
    "close",
    "almSource",
    "move",
    "link",
    "dragLink",
  ];
  defaultActions: ActionType[] = [...this.baseActions, "points", "mirror"];
  riskActions: ActionType[] = [...this.baseActions, "risk", "mirror"];
  dependActions: ActionType[] = [...this.baseActions, "points", "program"];
  programActions: ActionType[] = [...this.baseActions, "points", "depend"];

  get board() {
    return useBoardStore().boardByType("team");
  }

  get isNewStickyNoteEnabled() {
    return isFeatureEnabled(this.$route, "sticky-note");
  }

  contextActions(c?: RelativeCoordinate): ContextInfo {
    const loc = c ? this.location(c) : null;

    const actions: ContextInfo = {
      syncProgramBacklog: false,
      draw: true,
      selection: {
        stickyMove: true,
        link: true,
        mirror: false,
        team: false,
      },
    };

    if (loc?.isIteration || loc?.isRisks()) {
      actions.region = {
        name: loc.name,
        arrange: true,
        overview: true,
        sync: !!(loc.boardIteration && this.canSync(loc.boardIteration)),
        zoom: true,
      };
    }
    return actions;
  }

  canSync(iter: BoardIteration) {
    const { status } = iter.state;
    return useConnectionStore().alm && !!status && status !== "syncing";
  }

  metricsAction() {
    useModalStore().open(MetricsModal);
  }

  syncAction(event: LocatedContextActionEvent) {
    const loc = this.location(event.coord);

    useModalStore().open(ConfirmSyncIterationModal, {
      attrs: {
        iteration: loc.iterationId,
        status: loc.boardIteration?.state.status,
      },
    });
  }

  location(c: RelativeCoordinate | number) {
    return TeamBoardLocation.of(this.board.iterations, c);
  }

  eventLocation(e: MouseEvent) {
    return this.location(relativeClientCoord(e));
  }

  overview(loc: TeamBoardLocation, source: ActionSource = "mouse") {
    if (loc.isObjectives()) {
      return;
    }
    const attrs = {
      load: loc.boardIteration?.load,
      velocity: loc.boardIteration?.velocity,
      boardId: useBoardStore().boardByType("team").id,
      location: loc.index(),
    };
    toggleActions.showOverview(source, attrs);
  }

  actions(card: Card): ActionType[] {
    switch (card.type.functionality) {
      case "risk":
        return this.riskActions;
      case "dependency":
        return card.dependTeam ? this.dependActions : this.programActions;
      default:
        return this.defaultActions;
    }
  }

  get columns() {
    return Math.ceil(1 + this.iterations.length / 2);
  }

  get fieldWidth() {
    return this.width / this.columns;
  }

  get iterations() {
    return useIterationStore().iterations;
  }
}
</script>
<style lang="scss">
@use "@/styles/font";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.items-center {
  align-items: center;
}

.board-team {
  position: relative;

  h3,
  h4 {
    display: inline-block;
    vertical-align: middle;
  }

  img.icon {
    height: board.len(28px);
    vertical-align: middle;
    border-radius: board.len(4px);
  }

  .field {
    padding: board.len(12px);
  }

  .field.risks {
    color: colors-old.$alt-error-color;
    font-size: 80%;

    h3 {
      font-weight: font.$weight-bold;
      display: flex;
      gap: 0.5em;
      align-items: center;
      margin-top: 0.5em;
    }

    span {
      vertical-align: middle;
    }
  }

  .team-objectives {
    padding: 0;

    .header {
      h3 {
        font-size: board.len(16px);
        font-weight: font.$weight-bold;
      }
    }
  }
}
</style>
