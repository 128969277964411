import { Vue } from "vue-class-component";

import { useAppSizeStore } from "@/store/appSize";

export default class SidePanelLeft extends Vue {
  created() {
    useAppSizeStore().setLeft(0, 301);
  }

  unmounted() {
    useAppSizeStore().setLeft(68, 0);
  }
}
