// NOTE: Use explicit paths for imports, otherwise check-events.ts will fail
import { Functionality } from "../../baseTypes";
import { ObjectiveType, WorkingMode } from "../../model";

export type AnalyticsEvent = {
  readonly event: string;
} & {
  [key: string]: string | number | boolean;
};

/***
 * PLAN READOUT MODAL
 ***/

/**
 * Triggered after user sees  the plan readout modal
 */
export const planReadoutModalSeen = (): AnalyticsEvent => {
  return {
    event: "web.app.plan_readout_modal_seen",
  };
}; /**
 * Triggered when the plan readout modal is closed
 * (** not triggered when the user closes the tab/window)
 */
export const planReadoutModalClosed = (): AnalyticsEvent => {
  return {
    event: "web.app.plan_readout_modal_closed",
  };
};

/**
 * Triggered when the user switches tabs in the plan readout modal
 *
 * @param newTab Tab user clicked (tabId)
 * @param previousTab Current tab (tabId)
 */
export const planReadoutTabClicked = (
  newTab: string,
  previousTab: string,
): AnalyticsEvent => {
  return {
    event: "web.app.plan_readout_tab_clicked",
    newTab,
    previousTab,
  };
};

/**
 * Triggered when the user switches teams in the plan readout modal
 *
 * @param teamId ID of the team the user switched to
 * @param source Whether the user used the dropdown or the arrows to switch teams
 */
export const planReadoutTeamChanged = (
  teamId: string,
  source: "dropdown" | "arrows",
): AnalyticsEvent => {
  return {
    event: "web.app.plan_readout_team_changed",
    teamId,
    source,
  };
};

/**
 * Triggered when the user clicks on an objective in the plan readout modal
 * @param objectiveId
 */
export const planReadoutSelectObjective = (
  objectiveId: string,
  objectiveType: ObjectiveType,
) => {
  return {
    event: "web.app.plan_readout_select_objective",
    objectiveId,
    objectiveType,
  };
};

/**
 * Triggered when the user clicks on a risk in the plan readout modal
 * @param objectiveId
 */
export const planReadoutSelectRisk = (riskId: string) => {
  return {
    event: "web.app.plan_readout_select_risk",
    riskId,
  };
};

/**
 * Triggered when the expands/shrinks the 'linked stickies' section
 *
 * @param open True when the section is opened
 */
export const planReadoutObjectiveLinksToggled = (
  open: boolean,
): AnalyticsEvent => {
  return {
    event: "web.app.plan_readout_objective_links_toggled",
    open,
  };
};

/***
 * OBJECTIVES
 ***/

/**
 * Triggered when the user sees the objectives modal
 */
export const objectivesModalSeen = (): AnalyticsEvent => {
  return {
    event: "web.app.objectives_modal_seen",
  };
};

/**
 * Triggered when the user adds an objective
 */
export const objectiveCreated = (): AnalyticsEvent => {
  return {
    event: "web.app.objective_created",
  };
};

/**
 * Triggered when the user sets the business value for an objective
 *
 * @param arg0.hadPreviousValue Whether the objective had a non-null business value before
 * @param arg0.hasNewValue Whether a non-null business value was set
 * @param arg0.location Where the business value was set from
 */
export const objectiveBusinessValueSet = ({
  hadPreviousValue,
  hasNewValue,
  location,
}: {
  hadPreviousValue: boolean;
  hasNewValue: boolean;
  location: "plan-readout-modal" | "objectives-modal";
}): AnalyticsEvent => {
  return {
    event: "web.app.objective_business_value_set",
    hadPreviousValue,
    hasNewValue,
    location,
  };
};

/**
 * Triggered when the user sets the actual value for an objective
 *
 * @param arg0.hadPreviousValue Whether the objective had a non-null business value before
 * @param arg0.hasNewValue Whether a non-null business value was set
 */
export const objectiveActualValueSet = ({
  hadPreviousValue,
  hasNewValue,
}: {
  hadPreviousValue: boolean;
  hasNewValue: boolean;
}): AnalyticsEvent => {
  return {
    event: "web.app.objective_actual_value_set",
    hadPreviousValue,
    hasNewValue,
  };
};

/***
 * GENERAL SETTINGS
 ***/

/**
 * Triggered after user changes the work mode
 */
export const workModeChanged = (mode: WorkingMode): AnalyticsEvent => {
  return {
    event: "web.app.work_mode_changed",
    mode,
  };
};

/***
 * STICKY NOTE
 ***/

type StickyCreateAction = "long-press-on-board";
/**
 * Triggered after user create a sticky note
 */
export const stickyNoteCreated = (
  action: StickyCreateAction,
  type: Functionality,
): AnalyticsEvent => {
  return {
    event: "web.app.sticky_note_created",
    action,
    type,
  };
};

/***
 * TEAM
 ***/

/**
 * Triggered when the user changes the team
 */
export const teamChange = (
  trigger: "menu-top" | "menu-sidebar",
): AnalyticsEvent => {
  return {
    event: "web.app.team_change",
    trigger,
  };
};
