<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import TeamDropdown from "@/components/card/components/ActionMenu/components/TeamDropdown/TeamDropdown.vue";
import { cardKey } from "@/components/card/injectKeys";
import { Team } from "@/model";
import { NO_TEAM_ID, useTeamStore } from "@/store/team";
import { injectStrict } from "@/utils/context";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";

const card = injectStrict(cardKey);

const team = computed(() => {
  return useTeamStore().findTeam({ id: card.teamId ?? undefined });
});

const handleTeamChange = (team: Team | null) => {
  cardActions.setTeam("card", card.id, team?.id ?? NO_TEAM_ID);
};
</script>

<template>
  <AttributeChip :text="team?.name" text-placeholder="Team">
    <TeamDropdown
      show-search
      :show-clear="false"
      :title="$t('actionMenu.dependentOn')"
      @select="handleTeamChange"
    />
  </AttributeChip>
</template>
