<template>
  <div :id="board.id" class="board board-backlog">
    <load-component v-if="!board.loaded" is-board />
    <div class="backdrop">
      <kebab-menu
        class="kebab-menu"
        :board="$parent"
        mode="backlog"
        data-testid="kebab-menu"
      />
    </div>
    <template v-if="isNewStickyNoteEnabled">
      <StickyNote
        v-for="(card, index) in visibleCards"
        :key="card.data.id"
        :card="card.data"
        :board="board"
        :meta="card.meta"
        :board-size="boardSize"
        :size-override="cardSize"
        :draggable="false"
        place-by="order"
        :col="cardMiddleX(index)"
        :row="cardMiddleY(index)"
      />
    </template>
    <template v-else>
      <card
        v-for="(card, index) in visibleCards"
        v-show="show(card)"
        :key="card.data.id"
        :draggable="false"
        :card="card.data"
        :meta="card.meta"
        :color="color(card)"
        :height="cardSize"
        :width="cardSize"
        :board="board"
        :board-width="width"
        :board-height="height"
        :actions="actions"
        place-by="order"
        :col="cardMiddleX(index)"
        :row="cardMiddleY(index)"
      />
    </template>

    <div
      class="add"
      :style="{
        top: top(visibleCards.length) + 'px',
        left: left(visibleCards.length) + 'px',
        height: cardSize + 'px',
        width: cardSize + 'px',
      }"
      @click="add"
    />
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { cardActions } from "@/action/cardActions";
import { getAlmType } from "@/backend/Backend";
import StickyNote from "@/components-ng/StickyNote/StickyNote.vue";
import FluidBoard, { CardSize, ContextInfo } from "@/components/FluidBoard";
import { ActionType } from "@/components/card/actions";
import KebabMenu from "@/components/menu/KebabMenu.vue";
import ConfirmSyncBacklogModal from "@/components/modal/ConfirmSyncBacklogModal.vue";
import LoadComponent from "@/components/modal/components/LoadComponent.vue";
import { isFeatureEnabled } from "@/feature";
import { RelativeCoordinate, clientCoord } from "@/math/coordinates";
import { Board, BoardCard } from "@/model";
import { useBoardStore } from "@/store/board";
import { useConnectionStore } from "@/store/connection";
import { useModalStore } from "@/store/modal";

const cardMargin = 25;

@Options({ components: { KebabMenu, LoadComponent, StickyNote } })
export default class BaseBacklogBoard extends mixins(FluidBoard) {
  @Prop(Object) readonly backlogBoard!: Board;
  @Prop(Array) readonly actions!: ActionType[];

  columns = 0;

  get board() {
    return this.backlogBoard;
  }

  @Watch("size", { immediate: true })
  sizeChanges() {
    // sticky size has changed -> trigger font size recalc after DOM update
    this.$nextTick(
      () => (useBoardStore().currentBoard().cardSize.factor += 0.000001),
    );
  }

  get isNewStickyNoteEnabled() {
    return isFeatureEnabled(this.$route, "sticky-note");
  }

  add(e: MouseEvent) {
    cardActions.add("board", { pos: clientCoord(e) });
  }

  cardMiddleX(position: number) {
    const cardMiddle = this.cardSize / 2;
    return (this.left(position) + cardMiddle) / this.width;
  }

  cardMiddleY(position: number) {
    const cardMiddle = this.cardSize / 2;
    return (this.top(position) + cardMiddle) / this.height;
  }

  top(position: number) {
    const rowNumber = Math.floor(position / this.columns);
    const previousCardRowsHeight = rowNumber * this.size;
    const topMargin = this.height * 0.1;
    return topMargin + previousCardRowsHeight;
  }

  left(position: number) {
    const colNumber = position % this.columns;
    const previousCardColsWidth = colNumber * this.size;
    return previousCardColsWidth + cardMargin;
  }

  show(card: BoardCard) {
    return card.meta.mark !== "filter-out";
  }

  color(card: BoardCard) {
    return card.data.teamId ? card.data.type.altColor : card.data.type.color;
  }

  get cards(): BoardCard[] {
    return Object.values(this.board.cards).sort((a, b) => {
      const prio = b.data.priority - a.data.priority;
      // if priorities are equal, assure there's still a defined order
      return prio !== 0 ? prio : a.data.id > b.data.id ? 1 : -1;
    });
  }

  get visibleCards(): BoardCard[] {
    return this.cards.filter((card) => this.show(card));
  }

  get cardSize() {
    return this.size - cardMargin * 2;
  }

  get size() {
    const numberOfCards = this.visibleCards.length + 1;
    if (numberOfCards <= 10) {
      this.columns = 5;
      return this.width / 5;
    }
    const h = this.height * 0.8;
    const w = this.width;
    const ac = Math.ceil(Math.sqrt((w / h) * numberOfCards));
    const bc = Math.ceil(Math.sqrt((h / w) * numberOfCards));

    const bw = Math.floor(w / (h / bc));
    const bw2 = Math.floor(w / (h / (bc + 1)));
    const a1 = ac * Math.floor(h / (w / ac));
    const a2 = (ac + 1) * Math.floor(h / (w / (ac + 1)));
    const b1 = bw * bc;
    if (a1 >= numberOfCards && (b1 < numberOfCards || a1 <= b1)) {
      this.columns = ac;
      return w / ac;
    }
    if (a2 >= numberOfCards && (b1 < numberOfCards || a2 <= b1)) {
      this.columns = ac + 1;
      return w / (ac + 1);
    }
    if (b1 >= numberOfCards) {
      this.columns = bw;
      return h / bc;
    }
    this.columns = bw2;
    return h / (bc + 1);
  }

  contextActions(_c?: RelativeCoordinate): ContextInfo {
    return {
      syncProgramBacklog: !!getAlmType() && useConnectionStore().alm,
      draw: true,
      selection: {
        stickyMove: false,
        link: true,
        mirror: true,
        team: true,
      },
    };
  }

  syncProgramBacklogAction() {
    useModalStore().open(ConfirmSyncBacklogModal);
  }

  getRelativeCardSizes(): CardSize[] {
    return this.cards.map((card, i) =>
      this.calcBacklogboardRelativeCardSize(card, i),
    );
  }

  calcBacklogboardRelativeCardSize(card: BoardCard, index: number): CardSize {
    return {
      id: card.data.id,
      left: this.left(index) / this.width,
      top: this.top(index) / this.height,
      width: this.cardSize / this.width,
      height: this.cardSize / this.height,
    };
  }
}
</script>

<style lang="scss" scoped>
.add {
  position: absolute;
  background: url("@/assets/add-grey.svg") center/contain no-repeat;
  cursor: pointer;
}

.backdrop {
  align-items: baseline;
  display: flex;
  justify-content: right;
  padding-top: 1em;
  padding-right: 1em;

  .kebab-menu {
    flex: 0;
  }

  h2 {
    flex: 1;
  }
}
</style>
