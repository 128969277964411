<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";

import { cardActions } from "@/action/cardActions";
import { almSync } from "@/backend/Backend";
import { cardKey } from "@/components/card/injectKeys";
import { optimalFontSize } from "@/fontSizeOptimizer";
import { injectStrict } from "@/utils/context";
import { removeNonPrintable } from "@/utils/general";

defineExpose({ focus: () => textareaRef.value?.focus() });

onMounted(() => calculateOptimalFontSize(textareaRef.value?.value || ""));

// provide / inject
const card = injectStrict(cardKey);

// state
const fontSize = ref(300);
const textareaRef = ref<HTMLTextAreaElement>();

// computed
const maxTextLength = computed(() => (almSync() ? 255 : 640));

// event listeners
const handleFocus = (event: FocusEvent) => {
  const target = event.target as HTMLTextAreaElement;
  target.style.pointerEvents = "auto";
  target.spellcheck = true;

  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
  }

  // put the cursor at the end of the text
  const textLength = target.value.length;
  target.selectionStart = textLength;
  target.selectionEnd = textLength;
};

const handleBlur = (event: FocusEvent) => {
  const target = event.target as HTMLTextAreaElement;
  target.style.pointerEvents = "none";
  target.spellcheck = false;

  // deselect the text
  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
  }
};

const hanleTextInput = (event: Event) => {
  const target = event.target as HTMLTextAreaElement;

  const text = removeNonPrintable(target.value);
  cardActions.setText("card", card.id, text);
};

const handlePointerDown = (event: PointerEvent) => {
  if (document.activeElement === textareaRef.value) {
    event.stopPropagation();
  }
};

const calculateOptimalFontSize = (value: string) => {
  if (!textareaRef.value) return;
  optimalFontSize(textareaRef.value, value, true).then((fontData) => {
    fontSize.value = fontData.size;
  });
};

watch(
  () => card.text,
  (val) => calculateOptimalFontSize(val),
);
</script>

<template>
  <textarea
    ref="textareaRef"
    :maxlength="maxTextLength"
    :value="card.text"
    class="sticky-note-text-input"
    :style="{ fontSize: fontSize + '%' }"
    @keydown.enter.prevent
    @input="hanleTextInput"
    @focus="handleFocus"
    @blur="handleBlur"
    @pointerdown.capture="handlePointerDown"
  />
</template>

<style lang="scss" scoped>
@use "sass:color";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.sticky-note-text-input {
  all: unset;
  line-height: 1.2;
  pointer-events: none;
  background-color: transparent;
  user-select: auto;
  border: none;
  overflow: hidden;
  width: 100%;
  margin: 0 4px;

  &:focus {
    background-color: color.change(colors-old.$menu-color, $alpha: 0.08);
  }
}
</style>
