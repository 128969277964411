<template>
  <div class="menu-sidebar" :class="menuPosition">
    <div class="turn shift-button">
      <ActionButton
        :action="actions.shiftMenu"
        :disabled="isButtonDisabled"
        position="right"
      />
    </div>
    <menu-item-delimiter horizontal />
    <boards-item class="boards-item" />
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import { toggleActions } from "@/action/toggleActions";
import BoardsItem from "@/components/menu/BoardsItem.vue";
import MenuItemDelimiter from "@/components/menu/components/MenuItemDelimiter.vue";
import ActionButton from "@/components/ui/ActionButton/ActionButton.vue";
import EventBusUser from "@/mixins/EventBusUser";
import { togglePosition, useBoardMenuStore } from "@/store/boardMenu";
import { useSearchMenuStore } from "@/store/searchMenu";

@Options({
  components: {
    ActionButton,
    BoardsItem,
    MenuItemDelimiter,
  },
})
export default class MainMenu extends mixins(EventBusUser) {
  boardStore = useBoardMenuStore();
  searchStore = useSearchMenuStore();
  actions = toggleActions;

  @Watch("searchStore.isSearchActive") onSearchActiveChange(isActive: boolean) {
    this.boardStore.setMenuPosition(isActive ? "right" : "default");
  }

  get iconDirection() {
    return togglePosition(this.menuPosition);
  }

  get menuPosition() {
    return this.boardStore.position;
  }

  get isButtonDisabled() {
    return this.boardStore.disabledShiftButton;
  }
}
</script>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/caret" as *;
@use "@/styles/mixins" as *;
@use "@/styles/z-index";

#main-menu.navigation .menu-sidebar {
  position: fixed;
  width: 44px;
  left: 10px;
  z-index: z-index.$menu;
  font-size: 9px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
  background-color: colors-old.$back-color;

  @include default-shadow;

  border-radius: variables.$common-border-radius;
  padding: 2px;

  .shift-button {
    display: flex;
  }

  &.right {
    left: auto;
    right: 10px;

    .turn svg {
      transform: rotate(180deg);
    }
  }
}
</style>
