import { namedKey, namedNoKey } from "@/Shortcuts";
import { action, defineActions } from "@/action/actions";
import TeamObjectivesModal from "@/components/TeamObjectivesModal.vue";
import MetricsModal from "@/components/modal/MetricsModal.vue";
import OverallMetricsModal from "@/components/modal/OverallMetricsModal.vue";
import OverviewModal from "@/components/modal/OverviewModal.vue";
import { i18n } from "@/i18n";
import { useBoardStore } from "@/store/board";
import { useBoardMenuStore } from "@/store/boardMenu";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useDrawStore } from "@/store/draw";
import { useGPTStore } from "@/store/gpt";
import { useModalStore } from "@/store/modal";
import { usePointerStore } from "@/store/pointer";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useTimerStore } from "@/store/timer";
import { useWorkModeStore } from "@/store/workMode";

export const toggleActions = defineActions("toggle", {
  showPointer: action(() => usePointerStore().togglePointerActivation(), {
    name: /*$t*/ "action.pointer",
    shortcut: namedKey("KeyA", { modifiers: ["shift"] }),
    icon: "pointer",
  }),
  toggleWorkingMode: action(() => useWorkModeStore().toggleWorkingMode(), {
    shortcut: namedKey("KeyP", { modifiers: ["altCtrl"] }),
  }),
  showPlanningMode: action(
    () => (useWorkModeStore().workingMode = "planning"),
    {
      name: /*$t*/ "action.planMode",
      icon: "planning",
    },
  ),
  showExecutionMode: action(
    () => (useWorkModeStore().workingMode = "execution"),
    {
      name: /*$t*/ "action.executionMode",
      icon: "execution",
    },
  ),
  showTimer: action(
    () => {
      useTimerStore().toggleTimer();
      useBoardMenuStore().setMenuPosition(
        useTimerStore().active ? "left" : "default",
      );
    },
    {
      name: /*$t*/ "action.timer",
      shortcut: namedKey("KeyT", { modifiers: ["altCtrl"] }),
      icon: "timer",
    },
  ),
  showGPT: action(() => useGPTStore().expandGPT(), {
    name: /*$t*/ "action.showGPT",
  }),
  showSearch: action(() => useSearchMenuStore().toggleSearch(), {
    name: /*$t*/ "action.search",
    shortcut: namedKey("/"),
  }),
  showTeamObjectives: action(
    () => {
      const board = useBoardStore().currentBoard();
      if (board.type === "team") {
        useModalStore().open(TeamObjectivesModal, {
          attrs: { boardId: board.id },
        });
      }
    },
    {
      name: /*$t*/ "action.teamObjectives",
      shortcut: namedKey("KeyO", { modifiers: ["altCtrl"] }),
    },
  ),
  showOverview: action(
    (attrs) => useModalStore().open(OverviewModal, { attrs }),
    {
      name: /*$t*/ "action.showOverview",
      shortcut: namedNoKey(() => i18n.global.t("mouse.doubleClick")),
    },
  ),
  showProgramMetrics: action(() => useModalStore().open(OverallMetricsModal), {
    name: /*$t*/ "action.programMetrics",
    icon: "metrics",
  }),
  showTeamMetrics: action(() => useModalStore().open(MetricsModal), {
    name: /*$t*/ "action.teamMetrics",
    icon: "metrics",
  }),
  shiftMenu: action(() => useBoardMenuStore().toggleMenuPosition(), {
    name: /*$t*/ "action.shiftMenu",
    icon: "menu-shift",
  }),
  showStats: action(() => useClientSettingsStore().toggleShowStats(1), {
    shortcut: namedKey("KeyI", { modifiers: ["altCtrl"] }),
  }),
  showStatGraphs: action(() => useClientSettingsStore().toggleShowStats(2), {
    shortcut: namedKey("KeyJ", { modifiers: ["altCtrl"] }),
  }),
  showDraw: action(() => useDrawStore().toggleActive()),
});
