import TextMeasurer from "@/TextMeasurer";
import {
  FontData,
  RawFontData,
  TextFontData,
  fontSizeCache,
} from "@/components/input/fontSizeCache";
import { replaceLinks, toHtmlWithLinks } from "@/linkParser";
import { useClientSettingsStore } from "@/store/clientSettings";

export function optimalOneLineFontSize(
  el: HTMLElement,
  maxWidth: number,
  minScale: number,
  maxScale: number,
) {
  let scale = 100 * minScale;
  el.style.fontSize = scale + "%";
  const expand = maxWidth / el.clientWidth;
  if (expand <= 1) {
    return scale;
  }
  scale = Math.min(100 * maxScale, scale * expand);
  el.style.fontSize = scale + "%";
  return scale;
}

const maxSteps = 7;

export function optimalFontSize<EDIT extends boolean>(
  el: HTMLElement,
  inputText: string,
  edit: EDIT,
): Promise<FontData<EDIT>> {
  const bounds = el.getBoundingClientRect();
  const style = window.getComputedStyle(el);

  // Calculate padding
  const paddingTop = parseFloat(style.paddingTop);
  const paddingBottom = parseFloat(style.paddingBottom);
  const paddingLeft = parseFloat(style.paddingLeft);
  const paddingRight = parseFloat(style.paddingRight);

  // Adjust width and height by subtracting padding
  const adjustedWidth = bounds.width - paddingLeft - paddingRight;
  const adjustedHeight = bounds.height - paddingTop - paddingBottom;

  return fontSizeCache(
    inputText,
    adjustedWidth,
    adjustedHeight,
    edit,
    useClientSettingsStore().textScale,
    useClientSettingsStore().stickyFont,
    () => {
      const { text, links } = edit
        ? { text: inputText, links: [] }
        : replaceLinks(inputText);
      const measurer = new TextMeasurer(el);
      let max =
        measurer.maxFontSize() *
        (useClientSettingsStore().textScale
          ? wordDownsize(measurer.maxFontSizeForText(), text)
          : 0.33333);
      let min = max / 20;
      for (let i = 0; i < maxSteps; i++) {
        const size = (min + max) / 2;
        const measure = measurer.measureLines(size, text);
        if (measure.height <= el.clientHeight) {
          min = size;
        } else {
          max = size;
        }
      }
      min = Math.round(min * 1000) / 1000;
      measurer.setFontSize(min);
      const minMeasure = measurer.measureLines(min, text);
      //textareaButNotEdit happens for the sticky that has the cursor during zoom
      //this is ok, but we should not cache the result as it's wrong after zooming has finished
      const textareaButNotEdit = !edit && el.nodeName === "TEXTAREA";
      const raw: RawFontData = { size: min, cache: !textareaButNotEdit };
      const res = edit
        ? raw
        : ({
            ...raw,
            htmlLines: toHtmlWithLinks(text, links, minMeasure.lines),
            textLines: minMeasure.lines.map(([startPos, endPos]) =>
              text.substring(startPos, endPos),
            ),
          } as TextFontData);
      return res as FontData<EDIT>;
    },
  );
}

const shortTextLen = 50;
const smallestFontSize = 0.23;
const minWordLen = 4;

// downsize fontSize to assure long words in short texts are not wrapped
function wordDownsize(fontSize: (s: string) => number, text: string) {
  if (text.length > shortTextLen) {
    return 1;
  }
  const minWordFontSize = minFontSize(text.split(/\s+/));
  return Math.max(smallestFontSize, minWordFontSize);

  function minFontSize(ss: string[]): number {
    const wordFontSize = (s: string) =>
      s.length < minWordLen ? 1 : fontSize(s);
    return ss.reduce((min, word) => Math.min(min, wordFontSize(word)), 1);
  }
}
