<template>
  <div class="sticky-link-objectives">
    <template v-if="showObjectives">
      <search-input-self-focusing
        v-model="query"
        wrapper-class="link-modal-search-input"
      />
      <div class="scrollable-list">
        <navigable-list
          :first-list="committedObjectives"
          :second-list="uncommittedObjectives"
          @select="toggleLink"
        >
          <template #first-list-header>
            <span v-if="committedObjectives.length" class="objectives-header">
              {{ $t("objectives.committed") }}
            </span>
          </template>
          <template #row="{ item }">
            <linkable-objective-row :linkable-objective="item" />
          </template>
          <template #second-list-header>
            <span v-if="uncommittedObjectives.length" class="objectives-header">
              {{ $t("objectives.uncommitted") }}
            </span>
          </template>
          <template #second-list-row="{ item }">
            <linkable-objective-row :linkable-objective="item" />
          </template>
        </navigable-list>
      </div>
    </template>
    <span v-if="showEmptyMessage" class="empty-placeholder">
      {{ $t("linkModal.empty") }}
    </span>
  </div>
</template>
<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { linkActions } from "@/action/linkActions";
import LinkableObjectiveRow from "@/components/LinkableObjectiveRow.vue";
import NavigableList from "@/components/NavigableList.vue";
import SearchInputSelfFocusing from "@/components/SearchInputSelfFocusing.vue";
import SearchBase from "@/mixins/SearchBase";
import { Card, Linkable, Objective, isDependency } from "@/model";
import { useBoardStore } from "@/store/board";
import { useSelectionStore } from "@/store/selection";

type Committable = {
  isCommitted: boolean;
};

export type LinkableObjective = Linkable & Objective & Committable;

export function allowLinkObjectives(cards: Card[]): boolean {
  if (cards.some(isDependency)) {
    return false;
  }

  return (
    !["risk", "program"].includes(useBoardStore().currentBoard().type) ||
    cards.every((card) => card.teamId)
  );
}

@Options({
  components: { NavigableList, SearchInputSelfFocusing, LinkableObjectiveRow },
  emits: ["link-change"],
})
export default class StickyLinkObjectivesTab extends mixins(SearchBase) {
  @Prop({ type: Array, required: true }) readonly cards!: Card[];
  query = "";

  get objectives(): LinkableObjective[] {
    return [
      ...this.linkableCommittedObjectives,
      ...this.linkableUncommittedObjectives,
    ];
  }

  get committedObjectives(): LinkableObjective[] {
    return this.filteredObjectives().filter(
      (objective) => objective.isCommitted,
    );
  }

  get uncommittedObjectives(): LinkableObjective[] {
    return this.filteredObjectives().filter(
      (objective) => !objective.isCommitted,
    );
  }

  get committedObjectivesFromStore(): Objective[] {
    return useBoardStore().boardByType("team").objectives || [];
  }

  get linkableCommittedObjectives(): LinkableObjective[] {
    return this.committedObjectivesFromStore.map((objective) => ({
      ...objective,
      linked: this.cards.every((card) =>
        objective.cards.some(({ id }) => card.id === id),
      ),
      isCommitted: true,
    }));
  }

  get uncommittedObjectivesFromStore(): Objective[] {
    return useBoardStore().boardByType("team").stretchObjectives || [];
  }

  get linkableUncommittedObjectives(): LinkableObjective[] {
    return this.uncommittedObjectivesFromStore.map((objective) => ({
      ...objective,
      linked: this.cards.every((card) =>
        objective.cards.some(({ id }) => card.id === id),
      ),
      isCommitted: false,
    }));
  }

  filteredObjectives(): LinkableObjective[] {
    if (this.query === "") {
      return this.objectives;
    }
    return this.found;
  }

  @Watch("query")
  queryChanged() {
    const query = this.query.toLowerCase();
    this.search(this.objectives, (linkableItem) => isQueryMatch(linkableItem));

    function isQueryMatch(linkableItem: LinkableObjective) {
      return (
        query.length === 0 || linkableItem.text.toLowerCase().includes(query)
      );
    }
  }

  get showObjectives(): boolean {
    return this.objectives.length > 0 && allowLinkObjectives(this.cards);
  }

  get showEmptyMessage(): boolean {
    return !this.showObjectives || this.filteredObjectives().length === 0;
  }

  toggleLink(linkableObjective: LinkableObjective) {
    if (useBoardStore().areMultipleStickiesSelected) {
      useSelectionStore().addLinkingToHistory();
    }
    if (linkableObjective.linked) {
      linkableObjective.linked = false;
      this.cards.forEach((card) => {
        linkActions.removeObjective(
          "modal",
          card.id,
          useBoardStore().boardByType("team").id,
          linkableObjective.id,
        );
      });
    } else {
      linkableObjective.linked = true;
      this.cards.forEach((card) => {
        linkActions.addObjective(
          "modal",
          card.id,
          useBoardStore().boardByType("team").id,
          linkableObjective.id,
        );
      });
    }
    this.$emit("link-change");
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/utils";
@use "@/styles/modal" as *;

.sticky-link-objectives {
  .link-modal-search-input {
    margin-bottom: 16px;
  }

  .objectives-header {
    margin: 1rem 0 0.5rem;
    font-size: font.$size-normal;
    font-weight: font.$weight-bold;
  }

  .empty-placeholder {
    @include empty-placeholder;
  }

  .scrollable-list {
    overflow-y: scroll;
    max-height: 45vh;

    @include utils.hide-scrollbar;
  }
}
</style>
