import { UnleashClient } from "unleash-proxy-client";

import {
  environment,
  featureProxySecret,
  featureProxyUrl,
} from "@/Environment";

export interface FeatureToggles {
  isEnabled(toggleName: string): boolean;
}

export let featureToggles: FeatureToggles = dummyToggles();

export async function initFeatureToggles(userId: string) {
  if (featureProxyUrl && featureProxySecret) {
    const unleash = unleashToggles();
    await unleash.updateContext({ userId });
    await unleash.start();
    featureToggles = unleash;
  }
}

function unleashToggles() {
  return new UnleashClient({
    url: featureProxyUrl,
    clientKey: featureProxySecret,
    appName: environment,
    refreshInterval: 60 * 60 * 12, // update toggles after 12h
    disableMetrics: true,
  });
}

function dummyToggles() {
  return {
    isEnabled(_toggleName: string) {
      return false;
    },
  };
}
