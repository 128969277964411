<template>
  <div class="menu-header" :class="{ 'dark-mode': darkMode }">
    <div class="menu-header-left">
      <template v-if="!isSearchActive">
        <exit-item />
        <menu-item-delimiter />
      </template>
      <search-item v-model="searchValue" class="menu-item__search" />
      <template v-if="showMetrics">
        <menu-item-delimiter />
        <metrics-item />
      </template>
      <template v-if="showExecutionMode">
        <menu-item-delimiter />
        <work-mode-item />
      </template>
    </div>

    <board-name />

    <div class="menu-header-right">
      <alm-status class="menu-item" />

      <template v-if="links && showRiskyLinks && !isSearchActive">
        <risky-links />
        <menu-item-delimiter />
      </template>

      <board-users />
      <FacilitationMenu />
      <RunningTimers v-if="activeTimers" @click.stop="toggleTimer" />

      <template v-if="isZoomEnabled">
        <menu-item-delimiter />
        <app-zoom class="menu-item" />
      </template>
      <menu-item-delimiter />
      <user-actions is-user-on-board />
    </div>
    <transition>
      <g-p-t-panel v-if="showGPT" />
    </transition>
    <TimerPanel />
    <ActivityPanel />
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";

import { toggleActions } from "@/action/toggleActions";
import ActivityPanel from "@/components/ActivityPanel/ActivityPanel.vue";
import AlmStatus from "@/components/AlmStatus.vue";
import BoardName from "@/components/BoardName.vue";
import BoardUsers from "@/components/BoardUsers.vue";
import RiskyLinks from "@/components/RiskyLinks.vue";
import SearchItem from "@/components/Search/SearchItem.vue";
import RunningTimers from "@/components/Timer/RunningTimers.vue";
import TimerPanel from "@/components/Timer/TimerPanel.vue";
import UserActions from "@/components/UserActions.vue";
import AppZoom from "@/components/menu/AppZoom.vue";
import ExitItem from "@/components/menu/ExitItem.vue";
import GPTPanel from "@/components/menu/GPTPanel.vue";
import MetricsItem from "@/components/menu/MetricsItem.vue";
import FacilitationMenu from "@/components/menu/components/FacilitationMenu.vue";
import MenuItemDelimiter from "@/components/menu/components/MenuItemDelimiter.vue";
import WorkModeItem from "@/components/menu/components/WorkModeItem.vue";
import EventBusUser from "@/mixins/EventBusUser";
import { isPlanningBoard } from "@/model";
import { useBoardStore } from "@/store/board";
import { useGPTStore } from "@/store/gpt";
import { useLinkStore } from "@/store/link";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useTimerStore } from "@/store/timer";
import { useWorkModeStore } from "@/store/workMode";

@Options({
  components: {
    ActivityPanel,
    GPTPanel,
    MetricsItem,
    SearchItem,
    ExitItem,
    AppZoom,
    UserActions,
    AlmStatus,
    BoardUsers,
    RiskyLinks,
    BoardName,
    WorkModeItem,
    MenuItemDelimiter,
    RunningTimers,
    FacilitationMenu,
    TimerPanel,
  },
})
export default class TopMenuBar extends mixins(EventBusUser) {
  searchValue = "";

  get darkMode() {
    return (
      !this.isSearchActive &&
      useWorkModeStore().showExecutionMode &&
      useWorkModeStore().isExecutionMode
    );
  }

  get isSearchActive() {
    return useSearchMenuStore().isSearchActive;
  }

  get isZoomEnabled() {
    return useBoardStore().isCurrentBoardFluid;
  }

  get showAlmSyncStatus() {
    return useBoardStore().board?.type !== "flex";
  }

  get showRiskyLinks() {
    return (
      isPlanningBoard(useBoardStore().board?.type) ||
      useBoardStore().board?.type === "team"
    );
  }

  get showMetrics() {
    return (
      useBoardStore().board?.type === "program" ||
      useBoardStore().board?.type === "team"
    );
  }

  get showExecutionMode() {
    return useWorkModeStore().showExecutionMode && !this.isSearchActive;
  }

  get showGPT() {
    return useGPTStore().isGPTActive;
  }

  get links() {
    return useLinkStore().hasRiskyLinks(useBoardStore().currentBoard());
  }

  get activeTimers() {
    const allTimers = [
      ...useTimerStore().boardTimers,
      ...useTimerStore().artTimers,
    ];
    return allTimers.some((timer) => timer.data.state === "running");
  }

  toggleTimer() {
    toggleActions.showTimer("mainMenu");
  }
}
</script>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

.menu {
  &-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--back-color);
    border-bottom: 2px solid colors-old.$border-menu-color;
    height: variables.$top-header-height;
    z-index: z-index.$menu;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 3px;
    padding-right: 14px;
    transition: 0.15s ease-in-out background-color;

    &-left,
    &-right {
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-basis: 0;
    }

    &-right {
      justify-content: flex-end;

      .menu-item-delimiter {
        margin: 0 8px;
      }
    }

    .menu {
      &-item {
        min-height: 35px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &__search {
          max-height: variables.$top-header-height;
        }
      }
    }
  }
}

.plan-readout-button-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
