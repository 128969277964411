import { LocationQuery, LocationQueryValue } from "vue-router";

type QueryValue = LocationQueryValue | LocationQueryValue[];

export function firstQueryParam(query: LocationQuery, name: string) {
  return firstQueryValue(query[name]);
}

export function serializeObject(obj: object, prefix: string): LocationQuery {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [prefix + key, serialize(value)]),
  );
}

export function deserializeObject(query: LocationQuery, prefix: string) {
  return Object.fromEntries(
    Object.entries(query).flatMap(([key, value]) =>
      key.startsWith(prefix)
        ? [[key.substring(prefix.length), deserialize(firstQueryValue(value))]]
        : [],
    ),
  );
}

export function removePropsWithPrefix(query: LocationQuery, prefix: string) {
  return Object.fromEntries(
    Object.entries(query).flatMap(([key, value]) =>
      key.startsWith(prefix) ? [] : [[key, value]],
    ),
  );
}

function firstQueryValue(value: QueryValue): LocationQueryValue {
  return Array.isArray(value) ? value[0] : (value as LocationQueryValue);
}

function serialize(value: any): LocationQueryValue {
  if (Array.isArray(value)) {
    return "[" + value.join() + "]";
  }
  return value;
}

function deserialize(value: LocationQueryValue): QueryValue {
  if (!value) {
    return null;
  }
  return value.startsWith("[") && value.endsWith("]")
    ? value.substring(1, value.length - 1).split(",")
    : value;
}
