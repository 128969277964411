<template>
  <div class="link-info">
    <span>{{ teamName }}</span
    ><span v-if="teamName && (iterationName || almId)" data-t
      >&nbsp;/&nbsp;</span
    >
    <span>{{ iterationName }}</span
    ><span v-if="iterationName && almId" data-t>&nbsp;/&nbsp;</span>
    <span style="flex-shrink: 0">{{ almId }}</span>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { CardLink } from "@/model";
import { useIterationStore } from "@/store/iteration";
import { useTeamStore } from "@/store/team";

export default class LinkInfo extends Vue {
  @Prop(Object) readonly link!: CardLink;

  get teamName() {
    if (this.link.type === "objective") {
      return "";
    }
    const teamId = this.link.teamId;
    const team = useTeamStore().teams.find((t) => t.id === teamId);
    return team ? team.name : "";
  }

  get iterationName() {
    if (this.link.type === "objective") {
      return "";
    }
    const iterationId = this.link.iterationId;
    const iter = useIterationStore().iterations.find(
      (i) => i.id === iterationId,
    );
    return iter ? iter.name : "";
  }

  get almId() {
    return this.link.type === "objective" ? "" : this.link.almId || "";
  }
}
</script>

<style lang="scss">
.link-info {
  position: absolute;
  right: 4em;
  top: 0.3em;
  font-size: 50%;
  line-height: 1em;
  display: flex;

  span {
    flex-shrink: 1;
    word-break: break-all;
    height: 1em;
    overflow: hidden;
  }
}
</style>
