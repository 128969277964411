import { InjectionKey } from "vue";

import { Board, Card } from "@/model";

import CardComponent from "./Card.vue";

export const cardMethodsKey: InjectionKey<CardMethods> = Symbol();
export const cardKey: InjectionKey<Card> = Symbol("card");
export const boardKey: InjectionKey<Board> = Symbol("board");

export type CardMethods = {
  removePin: typeof CardComponent.prototype.removePin;
  animate: typeof CardComponent.prototype.animate;
  animateCopy: typeof CardComponent.prototype.animateCopy;
};
