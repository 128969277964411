const emojis = [
  [":)", "😀"],
  [";)", "😉"],
  [":(", "🙁"],
  [":-)", "😀"],
  [";-)", "😉"],
  [":-(", "🙁"],
  [":+1:", "👍"],
  [":-1:", "👎"],
  [":cool:", "😎"],
  [":heart:", "❤️"],
  [":fire:", "🔥"],
  [":shit:", "💩"],
  [":party:", "🎉"],
  [":100:", "💯"],
  [":ren:", "🦌"],
  [":rentouch:", "🦌"],
  [":ahirsch:", "🦌"],
  [":pizza:", "🍕"],
  [":coffee:", "☕"],
  ["->", "→"],
  ["=>", "⇒"],
  ["<-", "←"],
  ["<=", "⇐"],
];

export function replaceEmoji(
  text: string,
): { text: string; deltaLen: number } | undefined {
  const emoji = emojis.find((em) => text.includes(em[0]));
  if (emoji) {
    return {
      text: text.replace(emoji[0], emoji[1]),
      deltaLen: emoji[0].length - emoji[1].length,
    };
  }
}
