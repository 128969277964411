<script lang="ts" setup>
import { computed } from "vue";

import { useWorkModeStore } from "@/store/workMode";

const isProgressBarVisible = computed(() => {
  return useWorkModeStore().isExecutionMode;
});
</script>

<template>
  <div class="sticky-note-progress">
    <div v-if="isProgressBarVisible" class="progress-bar"></div>
    <div class="link-drag">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 17">
        <circle cx="5" cy="12" r="4.5" stroke="currentColor" />
        <path fill="currentColor" d="M4.5 0h1v8h-1z" />
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "../../utils";
@use "@/styles/colors";

.sticky-note-progress {
  $height: utils.px-to-em(30px);

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(105%);
  height: $height;
  pointer-events: none;

  .progress-bar {
    height: utils.px-to-em(10px);
    background-color: colors.$primary-color;
  }

  .link-drag {
    pointer-events: all;
    cursor: grabbing;
    position: relative;

    // height: utils.px-to-em(20px);
    width: utils.px-to-em(20px);
    color: colors.$primary-color;
    margin-left: auto;
    margin-right: auto;

    svg {
      pointer-events: none;
    }
  }
}
</style>
