<script setup lang="ts">
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";

type ButtonGroupButton = { content: string; isActive: boolean };

const props = defineProps<{ buttons: ButtonGroupButton[] }>();

const emit = defineEmits<{ change: [buttons: ButtonGroupButton[]] }>();

function change(toButton: ButtonGroupButton) {
  const buttons = props.buttons.map((button) => {
    return {
      ...button,
      isActive: button === toButton,
    };
  });
  emit("change", buttons);
}
</script>

<template>
  <div class="button-group">
    <BaseButton
      v-for="button in buttons"
      :key="button.content"
      :variant="button.isActive ? 'filled' : 'outlined'"
      :color="button.isActive ? 'primary' : 'grey'"
      @click="change(button)"
    >
      {{ button.content }}
    </BaseButton>
  </div>
</template>

<style lang="scss">
.button-group {
  display: flex;

  .button {
    &:not(:first-child, :last-child) {
      border-radius: 0;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
