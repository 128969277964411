import { defineStore } from "pinia";
import { v4 as uuid } from "uuid";

export const useClientStore = defineStore("client", {
  state: () => {
    return {
      // eslint-disable-next-line no-bitwise
      clientId: Math.floor(Math.random() * (1 << 16) * (1 << 16)).toString(16),
    };
  },
  actions: {
    newCorrelationId() {
      return this.clientId + uuid().substring(this.clientId.length);
    },
  },
});
