<template>
  <div class="keyboard-navigable-list">
    <slot name="first-list-header"></slot>
    <div
      v-for="item in firstList"
      :id="'navigable-item-' + item.id"
      :key="item.id"
      class="item"
      :class="{ current: isCurrent(item) }"
      @click.prevent="selectItem(item)"
      @pointermove="setCurrent(item)"
    >
      <slot name="row" :item="item"></slot>
    </div>

    <slot name="second-list-header"></slot>
    <div
      v-for="item in secondList"
      :id="'navigable-item-' + item.id"
      :key="item.id"
      class="item"
      :class="{ current: isCurrent(item) }"
      @click.prevent="selectItem(item)"
      @pointermove="setCurrent(item)"
    >
      <slot name="second-list-row" :item="item"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import ShortcutUser from "@/mixins/ShortcutUser";

export interface ItemModel {
  id: string;
}

@Options({ emits: ["select"] })
export default class NavigableList extends mixins(ShortcutUser) {
  @Prop({ type: Array, required: true }) readonly firstList!: ItemModel[];
  @Prop({ type: Array, required: false, default: () => [] })
  readonly secondList!: ItemModel[];

  current: ItemModel | null = null;

  created() {
    this.activateShortcuts();
  }

  activateShortcuts() {
    this.shortcut("ArrowUp", () => this.changeCurrent(-1));
    this.shortcut("ArrowDown", () => this.changeCurrent(1));
    this.shortcut("Enter", () => this.selectCurrentByKey());
    this.shortcut(" ", () => this.selectCurrentByKey());
  }

  isCurrent(item: ItemModel) {
    return !!this.current && item.id === this.current.id;
  }

  setCurrentByKey(item: ItemModel) {
    this.setCurrent(item);
    document
      .getElementById("navigable-item-" + item.id)
      ?.scrollIntoView({ block: "nearest" });
  }

  setCurrent(item: ItemModel) {
    this.current = item;
  }

  selectCurrentByKey() {
    if (this.current) {
      this.selectItem(this.current);
    }
  }

  findCurrentIndex() {
    let currentIndex = this.firstList.findIndex((item) => this.isCurrent(item));
    if (currentIndex < 0) {
      currentIndex = this.secondList.findIndex((item) => this.isCurrent(item));
      if (currentIndex >= 0) {
        currentIndex += this.firstList.length;
      }
    }
    return currentIndex;
  }

  changeCurrent(itemDiff: number) {
    const currentIndex = this.findCurrentIndex();
    const itemsLength = this.firstList.length + this.secondList.length;

    let newIndex = currentIndex + itemDiff;
    newIndex =
      newIndex >= itemsLength ? 0 : newIndex < 0 ? itemsLength - 1 : newIndex;
    if (newIndex < this.firstList.length) {
      this.setCurrentByKey(this.firstList[newIndex]);
    } else {
      this.setCurrentByKey(this.secondList[newIndex - this.firstList.length]);
    }
  }

  selectItem(item: ItemModel) {
    this.$emit("select", item);
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.keyboard-navigable-list {
  cursor: pointer;

  .item {
    padding: 0 0.8em;

    &.current {
      background: colors-old.$light-background-color;

      :deep(.icon-button) {
        background-color: colors-old.$light-background-color;
      }
    }
  }
}
</style>
