<template>
  <div
    v-focus-trap
    class="add-objective-card"
    data-testid="add-objective"
    tabindex="0"
    @focusout="unfocus"
    @pointerdown.prevent
  >
    <max-length-textarea
      ref="textarea"
      v-model="text"
      class="add-title-objective-textarea"
      autofocus
      :placeholder="$t('objectives.title.placeholder')"
      :maxlength="260"
    />
    <base-textarea
      v-bind="$attrs"
      v-model="description"
      :placeholder="$t('objectives.description.placeholder')"
      class="add-description-objective-textarea"
    />
    <div class="buttons-container">
      <BaseButton :disabled="!isAddButtonEnabled" @click="saveObjective">
        {{ $t("objectivesModal.add") }}
      </BaseButton>
      <BaseButton variant="outlined" color="grey" @click="cancel"
        >{{ $t("general.cancel") }}
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { objectiveActions } from "@/action/objectiveActions";
import BaseTextarea from "@/components/input/BaseTextarea.vue";
import MaxLengthTextarea from "@/components/input/MaxLengthTextarea.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { Board, ObjectiveType } from "@/model";
import { objectiveCreated } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

@Options({
  components: {
    BaseButton,
    MaxLengthTextarea,
    BaseTextarea,
  },
  emits: ["cancel"],
})
export default class AddObjectiveCard extends Vue {
  @Prop({ required: true, type: String }) readonly type!: ObjectiveType;
  @Prop({ required: true, type: Object }) readonly board!: Board;

  text = "";
  description = "";

  get isAddButtonEnabled() {
    return this.text.length !== 0;
  }

  unfocus(event: FocusEvent) {
    if (!this.text.length && !this.$el.contains(event.relatedTarget as Node)) {
      this.cancel();
    }
  }

  saveObjective() {
    trackEvent(objectiveCreated());
    const isCommitted = this.type === "committed";
    objectiveActions.add(
      "mainMenu",
      {
        boardId: this.board.id,
        text: this.text,
        description: this.description,
      },
      isCommitted,
    );
    this.text = "";
    this.description = "";
    this.cancel();
  }

  cancel() {
    this.$emit("cancel");
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins" as *;

.add-objective-card {
  margin-bottom: 12px;
  border: 1px solid colors-old.$objective-border-color;
  padding: 16px;
  width: 100%;

  @include objective-card-shadow;

  border-radius: variables.$objective-card-border-radius;

  :deep(.add-title-objective-textarea) {
    user-select: auto;
    padding: 10px 6px;
    resize: none;
    overflow: hidden;
    width: 100%;
    font-size: font.$size-normal;
    border: 1px solid colors-old.$hover-color;
    padding-right: 65px;

    &:focus {
      outline: none;
    }
  }

  :deep(.add-title-objective-textarea),
  .add-description-objective-textarea {
    background-color: colors-old.$light-background-color;
    border-radius: variables.$border-radius;

    &::placeholder {
      color: colors-old.$placeholder-color;
      font-weight: font.$weight-normal;
    }
  }

  .add-description-objective-textarea {
    min-height: 100px !important;
    margin-top: 10px;
    font-weight: font.$weight-normal;
  }

  &:hover {
    border: 1px solid colors-old.$objective-border-color-hovered;
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 10px;

    > * {
      min-width: 100px;
    }
  }
}
</style>
