<template>
  <div class="time">
    <suffix-input
      id="duration"
      :value="duration.hourStr"
      allowed="0123456789"
      :max-length="2"
      suffix="h"
      @change="$emit('duration', duration.withHours(+$event))"
    />
    <suffix-input
      :value="duration.minuteStr"
      allowed="0123456789"
      :max-length="2"
      suffix="m"
      @change="$emit('duration', duration.withMinutes(+$event))"
    />
    <suffix-input
      :value="duration.secondStr"
      allowed="0123456789"
      :max-length="2"
      suffix="s"
      @change="$emit('duration', duration.withSeconds(+$event))"
    />
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import Duration from "@/components/Timer/Duration";
import SuffixInput from "@/components/input/SuffixInput.vue";
import LabeledInput from "@/mixins/LabeledInput";

@Options({ components: { SuffixInput }, emits: ["duration"] })
export default class InputTime extends mixins(LabeledInput) {
  @Prop(Object) readonly duration!: Duration;

  mounted() {
    this.setForId("duration");
  }
}
</script>

<style lang="scss">
.time {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
</style>
