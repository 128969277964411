<script setup lang="ts">
import { computed } from "vue";

import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import { useSearchMenuStore } from "@/store/searchMenu";

import IterationItemTooltip from "../tooltips/IterationItemTooltip.vue";

const vKeyboardNav = keyboardNavigation();

const store = useSearchMenuStore();

const selection = computed({
  get: () => store.selectedIterations,
  set: (values: Array<{ id: number }>) =>
    (store.selectedIterationIds = new Set(values.map((val) => val.id))),
});
</script>

<template>
  <DropdownMenu class="search-select" width="sm" stay-on-click>
    <template #trigger="{ isOpen }">
      <IconButton icon="iteration" :activated="isOpen">
        <template #tooltip>
          <IterationItemTooltip :iterations="selection" />
        </template>
      </IconButton>
      <InfoMarker v-if="selection.length" :top="4" :right="4" />
    </template>
    <SelectableList
      v-model="selection"
      v-keyboard-nav
      :values="store.allIterations"
      :header="$t('general.iterations')"
    >
      <template #default="{ value }">
        {{ $t("general.namedIteration", { name: value.name }) }}
      </template>
    </SelectableList>
  </DropdownMenu>
</template>
