<script lang="ts" setup>
import { computed } from "vue";

import { boardKey, cardKey } from "@/components/card/injectKeys";
import { isBacklogBoard, isPlanningBoard, isSolutionBoard } from "@/model";
import { useBoardStore } from "@/store/board";
import { useWorkModeStore } from "@/store/workMode";
import { injectStrict } from "@/utils/context";

import StickyNoteRow from "../../../StickyNoteRow/StickyNoteRow.vue";
import MirrorStickyChip from "../MirrorStickyChip/MirrorStickyChip.vue";
import StickyLinksChip from "../StickyLinksChip/StickyLinksChip.vue";
import SelectStatusChip from "../StickyStatusChip/StickyStatusChip.vue";
import TeamSelectChip from "../TeamSelectChip/TeamSelectChip.vue";
import WSJFChip from "../WSJFChip/WSJFChip.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const isSelected = computed(() => useBoardStore().activeCardId === card.id);

const isTeamChipVisible = computed(() => {
  return (
    // visiable on all backlog boards
    isBacklogBoard(board.type) ||
    // visiable on solution planning board
    (isSolutionBoard(board.type) && isPlanningBoard(board.type))
  );
});

const isLinksChipVisible = computed(() => {
  // visible only if sticky is selected or is planning board
  return (
    isPlanningBoard(board.type) || isSelected.value || board.type === "team"
  );
});

const isMirrorChipVisible = computed(() => {
  return isSelected.value; // visible only if sticky is selected
});

const isWSJFChipVisible = computed(() => {
  const mode = useWorkModeStore();
  return (
    mode.isPlanningMode && // visible only in planning mode
    board.type !== "team" // hidden on team board
  );
});

const isStatusChipVisible = computed(() => {
  return useWorkModeStore().isExecutionMode; // visible only in execution mode
});
</script>

<template>
  <StickyNoteRow class="workitem-footer">
    <div v-if="isTeamChipVisible" class="team"><TeamSelectChip /></div>
    <StickyLinksChip v-if="isLinksChipVisible" />
    <MirrorStickyChip v-if="isMirrorChipVisible" />
    <div class="right-side">
      <WSJFChip v-if="isWSJFChipVisible" />
      <SelectStatusChip v-if="isStatusChipVisible" />
    </div>
  </StickyNoteRow>
</template>

<style lang="scss" scoped>
@use "../../../../utils";

.workitem-footer {
  .right-side {
    margin-left: auto;
  }

  .team {
    overflow: hidden;
    flex-shrink: 1;
  }
}
</style>
