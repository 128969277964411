<script setup lang="ts">
import { ref } from "vue";

import { cardActions } from "@/action/cardActions";
import BasePopup from "@/components-ng/BasePopup/BasePopup.vue";
import BasePopupTitle from "@/components-ng/BasePopup/components/BasePopupTitle/BasePopupTitle.vue";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import BoardNumberInput from "@/components/input/BoardNumberInput.vue";
import { injectStrict } from "@/utils/context";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);

const priority = ref(card.priority);

const selectPriority = (priority: number): void => {
  cardActions.setPriority("card", card.id, priority);
  cardActions.stopAlter("internal", card.id);
  dropdown.close();
};

const setInput = (input: number) => {
  priority.value = input;
};
</script>

<template>
  <BasePopup class="wsjf-container">
    <BasePopupTitle>{{ $t("actions.wsjf") }}</BasePopupTitle>
    <span class="wsjf-input-wrap">
      <BoardNumberInput
        class="wsjf-input"
        :value="priority"
        :maxlength="3"
        :data-testid="`wsjf-input`"
        @input="setInput"
        @blur="selectPriority(priority)"
        @keyup.enter="$event.target.blur()"
      />
    </span>
  </BasePopup>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.wsjf-container {
  width: 120px;
}

.wsjf-input-wrap {
  display: flex;
  height: 40px;
  margin: 6px;

  .wsjf-input {
    text-align: center;
    font-size: font.$size-normal;
    font-weight: font.$weight-medium;
    color: colors-old.$inactive-text-color;
  }

  .wsjf-input:focus {
    color: colors-old.$text-primary-color;
  }
}
</style>
