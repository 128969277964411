import { InjectionKey, inject } from "vue";

/**
 * Injects a value from the Vue composition API's injection system.
 * If the value is not found, an error is thrown.
 */
export function injectStrict<T>(key: InjectionKey<T>, fallback?: T) {
  const resolved = inject(key, fallback);
  if (!resolved) {
    throw new Error(`Could not resolve ${key.description}`);
  }
  return resolved;
}
