<template>
  <div class="team-iteration back-color" :class="flagType">
    <div
      v-if="isExecutionMode && isCurrentIteration"
      class="time-line"
      :style="{ width: iterationNow.iterationsPassed * 100 + '%' }"
    >
      <svg>
        <line x1="0" y1="0" x2="0" y2="100%" />
      </svg>
    </div>
    <status-distribution
      v-if="isExecutionMode"
      class="iteration-distribution"
      :value="distribution"
      :iteration="iteration"
      source-item-type="iteration"
    />
    <div class="head">
      <span class="left back-color">
        <h3 :title="iteration.name" class="iteration-name">
          {{ iteration.name }}
        </h3>
        <div class="row">
          <iteration-flag :type="flagType" class="flag-icon" />
          <div class="metric-wrap">
            <label :for="`capacity-${iteration.id}`" class="metric-title">{{
              $t("general.capacity")
            }}</label>
            <span class="metric-input-wrap">
              <board-number-input
                :id="`capacity-${iteration.id}`"
                class="metric-input"
                :value="teamIteration.velocity"
                :maxlength="3"
                @input="changeVelocity"
                @dblclick.stop
              />
            </span>
          </div>
          <div class="metric-wrap">
            <span class="metric-title">{{ $t("loadCapacityChart.load") }}</span>
            <span class="metric-input-wrap">
              <board-number-input
                class="metric-input"
                readonly
                :value="round(teamIteration.load, 1)"
              />
            </span>
          </div>
        </div>
      </span>
      <span class="right back-color">
        <div class="row">
          <h4 class="iteration-date">
            {{ dates(iteration) }}
          </h4>
        </div>
        <div class="row actions">
          <span
            class="stat-icon"
            @click="$emit('metrics')"
            @pointerenter="metrics(true)"
            @pointerleave="metrics(false)"
          >
            <SvgIcon name="pie" size=".75em" />
          </span>
          <kebab-menu :board="$parent" mode="iteration" />
        </div>
      </span>
    </div>
    <div
      v-if="showMetrics"
      class="load-stat"
      :style="{ zIndex: board.maxZ + 1 }"
    >
      <pie-chart
        :value="capacityAllocation.values"
        :total="capacityAllocation.total"
        :zoom="fakeZoom / zoom"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { round } from "lodash-es";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { fakeZoom } from "@/Settings";
import { boardActions } from "@/action/boardActions";
import IterationFlag from "@/components/IterationFlag.vue";
import PieChart from "@/components/PieChart.vue";
import { iterationStatusDistribution } from "@/components/StatusDistribution";
import StatusDistribution from "@/components/StatusDistribution.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import BoardNumberInput from "@/components/input/BoardNumberInput.vue";
import KebabMenu from "@/components/menu/KebabMenu.vue";
import {
  CapacityAllocation,
  calcCapacityAllocation,
} from "@/components/modal/metrics";
import { Board, Card, InfoLevel, Iteration } from "@/model";
import { useAppSizeStore } from "@/store/appSize";
import { useBoardStore } from "@/store/board";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useIterationStore } from "@/store/iteration";
import { useWorkModeStore } from "@/store/workMode";
import { plusDays } from "@/utils/date";
import { formatShortDate } from "@/utils/dateFormat";

@Options({
  components: {
    SvgIcon,
    PieChart,
    KebabMenu,
    StatusDistribution,
    IterationFlag,
    BoardNumberInput,
  },
  emits: ["metrics"],
})
export default class TeamIteration extends Vue {
  @Prop(Number) readonly index!: number;
  @Prop(Number) readonly load!: number;
  @Prop(Boolean) readonly readOnly!: boolean;

  showMetrics = false;
  fakeZoom = fakeZoom;
  capacityAllocation: CapacityAllocation = { total: 0, values: [] };
  round = round;

  get flagType(): InfoLevel {
    const v = this.teamIteration.velocity;
    if (!v) {
      return "default";
    }
    const r = this.teamIteration.load / v;
    return r < useClientSettingsStore().loadWarn / 100
      ? "ok"
      : r >= 1
      ? "error"
      : "warn";
  }

  get zoom() {
    return useAppSizeStore().appSize.zoom;
  }

  get board(): Board {
    return useBoardStore().boardByType("team");
  }

  get cards(): Card[] {
    return Object.values(this.board.cards).map((boardCard) => boardCard.data);
  }

  get isExecutionMode() {
    return useWorkModeStore().isExecutionMode;
  }

  get distribution() {
    return iterationStatusDistribution(this.cards, this.index);
  }

  get iteration() {
    return useIterationStore().iterations[this.index];
  }

  get teamIteration() {
    return useBoardStore().boardByType("team").iterations[this.index];
  }

  get iterationNow() {
    return useIterationStore().iterationProgress(new Date(), this.iteration);
  }

  get isCurrentIteration() {
    return (
      this.iterationNow.iterationsPassed > 0 &&
      this.iterationNow.iterationsPassed < 1
    );
  }

  metrics(show: boolean) {
    this.showMetrics = false;
    if (show) {
      this.capacityAllocation = calcCapacityAllocation(
        [this.board],
        this.index,
      );
      if (this.capacityAllocation.total > 0) {
        this.showMetrics = true;
      }
    }
  }

  changeVelocity(v: string) {
    boardActions.setVelocity(
      "board",
      useBoardStore().currentBoard().id,
      this.index,
      +v,
    );
  }

  dates(iter: Iteration) {
    return this.$t("date.range", {
      from: formatShortDate(iter.start),
      to: formatShortDate(plusDays(iter.end, -1)),
    });
  }
}
</script>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "sass:color";
@use "@/styles/time-line" as *;

.team-iteration {
  &.default,
  &.default .back-color {
    background-color: colors-old.$back-color;
  }

  &.ok,
  &.ok .back-color {
    background-color: color.scale(
      colors-old.$iteration-success-color,
      $lightness: 95%
    );
  }

  &.warn,
  &.warn .back-color {
    background-color: color.scale(
      colors-old.$iteration-warning-color,
      $lightness: 95%
    );
  }

  &.error,
  &.error .back-color {
    background-color: color.scale(
      colors-old.$iteration-error-color,
      $lightness: 95%
    );
  }

  .time-line {
    top: 0;
    left: 0;
  }

  .head {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .left {
      display: flex;
      flex-direction: column;
      gap: board.len(6px);

      .row {
        display: flex;
        gap: board.len(8px);
        align-items: center;
      }

      h3.iteration-name {
        font-size: board.len(14px);
        font-weight: font.$weight-bold;
        word-break: break-word;
      }

      .flag-icon {
        width: board.len(20px);
        height: board.len(20px);
      }

      .metric-wrap {
        display: flex;
        align-items: center;
        gap: board.len(6px);

        .metric-title {
          color: colors-old.$text-secondary-color;
          font-size: board.len(10px);
          font-weight: font.$weight-semi-bold;
        }

        .metric-input-wrap {
          display: inline-flex;
          width: board.len(28px);
          height: board.len(20px);

          .metric-input {
            text-align: center;
            font-size: board.len(10px);
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: board.len(6px);

      .row {
        display: flex;
        align-items: center;
        gap: board.len(6px);

        &.actions {
          justify-content: flex-end;
        }
      }

      .stat-icon {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: colors-old.$text-secondary-color;
      }

      .iteration-date {
        font-size: board.len(10px);
        font-weight: font.$weight-semi-bold;
        color: colors-old.$text-secondary-color;
        white-space: nowrap;
      }
    }
  }

  .load-stat {
    position: absolute;
    top: 1.8em;
    right: 1.4em;
    width: 5em;
    height: 5em;
  }

  .iteration-distribution {
    height: board.len(10px);
    position: absolute;
    left: board.len(12px);
    right: board.len(12px);
    top: 0;

    .bar-chart-wrap {
      padding-top: board.len(4px);

      .stacked-bar-chart {
        .empty-bar rect {
          fill: colors-old.$progress-empty-fill-color;
        }

        .bordered {
          border-width: board.len(1px);
        }
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}
</style>
