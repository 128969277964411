import { defineStore } from "pinia";

import { i18n } from "@/i18n";
import { Art, emptySelectList } from "@/model";
import { useBoardStore } from "@/store/board";

export const NO_ART_ID = "";

export const useArtStore = defineStore("art", {
  state: () => ({ art: emptySelectList<Art>() }),
  getters: {
    currentArt: (state) => {
      return state.art.current;
    },
    arts: (state) => {
      return state.art.list;
    },
    isMultiArt: (state) => {
      return state.art.list.length > 1;
    },
    artById: (state) => (artId?: Art["id"]) => {
      if (artId !== undefined) {
        return state.art.list.find((a) => a.id === artId);
      }
    },
    noArt: () => {
      return { id: NO_ART_ID, name: i18n.global.t("general.noArt") };
    },
  },
  actions: {
    setArt(art: Art) {
      this.art.selected = this.art.current = art;
      useBoardStore().updateObjectiveBoardId();
    },
    setArts(e: Art[]) {
      this.art.list = e;
    },
    isCurrentArt(artId?: string) {
      return !this.isMultiArt || artId === this.art.current.id;
    },
  },
});
