<script async setup lang="ts">
import { computed } from "vue";

import { Icon } from "@/types/icon";

import { getIconComponent } from "./loader";

type Props = { name: Icon; size?: string };
const props = withDefaults(defineProps<Props>(), {
  size: "100%",
});

const IconComponent = computed(() => getIconComponent(props.name));
</script>

<template>
  <IconComponent
    :width="size"
    :height="size"
    role="img"
    :class="$attrs.class"
  />
</template>
