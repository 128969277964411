<template>
  <base-planning-board
    ref="board"
    :planning-board="board"
    :group-iterations="groupIterations"
    :cards-for-distribution="cardsForDistribution"
    :field-class="fieldClass"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import BasePlanningBoard from "@/components/BasePlanningBoard.vue";
import { BoardIteration, Group } from "@/model";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useIterationStore } from "@/store/iteration";

@Options({ components: { BasePlanningBoard } })
export default class SolutionBoard extends Vue {
  get board() {
    return useBoardStore().boardByType("solution");
  }

  get groupIterations() {
    // TODO this is used to show load/capacity on the overview modal (and for coloring overloaded fields)
    // replace useIterationStore().iterations with the real calculated values for load/capacity
    // see https://rentouch.atlassian.net/browse/REN-11477
    return Object.fromEntries(
      useArtStore().arts.map((art) => [art.id, useIterationStore().iterations]),
    );
  }

  fieldClass(group: Group, _iter?: BoardIteration) {
    return group.name === useArtStore().currentArt.name ? "group-current" : "";
  }

  get cardsForDistribution() {
    // FIXME: https://rentouch.atlassian.net/browse/REN-11477
    return [];
  }

  boardComponent() {
    return this.$refs.board;
  }
}
</script>
