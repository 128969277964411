import { defineStore } from "pinia";

import {
  AlmSourceId,
  BoardType,
  StatusClass,
  statusClassNames,
} from "@/baseTypes";
import {
  AlmItemStatus,
  AlmItemType,
  AlmItemTypeMap,
  Board,
  Card,
  Status,
  StickyType,
} from "@/model";

const emptyAlmItemType = {
  statuses: [],
  globalTargetStatuses: [],
  dynamic: false,
};

export const useAlmItemTypeStore = defineStore("almItemTypes", {
  state: () => ({
    almItemTypes: {} as { [stickyTypeId: string]: AlmItemTypeMap },
  }),
  getters: {},
  actions: {
    findAlmItemType(
      type: StickyType,
      teamId: string | null | undefined,
      artId: string | null | undefined,
      almSourceId: AlmSourceId | null,
    ): AlmItemType {
      const boardKey = this.getBoardKey(
        type.origin,
        artId ?? undefined,
        teamId ?? undefined,
      );
      const almItemTypes = this.almItemTypes[type.id] || {};
      const mappingAlmItemType =
        almItemTypes[boardKey ?? ""] || almItemTypes[""];
      return (
        mappingAlmItemType?.[almSourceId ?? ""] ||
        mappingAlmItemType?.[""] ||
        emptyAlmItemType
      );
    },
    getBoardKey(
      boardType: BoardType,
      artId: string | undefined,
      teamId: string | undefined,
    ): string | undefined {
      switch (boardType) {
        case "backlog":
          return artId;
        case "team":
          return teamId;
      }
    },
    calcStatus(
      status: string | undefined,
      type: StickyType,
      teamId: string | null | undefined,
      artId: string | null | undefined,
      almSourceId: AlmSourceId | null,
    ): AlmItemStatus | undefined {
      if (type.functionality !== "workitem") {
        return;
      }
      const almItemType = this.findAlmItemType(
        type,
        teamId,
        artId,
        almSourceId,
      );
      return status
        ? this.findStatus(almItemType, status)
        : this.findInitialStatus(almItemType);
    },
    findStatusForCard(status: string, card: Card, board: Board): AlmItemStatus {
      const almItemType = this.findAlmItemType(
        card.type,
        card.teamId,
        board.artId ?? card.artId,
        card.almSourceId,
      );
      return this.findStatus(almItemType, status);
    },
    findStatus(almItemType: AlmItemType, status: string): AlmItemStatus {
      // if no ALM tool -> default statuses
      if (almItemType.statuses.length === 0) {
        const defaultStatus = defaultStatuses.find((s) => s.name === status);
        if (!defaultStatus) {
          return initialDefaultStatus;
        }
        return defaultStatus;
      }
      const almItemStatus = almItemType.statuses.find((s) => s.name === status);
      if (almItemStatus) {
        return almItemStatus;
      }
      // if alm tool & no status found -> undefined
      const dynamic = almItemType.dynamic;
      return {
        id: status,
        name: status,
        initial: false,
        statusClass: "undefined",
        next: dynamic ? [] : almItemType.globalTargetStatuses,
        dynamic,
        order: 0,
      };
    },
    findInitialStatus(almItemType: AlmItemType): AlmItemStatus {
      const initial = almItemType.statuses.find((s) => s.initial);
      if (initial) {
        return initial;
      }
      return initialDefaultStatus;
    },
  },
});

function createStatus(
  id: string,
  name: string,
  statusClass: StatusClass,
  order: number,
): Status {
  return { id, name, statusClass, order };
}

function createAlmItemStatus(
  baseStatus: Status,
  dynamic: boolean,
  initial: boolean,
  next: AlmItemStatus["next"],
): AlmItemStatus {
  return {
    ...baseStatus,
    dynamic,
    initial,
    next,
  };
}

const toDo: Status = createStatus("toDo", statusClassNames.todo, "todo", 0);
const inProgress: Status = createStatus(
  "inProgress",
  statusClassNames["in-progress"],
  "in-progress",
  1,
);
const done: Status = createStatus("done", statusClassNames.done, "done", 2);

const initialDefaultStatus: AlmItemStatus = createAlmItemStatus(
  toDo,
  false,
  true,
  [
    { transition: { id: "t1", name: "T1" }, status: inProgress, fields: [] },
    { transition: { id: "t2", name: "T2" }, status: done, fields: [] },
  ],
);

const defaultStatuses: AlmItemStatus[] = [
  initialDefaultStatus,
  createAlmItemStatus(inProgress, false, false, [
    { transition: { id: "t3", name: "T3" }, status: toDo, fields: [] },
    { transition: { id: "t4", name: "T4" }, status: done, fields: [] },
  ]),
  createAlmItemStatus(done, false, false, [
    { transition: { id: "t5", name: "T5" }, status: toDo, fields: [] },
    { transition: { id: "t6", name: "T6" }, status: inProgress, fields: [] },
  ]),
];
