<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import { AlmSourceId } from "@/baseTypes";
import BaseList from "@/components-ng/BaseList/BaseList.vue";
import BaseListItem from "@/components-ng/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListItems from "@/components-ng/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components-ng/BaseList/components/BaseListTitle/BaseListTitle.vue";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import { useBoardStore } from "@/store/board";
import { injectStrict } from "@/utils/context";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);

const sources = computed(() => {
  return useBoardStore().boardByType(card.type.origin)?.almSources;
});

const handleProjectChange = (id: AlmSourceId) => {
  cardActions.setAlmSource("card", card.id, id);
  dropdown.close();
};
</script>

<template>
  <BaseList role="list" class="change-project">
    <BaseListTitle>{{ $t("cardAction.project") }}</BaseListTitle>
    <BaseListItems>
      <BaseListItem
        v-for="source in sources"
        :key="source.id"
        role="listitem"
        :active="source.id === card.almSourceId"
        @click="handleProjectChange(source.id)"
      >
        {{ source.name }}
      </BaseListItem>
    </BaseListItems>
  </BaseList>
</template>

<style lang="scss" scoped>
.change-project {
  width: 200px;
  max-height: 350px;
}
</style>
