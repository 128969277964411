<template>
  <div class="flex-board-elem">
    <div class="image">
      <FlexBackground
        :type="board.flexType.background"
        class="action background"
        :name="board.name"
        @pointerup="!dragging && $emit('select', board)"
      />

      <div v-if="!deleting && !edit && !dragging" class="icons">
        <SvgIcon
          v-if="!readOnly"
          name="pen"
          size="40"
          class="action"
          @click="$emit('edit', board)"
        />
        <SvgIcon
          v-if="canDelete"
          name="action-menu/delete"
          size="40"
          class="action"
          @click="deleting = true"
        />
      </div>
      <div v-show="deleting" class="delete">
        {{ $t("flexBoardElem.confirm") }}
        <div class="bottom">
          <div class="action line line-right" @click="deleting = false">
            {{ $t("general.cancel") }}
          </div>
          <div class="action" @click="remove">
            <SvgIcon name="action-menu/delete" size="20" />
            {{ $t("flexBoardElem.delete") }}
          </div>
        </div>
      </div>
    </div>
    <div class="text">
      <input-field
        ref="input"
        :value="board.name"
        :max-length="30"
        :read-only="readOnly"
        underline
        placeholder="Board name"
        @change="$emit('setName', board, $event)"
        @focus="$emit('edit', board)"
        @blur="$emit('edit')"
      />
      <SvgIcon v-show="!readOnly && edit" name="check" size="20" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";

import { flexActions } from "@/action/flexActions";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import FlexBackground from "@/components/background/FlexBackground.vue";
import InputField from "@/components/input/InputField.vue";
import { BoardData } from "@/model";
import { useUserStore } from "@/store/user";

@Options({
  components: { SvgIcon, InputField, FlexBackground },
  emits: ["select", "edit", "setName"],
})
export default class FlexBoardElem extends Vue {
  @Prop(Object) readonly board!: BoardData<"flex">;
  @Prop(Boolean) readonly dragging!: boolean;
  @Prop(Boolean) readonly readOnly!: boolean;
  @Prop(Boolean) readonly edit!: boolean;

  @Ref("input") inputElem!: Vue;
  deleting = false;

  @Watch("edit")
  editChanged(e: boolean) {
    if (e) {
      const el = this.inputElem.$el as HTMLElement;
      el.focus();
      el.click();
    }
  }

  get canDelete() {
    return useUserStore().isAllowed("deleteBoard");
  }

  remove() {
    flexActions.delete("modal", this.board.id);
  }
}
</script>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.flex-board-elem {
  .icons {
    display: none;
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  &:hover {
    .icons {
      display: block;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .delete {
    position: absolute;
    background-color: colors-old.$delete-color;
    color: colors-old.$back-color;
    inset: 0 0 -2em;
    text-align: center;
    padding: 1em;

    .action svg {
      height: 1.5em;
      vertical-align: middle;
    }
  }

  .text {
    display: flex;
    align-items: center;

    input {
      text-align: center;
      border: none;
      padding: 0 20px;

      &.edit {
        border-bottom: 0.1em solid colors-old.$input-border-color;
      }
    }
  }
}
</style>
