import { defineStore } from "pinia";

import { Iteration } from "@/model";
import { isAfter, isBefore, weekDaysBetween } from "@/utils/date";

export interface IterationProgress {
  daysInIteration: number;
  daysPassedInIteration: number;
  iterationsPassed: number;
}

export const useIterationStore = defineStore("iteration", {
  state: () => {
    return { iterations: [] as Iteration[] };
  },
  getters: {
    iterationProgress:
      (state) =>
      (date: Date, iteration?: Iteration): IterationProgress => {
        const iters = state.iterations;
        if (isBefore(date, iters[0].start)) {
          return {
            daysInIteration: 0,
            daysPassedInIteration: 0,
            iterationsPassed: 0,
          };
        }
        if (!isBefore(date, iters[iters.length - 1].end)) {
          return {
            daysInIteration: 0,
            daysPassedInIteration: 0,
            iterationsPassed: iters.length,
          };
        }
        let i = iters.length - 1;
        while (i > 0 && isAfter(iters[i].start, date)) {
          i--;
        }

        if (!iteration) {
          iteration = iters[i];
        } else {
          if (iteration.id !== iters[i].id) {
            date = isBefore(date, iteration.start)
              ? iteration.start
              : iteration.end;
          }
          i = 0;
        }

        const daysInIteration = weekDaysBetween(iteration.start, iteration.end);
        const daysPassedInIteration = Math.min(
          daysInIteration,
          weekDaysBetween(iteration.start, date),
        );

        return {
          daysInIteration,
          daysPassedInIteration,
          iterationsPassed: i + daysPassedInIteration / daysInIteration,
        };
      },
  },
  actions: {},
});
