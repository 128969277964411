import { clamp } from "lodash-es";
import { Directive } from "vue";

import { terminateEvent } from "@/utils/dom";

/**
 * Enable keyboard navigation through a list of '.list-item's
 * using the up- / down-arrow keys
 *
 * Modifiers:
 * select: Automatically selects the next/previous item on key press
 * no-wrap: Keyboard navigation will not wrap around the end of the list
 */
export function keyboardNavigation(): Directive<HTMLElement> {
  let listenerController: AbortController | null = null;
  let currentIndex = 0;
  let activeElement: HTMLElement | null = null;
  let listItems: NodeListOf<any> | null = null;
  let autoSelect = false;
  let wrapDisabled = false;

  return {
    mounted(el, binding) {
      autoSelect = binding.modifiers["select"];
      wrapDisabled = binding.modifiers["no-wrap"];

      initListItems(el);
      activeElement = document.activeElement as HTMLElement | null;
      activeElement?.addEventListener("keydown", keyHandler);
      setCurrent(currentIndex, true);
    },

    updated(el) {
      initListItems(el);
    },

    unmounted() {
      listenerController?.abort();
      activeElement?.removeEventListener("keydown", keyHandler);
      currentIndex = -1;
    },
  };

  function initListItems(el: HTMLElement) {
    listenerController?.abort();
    listenerController = new AbortController();
    listItems = el.querySelectorAll(".list-item:not(.static):not(.disabled)");

    if (autoSelect) {
      initClickListeners();
    } else {
      initPointerListeners();
    }
  }

  /**
   * Ensure the currentIndex gets updated when the user clicks on a list item
   */
  function initClickListeners() {
    listItems?.forEach((item, index) => {
      item.addEventListener("click", () => setCurrent(index, false), {
        signal: listenerController!.signal,
        capture: true,
      });
    });
  }

  /**
   * Ensure the currentIndex is updated when the user hovers over a list item
   */
  function initPointerListeners() {
    listItems?.forEach((item, index) => {
      item.classList.add("no-hover");
      item.addEventListener("pointerenter", () => setCurrent(index, false), {
        signal: listenerController!.signal,
      });
    });
  }

  function setCurrent(index: number, fromKeyboard: boolean) {
    currentItem()?.classList.remove("current");
    currentIndex = index;
    const newItem = currentItem();
    if (newItem) {
      newItem.classList.add("current");
      if (fromKeyboard) {
        newItem.dispatchEvent(new PointerEvent("pointerenter"));
        if (autoSelect) {
          newItem.click();
        }
        newItem.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }
    }
  }

  function currentItem() {
    return listItems?.item(currentIndex);
  }

  /**
   * Return the wrapped or non-wrapped index, depending on the
   * given wrapDisabled modifier
   */
  function adjustIndex(index: number) {
    return wrapDisabled ? noWrap(index) : wrap(index);
  }

  /**
   * Return the given index if it's within 0 <= index <= listItems.length-1,
   * or give the nearest valid index (ie. -1 --> 0, length --> length-1)
   */
  function noWrap(index: number) {
    return clamp(index, 0, listItems?.length ? listItems.length - 1 : 0);
  }

  /**
   * Return the given index if it's within the array of listItems,
   * or give the index that simulates the list wrapping (ie. -1 --> last item)
   */
  function wrap(index: number) {
    const len = listItems?.length;
    return len === undefined ? -1 : (index + len) % len;
  }

  function keyHandler(event: KeyboardEvent) {
    switch (event.key) {
      case "ArrowUp":
        terminateEvent(event);
        setCurrent(adjustIndex(currentIndex - 1), true);
        break;
      case "ArrowDown":
        terminateEvent(event);
        setCurrent(adjustIndex(currentIndex + 1), true);
        break;
      case "Enter":
        currentItem()?.click();
        break;
    }
  }
}
