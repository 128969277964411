<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { getAlmType } from "@/backend/Backend";
import { NoSuccessError } from "@/backend/NoSuccessError";
import { sender } from "@/backend/Sender";
import { TimeoutError } from "@/backend/TimeoutError";
import { readableAlmType } from "@/backend/serverModel";
import ConfirmModal from "@/components/modal/ConfirmModal.vue";
import { captureException } from "@/sentry";
import { useArtStore } from "@/store/art";
import { useModalStore } from "@/store/modal";

const { t } = useI18n();
const syncState = ref({ status: "idle", error: "" });
const artId = computed(() => useArtStore().art?.current?.id);
const almName = readableAlmType(getAlmType());

async function sync() {
  syncState.value = { status: "syncing", error: "" };
  try {
    await sender.syncBacklog(
      artId.value ? parseInt(artId.value, 10) : undefined,
    );
    syncState.value.status = "success";
    setTimeout(useModalStore().hide, 1000);
  } catch (error) {
    syncState.value.status = "fail";
    if (error instanceof NoSuccessError) {
      syncState.value.error = t("backlogSync.failedSync", {
        errorMessage: error.response.message,
      });
    } else if (error instanceof TimeoutError) {
      syncState.value.error = t("backlogSync.timeout");
    } else {
      syncState.value.error = t("backlogSync.failedSync");
      captureException(error);
    }
  }
}
</script>

<template>
  <ConfirmModal
    :height="300"
    :question="$t('backlogBoard.confirmManualUpdate', { almName })"
    :subtext="$t('backlogBoard.stickyOverwrite', { almName })"
    :action-label="$t('iterationSync.updateStickies')"
    :state="syncState"
    :action="sync"
  />
</template>
