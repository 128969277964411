// from pi-admin's color picker
export const colorPalette = [
  // Greens
  { color: "#4dff6a" },
  { color: "#2d8644" },
  { color: "#90bb9b" },
  { color: "#cce0d1" },
  // Blues
  { color: "#4ce6ff" },
  { color: "#2265c3" },
  { color: "#93b8ec" },
  { color: "#bad1f3" },
  // Oranges
  { color: "#ff914c" },
  { color: "#f98006" },
  { color: "#fba651" },
  { color: "#fdd9b4" },
  // Yellows
  { color: "#ffff54" },
  { color: "#ddb929" },
  { color: "#f3d459" },
  { color: "#fff5ae" },
  // Reds
  { color: "#ff0000" },
  { color: "#d22d2d" },
  { color: "#e06c6c" },
  { color: "#ecac9e" },
  // Purples
  { color: "#684cff" },
  { color: "#915cd6" },
  { color: "#b6acea" },
  { color: "#d0cae3" },
  // Grayscale
  { color: "#1c1e21" },
  { color: "#afb2ba" },
  { color: "#dee0e3" },
  { color: "#ffffff" },
];
