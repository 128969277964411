<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import {
  boardKey,
  cardKey,
  cardMethodsKey,
} from "@/components/card/injectKeys";
import type { Team } from "@/model";
import { useBoardStore } from "@/store/board";
import { useTeamStore } from "@/store/team";
import { injectStrict } from "@/utils/context";

import BoardSelector, { SelectEvent } from "../BoardSelector/BoardSelector.vue";
import TeamSelector from "../TeamSelector/TeamSelector.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
const cardMethods = injectStrict(cardMethodsKey);

const teamBoards = computed(() => {
  const currentTeam = useTeamStore().currentTeam;
  return useBoardStore()
    .artTeamBoards()
    .filter((b) => b.team.id !== currentTeam.id);
});

const isRiskCard = computed(() => card.type.functionality === "risk");

const handleCardMove = (team: Team | null) => {
  if (!team) return;

  cardMethods.animate("moving", () => {
    cardMethods.removePin();
    cardActions.move("card", card.id, team?.id);
  });
};

const handleRiskCardMove = (event: SelectEvent) => {
  const { id: cardId, teamId: cardTeamId } = card;

  cardMethods?.animate("moving", () => {
    cardMethods.removePin();

    if (event.type === "team-board") {
      const boardTeamId = event.board.team.id;
      cardActions.move("card", cardId, boardTeamId);
    }

    if (event.type === "risk-board" && cardTeamId) {
      cardActions.toRisk("card", cardId, cardTeamId);
      cardActions.delete("internal", cardId, board.id);
    }
  });
};
</script>

<template>
  <div class="move-sticky no-wheel">
    <BoardSelector
      v-if="isRiskCard"
      :boards="teamBoards"
      @select="handleRiskCardMove"
    />
    <TeamSelector
      v-else
      show-other-arts
      :title="$t('action.moveSticky')"
      @select="handleCardMove"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/utils";

.move-sticky {
  width: 240px;
  max-height: 350px;
  overflow-y: scroll;

  @include utils.hide-scrollbar;
}
</style>
