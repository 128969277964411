<script setup lang="ts">
import DropdownMenu, {
  PropsWithInternalOpenState,
} from "@/components/DropdownMenu/DropdownMenu.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { Icon } from "@/types/icon";

type Props = {
  icon?: Icon;
  as?: "button" | "a";
  text?: string;
  textPlaceholder?: string;
  dropdownProps?: PropsWithInternalOpenState;
};
const props = withDefaults(defineProps<Props>(), {
  as: "button",
  text: undefined,
  textPlaceholder: undefined,
  icon: undefined,
  dropdownProps: () => ({ stayOnClick: true }),
});

defineOptions({ inheritAttrs: false });
defineEmits<{ click: [] }>();
</script>

<template>
  <DropdownMenu
    v-bind="props.dropdownProps"
    stay-on-click
    @click="$emit('click')"
  >
    <template #trigger>
      <component
        :is="props.as"
        data-ignore-click
        :class="[
          'attribute-chip',
          {
            active: props.text,
            rounded: !props.text && !props.textPlaceholder,
          },
        ]"
      >
        <slot name="icon">
          <span v-if="props.icon" class="icon">
            <SvgIcon :name="props.icon" />
          </span>
        </slot>
        <span v-if="props.text || props.textPlaceholder" class="text">
          {{ props.text || props.textPlaceholder }}
        </span>
      </component>
    </template>
    <div data-ignore-click>
      <slot />
    </div>
  </DropdownMenu>
</template>

<style scoped lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "../../_utils";

.attribute-chip {
  all: unset;
  display: inline-flex;
  align-items: center;
  padding: 0 utils.px-to-em(4px);
  height: utils.px-to-em(20px);
  color: rgba(colors-old.$text-primary-color, 0.4);
  border: utils.px-to-em(1px) dashed currentcolor;
  cursor: pointer;
  user-select: none;
  border-radius: utils.px-to-em(4px);
  box-sizing: border-box;
  max-width: 100%;

  &.rounded {
    border-radius: 50%;
    aspect-ratio: 1/1;
    justify-content: center;
  }

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:only-child) {
      margin-left: utils.px-to-em(4px);
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    height: utils.px-to-em(14px);
  }

  &:hover:not(.active, :active) {
    color: colors-old.$primary-color;
  }

  &.active {
    padding: utils.px-to-em(3px) utils.px-to-em(4px);
    background-color: rgba(colors-old.$text-primary-color, 0.08);
    color: colors-old.$text-primary-color;
    border: none;

    &:hover {
      background-color: rgba(colors-old.$text-primary-color, 0.12);
    }

    &:active {
      background-color: rgba(colors-old.$text-primary-color, 0.12);
    }
  }
}
</style>
