<template>
  <modal-legacy class="overview-modal" :width="width" :class="[color]">
    <modal-header-legacy :title="$t('overviewModal.title')">
      <span v-for="(name, index) in names" :key="index">{{ name }}</span>
    </modal-header-legacy>
    <div class="head">
      <div v-if="load !== undefined">
        {{
          $t("modal.loadValue", {
            number: load,
          })
        }}
      </div>
      <div v-if="load !== undefined">
        {{ $t("modal.capacityValue", { capacity: velocity }) }}
      </div>
    </div>
    <div
      class="scrollable"
      :style="{ fontSize }"
      @wheel="stopScrollPropagation"
    >
      <card
        v-for="(card, index) in cards"
        :key="card.data.id"
        :read-only="true"
        :card="card.data"
        :meta="card.meta"
        :color="card.data.type.color"
        place-by="order"
        :board-width="boardWidth"
        :board-height="size * rows"
        :row="row(index)"
        :col="col(index)"
        :height="size - 10"
        :width="size - 10"
        :board="board"
        :actions="actions(card)"
        :full-details="true"
        :draggable="false"
      />
    </div>
    <div class="bottom">
      <div class="action line line-right" @click="scale(-1)">
        <SvgIcon name="font-up" size="20" />
      </div>
      <div class="action line line-right" @click="scale(1)">
        <SvgIcon name="font-down" size="20" />
      </div>
    </div>
  </modal-legacy>
</template>

<script lang="ts">
import { clamp } from "lodash-es";
import { Options, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { cardsInLocation } from "@/components/BoardLocation";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import Card from "@/components/card/Card.vue";
import { ActionType } from "@/components/card/actions";
import ModalHeaderLegacy from "@/components/modal/ModalHeaderLegacy.vue";
import ModalLegacy from "@/components/modal/ModalLegacy.vue";
import ScrollSupport from "@/mixins/ScrollSupport";
import { BoardCard, isRisk } from "@/model";
import { useBoardStore } from "@/store/board";
import { useClientSettingsStore } from "@/store/clientSettings";

@Options({
  name: "OverviewModal",
  components: { SvgIcon, ModalHeaderLegacy, Card, ModalLegacy },
})
export default class OverviewModal extends mixins(ScrollSupport) {
  @Prop(String) readonly boardId!: string;
  @Prop(Array) readonly location!: string[];
  @Prop(Number) readonly velocity?: number;
  @Prop(Number) readonly load?: number;

  get board() {
    return useBoardStore().boardById(this.boardId);
  }

  get loc() {
    return useBoardStore().boardLocationFromIndex(this.location);
  }

  get cards() {
    return cardsInLocation(this.board, this.loc);
  }

  get names() {
    return (this.loc as any).names?.() || [this.loc.name];
  }

  get columns() {
    return useClientSettingsStore().overviewScale;
  }

  get fontSize() {
    return 100 - (this.columns - 4) * 10 + "%";
  }

  get width() {
    return document.documentElement.clientWidth * 0.85;
  }

  scale(diff: number) {
    const cols = clamp(this.columns + diff, 4, 8);
    if (cols !== this.columns) {
      useClientSettingsStore().overviewScale = cols;
    }
  }

  actions(card: BoardCard): ActionType[] {
    return isRisk(card.data) ? ["risk"] : ["points"];
  }

  get color() {
    const v = this.velocity;
    const l = this.load;
    if (!v || !l) {
      return "";
    }
    const r = l / v;
    return r < useClientSettingsStore().loadWarn / 100
      ? "green"
      : r >= 1
      ? "red"
      : "orange";
  }

  row(index: number) {
    return (0.5 + Math.floor(index / this.columns)) / this.rows;
  }

  col(index: number) {
    return (0.5 + (index % this.columns)) / this.columns;
  }

  get boardWidth() {
    return this.width - 60;
  }

  get rows() {
    return Math.ceil(this.cards.length / this.columns);
  }

  get size() {
    return this.boardWidth / this.columns;
  }
}
</script>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.overview-modal {
  &.red > div {
    background-color: colors-old.$error-back-color;
  }

  &.orange > div {
    background-color: colors-old.$warn-back-color;
  }

  h2 span {
    margin: 0 1em;
  }

  .head div {
    width: 30%;
  }

  .scrollable {
    top: 9rem;
    margin: 0 1rem 4rem;
  }

  .bottom {
    img {
      height: 1em;
    }
  }

  .card {
    .text {
      bottom: 1.2em;
    }

    .team {
      height: 0;
    }
  }
}
</style>
