import { defineStore } from "pinia";

import { BoardData, FlexBackground, FlexType, IdMap } from "@/model";
import { useBoardStore } from "@/store/board";

export const useFlexStore = defineStore("flex", {
  state: () => ({
    currentFlexBoard: null as BoardData<"flex"> | null,
    flexBackgrounds: {} as IdMap<FlexBackground>,
    flexTypes: [] as FlexType[],
  }),
  getters: {
    flexBoards: () =>
      Object.values(useBoardStore().boards).filter(
        (board) => board.type === "flex",
      ) as Array<BoardData<"flex">>,

    flexBoardByName() {
      return (name: string) =>
        this.flexBoards.find((board) => board.name === name);
    },

    flexBoardById() {
      return (id: string) => this.flexBoards.find((board) => board.id === id);
    },

    infoLink(state) {
      return (flexType: FlexType) =>
        state.flexBackgrounds[flexType.background]?.infoLink;
    },
  },
  actions: {
    setFlexBackgrounds(backgrounds: FlexBackground[]) {
      this.flexBackgrounds = Object.fromEntries(
        backgrounds.map((background) => [background.id, background]),
      );
    },
    setFlexModel(flexTypes: FlexType[], flexBoards: BoardData<"flex">[]) {
      this.flexTypes = flexTypes;
      useBoardStore().updateBoards("flex", flexBoards, (exist, board) => {
        exist.name = board.name;
        exist.flexType = board.flexType;
      });
    },
  },
});
