import { defineStore } from "pinia";

import { BoardType } from "@/baseTypes";

export const useServerSettingsStore = defineStore("serverSettings", {
  state: () => {
    return {
      isBacklogAlmStickyDeletable: true,
      isTeamAlmStickyDeletable: true,
      isPriorityEditable: true,
      confirmDelete: true,
      moveBetweenTeams: true,
    };
  },
  getters: {
    isStickyDeletable: (state) => (boardType: BoardType) => {
      switch (boardType) {
        case "backlog":
          return state.isBacklogAlmStickyDeletable;
        case "team":
          return state.isTeamAlmStickyDeletable;
        default:
          return true;
      }
    },
  },
  actions: {},
});
