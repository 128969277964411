<script lang="ts" setup>
export type Props = {
  width?: "xs" | "sm" | "md" | "lg" | "full";
};

const props = defineProps<Props>();
</script>

<template>
  <div
    :class="[
      'base-popup',
      {
        'exra-small': props.width === 'xs',
        small: props.width === 'sm',
        medium: props.width === 'md',
        large: props.width === 'lg',
        'full-width': props.width === 'full',
      },
    ]"
  >
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/font";
@use "@/styles/mixins";

.base-popup {
  @include mixins.default-shadow;

  display: inline-block;
  background-color: colors-old.$back-color;
  color: colors-old.$menu-color;
  border-radius: 6px;
  overflow: hidden;
  font-size: 14px;
  font-weight: font.$weight-semi-bold;

  &.extra-small {
    width: 120px;
  }

  &.small {
    width: 200px;
  }

  &.medium {
    width: 260px;
  }

  &.large {
    width: 300px;
  }

  &.full-width {
    display: block;
  }
}
</style>
