<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { getAlmType } from "@/backend/Backend";
import { NoSuccessError } from "@/backend/NoSuccessError";
import { sender } from "@/backend/Sender";
import { readableAlmType } from "@/backend/serverModel";
import ConfirmModal from "@/components/modal/ConfirmModal.vue";
import { IterationStatus } from "@/model";
import { useBoardStore } from "@/store/board";
import { useModalStore } from "@/store/modal";
import { useTeamStore } from "@/store/team";

const props = defineProps<{ iteration: string; status: IterationStatus }>();

const { t } = useI18n();
const syncState = ref({ status: props.status, detail: "" });
const almName = readableAlmType(getAlmType());

const iter = computed(() => parseInt(props.iteration, 10));

async function sync() {
  if (syncState.value.status === "syncing") {
    return;
  }
  setState("syncing");
  try {
    await sender.syncIteration(useTeamStore().currentTeam.id!, iter.value);
  } catch (err) {
    if (err instanceof NoSuccessError) {
      setState(
        "fail",
        t("iterationSync.failedSync", {
          errorMessage: err.response.message,
        }),
      );
    }
  }
  const state =
    useBoardStore().boardByType("team").iterations[iter.value].state;
  setTimeout(() => {
    if (state.status === "success") {
      useModalStore().hide();
    }
  }, 1000);
}

function setState(status: IterationStatus, detail = "") {
  syncState.value = { status, detail };
  useBoardStore().setIterationStatus({
    id: iter.value,
    ...syncState.value,
  });
}
</script>

<template>
  <ConfirmModal
    :height="300"
    :question="$t('iterationSync.confirmManualUpdate', { almName })"
    :subtext="$t('iterationSync.stickyOverwrite', { almName })"
    :action-label="$t('iterationSync.updateStickies')"
    :state="syncState"
    :action="sync"
  />
</template>
