<script lang="ts" setup>
import RoamSticky from "@/components/card/components/ActionMenu/components/ROAMSticky/ROAMSticky.vue";
import { cardKey } from "@/components/card/injectKeys";
import { injectStrict } from "@/utils/context";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";

const card = injectStrict(cardKey);
</script>

<template>
  <AttributeChip :text="card.risk || 'ROAM'" :dropdown-props="{ width: 'sm' }">
    <RoamSticky />
  </AttributeChip>
</template>
