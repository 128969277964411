import { AxiosError } from "axios";

import { CancelError } from "@/backend/CancelError";
import { currentRoute } from "@/router/navigation";
import { captureException, captureMessage } from "@/sentry";
import { ExtendedRequestConfig } from "@/services/api.config";
import { useGlobalErrorStore } from "@/store/globalError";

export function handleError(error: Error, message?: string): false | undefined {
  if (currentRoute().name !== "app") {
    captureException(error);
    useGlobalErrorStore().setGlobalError(error);
    return false;
  }
  if (error instanceof CancelError) {
    handleAxiosError(error.contexts.cause, error.message);
    return;
  }
  if (handleAxiosError(error, message)) {
    return;
  }
  captureException(error);
}

function handleAxiosError(error?: any, message?: string) {
  if (!isAxiosError(error)) {
    return false;
  }
  const config = error.config as ExtendedRequestConfig;
  const request = {
    url: joinUrl(config.baseURL, config.url),
    timeout: config.timeout,
    headers: config.headers,
    params: config.params,
    sentAt: config.sentAt,
    retry: config.retry,
    isGoogleReachable: config.isGoogleReachable,
  };
  const response = {
    data: error.response?.data,
    status: error.response?.status,
    statusText: error.response?.statusText,
    headers: error.response?.headers,
    receivedAt: new Date().toISOString(),
  };
  captureMessage((message ? `${message}: ` : "") + error.message, {
    request,
    response,
  });
  return true;
}

function joinUrl(base: string | undefined, path: string | undefined) {
  return (
    (base || "").replace(/\/$/, "") + "/" + (path || "").replace(/^\//, "")
  );
}

function isAxiosError(error?: any): error is AxiosError {
  return (
    error instanceof AxiosError ||
    (error instanceof Object && "isAxiosError" in error)
  );
}
