export default class Resolvable<T> {
  promise: Promise<T>;
  resolve!: (value: T | PromiseLike<T>) => void;
  reject!: (reason?: any) => void;
  resolved = false;
  rejected = false;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = (value) => {
        this.resolved = true;
        resolve(value);
      };
      this.reject = (reason) => {
        this.rejected = true;
        reject(reason);
      };
    });
  }

  get pending() {
    return !this.resolved && !this.rejected;
  }
}
