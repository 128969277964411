import { Vue } from "vue-class-component";

export default class LabeledInput extends Vue {
  setForId(id?: string) {
    if (id) {
      this.parentComponent.setForId?.(id);
    }
  }

  get error() {
    return !!this.parentComponent?.error;
  }

  get parentComponent() {
    return this.$parent as any;
  }
}
