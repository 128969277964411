<template>
  <canvas ref="canvas" class="pie-chart" data-no-screenshot />
</template>

<script lang="ts">
import { Prop, Ref, Vue, Watch } from "vue-property-decorator";

import { Color } from "@/baseTypes";
import { PieData } from "@/components/PieChart";
import color from "@/styles/color.module.scss";
import { contrastCssColor, toCssColor } from "@/utils/color";

const markDist = 0.15;
const hole = 0.4;

type Mark = "before" | "this" | "after" | "single" | "none";

function createPattern(width: number, color: string) {
  const size = 16;
  const c = document.createElement("canvas");
  c.width = size;
  c.height = size;
  const ctx = c.getContext("2d")!;

  const x0 = size + width;
  const x1 = -width;
  const y1 = -width;
  const y0 = size + width;

  ctx.strokeStyle = color;
  ctx.lineWidth = width;
  ctx.beginPath();
  ctx.moveTo(x0, y0);
  ctx.lineTo(x1, y1);
  ctx.moveTo(x0 - size, y0);
  ctx.lineTo(x1 - size, y1);
  ctx.moveTo(x0 + size, y0);
  ctx.lineTo(x1 + size, y1);
  ctx.stroke();
  return c;
}

export default class PieChart extends Vue {
  @Prop(Array) readonly value!: PieData[];
  @Prop(Number) readonly total!: number;
  @Prop(String) readonly select?: string;
  @Prop(Boolean) readonly hole?: boolean;
  @Prop(Number) readonly zoom?: number;
  @Ref("canvas") readonly canvasElem!: HTMLCanvasElement;

  ctx!: CanvasRenderingContext2D;
  emptyPattern = createPattern(4, color.darkDivider);
  width = 0;
  height = 0;
  size = 0;

  @Watch("value")
  onDataChange() {
    this.draw();
  }

  mounted() {
    this.ctx = this.canvasElem.getContext("2d")!;
    this.width = this.canvasElem.offsetWidth / (this.zoom || 1);
    this.height = this.canvasElem.offsetHeight / (this.zoom || 1);
    this.canvasElem.width = this.width;
    this.canvasElem.height = this.height;
    this.ctx.translate(this.width / 2, this.height / 2);
    this.ctx.rotate(-Math.PI / 2);
    this.draw();
  }

  draw() {
    this.size = Math.min(this.width, this.height) / 2;
    this.ctx.font = Math.min(18, this.size / 5) + "px sans-serif";
    const radius = this.size * 0.75;
    this.ctx.clearRect(
      -this.width / 2,
      -this.height / 2,
      this.width + 1,
      this.height + 1,
    );

    this.ctx.save();
    const data = this.value.filter((d) => d.value > 0);
    const select = data.findIndex((d) => d.name === this.select);
    const len = data.length;
    if (len === 0) {
      this.ctx.fillStyle = this.ctx.createPattern(this.emptyPattern, "repeat")!;
      this.drawCircle(radius);
      this.ctx.fillStyle = color.back;
      this.drawCircle(hole * radius);
    } else {
      let a = 0;
      for (let i = 0; i < len; i++) {
        const value = data[i].value / this.total;
        const e = a + value * 2 * Math.PI;
        this.ctx.fillStyle = toCssColor(data[i].color);
        this.drawArc(a, e, radius, this.markPos(i, select, len));
        if (value > 0.05) {
          this.drawText(
            value,
            data[i].color,
            (a + e) / 2,
            radius,
            i === select,
          );
        }
        a = e;
      }
    }
    this.ctx.restore();
  }

  markPos(i: number, select: number, len: number): Mark {
    const pos = i - select;
    if (select < 0) {
      return "none";
    }
    if (pos === 0) {
      return len === 1 ? "single" : "this";
    }
    if (pos === len - 1) {
      return "before";
    }
    if (pos === -(len - 1)) {
      return "after";
    }
    return "none";
  }

  drawText(
    value: number,
    color: Color,
    angle: number,
    radius: number,
    mark: boolean,
  ) {
    this.ctx.save();
    this.ctx.fillStyle = contrastCssColor(color);
    this.ctx.rotate(Math.PI / 2);
    const text = Math.round(100 * value) + "%";
    const size = this.ctx.measureText(text);
    const height = size.actualBoundingBoxAscent - size.actualBoundingBoxDescent;
    const c = this.dir(angle, 1);
    this.ctx.fillText(
      text,
      (radius * 0.9 - size.width / 2 + (mark ? markDist * radius : 0)) * c.y -
        size.width / 2,
      -(radius * 0.9 - height / 2 + (mark ? markDist * radius : 0)) * c.x +
        height / 2,
    );
    this.ctx.restore();
  }

  dir(angle: number, r: number) {
    return { x: r * Math.cos(angle), y: r * Math.sin(angle) };
  }

  drawArc(from: number, to: number, radius: number, mark: Mark) {
    this.ctx.save();
    this.ctx.beginPath();
    const c = this.dir(
      (from + to) / 2,
      mark === "this" ? markDist * radius : 0,
    );

    if (!this.hole) {
      this.ctx.moveTo(c.x, c.y);
      this.ctx.arc(c.x, c.y, radius, from, to, false);
      this.ctx.lineTo(c.x, c.y);
      this.ctx.stroke();
    } else {
      if (mark !== "this") {
        // clip shadow
        const s = new Path2D();
        const f = from - (mark === "after" ? 0.4 : 0);
        const t = to + (mark === "before" ? 0.4 : 0);
        s.arc(c.x, c.y, 2 * radius, f, t, false);
        s.arc(c.x, c.y, 0, t, f, true);
        this.ctx.clip(s);
      }
      this.ctx.arc(
        c.x,
        c.y,
        radius * (mark === "single" ? 1 + markDist : 1),
        from,
        to,
        false,
      );
      this.ctx.arc(c.x, c.y, hole * radius, to, from, true);
    }
    this.ctx.fill();
    this.ctx.restore();
  }

  drawCircle(radius: number) {
    this.ctx.beginPath();
    this.ctx.arc(0, 0, radius, 0, 2 * Math.PI);
    this.ctx.fill();
    this.ctx.stroke();
  }
}
</script>

<style lang="scss">
.pie-chart {
  width: 100%;
  height: 100%;
}
</style>
