import { Options, mixins } from "vue-class-component";

import Card from "@/components/card/Card.vue";
import { applyFullBoardSize } from "@/lowlevel";
import EventBusUser from "@/mixins/EventBusUser";
import ShortcutUser from "@/mixins/ShortcutUser";
import { Board } from "@/model";

@Options({ components: { Card } })
export default class BoardBase extends mixins(ShortcutUser, EventBusUser) {
  active = false;

  // must be overridden by subclasses
  get board(): Board {
    return {} as Board;
  }

  activated() {
    this.applyBoardSize();
    this.active = true;
  }

  deactivated() {
    this.active = false;
  }

  applyBoardSize() {
    applyFullBoardSize();
  }
}
