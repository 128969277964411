<template>
  <svg
    class="link-layer"
    :viewBox="`0 0 ${width} ${height}`"
    preserveAspectRatio="none"
    :style="{ stroke: color }"
    style="fill: transparent"
  >
    <defs>
      <g id="flashIcon">
        <circle cx="10" cy="10" r="10" />
        <path d="M9 16v-5H5l6-7v5h4l-6 7z" fill="white" />
      </g>
    </defs>
    <g v-for="(link, linkIndex) in links" :key="linkIndex">
      <path :style="link.style" :d="link.curve" />
      <path :style="link.style" :fill="link.style.stroke" :d="link.arrow" />
      <use
        v-if="showErrors && link.icon"
        xlink:href="#flashIcon"
        :fill="link.style.stroke"
        stroke="transparent"
        :x="link.icon.x"
        :y="link.icon.y"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { arrowAngle, arrowBoxFactor, arrowLen } from "@/Settings";
import { BoardType } from "@/baseTypes";
import { linkColors } from "@/colors";
import QuadraticSpline from "@/math/QuadraticSpline";
import { RelativeCoordinate, minus, plus, times } from "@/math/coordinates";
import EventBusUser from "@/mixins/EventBusUser";
import {
  Board,
  BoardCard,
  Link,
  MarkMode,
  isFaded,
  isFullyFaded,
} from "@/model";
import { useBoardStore } from "@/store/board";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useDraggingStore } from "@/store/dragging";
import { useLinkStore } from "@/store/link";
import { useSearchMenuStore } from "@/store/searchMenu";

type SVGStyles = {
  stroke?: string;
};

interface Path {
  curve: string;
  arrow?: string;
  style: SVGStyles;
  icon?: RelativeCoordinate;
}

export default class LinkLayer extends mixins(EventBusUser) {
  @Prop(Object) readonly board!: Board;
  @Prop(String) readonly color!: string;
  @Prop({ type: Boolean, default: false }) readonly showErrors!: boolean;
  width = 1600;
  height = 900;

  get links() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const comp = this;
    const permanentLinks = hasPermanentLinks(this.board.type);
    const res = new Array<Path>();

    useLinkStore().forEachLinkOnBoard(this.board, (link, from, to) => {
      if (!to) {
        addDragLink(from, link.id);
      } else {
        addLink(from, to, link);
      }
    });

    return res;

    function hasPermanentLinks(boardType: BoardType) {
      switch (boardType) {
        case "program":
          return useClientSettingsStore().permanentProgramLinks;
        case "solution":
          return true;
        default:
          return useClientSettingsStore().permanentTeamLinks;
      }
    }

    function addDragLink(from: BoardCard, dragId: string) {
      const c = coordinateById(dragId);
      if (c) {
        res.push(createPath(from.meta.pos, c, comp.color, "ff"));
      }
    }

    function addLink(from: BoardCard, to: BoardCard, link: Link) {
      const { color, showIcon } = getStateIconColor(link);
      const markMode = showIcon ? "normal" : minFaded(from, to);

      if (!shouldAddLink(markMode)) {
        return;
      }

      const transparency = markMode === "normal" ? "ff" : "80";

      res.push(
        createPath(
          coordinateOf(from),
          coordinateOf(to),
          color,
          transparency,
          getBoxSize(from, to),
          showIcon,
        ),
      );

      function shouldAddLink(markMode: MarkMode): boolean {
        return (
          !isHidden(from) &&
          !isHidden(to) &&
          ((permanentLinks && markMode !== "fade-out") ||
            useSearchMenuStore().showCardLinks(from) ||
            useSearchMenuStore().showCardLinks(to) ||
            useSearchMenuStore().matchesLinkStates(link)) &&
          isStateShown(link)
        );
      }

      function getBoxSize(from: BoardCard, to: BoardCard) {
        return from.data.type.id === to.data.type.id
          ? times(
              arrowBoxFactor,
              useBoardStore().currentBoard().cardSize.factor,
            )
          : undefined;
      }
    }

    function getStateIconColor(link: Link): {
      color: string;
      showIcon: boolean;
    } {
      const showIcon =
        comp.showErrors &&
        useSearchMenuStore().matchesLinkStates(link) &&
        ["warn", "error"].includes(link.state);
      return {
        color: showIcon ? linkColors[link.state] : comp.color,
        showIcon,
      };
    }

    function isStateShown(link: Link) {
      return (
        !comp.showErrors || useSearchMenuStore().matchesLinkStates(link, true)
      );
    }

    function isHidden(c: BoardCard) {
      return c.meta.mark === "hidden";
    }

    /**
     * Return the 'mark' of the most visible card
     */
    function minFaded(
      a: BoardCard,
      b: BoardCard,
    ): "fade-out" | "semi-fade-out" | "normal" {
      if (!isFaded(a.meta.mark) || !isFaded(b.meta.mark)) {
        return "normal";
      }
      if (!isFullyFaded(a.meta.mark) || !isFullyFaded(b.meta.mark)) {
        return "semi-fade-out";
      }
      return "fade-out";
    }

    function coordinateOf(card: BoardCard): RelativeCoordinate {
      return coordinateById(card.data.id) || card.meta.pos;
    }

    function coordinateById(id: string): RelativeCoordinate | undefined {
      return useDraggingStore().findById(id)?.pos;
    }

    function createPath(
      p0: RelativeCoordinate,
      p1: RelativeCoordinate,
      color: string,
      transparency: string,
      box?: RelativeCoordinate,
      showLink = false,
    ): Path {
      const spline = QuadraticSpline.forLink(p0, p1);
      const path = {
        style: {
          stroke: color + transparency,
        },
        curve: spline.asPath(comp.width, comp.height),
        arrow: "",
        icon: showLink
          ? spline.iconPosition(comp.width, comp.height, 16 / 9)
          : undefined,
      };
      if (box) {
        const tMax = spline.tMaxAtRectangle(minus(p1, box), plus(p1, box));
        const point = spline.interpolate(tMax);
        const angle = spline.angleAtT(tMax);
        path.arrow =
          command("M", point, 0, 0) +
          command(
            "L",
            point,
            arrowLen * Math.cos(angle - arrowAngle),
            arrowLen * Math.sin(angle - arrowAngle),
          ) +
          command(
            "L",
            point,
            arrowLen * Math.cos(angle + arrowAngle),
            arrowLen * Math.sin(angle + arrowAngle),
          ) +
          " l 0 0 z";
      }
      return path;
    }

    function command(
      cmd: string,
      base: RelativeCoordinate,
      x: number,
      y: number,
    ) {
      return ` ${cmd} ${(base.x + x) * comp.width} ${
        (base.y + y) * comp.height
      }`;
    }
  }
}
</script>

<style lang="scss">
@use "@/styles/z-index";

.link-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: z-index.$links;
  pointer-events: none;
}
</style>
