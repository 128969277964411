import * as FileSaver from "file-saver";

import { boardAspect, screenShotWidth } from "@/Settings";
import { effectiveBoardWidth } from "@/lowlevel";
import { captureException, captureMessage } from "@/sentry";
import { useAppSizeStore } from "@/store/appSize";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useSessionStore } from "@/store/session";
import { useToastStore } from "@/store/toast";
import { reloadOnMissingChunks } from "@/utils/import";

export function screenShot() {
  useToastStore().show(/*$t*/ "message.generateImage", { duration: 0 });
  setTimeout(async () => {
    try {
      await screenShotWithAdjustedDom();
    } finally {
      useToastStore().hide();
    }
  }, 100);
}

// adjust the DOM to avoid element styles that are not supported by html2canvas
async function screenShotWithAdjustedDom() {
  try {
    svgElementsOnBoard().forEach((svg) => {
      const style = window.getComputedStyle(svg);
      svg.setAttribute(
        "old-values",
        JSON.stringify({
          fill: svg.style.fill,
          stroke: svg.style.stroke,
          width: svg.getAttribute("width"),
          height: svg.getAttribute("height"),
        } satisfies SavedSvgAttributes),
      );
      setAllAttributes(svg, {
        fill: style.fill,
        stroke: style.stroke,
        width: "" + svg.clientWidth,
        height: "" + svg.clientHeight,
      });
    });
    await doScreenShot();
  } catch (fail) {
    captureException(fail);
  } finally {
    svgElementsOnBoard().forEach((svg) => {
      const oldValues = JSON.parse(
        svg.getAttribute("old-values")!,
      ) as SavedSvgAttributes;
      svg.removeAttribute("old-values");
      setAllAttributes(svg, oldValues);
    });
  }
}

interface SavedSvgAttributes {
  fill: string;
  stroke: string;
  width: string | null;
  height: string | null;
}

function setAllAttributes(svg: SVGSVGElement, attrs: SavedSvgAttributes) {
  svg.style.fill = attrs.fill;
  svg.style.stroke = attrs.stroke;
  setAttribute(svg, "width", attrs.width);
  setAttribute(svg, "height", attrs.height);
}

function setAttribute(svg: SVGSVGElement, name: string, value: string | null) {
  if (value) {
    svg.setAttribute(name, value);
  } else {
    svg.removeAttribute(name);
  }
}

function svgElementsOnBoard() {
  return document.querySelectorAll<SVGSVGElement>(".board svg, .draw-layer");
}

async function doScreenShot() {
  const { default: html2canvas } = await import("@nidi/html2canvas").catch(
    reloadOnMissingChunks,
  );
  const scale = screenShotScale();
  const canvas = await html2canvas(document.body, {
    scale,
    logging: false,
    ignoreElements: (e) => e.getAttribute("data-no-screenshot") !== null,
  });
  cutOutBoard(canvas, scale).toBlob((blob) => {
    if (blob) {
      FileSaver.saveAs(blob, screenShotName());
    } else {
      captureMessage("Could not generate screenshot blob");
    }
  });
}

function screenShotScale() {
  return (
    screenShotWidth / (useAppSizeStore().appSize.zoom * effectiveBoardWidth())
  );
}

function cutOutBoard(canvas: HTMLCanvasElement, scale: number) {
  const target = document.createElement("canvas");
  target.width = screenShotWidth;
  target.height = screenShotWidth / boardAspect;
  target
    .getContext("2d")!
    .drawImage(
      canvas,
      -useAppSizeStore().appSize.left * scale,
      -useAppSizeStore().appSize.top * scale,
    );
  return target;
}

function screenShotName() {
  const session = safeFilename(useSessionStore().session.current.name);
  const art = useArtStore().isMultiArt
    ? "-" + safeFilename(useArtStore().currentArt.name)
    : "";
  return `${session}${art}-${boardName()}.png`;
}

function safeFilename(s: string) {
  return s.replace(/[^A-Za-z0-9]+/g, "_");
}

function boardName() {
  const board = useBoardStore().currentBoard();
  switch (board.type) {
    case "team":
      return "team-" + safeFilename(board.team.name || "");
    case "flex":
      return safeFilename(board.name || "");
    default:
      return board.type;
  }
}
