<template>
  <div id="app-zoom" class="zoom" :class="{ active }">
    <BaseTooltip position="bottom">
      <span class="text cta" @click="toggle">{{
        $t("general.percent", { value: roundZoom })
      }}</span>
      <template #content>{{ $t("appZoom.zoom") }}</template>
    </BaseTooltip>
    <transition name="fade">
      <div v-show="active" class="zoom-controls">
        <span>{{ $t("appZoom.zoom") }}</span>
        <div
          class="plus"
          @pointerdown.stop="appZoom(1.05)"
          @pointerout="appZoom()"
          @pointerup="appZoom()"
        >
          <span>+</span>
        </div>
        <div class="zoomer">
          <div
            class="dot"
            :style="{ top: `${zoom}%` }"
            @pointerdown="zoomerPointerDown"
          ></div>
        </div>
        <div
          class="minus"
          @pointerdown.stop="appZoom(0.95)"
          @pointerout="appZoom()"
          @pointerup="appZoom()"
        >
          <span>-</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { clamp } from "lodash-es";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import { emitZoom, registerDrag, zoomState } from "@/Gestures";
import { maxZoom, minZoom } from "@/Settings";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import { centerCoord, windowCoord } from "@/math/coordinates";
import { useAppSizeStore } from "@/store/appSize";

@Options({ components: { BaseTooltip } })
export default class AppZoom extends Vue {
  zoomTimeout: number | undefined;
  factor = this.appSize.zoom;
  dragging = false;
  active = false;

  @Watch("appSize.zoom")
  onZoomChanged(zoom: number) {
    this.factor = zoom;
  }

  get appSize() {
    return useAppSizeStore().appSize;
  }

  get zoom() {
    return 100 * (1 - (this.factor - minZoom) / (maxZoom - minZoom));
  }

  get roundZoom() {
    return Math.round(100 * this.factor);
  }

  toggle() {
    this.active = !this.active;
  }

  zoomerPointerDown(e: PointerEvent) {
    registerDrag<{ max: number }>(this, e, {
      start(drag) {
        const p = drag.el.parentElement!;
        drag.start.y = -(
          p.offsetTop +
          p.parentElement!.offsetTop +
          p.parentElement!.parentElement!.offsetTop
        );
        drag.max = p.offsetHeight;
        this.startZoom();
        this.dragging = true;
        return true;
      },
      move(drag) {
        const pos = windowCoord(
          0,
          clamp(drag.pos.y - window.scrollY, 0, drag.max),
        );
        this.factor = zoomState.factor =
          maxZoom - (pos.y / drag.max) * (maxZoom - minZoom);
        return pos;
      },
      stop() {
        this.stopZoom();
        this.dragging = false;
      },
    });
  }

  appZoom(f?: number) {
    if (f) {
      const factor = clamp(zoomState.factor * f, minZoom, maxZoom);
      if (factor !== zoomState.factor) {
        if (!zoomState.zooming) {
          this.startZoom();
        }
        this.factor = zoomState.factor = factor;
        this.zoomTimeout = window.setTimeout(() => this.appZoom(f), 10);
      } else if (zoomState.zooming) {
        this.stopZoom();
      }
    } else if (this.zoomTimeout) {
      this.stopZoom();
    }
  }

  startZoom() {
    this.factor = zoomState.factor = this.appSize.zoom;
    emitZoom(centerCoord());
  }

  stopZoom() {
    clearTimeout(this.zoomTimeout);
    this.zoomTimeout = 0;
    emitZoom();
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins" as *;

#main-menu.navigation #app-zoom.zoom {
  position: relative;
  display: flex;
  align-items: center;

  .cta.text {
    color: var(--text-secondary-color);
    padding: 10px 8px;
    border-radius: variables.$button-icon-radius;
    display: inline-block;
    height: 100%;

    &:hover {
      color: var(--text-primary-color);
      background-color: var(--light-background-color);
    }
  }

  &.active {
    .cta.text {
      background-color: var(--active-background-color);
      color: colors-old.$primary-color;
    }
  }

  .zoom-controls {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 10px auto auto;
    height: 227px;
    width: 56px;
    background-color: colors-old.$back-color;

    @include default-shadow;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 25px 0;
    border-radius: 6px;

    > span {
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 12px;
      font-weight: font.$weight-bold;
      color: colors-old.$text-secondary-color;
    }

    .plus,
    .minus {
      position: relative;
      color: colors-old.$menu-color;
      text-align: center;
      font-size: 24px;
      transition: 0.3s ease-in-out background-color;
      width: 30px;
      min-height: 30px;
      border-radius: 6px;

      span {
        position: absolute;
        inset: 0;
        margin: auto;
      }

      &:hover {
        background-color: colors-old.$light-background-color;
      }
    }

    .zoomer {
      position: relative;
      border: 2px solid colors-old.$divider-color;
      border-top: 0;
      height: 100%;
      border-radius: 40px;
      margin-bottom: 15px;
      margin-top: 10px;
    }

    .dot {
      position: absolute;
      left: -6px !important;
      right: 0;
      margin: auto;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: colors-old.$menu-color;
    }
  }
}
</style>
