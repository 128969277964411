<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";

import { getBoardIdsOfGroupedStickies } from "@/backend/Backend";
import MirrorSticky from "@/components/card/components/ActionMenu/components/MirrorSticky/MirrorSticky.vue";
import { cardKey } from "@/components/card/injectKeys";
import { useBoardStore } from "@/store/board";
import { injectStrict } from "@/utils/context";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";

const card = injectStrict(cardKey);

// TODO: this will need to be cached
const boardsStickyMirroredTo = ref<Array<string> | undefined>();
onMounted(async () => {
  const boardIds = await getBoardIdsOfGroupedStickies(card.id);
  boardsStickyMirroredTo.value = boardIds.filter(
    (id) => id !== useBoardStore().currentBoard().id,
  );
});

const mirrorCount = computed(() => {
  const count = boardsStickyMirroredTo.value?.length || 0;
  return count === 0 ? "" : count.toString();
});
</script>

<template>
  <AttributeChip
    v-if="boardsStickyMirroredTo"
    icon="thin/mirror"
    text-placeholder="0"
    :text="mirrorCount"
  >
    <MirrorSticky />
  </AttributeChip>
</template>
