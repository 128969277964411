export type Lines = Array<[number, number]>;

export function breakLines(
  text: string,
  width: number,
  measure: (chars: string[], from: number, to: number) => number,
): Lines {
  const chars = Array.from(text);
  const lines = new Array<[number, number]>();
  let lineStart = 0;
  for (let i = 0; i < chars.length; i++) {
    if (chars[i] === "\n") {
      setLine(i, i + 1);
    } else if (measure(chars, lineStart, i + 1) > width) {
      switch (chars[i]) {
        case " ": {
          const p = i;
          while (i < chars.length + 1 && chars[i + 1] === " ") {
            i++;
          }
          setLine(p, i + 1);
          break;
        }
        case "-":
          setLine(i, i);
          break;
        default: {
          for (let p = i; ; p--) {
            if (chars[p] === " ") {
              setLine(p, p + 1);
              break;
            }
            if (chars[p] === "-") {
              setLine(p + 1, p + 1);
              break;
            }
            if (p === lineStart) {
              setLine(i, i);
              break;
            }
          }
          break;
        }
      }
    }
  }
  setLine(chars.length, 0);
  return asStringPositions(chars, lines);

  function setLine(to: number, from: number) {
    lines.push([lineStart, to]);
    lineStart = from;
  }
}

// positions in Lines assume that each character has length 1 (because we use Array.from(s))
// this is not true for strings e.g. "😇".length==2
// so convert the positions back so that they are usable with string functions like substring etc.
function asStringPositions(chars: string[], lines: Lines) {
  const stringLines = lines.map((line) => line.slice()) as Lines;
  for (let i = 0; i < chars.length; i++) {
    const len = chars[i].length;
    if (len > 1) {
      for (let j = 0; j < lines.length; j++) {
        if (lines[j][0] > i) {
          stringLines[j][0] += len - 1;
        }
        if (lines[j][1] > i) {
          stringLines[j][1] += len - 1;
        }
      }
    }
  }
  return stringLines;
}
