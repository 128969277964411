<template>
  <input
    v-bind="$attrs"
    v-model="inputValue"
    :type="type"
    :maxlength="maxlength"
    class="base-board-input"
    :class="{ 'has-borders': !noBorders }"
  />
</template>

<script lang="ts">
import { Options, Prop, Vue } from "vue-property-decorator";

const Component = Options;

@Component({ emits: ["update:modelValue"] })
export default class BaseBoardInput extends Vue {
  @Prop([Number, String]) readonly modelValue!: string | number;
  @Prop({ type: String, default: "text" }) readonly type!: string;
  @Prop([Number, String]) readonly maxlength!: number | string;
  @Prop({ type: Boolean, default: false }) readonly noBorders!: boolean;

  get inputValue() {
    return this.modelValue;
  }

  set inputValue(newValue) {
    this.$emit("update:modelValue", newValue);
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.base-board-input {
  cursor: initial;
  background-color: transparent;
  outline: none;
  width: 100%;
  font-weight: font.$weight-semi-bold;

  &.has-borders {
    border: board.len(3px) solid colors-old.$input-border-color;
    border-radius: board.len(10px);
    height: 100%;
  }

  &:focus {
    border-color: colors-old.$input-focus-border-color;
  }
}
</style>
