<template>
  <div
    class="objective-row"
    data-testid="team-objective-row"
    :class="{ selected }"
    @pointerenter="setLinkingTarget"
    @pointerleave="resetLinkingTarget"
  >
    <div class="objective-title">
      <span class="objective-index">{{ index }}</span>
      <span>{{ objective.text }}</span>
    </div>
    <div class="objective-right-content">
      <objective-link-button
        class="objective-link-button"
        :link-count="linkCount"
        @click.stop="showLinkModal"
      />
      <span class="objective-business-value">{{ objective.bv }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import ObjectiveLinkModal from "@/components/modal/ObjectiveLinkModal.vue";
import ObjectiveLinkButton from "@/components/objectives/ObjectiveLinkButton.vue";
import { Objective, isDependency } from "@/model";
import { useBoardStore } from "@/store/board";
import { useLinkStore } from "@/store/link";
import { useModalStore } from "@/store/modal";

@Options({ components: { ObjectiveLinkButton } })
export default class TeamObjectiveRow extends Vue {
  @Prop({ type: Object, required: true }) readonly objective!: Objective;
  @Prop({ type: Number, required: true }) readonly index!: number;
  @Prop({ type: Boolean, default: false }) readonly selected!: boolean;

  get linkCount(): number {
    return useLinkStore().teamObjectiveLinkCount(this.objective.id);
  }

  showLinkModal() {
    useModalStore().open(ObjectiveLinkModal, {
      attrs: {
        boardId: useBoardStore().currentBoard().id,
        objectiveId: this.objective.id,
      },
    });
  }

  setLinkingTarget() {
    const linkStore = useLinkStore();
    const isAlreadyLinked = this.objective.cards.some((card) =>
      linkStore.isLinkedFrom(card.id),
    );
    const isFromDependencySticky =
      linkStore.linking.from && isDependency(linkStore.linking.from);

    if (linkStore.linking.from && !isAlreadyLinked && !isFromDependencySticky) {
      linkStore.setLinkingTarget({
        id: this.objective.id,
        type: "objective",
      });
    }
  }

  resetLinkingTarget() {
    useLinkStore().resetLinkingTarget();
  }
}
</script>
<style lang="scss">
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.objective-row {
  margin: 0 board.len(12px) board.len(2px) board.len(12px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: board.len(10px);
  border-radius: 1.5 * variables.$border-radius;
  border: board.len(1px) solid colors-old.$hover-color;

  &:hover,
  &:focus {
    border: board.len(1px) solid colors-old.$objective-hover-color;
  }

  &.selected {
    border: board.len(1px) solid colors-old.$objective-highlight-color;
  }
}

.objective-title {
  display: inline-flex;
  font-size: board.len(14px);
  padding-right: board.len(40px);
  word-break: break-word;
  white-space: pre-line;
}

.objective-right-content {
  display: flex;
  text-align: right;
  align-items: center;
  gap: board.len(16px);
}

.objective-link-button {
  font-weight: font.$weight-semi-bold;
}

.objective-business-value {
  display: inline-block;
  font-size: board.len(14px);
  font-weight: font.$weight-semi-bold;
  width: board.len(16px);
  margin-right: board.len(24px);
}

.objective-index {
  margin-right: board.len(22px);
  color: colors-old.$text-secondary-color;
  font-weight: font.$weight-bold;
}
</style>
