<script setup lang="ts">
import { clamp } from "lodash-es";

import BoardNumberInput from "@/components/input/BoardNumberInput.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";

const emit = defineEmits<{ "update:modelValue": [value: number] }>();

const props = withDefaults(
  defineProps<{
    modelValue: number;
    minValue?: number;
    maxValue?: number;
  }>(),
  { minValue: 0, maxValue: 100 },
);

let repeatTimeout = 0;

function startChange(diff: number, delay = 500) {
  const val = props.modelValue + diff;
  if (val >= props.minValue && val <= props.maxValue) {
    emit("update:modelValue", val);
    repeatTimeout = window.setTimeout(() => startChange(diff, 30), delay);
  }
}

function endChange() {
  clearTimeout(repeatTimeout);
}

function handleEnterKey(event: KeyboardEvent) {
  event.stopPropagation();
  (event.target as HTMLInputElement).blur();
}

function handleInputChange(v: string) {
  let newValue = parseInt(v, 10);
  if (isNaN(newValue)) {
    newValue = props.modelValue;
  }
  emit("update:modelValue", newValue);
}

function validateInput() {
  emit(
    "update:modelValue",
    clamp(props.modelValue, props.minValue, props.maxValue),
  );
}
</script>

<template>
  <div class="percentage-input">
    <IconButton
      icon="minus"
      @mousedown="startChange(-1)"
      @mouseup="endChange"
    />
    <BoardNumberInput
      :min="minValue"
      :max="maxValue"
      :value="modelValue"
      :maxlength="3"
      no-borders
      @input="handleInputChange"
      @blur="validateInput"
      @keydown.enter="handleEnterKey"
    />
    <IconButton icon="plus" @mousedown="startChange(1)" @mouseup="endChange" />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.percentage-input {
  height: 40px;
  width: 120px;
  border-radius: 4px;
  border: 1px solid colors-old.$divider-color;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: colors-old.$text-secondary-color;
  }
}
</style>
