<script setup lang="ts">
import { computed } from "vue";

import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import SelectNamedValue from "@/components/ui/SelectNamedValue/SelectNamedValue.vue";
import { dependencyLinkItems, useSearchMenuStore } from "@/store/searchMenu";

import DependencyLinkItemTooltip from "../tooltips/DependencyLinkItemTooltip.vue";

const store = useSearchMenuStore();

const selection = computed({
  get: () => store.selectedDependencyLinkItems,
  set: (values: Array<{ id: string }>) =>
    (store.selectedDependencyLink = new Set(values.map((val) => val.id))),
});
</script>

<template>
  <DropdownMenu class="search-select" width="sm" stay-on-click>
    <template #trigger="{ isOpen }">
      <IconButton icon="link" :activated="isOpen">
        <template #tooltip>
          <DependencyLinkItemTooltip :items="selection" />
        </template>
      </IconButton>
      <InfoMarker v-if="selection.length" :top="4" :right="4" />
    </template>
    <SelectNamedValue
      v-model="selection"
      :values="dependencyLinkItems"
      :header="$t('dependency.stickies')"
      translate
    />
  </DropdownMenu>
</template>
