export class ApplicationError extends Error {
  constructor(
    public name: string,
    message: string,
    public contexts: Contexts,
  ) {
    super(message);
  }
}

export type Contexts = Record<string, Record<string, unknown>>;
