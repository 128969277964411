import color from "@/styles/color.module.scss";

/* eslint-disable no-console */
function showLogo() {
  const black = "color:" + color.textPrimary;
  const blue = "color:" + color.ok;
  const red = "color:" + color.error;
  console.log(
    "             %c███████████\n" +
      "             █         █\n" +
      "             █         █   %c      (_)     | |                 (_)\n" +
      "%c███████████  %c█         █   %c _ __  _ _ __ | | __ _ _ __  _ __  _ _ __   __ _\n" +
      "%c█         █  %c█         █   %c| '_ \\| | '_ \\| |/ _` | '_ \\| '_ \\| | '_ \\ / _` |\n" +
      "%c█         █  %c█         █   %c| |_) | | |_) | | (_| | | | | | | | | | | | (_| |\n" +
      "%c█         █  %c███████████   %c| .__/|_| .__/|_|\\__,_|_| |_|_| |_|_|_| |_|\\__, |\n" +
      "%c█         █                %c| |     | |                                 __/ |\n" +
      "%c█         █                %c|_|     |_|                                |___/\n" +
      "%c███████████",
    blue + ";line-height: 12px",
    black,
    red,
    blue,
    black,
    red,
    blue,
    black,
    red,
    blue,
    black,
    red,
    blue,
    black,
    red,
    black,
    red,
    black,
    red,
  );
}

export function showHelp() {
  showLogo();
  console.log(
    "\n%cCommand                        Description                    Example\n" +
      '%clogger.config(levels)          Configure the logger.          logger.config("*:debug,backend:trace")\n' +
      'logger(name)(message)          Log a message.                 logger("backend")("data loaded")\n' +
      "stats()                        Show the current statistics.\n" +
      "allStats()                     Show the complete statistics history.",
    "font-weight: bold",
    "font-weight: normal",
  );
  console.log(
    "If not everything seems to be logged, %ccheck the console settings of your browser.%c It might filter messages.",
    "font-weight: bold",
    "font-weight: normal",
  );
}

Object.defineProperty(window, "help", {
  get() {
    showHelp();
    return "";
  },
});
