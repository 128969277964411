<script setup lang="ts">
import { computed } from "vue";

import { toggleActions } from "@/action/toggleActions";
import ActionButton from "@/components/ui/ActionButton/ActionButton.vue";
import { useBoardStore } from "@/store/board";

const isProgramBoard = computed(
  () => useBoardStore().board?.type === "program",
);
const isTeamBoard = computed(() => useBoardStore().board?.type === "team");
</script>

<template>
  <div class="fill-parent">
    <ActionButton
      v-if="isProgramBoard"
      :action="toggleActions.showProgramMetrics"
    />
    <ActionButton v-if="isTeamBoard" :action="toggleActions.showTeamMetrics" />
  </div>
</template>

<style lang="scss" scoped>
.fill-parent {
  display: flex;
}
</style>
