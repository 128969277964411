import { Ref, watch } from "vue";

export function useAnimation(el: Ref<HTMLElement | undefined>) {
  function animate(cssClass: string, onEnd?: () => void) {
    el.value?.addEventListener(
      "animationend",
      () => {
        onEnd?.();
        el.value?.classList.remove(cssClass);
      },
      { once: true },
    );
    el.value?.classList.add(cssClass);
  }

  function animateOn(cssClass: string, value: Ref<any>) {
    watch(value, () => animate(cssClass));
  }

  return { animate, animateOn };
}
