<template>
  <div class="load-stat-modal-capacity-allocation-body">
    <metrics-modal-body-layout>
      <template #left>
        <div class="container">
          <h3>{{ title }}</h3>
          <h4>
            {{
              $t("modal.loadValue", {
                number: parseFloat(load().toFixed(1)),
              })
            }}
          </h4>
          <pie-chart
            :value="iterData().values"
            :total="load()"
            :select="selectName"
            :hole="true"
            :style="{ width: teamSize + 'px', height: teamSize + 'px' }"
          />
          <div class="legend">
            <div
              v-for="stickyType in stickyTypes"
              :key="stickyType.id"
              class="item"
              :title="stickyType.name"
              :class="{
                selected: stickyType === selected,
                current: stickyType === hoverSelected,
              }"
              @click="select(stickyType, false)"
              @pointerenter="select(stickyType, true)"
              @pointerleave="select(null, true)"
            >
              <span
                class="color"
                :style="{ backgroundColor: color(stickyType) }"
              ></span>
              <span>{{ stickyType.name }}</span>
            </div>
          </div>
        </div>
      </template>
      <template #right>
        <div
          v-for="(iter, i) in items"
          :key="iter.name"
          :style="{
            left: left(i) + '%',
            top: top(i) + '%',
            width: 200 / items.length + '%',
          }"
        >
          <h3>{{ iter.name }}</h3>
          <h4>
            {{
              $t("modal.loadValue", {
                number: parseFloat(load(i).toFixed(1)),
              })
            }}
          </h4>
          <div>
            <pie-chart
              :value="iterData(i).values"
              :total="load(i)"
              :select="selectName"
              :hole="true"
              :style="{ width: iterSize + 'px', height: iterSize + 'px' }"
            />
          </div>
        </div>
      </template>
    </metrics-modal-body-layout>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import PieChart from "@/components/PieChart.vue";
import MetricsModalBodyLayout from "@/components/modal/MetricsModalBodyLayout.vue";
import {
  CapacityAllocation,
  calcCapacityAllocation,
} from "@/components/modal/metrics";
import { i18n } from "@/i18n";
import { StickyType } from "@/model";
import { useBoardStore } from "@/store/board";
import { useIterationStore } from "@/store/iteration";
import { toCssColor } from "@/utils/color";

export type CapacityAllocationBodyItem = { name: string };

@Options({
  components: { PieChart, MetricsModalBodyLayout },
})
export default class MetricsModalCapacityAllocationBody extends Vue {
  title = i18n.global.t("metricsModal.allIterations");
  width = 0;
  height = 0;
  iterSize = 0;
  teamSize = 0;
  selected: StickyType | null = null;
  hoverSelected: StickyType | null = null;

  created() {
    this.width = document.documentElement.clientWidth * 0.75;
    this.height = document.documentElement.clientHeight * 0.85;
    const pieWidth = this.width / 2 / Math.ceil(this.items.length / 2);
    const pieHeight = (this.height - 150) / 2 - 100;
    this.iterSize = Math.min(pieWidth, pieHeight);
    this.teamSize = this.width / 4;
  }

  get items(): CapacityAllocationBodyItem[] {
    return useIterationStore().iterations;
  }

  load(itemIndex?: number): number {
    return this.iterData(itemIndex).total;
  }

  left(i: number) {
    const line = Math.ceil(this.items.length / 2);
    return (100 * (i % line)) / line;
  }

  top(i: number) {
    const line = Math.ceil(this.items.length / 2);
    return 50 * Math.floor(i / line);
  }

  iterData(iterationIndex?: number): CapacityAllocation {
    return calcCapacityAllocation([this.board], iterationIndex);
  }

  get board() {
    return useBoardStore().boardByType("team");
  }

  get stickyTypes() {
    return this.board.stickyTypes.filter(
      (t) => t.origin === "team" && t.functionality === "workitem",
    );
  }

  color(type: StickyType) {
    return toCssColor(type.color);
  }

  select(type: StickyType | null, hover: boolean) {
    if (hover) {
      this.hoverSelected = type;
    } else {
      this.selected = type === this.selected ? null : type;
    }
  }

  get selectName() {
    return (this.hoverSelected || this.selected)?.name;
  }
}
</script>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.load-stat-modal-capacity-allocation-body {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: center;
    height: 100%;
    width: 80%;
  }

  .mode {
    cursor: pointer;
    position: absolute;
    top: 1em;
    right: 1em;
    background-color: colors-old.$menu-color;
    color: colors-old.$back-color;
    padding: 0.5em;
  }

  h2 {
    white-space: pre;
  }

  h3 {
    font-size: 20px;
    font-weight: font.$weight-normal;
    margin-bottom: 8px;
  }

  h4 {
    font-size: 28px;
    font-weight: font.$weight-extra-bold;
  }

  .legend {
    align-content: space-between;
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    max-width: 100%;

    .item {
      padding: 0.5em;
      flex: 1 1 50%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .color {
      display: inline-block;
      border: 1px solid colors-old.$menu-color;
      width: 0.8em;
      height: 0.8em;
      border-radius: 0.4em;
      margin-right: 1em;
    }
  }

  .right {
    margin: 2% 0;

    h4 {
      font-size: 20px;
    }
  }
}
</style>
