import { groupBy } from "lodash-es";
import { defineStore } from "pinia";
import { reactive } from "vue";

import { Card, StickyChange } from "@/model";
import { useCardStore } from "@/store/card";
import { atDayStart } from "@/utils/date";

// StickyChange with properties related to how it should be rendered
interface DisplayableStickyChange extends StickyChange {
  expandable: boolean;
  expanded: boolean;
}

const isExpandable = (change: StickyChange) =>
  ["update", "mirror", "unmirror", "link", "unlink"].includes(change.kind);

export const useActivityStore = defineStore("activity", {
  state: () => ({
    active: false,
    card: null as Card | null,
    stickyChanges: [] as StickyChange[],
    incomplete: false,
  }),
  getters: {
    stickyChangesByDay: (
      state,
    ): Array<{
      stickyChanges: Array<DisplayableStickyChange>;
      date: Date;
    }> => {
      const groups = Object.entries(
        groupBy(state.stickyChanges, (change) =>
          atDayStart(change.timestamp).getTime(),
        ),
      );
      return groups.map(([group, stickyChanges]) => ({
        stickyChanges: stickyChanges.map((change) =>
          reactive({
            ...change,
            expanded: false,
            expandable: isExpandable(change),
          }),
        ),
        date: new Date(+group),
      }));
    },
  },
  actions: {
    setStickyChanges(changes: StickyChange[]) {
      const len = changes.length;
      this.incomplete = len > 0 && changes[len - 1].kind !== "create";
      // if we have an incomplete history, remove the first change
      // as it's an artificial change from "no fields" to "fields at the moment sticky activity was implemented"
      if (this.incomplete) {
        changes.splice(len - 1, 1);
      }
      this.stickyChanges = changes;
    },
    toggle(id: string) {
      if (this.active && this.card?.id === id) {
        this.inactivate();
      } else {
        this.active = true;
        this.card = useCardStore().cards[id];
      }
    },
    inactivate() {
      this.active = false;
      this.card = null;
      this.stickyChanges = [];
    },
  },
});
