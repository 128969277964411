import { BoardType, RiskType } from "@/baseTypes";
import { i18n } from "@/i18n";
import { Board } from "@/model";
import { useArtStore } from "@/store/art";
import { Icon } from "@/types/icon";

const riskI18nKeys: {
  [id in RiskType]: { short: string; long: string; icon: Icon };
} = {
  R: {
    short: /*$t*/ "risk.resolved.short",
    long: /*$t*/ "risk.resolved",
    icon: "risk/resolved",
  },
  O: {
    short: /*$t*/ "risk.owned.short",
    long: /*$t*/ "risk.owned",
    icon: "risk/owned",
  },
  A: {
    short: /*$t*/ "risk.accepted.short",
    long: /*$t*/ "risk.accepted",
    icon: "risk/accepted",
  },
  M: {
    short: /*$t*/ "risk.mitigated.short",
    long: /*$t*/ "risk.mitigated",
    icon: "risk/mitigated",
  },
  "": {
    short: /*$t*/ "risk.unRoam.short",
    long: /*$t*/ "risk.unRoam",
    icon: "risk/roam",
  },
};

export function riskTypeName(risk: RiskType) {
  return i18n.global.t(riskI18nKeys[risk].short);
}

export function riskTitle(risk: RiskType) {
  return i18n.global.t(riskI18nKeys[risk].long);
}

export function riskIcon(risk: RiskType) {
  return riskI18nKeys[risk].icon;
}

const boardI18nKeys: {
  [boardType in BoardType]: { short: string; long: string; icon: Icon };
} = {
  backlog: {
    short: /*$t*/ "board.backlog",
    long: /*$t*/ "board.backlog.long",
    icon: "board/backlog",
  },
  program: {
    short: /*$t*/ "board.program",
    long: /*$t*/ "board.program.long",
    icon: "board/program",
  },
  solution_backlog: {
    short: /*$t*/ "board.solutionBacklog",
    long: /*$t*/ "board.solutionBacklog.long",
    icon: "board/solution-backlog",
  },
  solution: {
    short: /*$t*/ "board.solution",
    long: /*$t*/ "board.solution.long",
    icon: "board/solution",
  },
  risk: {
    short: /*$t*/ "board.risk",
    long: /*$t*/ "board.risk.long",
    icon: "board/risk",
  },
  team: {
    short: /*$t*/ "board.team",
    long: /*$t*/ "board.team.long",
    icon: "board/team",
  },
  flex: {
    short: /*$t*/ "board.flex",
    long: /*$t*/ "board.flex.long",
    icon: "board/canvas",
  },
  objectives: {
    short: /*$t*/ "board.objectives",
    long: /*$t*/ "board.objectives.long",
    icon: "board/objectives",
  },
};

export function boardTypeName(type: BoardType) {
  return i18n.global.t(boardI18nKeys[type].short);
}

export function boardIcon(board: Board) {
  return boardI18nKeys[board.type].icon;
}

export function boardTitle(board: Board, withArt = true) {
  const key = boardI18nKeys[board.type];
  switch (board.type) {
    case "team":
      return i18n.global.t(key.long, { team: board.team.name });
    case "flex":
      return i18n.global.t(key.long, { name: board.name });
    default: {
      if (!withArt) {
        return i18n.global.t(key.short);
      }
      const art = useArtStore().artById(board.artId);
      const hasArt = board.artId && art?.name;
      return i18n.global.t(key.long, { art: hasArt ? `(${art.name})` : "" });
    }
  }
}
