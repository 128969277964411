import { mixins } from "vue-class-component";

import ShortcutUser from "@/mixins/ShortcutUser";
import { nextIndex } from "@/utils/list";

export default class SelectList<TItem = any> extends mixins(ShortcutUser) {
  selectList: TItem[] = [];
  selectIndex = -1;
  scrollable: HTMLElement | null | undefined = undefined;
  upDownOffset = 1;
  leftRightOffset = 0;

  created() {
    if (this.upDownOffset !== 0) {
      this.shortcut("ArrowDown", () => this.selectNext(this.upDownOffset), {
        repeat: true,
      });
      this.shortcut("ArrowUp", () => this.selectNext(-this.upDownOffset), {
        repeat: true,
      });
    }
    if (this.leftRightOffset !== 0) {
      this.shortcut("ArrowRight", () => this.selectNext(this.leftRightOffset), {
        repeat: true,
      });
      this.shortcut("ArrowLeft", () => this.selectNext(-this.leftRightOffset), {
        repeat: true,
      });
    }
    this.shortcut("Enter", (e) => {
      this.select(this.selectedItem(), e);
    });
  }

  selectNext(offset: number) {
    if (this.selectList.length > 0) {
      this.setSelected(nextIndex(this.selectList, this.selectIndex, offset));
    }
  }

  selectedItem() {
    return this.selectIndex < 0 ? undefined : this.selectList[this.selectIndex];
  }

  setSelected(i: number) {
    this.selectIndex = i;
    if (i >= 0) {
      const item = this.selectedItem();
      if (item) {
        const elem = document.getElementById(this.optionId(item));
        elem?.scrollIntoView({ block: "nearest" });
      }
    }
  }

  select(_value: TItem | undefined, _event: Event) {}

  optionId(_item: TItem): string {
    return "";
  }
}
