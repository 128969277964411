<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { Counter } from "@/Counter";
import { Statistics } from "@/model";

const props = defineProps<{
  stats: Statistics[];
  properties: string;
  diff?: boolean;
}>();
const colors = [
  ["0,0,255", "0,0,127"],
  ["255,0,127", "127,0,63"],
];

const canvasRef = ref();
const context = computed(() => canvasRef.value?.getContext("2d"));

watch(() => props.stats.length, draw);

const properties = computed(() => props.properties.split(","));

function val(prop: number, i: number) {
  return (props.stats[i] as any)[properties.value[prop]];
}

function eff(value: number, last: number) {
  return props.diff ? value - last : value;
}

function draw() {
  const ctx = context.value;
  if (!ctx) {
    return;
  }
  ctx.clearRect(0, 0, 100, 100);
  if (props.stats.length > 0) {
    const end = props.stats.length;
    const start = Math.max(0, end - 250);
    const counter = val(0, 0).ok !== undefined;
    let max = 0;
    let lastN = 0;
    let lastC = new Counter();
    for (let p = 0; p < properties.value.length; p++) {
      for (let i = start; i < end; i++) {
        const v = val(p, i);
        if (counter) {
          max = Math.max(max, eff(v.ok, lastC.ok), eff(v.fail, lastC.fail));
          lastC = v;
        } else {
          max = Math.max(max, eff(v, lastN));
          lastN = v;
        }
      }
    }

    const xf = 100 / (end - start);
    for (let p = 0; p < properties.value.length; p++) {
      for (let r = 0; r < (counter ? 2 : 1); r++) {
        ctx.beginPath();
        ctx.moveTo(0, 100);
        let last = 0;
        for (let i = start; i < end; i++) {
          let v = val(p, i);
          if (counter) {
            v = r === 0 ? v.ok : v.fail;
          }
          ctx.lineTo((i - start) * xf, 100 - (eff(v, last) * 100) / max);
          last = v;
        }
        ctx.lineTo((end - 1 - start) * xf, 100);
        ctx.closePath();
        ctx.fillStyle = `rgba(${colors[p][r]},.5)`;
        ctx.fill();
      }
    }
  }
}
</script>

<template>
  <canvas
    ref="canvasRef"
    width="100"
    height="100"
    class="graph"
    data-no-screenshot
  />
</template>

<style lang="scss">
.graph {
  width: 100%;
  height: 100%;
}
</style>
