import { defineStore } from "pinia";

import { ServerLink } from "@/backend/serverModel";
import { BoardType } from "@/baseTypes";
import { sendLinkStateUpdate } from "@/mixins/EventBusUser";
import {
  Board,
  BoardCard,
  BoardWithObjectives,
  Card,
  CardLink,
  IdMap,
  InfoLevel,
  Link,
  LinkType,
  Linking,
  LinkingTarget,
  Objective,
  ObjectiveLink,
  Team,
  isBacklogBoard,
  isDependency,
  isWorkitem,
} from "@/model";
import { useCardStore } from "@/store/card";
import { useObjectiveStore } from "@/store/objective";

import { useBoardStore } from "./board";

export const useLinkStore = defineStore("link", {
  state: () => {
    return {
      links: [] as Link[],
      linksById: {} as { [id: string]: Link },
      linksByFrom: {} as { [id: string]: Link[] },
      linksByTo: {} as { [id: string]: Link[] },
      markingObjectiveLinkedCards: false,
      markingCardLinkedCards: null as BoardCard | null,
      linking: { from: null, to: null } as Linking,
      linkTypes: [] as LinkType[],
      linkGroups: {} as { [id: string]: IdMap<BoardCard> },
    };
  },
  getters: {
    linksByCard:
      (state) =>
      (lnkId: Link["from"] | Link["to"]): Link[] => {
        return [
          ...(state.linksByFrom[lnkId] || []),
          ...(state.linksByTo[lnkId] || []),
        ];
      },
    isLinkedFrom: (state) => (id: LinkingTarget["id"]) => {
      return state.linking.from?.id === id;
    },
    isMarkingLinkedCards: (state) => (cardId: string) => {
      return state.markingCardLinkedCards?.data.id === cardId;
    },
    isMarkingLinks: (state) => {
      return (
        state.markingObjectiveLinkedCards || !!state.markingCardLinkedCards
      );
    },
    /**
     * Returns a map of backlog card IDs linked to an objective to sets of non backlog board
     * linked card IDs for a given team objective.
     *
     * @returns - A map where keys are backlog card IDs and values are sets of linked card IDs.
     */
    teamObjectiveBacklogLinks() {
      return (
        objectiveId: Objective["id"],
        { teamId }: { teamId?: Team["id"] } = {},
      ): Map<Card["id"], Set<Card["id"]>> => {
        const linkedBacklogCards = useObjectiveStore().linkedBacklogCards(
          objectiveId,
          { teamId },
        );
        return new Map(
          linkedBacklogCards.map((card) => {
            const linkedCardIds = this.linksByCard(card.id).flatMap((link) => {
              const linkedId = getLinkTargetId(card, link);
              const linkedCard = useCardStore().cards[linkedId];
              return linkedCard && !isBacklogBoard(linkedCard.type.origin)
                ? [linkedId]
                : [];
            });
            return [card.id, new Set(linkedCardIds)];
          }),
        );
      };
    },
    teamObjectiveLinkCount() {
      return (
        objectiveId: Objective["id"],
        { teamId }: { teamId?: Team["id"] } = {},
      ) => {
        const objective = useObjectiveStore().teamObjectiveById(objectiveId, {
          teamId,
        });
        if (!objective) {
          return 0;
        }

        return new Set([
          ...objective.cards
            .filter((card) => card.isOrigin)
            .map((card) => card.id),
          ...Array.from<[Card["id"], Set<Card["id"]>]>(
            this.teamObjectiveBacklogLinks(objectiveId),
          ).flatMap(([blacklogCardId, linkedIds]) => [
            blacklogCardId,
            ...linkedIds,
          ]),
        ]).size;
      };
    },
    cardsByLink:
      (state) =>
      (linkTargetId: string): Card[] => {
        const groups = state.linkGroups[linkTargetId];
        if (groups) {
          const res = [];
          for (const id in groups) {
            res.push(groups[id].data);
          }
          return res;
        }
        const card = useCardStore().cards[linkTargetId];
        if (!card) {
          // can happen because of REN-8846
          return [];
        }
        return [card];
      },
    boardCardByLink:
      (state) =>
      (linkTargetId: string, board: Board): BoardCard | undefined => {
        const groups = state.linkGroups[linkTargetId];
        return groups ? groups[board.id] : board.cards[linkTargetId];
      },
    getCardLinkLabel() {
      return (card: Card) => {
        const cardLinks = this.getCardLinks(card);
        if (cardLinks.length === 0) {
          return;
        }
        const labelCard =
          cardLinks.find((cardLink) => cardLink.origin === "backlog") ||
          cardLinks[0];
        return labelCard.text;
      };
    },
    getCardLinks:
      (state) =>
      (card: Card): CardLink[] => {
        const cardLinks = card.links
          .flatMap((link): CardLink | CardLink[] => {
            if (!link.to) {
              return [];
            }
            const linkedCard = getCardByLinkId(getLinkTargetId(card, link));
            if (!linkedCard) {
              return [];
            }
            return {
              id: link.id,
              type: "sticky",
              text: linkedCard.text,
              iterationId: linkedCard.iterationId,
              almId: linkedCard.almId,
              teamId: linkedCard.teamId,
              color: linkedCard.type.color,
              origin: linkedCard.type.origin,
            };
          })
          .reverse();
        const objectiveLinks = card.objectives
          .flatMap((objective): CardLink | CardLink[] => {
            const board = useBoardStore().boards[
              objective.boardId
            ] as BoardWithObjectives;
            const boardObjective = [
              ...(board?.objectives ?? []),
              ...(board?.stretchObjectives ?? []),
            ].find(({ id }) => objective.id === id);
            if (!boardObjective) {
              return [];
            }

            return {
              id: `${card.id}-${objective.boardId}-${objective.id}`,
              type: "objective",
              text: boardObjective.text,
              color: null,
              objectiveId: objective.id,
              boardId: objective.boardId,
              origin: "team",
            };
          })
          .reverse();
        return [...cardLinks, ...objectiveLinks];

        function getCardByLinkId(linkId: string) {
          const groups = state.linkGroups[linkId];
          if (groups && Object.keys(groups).length > 0) {
            return Object.entries(groups)[0][1].data;
          }
          const card = useCardStore().cards[linkId];
          if (!card) {
            // can happen because of REN-8846
            return;
          }
          return card;
        }
      },
  },
  actions: {
    setLinks(links: Link[]) {
      this.links = [];
      for (const link of links) {
        this.addLink(link);
      }
    },
    addLink(link: Link) {
      this.links.push(link);
      this.linksById[link.id] = link;
      add(this.linksByFrom, link.from, link);
      add(this.linksByTo, link.to, link);

      function add(map: { [id: string]: Link[] }, id: string, link: Link) {
        if (!(id in map)) {
          map[id] = [];
        }
        map[id].push(link);
      }
    },
    addToLinkGroup(groupId: string, boardId: string, boardCard: BoardCard) {
      if (!this.linkGroups[groupId]) this.linkGroups[groupId] = {};
      this.linkGroups[groupId][boardId] = boardCard;
    },
    removeFromLinkGroup(card: Card, boardId: string) {
      if (card.groupId && this.linkGroups[card.groupId]) {
        delete this.linkGroups[card.groupId][boardId];
      }
    },
    removeLink(index: number) {
      const link = this.links[index];
      this.links.splice(index, 1);
      delete this.linksById[link.id];
      remove(this.linksByFrom, link.from, link);
      remove(this.linksByTo, link.to, link);
      return link;

      function remove(map: { [id: string]: Link[] }, id: string, link: Link) {
        const i = map[id].findIndex((l) => l.id === link.id);
        if (i >= 0) {
          map[id].splice(i, 1);
        }
      }
    },

    add(link: Link) {
      if (this.linksById[link.id]) {
        return;
      }
      this.setLinkState(link);
      this.addLink(link);
      this.updateLinks(link);
    },

    remove(linkId: Link["id"] | ServerLink["id"]) {
      const linkIndex = this.links.findIndex((link) => link.id === linkId);
      if (linkIndex < 0) {
        return;
      }
      const link = this.removeLink(linkIndex);
      this.updateLinks(link);
    },
    addObjectiveLink(
      cardId: Card["id"],
      linkInfo: { boardId: string; objectiveId: string },
    ) {
      const card = useCardStore().cards[cardId];
      if (!card) {
        return;
      }
      if (
        card.objectives.every(
          (cardObjective) =>
            cardObjective.boardId !== linkInfo.boardId ||
            cardObjective.id !== linkInfo.objectiveId,
        )
      ) {
        card.objectives.push({
          id: linkInfo.objectiveId,
          boardId: linkInfo.boardId,
        });
      }
    },
    removeObjectiveLink(
      cardId: Card["id"],
      linkInfo: Pick<ObjectiveLink, "objectiveId" | "boardId">,
    ) {
      const card = useCardStore().cards[cardId];
      if (!card) {
        return;
      }
      card.objectives = card.objectives.filter(
        (cardObjective) =>
          cardObjective.boardId !== linkInfo.boardId ||
          cardObjective.id !== linkInfo.objectiveId,
      );
    },
    findLink(
      linkInfo: { id: Card["id"]; toId: Card["id"] } | { linkId: Link["id"] },
    ): Link | undefined {
      if ("linkId" in linkInfo) {
        return this.linksById[linkInfo.linkId];
      }
      // linkInfo.id and linkInfo.toId are always card ids, not groupIds
      const card = useCardStore().cards[linkInfo.id];
      const toCard = useCardStore().cards[linkInfo.toId];
      return this.links.find(
        (link) =>
          (link.from === linkId(card) || link.from === linkId(toCard)) &&
          (link.to === linkId(card) || link.to === linkId(toCard)),
      );
    },
    setLinkState(link: Link) {
      const fromCards = this.cardsByLink(link.from);
      const toCards = this.cardsByLink(link.to);
      if (fromCards.length && toCards.length) {
        link.state = calcLinkState(fromCards[0], toCards[0]);
      }
    },
    updateLinks(link: Link) {
      this.cardsByLink(link.from).forEach((c) => {
        c.links = this.linksByCard(link.from);
      });
      this.cardsByLink(link.to).forEach((c) => {
        c.links = this.linksByCard(link.to);
      });
    },
    setCardLinkStates(card: Card) {
      card.links.forEach((link) => {
        this.setLinkState(link);
      });
      sendLinkStateUpdate();
    },

    // breadth first search from to given cards following all links
    linkedCardDistances(card: BoardCard): IdMap<number> {
      const boardCardByLink = this.boardCardByLink;

      const queue = [{ card, distance: 0 }];
      const linked: IdMap<number> = {};
      while (queue.length > 0) {
        const [item] = queue.splice(0, 1);
        if (!(item.card.data.id in linked)) {
          linked[item.card.data.id] = item.distance;
          item.card.data.links.forEach((link) => {
            addToQueue(link.from, item.distance + 1);
            addToQueue(link.to, item.distance + 1);
          });
        }
      }
      return linked;

      function addToQueue(linkTargetId: string, distance: number) {
        const card = boardCardByLink(
          linkTargetId,
          useBoardStore().currentBoard(),
        );
        if (card && !(card.data.id in linked)) {
          queue.push({ card, distance });
        }
      }
    },

    markObjectiveLinkedCards(objectiveId: string) {
      this.markingObjectiveLinkedCards = true;
      Object.values(useBoardStore().currentBoard().cards).forEach(
        (boardCard) => {
          const isLinkedCard = boardCard.data.objectives.some(
            (objective) => objective.id === objectiveId,
          );
          boardCard.meta.mark = isLinkedCard ? "normal" : "fade-out";
        },
      );
    },

    setLinkingTarget(target: LinkingTarget) {
      this.linking.to = target;
    },
    resetLinkingTarget() {
      this.linking.to = null;
    },
    removeAllMarks() {
      Object.values(useBoardStore().currentBoard().cards).forEach((boardCard) =>
        makeLinksUnmarked(boardCard),
      );
      this.markingObjectiveLinkedCards = false;
      this.markingCardLinkedCards = null;
    },
    forEachLinkOnBoard(
      board: Board,
      action: (link: Link, from: BoardCard, to?: BoardCard) => void,
    ) {
      Object.values(board.cards).forEach((from) => {
        for (const link of from.data.links) {
          if (!link.to) {
            action(link, from);
          } else if (linkId(from.data) === link.from) {
            const to = this.boardCardByLink(link.to, board);
            if (to) {
              action(link, from, to);
            }
          }
        }
      });
    },
    riskyLinkCount(board: Board) {
      const counts = { risky: 0, critical: 0 };
      this.forEachLinkOnBoard(board, (link, from, to) => {
        if (to && link.state === "warn") {
          counts.risky++;
        }
        if (to && link.state === "error") {
          counts.critical++;
        }
      });
      return counts;
    },
    hasRiskyLinks(board: Board) {
      const counts = this.riskyLinkCount(board);
      return counts.risky > 0 || counts.critical > 0;
    },
  },
});

export function linkId(card: Card): NonNullable<Card["groupId"] | Card["id"]> {
  return card.groupId || card.id;
}

export function showCardLinkedObjectives(card: BoardCard) {
  return card.meta.editing || card.meta.dragging || card.meta.isHighlighted;
}

// NB: in order to be consistent, only card properties should be used
// that are the same across all mirrored instances of a card
// e.g. using teamId on a dependency card is not ok because it can be mirrored to multiple team boards
// TODO: The export is only for testing purposes. This is a code smell and it
// means the code/tests should be restructured.
export function calcLinkState(from: Card, to: Card): InfoLevel {
  // states for backlog sticky linked to
  // a team sticky in earlier/same/later iteration
  const backlogToTeamLinkStates: InfoLevel[] = ["ok", "ok", "error"];

  // states for a dependency sticky linked to
  // a team/backlog sticky of same/different team in earlier/same/later iteration
  const dependencyLinkStates: { [situation: string]: InfoLevel[] } = {
    teamStickyOfSameGroup: ["ok", "ok", "error"],
    teamStickyOfDifferentGroup: ["ok", "ok", "ok"],
    backlogStickyOfSameGroup: ["ok", "ok", "error"],
    backlogStickyOfDifferentGroup: ["error", "warn", "ok"],
    solution_backlogStickyOfSameGroup: ["ok", "ok", "error"],
    solution_backlogStickyOfDifferentGroup: ["error", "warn", "ok"],
  };

  const cards = [from, to];
  if (cards.some((card) => card.iterationId === null)) {
    return "default";
  }

  const backlog = workitemFrom(cards, "backlog");
  const team = workitemFrom(cards, "team");
  if (backlog?.teamId && team?.teamId) {
    const order = iterationOrder(backlog, team);
    return backlogToTeamLinkStates[order];
  }

  const solutionBacklog = workitemFrom(cards, "solution_backlog");
  const dependency = cards.find(isDependency);
  const nonDependency = solutionBacklog || backlog || team;
  if (dependency && nonDependency) {
    const isArtLevel = !solutionBacklog;
    const dependencyGroup = groupOf(dependency, isArtLevel);
    const nonDependencyGroup = groupOf(nonDependency, isArtLevel);
    if (dependencyGroup && nonDependencyGroup) {
      const situation =
        nonDependency.type.origin +
        "StickyOf" +
        (dependencyGroup === nonDependencyGroup ? "Same" : "Different") +
        "Group";
      const order = iterationOrder(dependency, nonDependency);
      return dependencyLinkStates[situation][order];
    }
  }

  return "default";

  function groupOf(card: Card, artLevel: boolean) {
    if (isDependency(card)) {
      return artLevel ? card?.dependTeam?.id : card?.dependTeam?.artId;
    }
    return artLevel ? card?.teamId : card?.artId;
  }

  function workitemFrom(cards: Card[], origin: BoardType) {
    return cards.find(
      (card) => isWorkitem(card) && card.type.origin === origin,
    );
  }

  function iterationOrder(a: Card, b: Card) {
    const iterDiff = b.iterationId! - a.iterationId!;
    return iterDiff < 0 ? 0 : iterDiff > 0 ? 2 : 1;
  }
}

function makeLinksUnmarked(card: BoardCard) {
  card.meta.mark = "normal";
  card.meta.isHighlighted = false;
  card.meta.isRelatedToHighlighted = false;
}

export function linkBetween(card: Card, other?: Card | null) {
  return (
    card !== other &&
    other &&
    card.links.find((l) => l.from === linkId(other) || l.to === linkId(other))
  );
}

export function getLinkTargetId(card: Card, link: Link): string {
  return linkId(card) === link.from ? link.to : link.from;
}
