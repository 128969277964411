<template>
  <metrics-modal-body-layout>
    <template #left>
      <load-capacity-chart
        class="summary-chart"
        :capacity="sum(items.map((item) => item.capacity))"
        :load="sum(items.map((item) => item.load))"
        :warning-threshold="warningThreshold"
        :title="sumTitle"
      />
    </template>
    <template #right>
      <div class="full-size">
        <div class="chart-container scrollable">
          <load-capacity-chart
            v-for="item in items"
            :key="item.id"
            :style="`flex: 0 1 ${chartBasis}%;`"
            :capacity="item.capacity"
            :load="item.load"
            :warning-threshold="warningThreshold"
            :title="item.title"
          />
        </div>
      </div>
    </template>
  </metrics-modal-body-layout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import MetricsModalBodyLayout from "@/components/modal/MetricsModalBodyLayout.vue";
import LoadCapacityChart from "@/components/modal/components/LoadCapacityChart.vue";
import { i18n } from "@/i18n";
import { BoardIteration, Team } from "@/model";
import { useBoardStore } from "@/store/board";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useIterationStore } from "@/store/iteration";

const columnGapPercent = 2;

export interface LoadCapacityItem {
  id: number | string;
  capacity: number;
  load: number;
  title: string;
}

export function toLoadCapacityItem(
  teamIterations: BoardIteration[],
): LoadCapacityItem[] {
  return teamIterations.map((teamIteration, i) => {
    return {
      load: teamIteration.load,
      capacity: teamIteration.velocity,
      title: useIterationStore().iterations[i].name,
      id: useIterationStore().iterations[i].id,
    };
  });
}

@Options({ components: { MetricsModalBodyLayout, LoadCapacityChart } })
export default class MetricsModalLoadCapacityBody extends Vue {
  @Prop(Object) team?: Team | undefined = undefined;

  sumTitle = i18n.global.t("metricsModal.allIterations");

  get items(): LoadCapacityItem[] {
    return toLoadCapacityItem(this.board.iterations);
  }

  sum(items: number[]) {
    return items.reduce((a, b) => a + b, 0);
  }

  get warningThreshold() {
    return useClientSettingsStore().loadWarn;
  }

  get chartBasis() {
    const cols = Math.ceil(this.items.length / 2);
    return Math.floor((100 - (columnGapPercent - 1) * cols) / cols);
  }

  /**
   * Gets the board for either the team specified as a prop, or the current team
   */
  get board() {
    return useBoardStore().boardByType("team", { teamId: this.team?.id });
  }
}
</script>

<style lang="scss">
.summary-chart {
  width: 50%;
  height: 73.5%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  :deep {
    .chart {
      max-width: unset;
    }

    .subtitle {
      font-size: 28px;
    }
  }
}

.full-size {
  width: 100%;
  height: 100%;
}

.chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 4% 2%;
  overflow: auto;
}
</style>
