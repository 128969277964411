import { defineStore } from "pinia";

import {
  BoardId,
  BoardWithObjectives,
  Id,
  Objective,
  ObjectiveType,
  ObjectiveUpdate,
  Team,
  isBacklogBoard,
} from "@/model";
import { captureMessage } from "@/sentry";
import { useBoardStore } from "@/store/board";
import { useCardStore } from "@/store/card";
import { objectId } from "@/utils/objectId";

export const useObjectiveStore = defineStore("objective", {
  getters: {
    teamObjectiveById() {
      return (
        objectiveId: Objective["id"],
        { teamId }: { teamId?: Team["id"] } = {},
      ): Objective | undefined => {
        const board = useBoardStore().boardByType("team", { teamId });
        const objectives = [
          ...(board.objectives ?? []),
          ...(board.stretchObjectives ?? []),
        ];

        return objectives.find((objective) => objective.id === objectiveId);
      };
    },
    teamObjectiveTypeById() {
      return (
        objectiveId: Objective["id"],
        { teamId }: { teamId?: Team["id"] } = {},
      ): ObjectiveType | undefined => {
        const objective = this.teamObjectiveById(objectiveId, { teamId });
        if (!objective) return;
        const board = useBoardStore().boardByType("team", { teamId });

        const isInObjectives = board.objectives.some(
          (objective) => objective.id === objectiveId,
        );
        if (isInObjectives) return "committed";
        return "uncommitted";
      };
    },
    linkedBacklogCards() {
      return (
        objectiveId: Objective["id"],
        { teamId }: { teamId?: Team["id"] } = {},
      ) => {
        const objective = this.teamObjectiveById(objectiveId, { teamId });
        if (!objective) {
          return [];
        }
        return objective.cards.flatMap((ref) => {
          const card = useCardStore().cards[ref.id];
          return ref.isOrigin && isBacklogBoard(card?.type?.origin)
            ? [card]
            : [];
        });
      };
    },
  },
  actions: {
    add(e: Partial<Objective> & BoardId, isCommitted = true) {
      const board = useBoardStore().boards[e.boardId] as BoardWithObjectives;
      const id = e.id || objectId();
      const text = e.text ? e.text : "";
      const description = e.description ? e.description : "";
      const obj = { id, text, bv: 0, av: null, description, cards: [] };
      (isCommitted ? board.objectives : board.stretchObjectives).unshift(obj);
      return obj;
    },

    remove(item: Id & BoardId) {
      const boardId = item.boardId || useBoardStore().boardId();
      const board = useBoardStore().boards[boardId] as BoardWithObjectives;
      const objectiveIndex = board.objectives.findIndex(
        (o) => o.id === item.id,
      );

      if (objectiveIndex > -1) {
        board.objectives.splice(objectiveIndex, 1);
      }

      const stretchObjectiveIndex = board.stretchObjectives.findIndex(
        (o) => o.id === item.id,
      );
      if (stretchObjectiveIndex > -1) {
        board.stretchObjectives.splice(stretchObjectiveIndex, 1);
      }
    },

    move(e: Id & BoardId & { stretch: boolean; rank: number }) {
      const boardId = e.boardId || useBoardStore().boardId();
      const board = useBoardStore().boards[boardId] as BoardWithObjectives;
      const { objective, objectives, pos } = objectiveById(board, e.id);
      if (!objective) {
        captureMessage("Move of unknown objective", { info: { data: e } });
        return;
      }
      objectives.splice(pos, 1);
      const target = e.stretch ? board.stretchObjectives : board.objectives;
      target.splice(e.rank, 0, objective);
    },

    update(e: Id & BoardId & ObjectiveUpdate) {
      const board = useBoardStore().boards[e.boardId] as BoardWithObjectives;
      const { objective } = objectiveById(board, e.id);
      if (!objective) {
        if (e.text === "") {
          this.add(e);
        } else {
          captureMessage("Update of unknown objective", { info: { data: e } });
        }
        return;
      }

      objective.text = e.text ?? objective.text;
      objective.description = e.description ?? objective.description;
      objective.bv = e.bv ?? objective.bv;
      objective.av = e.av ?? objective.av;
      objective.cards = e.cards || objective.cards;
    },
  },
});

function objectiveById(
  board: BoardWithObjectives,
  id: string,
): {
  objective: Objective;
  objectives: Objective[];
  pos: number;
} {
  let objectives = board.objectives;
  let pos = objectives.findIndex((o) => o.id === id);
  if (pos < 0) {
    objectives = board.stretchObjectives;
    pos = objectives.findIndex((o) => o.id === id);
  }
  return { objective: objectives[pos], objectives, pos };
}
