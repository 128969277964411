export type Color = [number, number, number, number];

export type ArtId = number;

export const boardTypes = [
  "backlog",
  "program",
  "solution_backlog",
  "solution",
  "risk",
  "team",
  "flex",
  "objectives",
] as const;
export type BoardType = (typeof boardTypes)[number];

export function isBoardType(s: string) {
  return boardTypes.includes(s as BoardType);
}

export type Functionality = "workitem" | "note" | "risk" | "dependency";

export const riskTypes = ["R", "O", "A", "M", ""];
export type RiskType = (typeof riskTypes)[number];

export const statusClasses = [
  "undefined",
  "todo",
  "in-progress",
  "done",
] as const;
export type StatusClass = (typeof statusClasses)[number];

export const statusClassNames: { [key in StatusClass]: string } = {
  undefined: "Unknown",
  todo: "To Do",
  "in-progress": "In Progress",
  done: "Done",
};

export type AlmSourceId = number | string;

export type ExecutionMode = "normal" | "preview" | "confirm";
