<template>
  <canvas id="paint-canvas" ref="canvas" width="6400" height="3600" />
</template>

<script lang="ts">
import { mixins } from "vue-class-component";
import { Ref } from "vue-property-decorator";

import { PaintFunctions, PaintLayer, registerPaintLayer } from "@/PaintLayer";
import { fakeZoom } from "@/Settings";
import { Lines } from "@/lineBreaker";
import EventBusUser from "@/mixins/EventBusUser";
import { useAppSizeStore } from "@/store/appSize";
import { clearStyles, getStyleInfo } from "@/styleCache";
import color from "@/styles/color.module.scss";

export default class DefaultPaintLayer
  extends mixins(EventBusUser)
  implements PaintLayer
{
  @Ref("canvas") readonly canvasElem!: HTMLCanvasElement;
  ctx!: CanvasRenderingContext2D;
  charWidthCache = new Map<string, number>();

  mounted() {
    registerPaintLayer(this);
    this.ctx = this.canvasElem.getContext("2d")!;
    this.onSetting("stickyFont", () => {
      this.charWidthCache.clear();
      clearStyles();
    });
  }

  init(elem: SVGElement | HTMLElement): PaintFunctions {
    const ctx = this.ctx;
    const style = getStyleInfo(elem);
    const { size: elemFontSize, unit: elemFontUnit } = parseFont(elem);
    const pixelPerFontUnit = measurePixelPerFontUnit(
      parseFloat(elemFontSize),
      style.minFontSize,
      style.maxFontSize,
    );
    const unscaledCanvasUnitsPerPixel =
      this.canvasElem.width / this.canvasElem.offsetWidth;
    const zoomFactor = fakeZoom / useAppSizeStore().appSize.zoom;
    const { boxWidth, boxHeight, canvasUnitsPerPixel } = calcElementSize(elem);

    const makeVisible = () => {
      const elStyle = (this.$el as HTMLElement).style;
      elStyle.visibility = "visible";
      elStyle.zIndex = "3";
    };

    function parseFont(elem: SVGElement | HTMLElement) {
      const style = elem.style.fontSize;
      if (style) {
        const [input, size, unit] = /([0-9.]+)(.*)/.exec(style) || [];
        if (!input) {
          throw Error(`unparseable font size '${style}'`);
        }
        return { size, unit };
      }
      const attr = elem.getAttribute("font-size");
      if (attr) {
        return { size: attr, unit: "px" };
      }
      throw Error(
        "unparseable font size: neither style.fontSize nor font-size attribute found.",
      );
    }

    function calcElementSize(elem: SVGElement | HTMLElement) {
      if (elem instanceof SVGElement) {
        const svg = findSvgRoot(elem);
        const svgUnitsPerPixel = svg.clientWidth / +svg.getAttribute("width")!;
        const width = +elem.getAttribute("width")!;
        const height = +elem.getAttribute("height")!;
        return {
          boxWidth: (width / zoomFactor) * svgUnitsPerPixel,
          boxHeight: (height / zoomFactor) * svgUnitsPerPixel,
          canvasUnitsPerPixel: unscaledCanvasUnitsPerPixel * svgUnitsPerPixel,
        };
      }
      // offsetWidth does not take into account "CSS transformation: scale", but getBoundingClientRect does.
      // getBoundingClientRect has higher precision but to use it, the possible scalings have to be inversed.
      // there are 3 possible CSS scalings: fakeZoom, InputText's editZoom and board zoom.
      const box = elem.getBoundingClientRect();
      // sometimes elem.getBoundingClientRect() seems to not be updated correctly (browser error?)
      // it's wrong by a factor for 10 (=fakeZoom?), so try to correct it
      const rawEditZoom = zoomFactor * (box.width / elem.offsetWidth);
      let [boxWidth, boxHeight] =
        rawEditZoom < 0.9
          ? [box.width * fakeZoom, box.height * fakeZoom]
          : [box.width, box.height];
      // to get effect of editZoom only, cancel out the other two
      const editZoom = Math.round(zoomFactor * (boxWidth / elem.offsetWidth));
      return {
        boxWidth,
        boxHeight,
        canvasUnitsPerPixel: unscaledCanvasUnitsPerPixel * editZoom,
      };
    }

    function findSvgRoot(el: SVGElement): SVGElement {
      while (el.nodeName !== "svg") {
        el = el.parentElement as unknown as SVGElement;
      }
      return el;
    }

    function setElementFontSize(size: number) {
      elem.style.fontSize = size + elemFontUnit;
    }

    function measurePixelPerFontUnit(
      fontSize: number,
      minFontSize: number,
      maxFontSize: number,
    ) {
      setElementFontSize(fontSize);
      let pixel = parseFloat(window.getComputedStyle(elem).fontSize);
      // getComputedStyle may respect browser's minimum/maximum font size
      // therefore adjust font size if it's outside the limits
      if (pixel <= minFontSize || pixel >= maxFontSize) {
        const fontSizeFactor = 100 / pixel;
        setElementFontSize(fontSize * fontSizeFactor);
        pixel =
          parseFloat(window.getComputedStyle(elem).fontSize) / fontSizeFactor;
        setElementFontSize(fontSize);
      }
      return pixel / fontSize;
    }

    const charWidthCache = this.charWidthCache;

    return {
      canvasFontSize: 0,
      canvasFont: "",
      lineHeight: 0,
      widthInCanvas: Math.floor(
        boxWidth * unscaledCanvasUnitsPerPixel * zoomFactor,
      ),
      heightInCanvas: Math.floor(
        boxHeight * unscaledCanvasUnitsPerPixel * zoomFactor,
      ),

      setCanvasFont(size: number) {
        const pixelSize = size * pixelPerFontUnit;
        this.lineHeight = pixelSize * style.lineHeightFactor;
        this.canvasFontSize = pixelSize * canvasUnitsPerPixel;
        this.canvasFont = `${style.fontWeight} ${style.fontFamily}`;
        ctx.font = `${style.fontWeight} ${this.canvasFontSize}px ${style.fontFamily}`;
      },

      setElementFontSize,

      get maxFontSize() {
        return (
          this.heightInCanvas /
          (canvasUnitsPerPixel * pixelPerFontUnit * style.lineHeightFactor)
        );
      },

      stringWidth(s: string): number {
        return ctx.measureText(s).width;
      },

      charWidth(char1: string, char2?: string): number {
        const key = this.canvasFont + char1 + (char2 || "");
        const val = charWidthCache.get(key);
        if (val !== undefined) {
          return val * this.canvasFontSize;
        }
        const width = char2
          ? this.stringWidth(char1 + (char2 || "")) -
            this.charWidth(char1) -
            this.charWidth(char2)
          : this.stringWidth(char1);
        charWidthCache.set(key, width / this.canvasFontSize);
        return width;
      },

      displayLines(x: number, y: number, text: string, lines: Lines) {
        makeVisible();
        ctx.clearRect(x, y, this.widthInCanvas, this.heightInCanvas);
        ctx.strokeStyle = color.textPrimary;
        ctx.strokeRect(x, y, this.widthInCanvas, this.heightInCanvas);
        let i = 0;
        for (const [startPos, endPos] of lines) {
          ctx.fillText(
            text.substring(startPos, endPos),
            x,
            y + this.canvasFontSize * (1 + style.lineHeightFactor * i),
          );
          i++;
        }
      },
    };
  }
}
</script>

<style lang="scss">
@use "@/styles/variables";

#paint-canvas {
  visibility: hidden;
  position: absolute;
  pointer-events: none;
  width: 100% * variables.$fake-zoom;
  height: 100% * variables.$fake-zoom;
  font-size: 1rem;
}
</style>
