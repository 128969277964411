<script setup lang="ts">
import { ref } from "vue";

import { CardCreateProps } from "@/action/cardActions";
import { AlmSourceId } from "@/baseTypes";
import FluidBoard from "@/components/FluidBoard";
import SelectNamedValue from "@/components/ui/SelectNamedValue/SelectNamedValue.vue";
import SelectStickyType from "@/components/ui/SelectStickyType/SelectStickyType.vue";
import { WindowCoordinate } from "@/math/coordinates";
import { AlmSource, StickyType } from "@/model";
import { useBoardStore } from "@/store/board";

const props = defineProps<{
  board: FluidBoard;
  position: WindowCoordinate;
  card: Partial<CardCreateProps>;
}>();

const emit = defineEmits<{
  select: [type: StickyType, almSourceId?: AlmSourceId];
}>();

const cardType = ref<StickyType | undefined>(props.card.type);
const almSourceId = ref<AlmSourceId | undefined>(props.card.almSourceId);
const emitSent = ref<boolean>(false);

const cardTypes = useBoardStore().creatableStickyTypes;
const almSources = useBoardStore().currentBoard().almSources;

function setType(type: StickyType) {
  cardType.value = type;
  if (!almSourceNeeded()) {
    emitSelect();
  }
}

function typeSelected(e: Event) {
  if (almSourceNeeded()) {
    e.stopPropagation();
  }
}

function setAlmSource(source: AlmSource) {
  almSourceId.value = source.id;
  emitSelect();
}

function emitSelect() {
  emit("select", cardType.value!, almSourceId.value);
  emitSent.value = true;
}

function almSourceNeeded() {
  return almSources.length > 1 && almSourceId.value === undefined;
}
</script>

<template>
  <div style="width: 200px" :class="{ loading: emitSent }">
    <SelectNamedValue
      v-if="cardType && almSourceNeeded()"
      :header="$t('actions.selectProject')"
      :values="almSources"
      :disabled="emitSent"
      :model-value="almSources[0]"
      @update:model-value="setAlmSource($event as AlmSource)"
    />
    <SelectStickyType
      v-else
      :header="$t('actions.selectStickyType')"
      :sticky-types="cardTypes"
      :disabled="emitSent"
      :model-value="cardType || cardTypes[0]"
      @update:model-value="setType($event as StickyType)"
      @click="typeSelected"
    />
  </div>
</template>

<style scoped>
.loading {
  cursor: wait;
}
</style>
