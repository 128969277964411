<template>
  <modal-legacy class="load-stat-modal" :width="width" :height="height">
    <modal-header-legacy :title="activeTab.content">
      <h2>{{ activeTab.subtitle }}</h2>
      <template #action>
        <div>
          <button-group :buttons="tabs" @change="changeTab"></button-group>
        </div>
      </template>
    </modal-header-legacy>
    <div class="head" />
    <component :is="activeTabComponent" />
  </modal-legacy>
</template>

<script lang="ts">
import { markRaw } from "vue";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import MetricsModalCapacityAllocationBody from "@/components/modal/MetricsModalCapacityAllocationBody.vue";
import MetricsModalLoadCapacityBody from "@/components/modal/MetricsModalLoadCapacityBody.vue";
import ModalHeaderLegacy from "@/components/modal/ModalHeaderLegacy.vue";
import ModalLegacy from "@/components/modal/ModalLegacy.vue";
import ButtonGroup from "@/components/modal/components/ButtonGroup.vue";
import { useBoardStore } from "@/store/board";
import { useModalStore } from "@/store/modal";

@Options({
  name: "MetricsModal",
  components: {
    ButtonGroup,
    ModalHeaderLegacy,
    ModalLegacy,
  },
})
export default class MetricsModal extends Vue {
  @Prop({ default: "allocation" }) readonly tab?: string;

  width = 0;
  height = 0;
  tabs = new Array<any>();

  created() {
    this.tabs = [
      {
        id: "allocation",
        content: this.$t("metricsModal.capacityAllocation"),
        subtitle: this.$t("general.namedTeam", { name: this.board.team.name }),
        component: markRaw(MetricsModalCapacityAllocationBody),
      },
      {
        id: "load",
        content: this.$t("metricsModal.loadVsCapacity"),
        subtitle: this.$t("general.namedTeam", { name: this.board.team.name }),
        component: markRaw(MetricsModalLoadCapacityBody),
      },
    ];
    this.width = document.documentElement.clientWidth * 0.75;
    this.height = document.documentElement.clientHeight * 0.85;
    this.tabChanged();
  }

  // TODO moving isActive out of this.tabs, we can get rid of the watcher (see PlanReadoutModel)
  @Watch("tab")
  tabChanged() {
    this.tabs.forEach((tab) => (tab.isActive = tab.id === this.tab));
  }

  get board() {
    return useBoardStore().boardByType("team");
  }

  get activeTab() {
    return this.tabs.find((tab) => tab.isActive)!;
  }

  get activeTabComponent() {
    return this.activeTab?.component;
  }

  changeTab(tabs: any[]) {
    const tab = tabs.find((tab) => tab.isActive);
    useModalStore().changeAttrs({ tab: tab.id });
  }
}
</script>

<style lang="scss" scoped>
.load-stat-modal {
  .head {
    margin-top: 0;
  }
}

:deep(.button-group) {
  flex-direction: column;
}
</style>
