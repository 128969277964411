<script lang="ts" setup></script>

<template>
  <div class="sticky-note-pin">
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <line x1="10" y1="5" x2="10" y2="15" stroke-width="2" stroke="#1c1e21" />
      <circle cx="10" cy="5" r="5" fill="#ff123b" />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
@use "../../utils";
@use "@/styles/colors";

.sticky-note-pin {
  position: absolute;
  cursor: pointer;
  width: utils.px-to-em(30px);
  display: flex;
  justify-content: center;
  right: 45%;
  pointer-events: none;
  transform: rotate(30deg);
}
</style>
