<script setup lang="ts">
import { computed } from "vue";

import UserActions from "@/components/UserActions.vue";
import MenuItemDelimiter from "@/components/menu/components/MenuItemDelimiter.vue";
import { useSessionStore } from "@/store/session";
import { useUserStore } from "@/store/user";

const canSetup = computed(() => useUserStore().isAllowed("setup"));
const setupUrl = computed(() => useSessionStore().sessionAdminUrl());
</script>

<template>
  <div>
    <div class="page">
      <div class="page-top-bar">
        <div class="logo-header">
          <div>
            <a href="https://www.piplanning.io/" target="_blank">
              <img src="@/assets/logo-named.svg" />
            </a>
          </div>
        </div>
        <div class="page-nav">
          <h2 v-if="canSetup" class="btn-rte-cockpit">
            <a target="_blank" :href="setupUrl">{{
              $t("userAction.rteCockpit")
            }}</a>
          </h2>
          <MenuItemDelimiter />
          <UserActions />
        </div>
      </div>

      <div class="page-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

.page {
  font-size: 14px;
  background-color: colors-old.$back-color;
  min-height: 100vh;

  &-top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px;
    background-color: colors-old.$back-color;
    border-bottom: 1px solid colors-old.$divider-color;
    z-index: z-index.$low;
  }

  h1,
  h2 {
    margin: 0;
  }

  .page-nav {
    position: relative;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
    }

    .btn-rte-cockpit {
      a {
        border: 1px solid colors-old.$divider-color;
        padding: 10px 20px;
        border-radius: 4px;
        margin-left: 0;
        font-size: 16px;
        display: flex;
        align-items: center;
        transition: 0.3s ease-in-out border-color;

        &:hover {
          color: colors-old.$menu-item-focus-color;
          border-color: colors-old.$menu-item-focus-color;
        }
      }
    }

    h2 {
      margin-left: 20px;
    }

    .menu-item-delimiter {
      margin: 0 15px;
    }
  }

  .page-content {
    max-width: 700px;
    width: 100%;
    margin: auto;
    padding: 120px 30px 0;
  }
}
</style>
