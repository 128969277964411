import { AxiosResponse } from "axios";
import { NavigationGuard, RouteRecordRaw } from "vue-router";

import { getConfig } from "@/Environment";
import { clearUserData } from "@/login";
import AppPage from "@/pages/AppPage/AppPage.vue";
import ArtPage from "@/pages/ArtPage/ArtPage.vue";
import BasePage from "@/pages/BasePage.vue";
import LoginPage from "@/pages/LoginPage/LoginPage.vue";
import MobileInfoPage from "@/pages/MobileInfoPage/MobileInfoPage.vue";
import SessionPage from "@/pages/SessionPage/SessionPage.vue";
import TeamPage from "@/pages/TeamPage/TeamPage.vue";
import { clearSessionData } from "@/router/sessionData";
import { addBreadcrumb } from "@/sentry";
import AuthService, { LoginResponse } from "@/services/auth.service";
import { useSessionStore } from "@/store/session";

import { LogoutReason } from "./types";
import { companyParam, isSmallScreen } from "./utils";

const beforeLogout: NavigationGuard = async (to, from, next) => {
  Intercom("shutdown");
  const logoutReason = (to.query.reason || "unknown") as LogoutReason;
  await addBreadcrumb("navigation", {
    message: "Logging out user",
    data: {
      reason: logoutReason,
      from: from.fullPath,
      to: to.fullPath,
    },
  });
  clearUserData();
  try {
    const res = await AuthService.logout(logoutReason);
    if (res.data.redirectUrl) {
      window.location.assign(res.data.redirectUrl);
      next(false);
    } else {
      clearModelAndNavigateTo("login");
    }
  } catch (_) {
    clearModelAndNavigateTo("login");
  }

  function clearModelAndNavigateTo(name: string) {
    clearSessionData();
    next({ name });
  }
};

const beforeAuthRouteEnter: NavigationGuard = (to, _from, next) => {
  if (
    isSmallScreen() &&
    getConfig().isCloud &&
    localStorage.getItem("device") !== "desktop"
  ) {
    next({ name: "mobile" });
  } else if (getConfig().isCloud) {
    const companyId = companyParam();
    if (companyId) {
      // go to FusionAuth login page with company already resolved
      followRedirect(
        AuthService.login({
          type: "" + (to.query.type || ""),
          path: "" + (to.query.path || ""),
          companyId,
        }),
      );
    } else {
      // go to OauthLoginPage to resolve company first
      next();
    }
  } else {
    // go to FusionAuth login page
    followRedirect(
      AuthService.loginPage({
        type: "" + (to.query.type || ""),
        path: "" + (to.query.path || ""),
      }),
    );
  }

  function followRedirect(response: Promise<AxiosResponse<LoginResponse>>) {
    response
      .then((res) => {
        if (res.data.success && res.data.redirectUrl) {
          window.location.assign(res.data.redirectUrl);
        } else {
          next();
        }
      })
      .catch(() => next());
  }
};

export const routes: RouteRecordRaw[] = [
  {
    name: "page",
    path: "/page",
    component: BasePage,
    children: [
      {
        name: "session",
        path: "session/:session?",
        meta: { title: /*$t*/ "page.session" },
        component: SessionPage,
      },
      {
        name: "art",
        path: "art",
        meta: { title: /*$t*/ "page.art" },
        component: ArtPage,
        beforeEnter(to, from, next) {
          if (useSessionStore().session.selected) {
            next();
          } else {
            next({ name: "session" });
          }
        },
      },
      {
        name: "team",
        path: "team",
        meta: { title: /*$t*/ "page.team" },
        component: TeamPage,
      },
    ],
  },
  {
    name: "mobile",
    path: "/mobile",
    component: MobileInfoPage,
    beforeEnter(to, from, next) {
      if (!isSmallScreen()) {
        return next({ name: "login" });
      }
      next();
    },
    meta: { needsAuth: false },
  },
  {
    name: "login",
    path: "/page/login",
    meta: { title: /*$t*/ "page.login", needsAuth: false },
    component: LoginPage,
    beforeEnter: beforeAuthRouteEnter,
  },
  {
    path: "/page/oauth",
    redirect: { name: "login" },
  },
  {
    name: "app",
    path: "/app/:session/:team/:board/:name?",
    component: AppPage,
  },
  {
    name: "logout",
    path: "/logout",
    meta: { needsAuth: false },
    component: LoginPage,
    beforeEnter: beforeLogout,
  },
  {
    name: "all",
    path: "/:pathMatch(.*)*",
    redirect: { name: "session", params: {} },
  },
];
