import { ApplicationError } from "@/ApplicationError";

export class NoSuccessError extends ApplicationError {
  constructor(
    endpoint: string,
    contexts: { request: any; response: NoSuccessResponse },
  ) {
    super(
      "NoSuccessError",
      `Server call unsuccessful to ${endpoint}`,
      contexts as any,
    );
  }

  get response(): NoSuccessResponse {
    return this.contexts.response as any;
  }
}

export interface NoSuccessResponse {
  message: string;
  code?: string;
  details?: any;
}
