import { dummyBackendSession } from "@/backend/DummyBackendSession";
import { ArtId } from "@/baseTypes";
import { RelativeCoordinate } from "@/math/coordinates";
import { Card, Objective, Reaction, Shape } from "@/model";
import { isZombieType } from "@/store/stickyType";

import { BackendSession } from "./BackendSession";
import {
  ServerIterationSyncState,
  ServerObjective,
  ServerSticky,
} from "./serverModel";

export class Sender {
  backendSess: BackendSession = dummyBackendSession();

  startAlterCard(boardId: string, id: string) {
    this.backendSess.boardPublish(boardId, "sticky.start_update", [id, {}]);
  }

  alterCard(
    boardId: string,
    id: string,
    props: Partial<Card & RelativeCoordinate>,
    priorities?: boolean,
  ) {
    this.backendSess.boardPublish(boardId, "sticky.update", [
      id,
      convertToServerSticky(props, priorities),
    ]);
  }

  stopAlterCard(
    boardId: string,
    id: string,
    props?: Partial<Card> | RelativeCoordinate,
  ) {
    this.backendSess.boardPublish(boardId, "sticky.stop_update", [
      id,
      convertToServerSticky(props),
    ]);
  }

  addCard(
    boardId: string,
    props: Partial<Card & RelativeCoordinate>,
  ): Promise<string> {
    return this.backendSess.newSticky(boardId, convertToServerSticky(props));
  }

  deleteCard(boardId: string, id: string) {
    this.stopAlterCard(boardId, id, {});
    this.backendSess.boardPublish(boardId, "sticky.remove", [id]);
  }

  scaleBoard(boardId: string, value: number) {
    this.backendSess.boardPublish(boardId, "sticky.scale", [value]);
  }

  frontCard(boardId: string, id: string) {
    this.backendSess.boardPublish(boardId, "sticky.bring_to_front", [id]);
  }

  setVelocity(boardId: string, iteration: number, velocity: number) {
    this.backendSess.boardPublish(boardId, "capacity.update", [
      iteration,
      "" + velocity,
    ]);
  }

  addObjective(boardId: string, objective: Objective) {
    this.backendSess.boardPublish(boardId, "objective.add", [
      {
        objective_id: objective.id,
        ...convertToServerObjective(objective),
      },
    ]);
  }

  deleteObjective(boardId: string, id: string) {
    this.backendSess.boardPublish(boardId, "objective.remove", [id]);
  }

  updateObjective(boardId: string, objective: Objective) {
    this.backendSess.boardPublish(boardId, "objective.update", [
      objective.id,
      convertToServerObjective(objective),
    ]);
  }

  moveObjective(
    boardId: string,
    objectiveId: string,
    stretch: boolean,
    rank: number,
  ) {
    this.backendSess.boardPublish(boardId, "objective.move", [
      objectiveId,
      stretch,
      rank,
    ]);
  }

  async mirror(cardId: string, toBoardId: string, props?: Partial<Card>) {
    const res = await this.backendSess.mirror(
      cardId,
      toBoardId,
      convertToServerSticky(props),
    );
    return res.created ? res.mirrored_id : undefined;
  }

  move(cardId: string, teamId: string) {
    this.backendSess.move(cardId, teamId);
  }

  toRisk(
    cardId: string,
    boardId: string,
    text: string,
    typeId: string,
    teamId: string,
  ) {
    this.backendSess.toRisk(cardId, boardId, text, typeId, teamId);
  }

  addLink(cardId: string, toId: string): Promise<{ id: string }> {
    return this.backendSess.addLink(cardId, toId);
  }

  async addObjectiveLink(
    cardId: string,
    boardId: string,
    objectiveId: string,
  ): Promise<void> {
    await this.backendSess.addObjectiveLink(cardId, boardId, objectiveId);
  }

  deleteLink(linkId: string): Promise<void> {
    return this.backendSess.deleteLink(linkId);
  }

  async deleteObjectiveLink(
    cardId: string,
    boardId: string,
    objectiveId: string,
  ): Promise<void> {
    await this.backendSess.deleteObjectiveLink(cardId, boardId, objectiveId);
  }

  syncIteration(
    teamId: string,
    iterationId: number,
  ): Promise<ServerIterationSyncState> {
    return this.backendSess.syncIteration(teamId, iterationId);
  }

  syncBacklog(artId?: ArtId) {
    return this.backendSess.syncBacklog(artId);
  }

  addBoard(type: string, name: string): Promise<string> {
    return this.backendSess.addBoard(type, name);
  }

  updateBoard(id: string, name: string) {
    this.backendSess.updateBoard(id, name);
  }

  deleteBoard(id: string) {
    this.backendSess.deleteBoard(id);
  }

  addCategory(name: string): Promise<string> {
    return this.backendSess.addCategory(name);
  }

  updateCategory(id: string, update: { name?: string; position?: number }) {
    this.backendSess.updateCategory(id, update);
  }

  deleteCategory(id: string) {
    this.backendSess.deleteCategory(id);
  }

  addBoardToCategory(boardId: string, categoryId: string) {
    this.backendSess.addBoardToCategory(boardId, categoryId);
  }

  removeBoardFromCategory(boardId: string, categoryId: string) {
    this.backendSess.removeBoardFromCategory(boardId, categoryId);
  }

  pointerPos(boardId: string, pos: RelativeCoordinate) {
    this.backendSess.boardPublish(boardId, "pointer.position", [pos]);
  }

  addUserOnBoard(boardId: string, userId: string) {
    this.backendSess.boardPublish(boardId, "subscribers.join", [userId]);
  }

  removeUserFromBoard(boardId: string, userId: string) {
    this.backendSess.boardPublish(boardId, "subscribers.leave", [userId]);
  }

  addReaction(cardId: string, reaction: Reaction) {
    this.backendSess.addReaction(cardId, reaction);
  }

  removeReaction(cardId: string, reaction: Reaction) {
    this.backendSess.removeReaction(cardId, reaction);
  }

  addShape(boardId: string, shape: Shape) {
    return this.backendSess.addShape(boardId, shape);
  }

  editShape(boardId: string, shape: Shape) {
    return this.backendSess.editShape(boardId, shape);
  }

  removeShape(boardId: string, shapeId: string) {
    return this.backendSess.removeShape(boardId, shapeId);
  }
}

/**
 * Converts a card to a ServerSticky
 */
function convertToServerSticky(
  data?: Partial<Card & RelativeCoordinate>,
  priorities?: boolean,
): Partial<ServerSticky> {
  const value: Partial<ServerSticky> = {};
  if (data) {
    if (data.x !== undefined && data.y !== undefined) {
      value.a_center = [data.x, 1 - data.y];
    }
    if (data.text !== undefined) {
      value.text = data.text;
    }
    if (data.status !== undefined) {
      value.status = data.status.name;
    }
    if (data.transition !== undefined) {
      value.transition = data.transition;
    }
    if (data.type !== undefined && !isZombieType(data.type)) {
      value.type_id = data.type.id;
    }
    if (data.priority !== undefined) {
      if (priorities) {
        value.step_value = data.priority;
      } else {
        value.WSJF_value = data.priority;
      }
    }
    if (data.points !== undefined) {
      value.story_points = data.points;
    }
    if (data.teamId !== undefined) {
      value.team_id = data.teamId === null ? null : +data.teamId;
    }
    if (data.precondTeam !== undefined) {
      value.precond_team = data.precondTeam.name;
      value.precond_team_id = +data.precondTeam.id;
    }
    if (data.dependTeam !== undefined) {
      value.depend_team = data.dependTeam.name;
      value.depend_team_id = +data.dependTeam.id;
    }
    if (data.iterationId !== undefined) {
      value.iteration_number = data.iterationId;
    }
    if (data.flagType !== undefined) {
      value.flag_type = data.flagType.isEmpty()
        ? null
        : data.flagType.toString();
    }
    if (data.risk !== undefined) {
      value.risk = data.risk;
    }
    if (data.almSourceId !== undefined) {
      value.alm_source = data.almSourceId;
    }
    if (data.artId !== undefined) {
      value.art_id = data.artId;
    }
  }
  return value;
}

function convertToServerObjective(
  data: Partial<Objective>,
): Partial<ServerObjective> {
  const value: Partial<ServerObjective> = {};
  if (data.id !== undefined) {
    value.objective_id = data.id;
  }
  if (data.text !== undefined) {
    value.text = data.text;
  }
  if (data.bv !== undefined) {
    value.bv = data.bv;
  }
  if (data.av !== undefined) {
    value.av = data.av;
  }
  if (data.description !== undefined) {
    value.description = data.description;
  }
  return value;
}

export const sender = new Sender();
