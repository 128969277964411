<script lang="ts" setup>
import { cardActions } from "@/action/cardActions";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import {
  boardKey,
  cardKey,
  cardMethodsKey,
} from "@/components/card/injectKeys";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { useBoardStore } from "@/store/board";
import { useLinkStore } from "@/store/link";
import { injectStrict } from "@/utils/context";

defineProps<{ mode: "delete" | "remove" }>();

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
const cardMethods = injectStrict(cardMethodsKey);
const dropdown = injectStrict(dropdownKey);

const handleDelete = () => {
  useBoardStore().markCard(card.id, "hidden"); // hide possible links immediately
  cardMethods.animate("deleting", () =>
    cardActions.delete("card", card.id, board.id),
  );
  const isPinnedCard = useLinkStore().isMarkingLinkedCards(card.id);
  if (isPinnedCard) {
    useLinkStore().removeAllMarks();
  }
};

const handleCancel = () => {
  dropdown.close();
};
</script>

<template>
  <div class="delete-sticky">
    <h4 class="title">
      {{
        mode === "delete"
          ? $t("action.deleteSticky")
          : $t("action.removeSticky")
      }}
    </h4>
    <p class="description">
      {{
        mode === "delete"
          ? $t("action.deleteSticky.description")
          : $t("action.removeSticky.description")
      }}
    </p>
    <div class="actions">
      <BaseButton
        v-if="mode === 'delete'"
        full-width
        color="destructive"
        @click="handleDelete"
      >
        {{ $t("general.delete") }}
      </BaseButton>
      <BaseButton v-else full-width variant="outlined" @click="handleDelete">
        {{ $t("cardAction.remove") }}
      </BaseButton>
      <BaseButton
        full-width
        variant="outlined"
        color="grey"
        @click="handleCancel"
      >
        {{ $t("general.cancel") }}
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/font";

.delete-sticky {
  color: colors-old.$menu-color;
  width: 284px;
  padding: 20px;

  .actions {
    display: flex;
    gap: 12px;
    margin-top: 20px;
  }

  .title {
    all: unset;
    display: block;
    font-weight: font.$weight-extra-bold;
    font-size: 14px;
  }

  .description {
    all: unset;
    display: block;
    font-weight: font.$weight-medium;
    font-size: 12px;
    margin-top: 8px;
  }
}
</style>
