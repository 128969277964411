import { nextTick } from "vue";

import { fakeZoom } from "@/Settings";
import { i18n } from "@/i18n";
import { WindowCoordinate, windowCoord } from "@/math/coordinates";
import { AppSize } from "@/model";
import { pushZoomState } from "@/router/navigation";
import { useAppSizeStore } from "@/store/appSize";
import { useBoardStore } from "@/store/board";

/**
 * zoom out fully and scroll to center so that padding is not visible.
 */
export function resetZoom(size = useAppSizeStore().appSize) {
  applyZoom(1, {
    target: windowCoord(size.padding.left, size.padding.top),
    push: true,
  });
}

export function applyZoom(
  zoom: number,
  scrollTo?: { target: WindowCoordinate; smooth?: boolean; push?: boolean },
) {
  if (!useBoardStore().currentBoard().cardSize) {
    return;
  }
  useAppSizeStore().setAppZoom(zoom);
  // wait for the zoom to update the DOM before calculating the coordinates
  nextTick(() => {
    if (scrollTo) {
      window.scrollTo({
        left: scrollTo.target.x,
        top: scrollTo.target.y,
        behavior: scrollTo.smooth ? "smooth" : "auto",
      });
      if (scrollTo.push) {
        pushZoomState();
      }
    } else {
      window.scrollTo({
        left: (document.documentElement.scrollWidth - window.innerWidth) / 2,
        top: (document.documentElement.scrollHeight - window.innerHeight) / 2,
        behavior: "auto",
      });
    }
  });
}

export function applyFluidBoardSize(size: AppSize, smooth = false) {
  if (size.zoom === 1) {
    resetZoom(size);
  }
  applyBoardSize(
    {
      scale: size.zoom / fakeZoom,
      top: size.top + "px",
      left: size.left + "px",
      height: size.height + "px",
      width: size.width + "px",
      paddingRight: size.padding.right + "px",
      paddingBottom: size.padding.bottom + "px",
    },
    smooth,
  );
}

export function applyFullBoardSize() {
  // Board needs to be downscaled so we follow the pattern of fluid boards:
  // - first scale down x 0.1 here then scale back up x 10 in styling of individual full sized board
  // We need to keep this behavior to avoid bad-looking zooming in and out when switching
  // between boards during transition animation.
  applyBoardSize({
    scale: 1 / fakeZoom,
    top: "0",
    left: "0",
    height: "100vh",
    width: "100%",
    paddingRight: "0",
    paddingBottom: "0",
  });
}

function applyBoardSize(
  size: {
    scale: number;
    top: string;
    left: string;
    height: string;
    width: string;
    paddingRight: string;
    paddingBottom: string;
  },
  smooth = false,
) {
  const boardElem = document.getElementById("boards");
  const borderElem = document.getElementById("boards-border");
  if (boardElem && borderElem) {
    if (smooth) {
      boardElem.classList.add("smooth");
      setTimeout(() => boardElem.classList.remove("smooth"), 500);
    }
    const boardStyle = boardElem.style;
    boardStyle.transform = `scale(${size.scale})`;
    boardStyle.top = size.top;
    boardStyle.left = size.left;
    boardStyle.height = size.height;
    boardStyle.width = size.width;

    const borderStyle = borderElem.style;
    borderStyle.transform = `scale(${1 / size.scale})`;
    borderStyle.width = size.paddingRight;
    borderStyle.height = size.paddingBottom;
  }
}

export function effectiveBoardWidth() {
  return parseInt(document.getElementById("boards")!.style.width);
}

const scaleAttr = "data-scale";
const scrollbarAdjusted = "scrollbar-adjusted";

/**
 * Scale the element and zoom out right after, so it appears as before scaling.
 * As result this will increase width of scroll bar and will fix the problem
 * of slow scrolling in Chrome. Scaling is computed based on the current element
 * fontSize but won't exceed 10x scaling (to avoid extremely large scroll bar when font size
 * is too large).
 */
export function adjustScrollbarWidth(el: HTMLElement) {
  if (isInBoard(el)) {
    const fontSize = getComputedStyle(el).fontSize;
    const fontSizeBasedZoom = parseInt(fontSize, 10) / fakeZoom;
    const zoom = Math.min(fontSizeBasedZoom, 10);
    el.classList.add(scrollbarAdjusted);
    el.setAttribute(scaleAttr, "" + zoom);
    el.style.transformOrigin = "top left";
    setElementScale(el);
  }
}

export function scrollbarAdjustedElements() {
  return document.querySelectorAll<HTMLElement>("." + scrollbarAdjusted);
}

export function setElementScale(el: HTMLElement, set = true) {
  const zoom = el.getAttribute(scaleAttr);
  if (zoom) {
    if (set) {
      el.style.transform = "scale(" + +zoom + ")";
      const percent = 100 / +zoom + "%";
      el.style.height = el.style.width = el.style.fontSize = percent;
    } else {
      el.style.transform = "";
      el.style.height = el.style.width = el.style.fontSize = "";
    }
  }
}

function isInBoard(el: Element | null) {
  while (el) {
    if (el.id === "boards") {
      return true;
    }
    el = el.parentElement;
  }
}

const isMac = navigator.platform.includes("Mac");

export function shiftKey() {
  return isMac ? "⇧" : i18n.global.t("key.shift") + " ";
}

export function modKey() {
  return isMac ? "⌃" : i18n.global.t("key.ctrl") + " ";
}

export function altModKeys() {
  return isMac ? ["⌥", "⌘"] : [i18n.global.t("key.alt") + " "];
}

export const lineSeparator =
  navigator.userAgent.indexOf("Windows") < 0 ? "\n" : "\r\n";

export function whenChildElementWithClass(
  element: Element,
  className: string,
  action: (child: Element) => void,
) {
  const child = element.querySelector(`.${className}`);
  if (child) {
    action(child);
  } else {
    const observer = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof Element && node.classList.contains(className)) {
            observer.disconnect();
            action(node);
          }
        });
      }
    });
    observer.observe(element, { childList: true });
    setTimeout(() => observer.disconnect(), 10000);
  }
}
