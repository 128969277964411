import { Vue } from "vue-class-component";

export default class TimeoutUser extends Vue {
  private timeoutId = -1;

  runLater(action: () => void, timeout: number) {
    this.timeoutId = window.setTimeout(action, timeout);
  }

  unmounted() {
    window.clearTimeout(this.timeoutId);
  }
}
