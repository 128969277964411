<script setup lang="ts">
import BoardBase from "@/components/BoardBase";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import BoardContextMenu, {
  BoardContextMenuMode,
} from "@/components/menu/BoardContextMenu.vue";
import { clientCoord } from "@/math/coordinates";
import { useContextMenuStore } from "@/store/contextMenu";

const props = defineProps<{ board: BoardBase; mode: BoardContextMenuMode }>();

function showMenu(e: MouseEvent) {
  useContextMenuStore().open(BoardContextMenu, {
    position: clientCoord(e),
    mode: props.mode,
  });
}
</script>

<template>
  <button tabindex="-1" class="kebab-menu reset" @click.stop="showMenu">
    <SvgIcon name="vertical-more" size=".75em" />
  </button>
</template>

<style lang="scss" scoped>
@use "@/styles/reset" as *;
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.kebab-menu {
  font-size: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  color: colors-old.$text-secondary-color;
}
</style>
