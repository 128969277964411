import { Ref, computed, onMounted, ref, watch } from "vue";

import { cardZoomHeight, fakeZoom } from "@/Settings";
import { down } from "@/Shortcuts";
import { Card } from "@/model";
import { useAppSizeStore } from "@/store/appSize";
import { useBoardStore } from "@/store/board";
import { useZoomStore } from "@/store/zoom";

type Props = {
  el: Ref<HTMLDivElement | undefined>;
  card: Card;
  zoomFactor: Ref<number>;
  config?: { disabled?: boolean };
};

type ReturnVal = Readonly<{
  zoomFactor: number;
  zoomOut: () => void;
  zoomIn: (focus?: boolean) => void;
}>;

export function useStickyNoteZoom({
  el,
  card,
  zoomFactor,
  config = {},
}: Props): ReturnVal {
  onMounted(() => {
    if (config.disabled) return;

    el?.value?.addEventListener("pointerenter", handleStickyNotePointerEnter);
    el?.value?.addEventListener("pointerleave", handleStickyNotePointerLeave);
  });

  const isZoomedIn = computed(() => zoomFactor.value > 1);
  const isStickyNoteActive = computed(() => {
    return useBoardStore().activeCardId === card.id;
  });
  const hovered = ref(false);

  // Zoom in when the user preses the space bar and then hovers over the sticky note.
  // when the user releases the space bar the textarea should be focused
  const handleKeyUp = (event: KeyboardEvent) => {
    if (hovered.value && event.code === "Space") {
      zoomIn(true);
    }
  };

  // used to zoom in when the user hovers over the sticky note and then presses the space bar
  const handleKeyDown = (event: KeyboardEvent) => {
    if (!isZoomedIn.value && hovered.value && event.code === "Space") {
      zoomIn();
    }
  };

  // zoom in and optionally focus the textarea
  const zoomIn = (focus?: boolean) => {
    // skip zooming in if the sticky note is already zoomed in or if a sticky note is already active
    if (isZoomedIn.value) return;
    if (isStickyNoteActive.value) return;

    if (focus) {
      useBoardStore().setActiveCardId(card.id);
      el?.value?.click();
    }

    const elHeight = el?.value?.offsetHeight ?? 1;
    const appZoomLevel = useAppSizeStore().appSize.zoom;
    const windowHeight = window.innerHeight * fakeZoom;
    const factor = cardZoomHeight * (windowHeight / elHeight / appZoomLevel);

    if (factor > 1) {
      zoomFactor.value = factor;
    }
  };

  const zoomOut = () => {
    zoomFactor.value = 1;
  };

  const handleStickyNotePointerEnter = (_event: PointerEvent) => {
    hovered.value = true;

    if (down["Space"]) zoomIn();

    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("keydown", handleKeyDown);
  };

  const handleStickyNotePointerLeave = (_event: PointerEvent) => {
    hovered.value = false;

    if (down["Space"]) zoomOut();

    document.removeEventListener("keyup", handleKeyUp);
    document.removeEventListener("keydown", handleKeyDown);
  };

  // allows zooming in and out from the outside
  watch(
    () => useZoomStore().zoomedStickyNoteId,
    () => {
      if (isZoomedIn.value && useZoomStore().zoomedStickyNoteId !== card.id) {
        zoomOut();
        return;
      }

      if (!isZoomedIn.value && useZoomStore().zoomedStickyNoteId === card.id) {
        zoomIn(useZoomStore().withFocus);
      }
    },
  );

  // zoom out when another sticky note becomes active
  watch(
    () => useBoardStore().activeCardId,
    () => {
      if (isZoomedIn.value && useBoardStore().activeCardId !== card.id) {
        zoomOut();
      }
    },
  );

  return {
    zoomFactor: zoomFactor.value,
    zoomIn,
    zoomOut,
  };
}
