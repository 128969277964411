import { clamp } from "lodash-es";

/** coordinate systems:
 - window (center of a card), delivered by mouse input
 - card (scaled by zoom, relative to a certain card)
 - board (scaled by zoom, limited by border for the top left corner of a card, used to draw card)
 - relative (0..1 for the center of a card, used for backend)
 */
export type WindowCoordinate = Coordinate & { _type: "window" };
export type BoardCoordinate = Coordinate & { _type: "board" };
export type RelativeCoordinate = Coordinate & { _type: "relative" };
export type AnyCoordinate =
  | WindowCoordinate
  | BoardCoordinate
  | RelativeCoordinate;

interface Coordinate {
  x: number;
  y: number;
}

export type CoordinateComponent = "x" | "y";

export interface Rectangle<T extends AnyCoordinate> {
  p0: T;
  p1: T;
}

export type Line<T extends AnyCoordinate> = Rectangle<T>;

export type LineComponent = "p0" | "p1";

export function oppositeLineComponent(c: LineComponent) {
  return c === "p0" ? "p1" : "p0";
}

export function isCoordinate(c?: unknown): c is Coordinate {
  return !!c && (c as Coordinate).x !== undefined;
}

export function clientCoord(elem: MouseEvent): WindowCoordinate {
  return windowCoord(elem.clientX, elem.clientY);
}

export function offsetCoord(e: MouseEvent): WindowCoordinate {
  return windowCoord(e.offsetX, e.offsetY);
}

export function scrollCoord(): WindowCoordinate {
  return windowCoord(window.scrollX, window.scrollY);
}

export function centerCoord() {
  return windowCoord(window.innerWidth / 2, window.innerHeight / 2);
}

export function boundsCoord(elem: HTMLElement): WindowCoordinate {
  const { left, top } = elem.getBoundingClientRect();
  return windowCoord(left, top);
}

export function relativeCoord(x: number, y: number): RelativeCoordinate {
  return { x, y } as RelativeCoordinate;
}

export function windowCoord(x: number, y: number): WindowCoordinate {
  return { x, y } as WindowCoordinate;
}

export function boardCoord(x: number, y: number): BoardCoordinate {
  return { x, y } as BoardCoordinate;
}

export const offScreen = relativeCoord(-10, -10);

export function isOffScreen(c: RelativeCoordinate) {
  return c.x === offScreen.x && c.y === offScreen.y;
}

export function add<T extends AnyCoordinate>(a: T, b: T) {
  a.x += b.x;
  a.y += b.y;
}

export function plus<T extends AnyCoordinate>(a: T, b: T): T {
  return { x: a.x + b.x, y: a.y + b.y } as T;
}

export function minus<T extends AnyCoordinate>(a: T, b?: T): T {
  return (b ? { x: a.x - b.x, y: a.y - b.y } : { x: -a.x, y: -a.y }) as T;
}

export function times<T extends AnyCoordinate>(
  a: T,
  factor1: number,
  factor2?: number,
): T {
  return { x: a.x * factor1, y: a.y * (factor2 ?? factor1) } as T;
}

export function divided<T extends AnyCoordinate>(
  a: T,
  factor1: number,
  factor2?: number,
): T {
  return { x: a.x / factor1, y: a.y / (factor2 ?? factor1) } as T;
}

const ETA = 0.000001;

export function isEqual(a: RelativeCoordinate, b: RelativeCoordinate) {
  return isNear(a.x, b.x) && isNear(a.y, b.y);
}

function isNear(a: number, b: number) {
  return Math.abs(a - b) < ETA;
}

export function distance2<T extends AnyCoordinate>(a: T, b: T): number {
  return (a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y);
}

export function interpolate<T extends AnyCoordinate>(v: number, a: T, b: T): T {
  return { x: a.x - v * (a.x - b.x), y: a.y - v * (a.y - b.y) } as T;
}

export function insideRectangle<T extends AnyCoordinate>(
  c: T,
  r: Rectangle<T>,
) {
  return c.x >= r.p0.x && c.x <= r.p1.x && c.y >= r.p0.y && c.y <= r.p1.y;
}

export function clampRelativeCoord(c: RelativeCoordinate): RelativeCoordinate {
  return relativeCoord(clamp(c.x, 0, 1), clamp(c.y, 0, 1));
}

/**
 * Return the point on the line that is nearest the given point.
 * @return {
 * t: the fractional position along the line 0..1 (0: p0, 1: p1, 0.5: middle of the line),
 * point: the point itself
 * }
 */
export function nearestLinePoint<T extends AnyCoordinate>(
  point: T,
  line: Line<T>,
) {
  const len = distance2(line.p0, line.p1);
  const t =
    ((point.x - line.p0.x) * (line.p1.x - line.p0.x) +
      (point.y - line.p0.y) * (line.p1.y - line.p0.y)) /
    len;
  return { t, point: interpolate(clamp(t, 0, 1), line.p0, line.p1) };
}

export function isHorizontal<T extends AnyCoordinate>(line: Line<T>) {
  return Math.abs(line.p0.y - line.p1.y) < ETA;
}
