import { namedKey } from "@/Shortcuts";
import { action, defineActions } from "@/action/actions";
import { BoardType } from "@/baseTypes";
import FlexModal from "@/components/modal/FlexModal.vue";
import SwitchTeamboardModal from "@/components/modal/SwitchTeamboardModal.vue";
import { Art, Team } from "@/model";
import { currentRoute, goToBoard, navigateBack } from "@/router/navigation";
import { useBoardStore } from "@/store/board";
import { useFlexStore } from "@/store/flex";
import { useModalStore } from "@/store/modal";
import { useTeamStore } from "@/store/team";
import { nextElement } from "@/utils/list";

export const navigationActions = defineActions("navigation", {
  goToBacklog: action(() => goToBoard("backlog"), {
    name: /*$t*/ "action.backlogBoard",
    shortcut: namedKey("1", { hideKey: true }),
    icon: "board/backlog",
  }),
  goToProgram: action(() => goToBoard("program"), {
    name: /*$t*/ "action.programBoard",
    shortcut: namedKey("2", { hideKey: true }),
    icon: "board/program",
  }),
  goToSolutionBacklog: action(() => goToBoard("solution_backlog"), {
    name: /*$t*/ "action.solutionBacklogBoard",
    icon: "board/solution-backlog",
  }),
  goToSolution: action(() => goToBoard("solution"), {
    name: /*$t*/ "action.solutionBoard",
    icon: "board/solution",
  }),
  goToRisk: action(() => goToBoard("risk"), {
    name: /*$t*/ "action.riskBoard",
    shortcut: namedKey("3", { hideKey: true }),
    icon: "board/risk",
  }),
  goToTeam: action(
    () => {
      if (currentRoute().params.board === "team") {
        useModalStore().open(SwitchTeamboardModal);
      } else {
        goToBoard("team");
      }
    },
    {
      name: /*$t*/ "action.teamBoard",
      shortcut: namedKey("4", { hideKey: true }),
      icon: "board/team",
    },
  ),
  goToNextTeam: action(
    () => goToBoard({ type: "team", team: findNextTeam(1) }),
    {
      shortcut: namedKey("Digit4", { modifiers: ["altCtrl"] }),
    },
  ),
  goToPreviousTeam: action(
    () => goToBoard({ type: "team", team: findNextTeam(-1) }),
    {
      shortcut: namedKey("Digit4", {
        modifiers: ["altCtrl", "shift"],
      }),
    },
  ),

  goToCanvas: action(() => useModalStore().open(FlexModal), {
    name: /*$t*/ "action.canvases",
    shortcut: namedKey("5", { hideKey: true }),
    icon: "board/canvas",
  }),
  goToNextCanvas: action(
    () => {
      if (useFlexStore().flexBoards.length > 0) {
        goToBoard({ type: "flex", name: findNextFlex(1).name });
      }
    },
    {
      shortcut: namedKey("Digit5", {
        modifiers: ["altCtrl"],
      }),
    },
  ),
  goToPreviousCanvas: action(
    () => {
      if (useFlexStore().flexBoards.length > 0) {
        goToBoard({ type: "flex", name: findNextFlex(-1).name });
      }
    },
    {
      shortcut: namedKey("Digit5", {
        modifiers: ["altCtrl", "shift"],
      }),
    },
  ),

  goToObjectives: action(() => goToBoard("objectives"), {
    name: /*$t*/ "action.objectivesBoard",
    shortcut: namedKey("6", { hideKey: true }),
    icon: "board/objectives",
  }),
  goBack: action(navigateBack, {
    name: /*$t*/ "userAction.mainMenu",
    shortcut: namedKey("KeyQ"),
  }),
  changeArt: action((boardType: BoardType, art: Art) =>
    goToBoard({ type: boardType, artId: art.id }),
  ),
  changeTeam: action((team: Team) => goToBoard({ type: "team", team })),
  selectTeam: action((team: Team) => useTeamStore().selectTeam(team)),
});

function findNextTeam(step: number) {
  return nextElement(
    useTeamStore().teamsInCurrentArt,
    (team) => team.id === useTeamStore().team.current.id,
    step,
  );
}

function findNextFlex(step: number) {
  return nextElement(
    useFlexStore().flexBoards,
    (board) => board.id === useBoardStore().boardByType("flex").id,
    step,
  );
}
