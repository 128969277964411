<script setup lang="ts">
import { computed } from "vue";

import ModalTab from "@/components/ModalTab.vue";
import TabbedModal from "@/components/TabbedModal.vue";
import StickyLinkObjectivesTab, {
  allowLinkObjectives,
} from "@/components/modal/StickyLinkObjectivesTab.vue";
import StickyLinkStickiesTab from "@/components/modal/StickyLinkStickiesTab.vue";
import { useCardStore } from "@/store/card";
import { useModalStore } from "@/store/modal";

const props = defineProps<{ cardIds: string[] }>();

const cards = computed(
  () =>
    props.cardIds
      .map((id) => useCardStore().cards[id])
      .filter((card) => !!card), //cards might be loading
);

const showObjectivesTab = computed(() => allowLinkObjectives(cards.value));

function close() {
  useModalStore().hide();
}
</script>

<template>
  <TabbedModal :title="$t('linkModal.title', cards.length)">
    <ModalTab :title="$t('linkModal.stickies')">
      <StickyLinkStickiesTab :cards="cards" @link-change="close" />
    </ModalTab>
    <ModalTab v-if="showObjectivesTab" :title="$t('general.objectives')">
      <StickyLinkObjectivesTab :cards="cards" @link-change="close" />
    </ModalTab>
  </TabbedModal>
</template>
