import { zoomFactor } from "@/Settings";
import { Color } from "@/baseTypes";
import { i18n } from "@/i18n";
import { RelativeCoordinate, times } from "@/math/coordinates";
import {
  AlmItemStatus,
  AlmItemType,
  AlmItemTypeMap,
  AlmType,
  Art,
  Board,
  BoardData,
  BoardIteration,
  Category,
  FieldChange,
  FlexBackground,
  FlexType,
  IdMap,
  Iteration,
  Link,
  LinkType,
  Objective,
  ObjectiveUpdate,
  Priority,
  Reaction,
  Reactions,
  SearchResult,
  Session,
  StickyChange,
  StickyChangeKind,
  StickyType,
  TargetStatus,
  Team,
  TimerEvent,
  TransitionField,
  reactions,
  stickyActivityKeys,
} from "@/model";
import { captureMessage } from "@/sentry";
import { loadUserImmediate } from "@/services/user.service";
import { useStickyTypeStore } from "@/store/stickyType";
import { useTeamStore } from "@/store/team";
import { plusDays, utcDateToLocalMidnight } from "@/utils/date";
import { mapObjectValues } from "@/utils/general";

import { BackendSession } from "./BackendSession";
import {
  ServerAlmItemStatus,
  ServerAlmItemTransition,
  ServerAlmItemTransitionField,
  ServerAlmItemType,
  ServerAlmItemTypeMap,
  ServerAlmTypeData,
  ServerArt,
  ServerBoard,
  ServerBoardIteration,
  ServerCategory,
  ServerEvent,
  ServerFlexBackground,
  ServerFlexBoard,
  ServerFlexType,
  ServerIteration,
  ServerLink,
  ServerLinkType,
  ServerObjective,
  ServerSearchResult,
  ServerSession,
  ServerSettings,
  ServerStickyChange,
  ServerStickyType,
  ServerTeam,
} from "./serverModel";

export function mapSessions(sessions: ServerSession[]): Session[] {
  return sessions
    .sort((a, b) => {
      const d = b.creation_date - a.creation_date;
      return d > 0 ? 1 : d < 0 ? -1 : 0;
    })
    .map((session) => {
      return {
        id: session.session_id,
        name: session.name,
        startDate: session.start_date
          ? utcDateToLocalMidnight(session.start_date)
          : null,
        archived: session.archived,
        almStatus: null,
      };
    });
}

export function mapLinkTypes(types: ServerLinkType[]): LinkType[] {
  return types.map((type) => ({
    id: type._id,
    from: type.from_sticky_type,
    to: type.to_sticky_type,
  }));
}

export function mapLinks(links: ServerLink[]): Link[] {
  return links.map((link) => ({
    id: link.id,
    from: link.from_sticky_id,
    to: link.to_sticky_id,
    type: link.link_type_id,
    state: "default",
  }));
}

export function mapBoard(
  board: ServerBoard | ServerFlexBoard,
  stickyTypes: StickyType[],
  flexTypes: FlexType[],
  almSourceProvider: BackendSession["almSource"],
): Board {
  return board.board_type === "flex"
    ? mapFlexBoard(board, stickyTypes, flexTypes)
    : mapNonFlexBoard(board, stickyTypes, almSourceProvider);
}

export function mapFlexBoard(
  board: ServerFlexBoard,
  stickyTypes: StickyType[],
  flexTypes: FlexType[],
): BoardData<"flex"> {
  return {
    ...baseBoardData(board, stickyTypes),
    flexType: flexTypes.find((type) => type.id === board.flexboard_type)!,
    name: board.name,
    almSources: [],
  } as BoardData<"flex">;
}

export function mapNonFlexBoard(
  board: ServerBoard,
  stickyTypes: StickyType[],
  almSourceProvider: BackendSession["almSource"],
): Board {
  const alm = almSourceProvider(board.board_type, {
    teamId: board.user_id,
    artId: board.art_id,
  });
  return {
    ...baseBoardData(board, stickyTypes),
    team:
      board.user_id === null
        ? undefined
        : useTeamStore().teams.find((t) => t.id === "" + board.user_id),
    almSources: alm ? alm.sources.map(({ id, name }) => ({ id, name })) : [],
    artId: board.art_id ? "" + board.art_id : undefined,
  } as Board;
}

function baseBoardData(
  board: ServerBoard | ServerFlexBoard,
  stickyTypes: StickyType[],
): Partial<Board> {
  return {
    id: board.board_id,
    type: board.board_type,
    stickyTypes: useStickyTypeStore().boardStickyTypes(
      board.board_type,
      board.board_type === "flex" ? board.flexboard_type : undefined,
      stickyTypes,
    ),
    cardSize: { factor: 1, ...zoomFactor },
    cards: {},
    maxZ: 0,
    loaded: 0,
    selected: {},
    shapes: board.shapes || [],
  };
}

export function mapScale(scale: number): {
  factor: number;
} & RelativeCoordinate {
  return { factor: scale, ...times(zoomFactor, scale) };
}

export function mapBoardIterations(
  bis: ServerBoardIteration[],
  existing?: BoardIteration[],
): BoardIteration[] {
  const iter = new Array<BoardIteration>();
  bis.forEach((bi) => {
    const old = existing?.[bi.board_iteration_id];
    iter[bi.board_iteration_id] = {
      velocity: +bi.velocity,
      load: old?.load || 0,
      state: old?.state || { status: null, detail: null },
    };
  });
  return iter;
}

export function mapStickyType(stickyType: ServerStickyType): StickyType {
  const priorities = prioritiesByValue(stickyType.step_estimate_values);
  const color = checkColor(stickyType.id, stickyType.color);
  return {
    id: stickyType.id,
    name: stickyType.name,
    almType: stickyType.alm_type,
    color,
    altColor: stickyType.alt_color ? stickyType.alt_color : color,
    priorities,
    functionality: stickyType.functionality,
    origin: stickyType.origin_board_type,
    flexOrigin: stickyType.origin_flexboard_type || null,
    usable: stickyType.available_board_types,
    flexUsable: stickyType.available_flexboard_types || [],
  };

  function prioritiesByValue(stepEstimates?: Priority[]) {
    if (stepEstimates) {
      return Object.fromEntries(
        stepEstimates.map((stepEstimate) => [stepEstimate.value, stepEstimate]),
      );
    }
  }

  function checkColor(id: string, c: Color | undefined | null): Color {
    if (c) {
      return c;
    }
    captureMessage("Sticky type has no color", { stickyType: { id } });
    return [0.5, 0.5, 0.5, 1];
  }
}

export function mapAlmTypes(almTypes: ServerAlmTypeData[]): IdMap<AlmType> {
  return Object.fromEntries(
    almTypes.map((almType) => [
      almType.id,
      { id: almType.id, isMapped: almType.is_mapped },
    ]),
  );
}

export function mapAlmItemTypes(
  almItemTypes: ServerAlmItemTypeMap,
): AlmItemTypeMap {
  return mapObjectValues(almItemTypes, (teamType) =>
    mapObjectValues(teamType, mapAlmItemType),
  );
}

export function mapAlmItemType(almItemType: ServerAlmItemType): AlmItemType {
  const transitions = transitionFilter(almItemType);
  return {
    statuses: almItemType.statuses.map((status) =>
      mapStatus(almItemType, status),
    ),
    globalTargetStatuses: mapTargetStatus(
      almItemType.statuses,
      transitions.globalFromStatus(),
    ),
    dynamic: almItemType.dynamic,
  };
}

function mapStatus(
  almItemType: ServerAlmItemType,
  status: ServerAlmItemStatus,
): AlmItemStatus {
  const transitions = transitionFilter(almItemType);
  return {
    id: status.id,
    name: status.name,
    initial: transitions.isStatusInitial(status),
    statusClass: status.category,
    order: status.order,
    next: mapTargetStatus(almItemType.statuses, [
      ...transitions.fromStatus(status),
      ...transitions.globalFromStatus(status),
    ]).sort((a, b) => {
      return a.status.order - b.status.order;
    }),
    dynamic: almItemType.dynamic,
  };
}

function mapTargetStatus(
  statuses: ServerAlmItemStatus[],
  transitions: ServerAlmItemTransition[],
): TargetStatus[] {
  return transitions.map((transition) => {
    const target = statuses.find(
      (status) => status.id === transition.to_status,
    )!;
    return {
      transition: { id: transition.id, name: transition.name },
      status: {
        id: target.id,
        name: target.name,
        statusClass: target.category,
        order: target.order,
      },
      fields: mapFields(transition.fields),
    };
  });

  function mapFields(
    fields: ServerAlmItemTransitionField[],
  ): TransitionField[] {
    return fields.map((field) => ({
      id: field.id,
      name: field.name,
      required: field.required,
      type: field.type,
      itemType: field.item_type,
      values: field.values,
    }));
  }
}

function transitionFilter(almItemType: ServerAlmItemType) {
  return {
    // status is initial when there's an initial transition to it
    isStatusInitial(status: ServerAlmItemStatus) {
      return almItemType.transitions.some(
        (transition) =>
          transition.type === "initial" && transition.to_status === status.id,
      );
    },
    // statuses that can be reached from a given status by normal transitions
    fromStatus(status: ServerAlmItemStatus) {
      return almItemType.transitions.filter(
        (transition) =>
          transition.type === "normal" &&
          transition.from_statuses.includes(status.id),
      );
    },
    // statuses that can be reached by global transitions (excluding the given one)
    globalFromStatus(fromStatus?: ServerAlmItemStatus) {
      return almItemType.transitions.filter(
        (transition) =>
          transition.type === "global" &&
          (!fromStatus || transition.to_status !== fromStatus.id),
      );
    },
  };
}

export function mapIterations(iters: ServerIteration[]): Iteration[] {
  return iters
    .sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0))
    .map((iter) => ({
      id: iter.id,
      name: iter.name,
      start: utcDateToLocalMidnight(iter.start),
      end: plusDays(utcDateToLocalMidnight(iter.end), 1),
    }));
}

export function mapObjective(objective: ServerObjective): Objective {
  const mappedObjective = mapPartialObjective(objective);

  return { ...mappedObjective, cards: mappedObjective.cards || [] };
}

/**
 * Similar to mapObjective, but the return value may not have a cards property
 * (used to communicate that the cards property hasn't been updated)
 */
export function mapPartialObjective(
  objective: ServerObjective,
): ObjectiveUpdate {
  return {
    id: objective.objective_id,
    text: objective.text,
    description: objective.description,
    bv: objective.bv,
    av: objective.av,
    cards: objective.stickies?.map((obj) => {
      return { id: obj.id, isOrigin: obj.is_origin };
    }),
  };
}

export function mapFlexTypes(types: ServerFlexType[]): FlexType[] {
  return types.map((type) => ({
    id: type.id,
    name: type.name,
    background: type.background,
  }));
}

export function mapSearchResults(
  results: ServerSearchResult[],
): SearchResult[] {
  return results.map((result) => ({
    kind: "sticky",
    boardId: result.board_id,
    id: result.id,
    text: result.text,
    typeId: result.type_id,
    teamId: result.team_id,
    artId: result.art_id,
    flag: result.flag_type,
    almId: result.alm_issue_id,
  }));
}

export function mapEvents(events: ServerEvent[]): TimerEvent[] {
  return events.flatMap((event) =>
    event.type === "timer"
      ? [
          {
            id: event.id,
            boardId: event.board_id,
            artId: event.art_id,
            type: "timer",
            createdById: event.created_by,
            createdAt: event.created_at,
            updatedById: event.updated_by,
            updatedAt: event.updated_at,
            data: {
              name: event.data.name,
              state: event.data.state,
              duration: event.data.duration,
              end: event.data.end,
            },
          },
        ]
      : [],
  );
}

export function mapReactions(reactionUsers: {
  [reaction in Reaction]?: string[];
}): Reactions {
  const res = {} as Reactions;
  for (const reaction of reactions) {
    res[reaction] = (reactionUsers[reaction] || []).map((id) =>
      loadUserImmediate({ id }),
    );
  }
  return res;
}

export function mapCategories(categories: ServerCategory[]): Category[] {
  return categories.map((cat) => ({
    id: cat._id,
    name: cat.name,
    boardIds: cat.board_ids,
  }));
}

export function mapArts(arts: ServerArt[]): Art[] {
  return arts.map((art) => ({
    id: art.id ? "" + art.id : "",
    name: art.name,
  }));
}

export function mapTeams(teams: ServerTeam[]): Team[] {
  return teams.map((team) => ({
    id: "" + team.user_id,
    name: team.name,
    artId: team.art_id ? "" + team.art_id : undefined,
  }));
}

export function mapSettings(settings: ServerSettings) {
  return {
    isBacklogAlmStickyDeletable: !settings.backlog_forbid_JIRA_sticky_delete,
    isTeamAlmStickyDeletable: !settings.team_forbid_sticky_delete,
    isPriorityEditable: !settings.backlog_forbid_WSJF_edit,
    confirmDelete: settings.confirm_sticky_delete || false,
    moveBetweenTeams: settings.move_activated || false,
  };
}

export function mapFlexBackgrounds(
  backgrounds: ServerFlexBackground[],
): FlexBackground[] {
  return backgrounds.map(({ id, name, info_link }) => ({
    id,
    name,
    infoLink: info_link,
  }));
}

export function mapStickyChanges(
  changes: ServerStickyChange[],
): StickyChange[] {
  changes.reverse();
  return changes.flatMap((change) => {
    // Guard to ensure the FE only shows changes it knows how to handle
    const allowedKinds: StickyChangeKind[] = [
      "create",
      "update",
      "delete",
      "mirror",
      "unmirror",
      "link",
      "unlink",
    ];
    if (!allowedKinds.includes(change.kind)) {
      return [];
    }

    let fields: FieldChange[] = [];
    if (change.kind === "update") {
      fields = (change.changes || []).flatMap((fieldChange) => {
        const key =
          stickyActivityKeys[
            fieldChange.key as keyof typeof stickyActivityKeys
          ];
        switch (key) {
          case undefined:
            return [];
          case "reactions": {
            const res = new Array<FieldChange>();
            for (const reaction of reactions) {
              const from = hasUserReaction(
                fieldChange.old,
                reaction,
                change.user_id,
              );
              const to = hasUserReaction(
                fieldChange.new,
                reaction,
                change.user_id,
              );
              if (from !== to) {
                res.push({
                  name: key,
                  old: from ? reaction : undefined,
                  new: to ? reaction : undefined,
                });
              }
            }
            return res;
          }
          case "teamId":
          case "dependTeamId":
            return {
              name: key,
              old: fieldChange.old ? "" + fieldChange.old : undefined,
              new: fieldChange.new ? "" + fieldChange.new : undefined,
            };
          default:
            return {
              name: key,
              old: fieldChange.old,
              new: fieldChange.new,
            };
        }
      });
    }

    // Represent the mirror event as a FieldChange
    if (change.kind === "mirror" || change.kind === "unmirror") {
      fields = [
        {
          name: "mirrorBoard",
          old: change.from_board,
          new: change.to_board,
        },
      ];
    }

    if (change.kind === "link") {
      fields = [{ name: "link", new: change.linked_to }];
    }

    if (change.kind === "unlink") {
      fields = [{ name: "link", old: change.linked_to }];
    }

    // Filter out updates that don't have any changes to display
    return change.kind !== "update" || fields.length > 0
      ? {
          timestamp: new Date(change.timestamp),
          user: loadUserImmediate(
            {
              id: change.user_id,
              name: i18n.global.t("stickyChange.unknownUser"), // Fallback name if user was deleted
            },
            true,
          ),
          kind: change.kind,
          fields,
        }
      : [];
  });
}

function hasUserReaction(
  reactions: Reactions | undefined,
  reaction: Reaction,
  userId: string,
) {
  return !!((reactions?.[reaction] as []) || []).find((id) => id === userId);
}
