<script setup lang="ts">
import { computed } from "vue";

import { StatusClass } from "@/baseTypes";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import SelectStatusClass from "@/components/ui/SelectStatusClass/SelectStatusClass.vue";
import { statusClassItems, useSearchMenuStore } from "@/store/searchMenu";

import StatusClassItemTooltip from "../tooltips/StatusClassItemTooltip.vue";

const store = useSearchMenuStore();

const selection = computed({
  get: () => store.selectedStatusClassItems,
  set: (values: Array<{ id: StatusClass }>) =>
    (store.selectedStatusClasses = new Set(values.map((val) => val.id))),
});
</script>

<template>
  <DropdownMenu class="search-select" width="sm" stay-on-click>
    <template #trigger="{ isOpen }">
      <IconButton icon="status-class" :activated="isOpen">
        <template #tooltip>
          <StatusClassItemTooltip :status-class-items="selection" />
        </template>
      </IconButton>
      <InfoMarker v-if="selection.length" :top="4" :right="4" />
    </template>
    <SelectStatusClass v-model="selection" :status-classes="statusClassItems" />
  </DropdownMenu>
</template>
