<script setup lang="ts">
import { flagColors } from "@/colors";
import { iconMap } from "@/components-ng/StickyNote/components/StickyNoteHeader/components/FlagChip/flagMap";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import CardFlag from "@/components/card/CardFlag";

const props = defineProps<{ flag: CardFlag }>();
</script>

<template>
  <SvgIcon
    :name="iconMap[props.flag.icon]"
    :color="flagColors[props.flag.colorIndex]"
    class="flag-icon"
  />
</template>

<style lang="scss" scoped>
.flag-icon {
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 0.4em;

  :deep(svg) {
    height: 100%;
    width: 100%;
  }
}
</style>
