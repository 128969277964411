export default class DelayedAction {
  private executionTime = 0;
  private timeout = 0;

  constructor(private delay: number) {}

  start(action: () => void) {
    this.executionTime = 0;
    this.timeout = window.setTimeout(() => action(), this.delay);
  }

  executed() {
    this.executionTime = performance.now();
    clearTimeout(this.timeout);
  }

  cancel() {
    if (this.executionTime !== 0) {
      this.executionTime = performance.now();
    }
    clearTimeout(this.timeout);
  }

  justExecuted(timeAgo: number) {
    return performance.now() < this.executionTime + timeAgo;
  }
}
