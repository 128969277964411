<template>
  <div class="edit-timer">
    <inactive-timer
      v-if="isState('stopped')"
      :name="name"
      :duration="duration"
      @name="name = $event"
      @duration="duration = $event"
      @start="setState('running')"
      @cancel="remove"
    />
    <active-timer
      v-if="isState('paused')"
      :name="name"
      :duration="duration"
      :user="timer.updatedByUser"
      :paused="true"
    >
      <IconButton icon="timer/play" @click="setState('running')" />
      <IconButton icon="timer/stop" @click="setState('stopped')" />
    </active-timer>
    <active-timer
      v-if="isState('running')"
      :name="name"
      :duration="duration"
      :user="timer.updatedByUser"
      :paused="false"
    >
      <IconButton icon="timer/pause" @click="setState('paused')" />
      <IconButton icon="timer/stop" @click="setState('stopped')" />
    </active-timer>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { keepEndedTimer } from "@/Settings";
import { deleteEvent, updateTimer } from "@/backend/Backend";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import EventBusUser from "@/mixins/EventBusUser";
import { ServerTime, TimerEvent, TimerState } from "@/model";
import { serverTime } from "@/serverTime";

import Duration from "../Duration";
import ActiveTimer from "./ActiveTimer.vue";
import InactiveTimer from "./InactiveTimer.vue";

@Options({
  components: {
    IconButton,
    ActiveTimer,
    InactiveTimer,
  },
})
export default class EditTimer extends mixins(EventBusUser) {
  @Prop(Object) readonly timer!: TimerEvent;

  name = this.timer.data.name;
  state = this.timer.data.state;
  duration = Duration.fromTimer(this.timer.data, serverTime);

  created() {
    if (this.timerFinished()) {
      this.remove();
    } else {
      this.onServerTick((serverTime) => this.update(serverTime));
    }
  }

  timerFinished() {
    return (
      this.isState("running") &&
      Duration.fromEnd(this.timer.data.end, serverTime).value <
        -keepEndedTimer / 1000
    );
  }

  update(serverTime: ServerTime) {
    if (this.isState("running") && this.duration.value > 0) {
      this.duration = Duration.fromEnd(this.timer.data.end, serverTime);
    }
  }

  isState(state: TimerState) {
    return this.state === state;
  }

  async setState(state: TimerState) {
    if (!this.isState(state)) {
      this.state = state;
      this.timer.data.end = this.duration.end(serverTime);
      await updateTimer(this.timer.id, {
        name: this.name,
        state,
        duration: this.duration.value,
        end: this.timer.data.end,
      });
    }
  }

  remove() {
    deleteEvent(this.timer.id);
  }
}
</script>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.edit-timer {
  border-top: 1px solid colors-old.$divider-color;
  border-bottom: 1px solid colors-old.$divider-color;
  padding: 2em;
}
</style>
