<script lang="ts" setup>
import BasePopup from "@/components-ng/BasePopup/BasePopup.vue";
import BasePopupFooter from "@/components-ng/BasePopup/components/BasePopupFooter/BasePopupFooter.vue";
import BasePopupTitle from "@/components-ng/BasePopup/components/BasePopupTitle/BasePopupTitle.vue";
import CardFlag from "@/components/card/CardFlag";
import FlagPicker from "@/components/flags/FlagPicker.vue";

defineProps<{
  selected: CardFlag | null;
  noStickerOption?: boolean;
}>();

const emit = defineEmits<{ select: [cardFlag: CardFlag | null] }>();

function handleFlagChange(cardFlag: CardFlag | null) {
  emit("select", cardFlag);
}
</script>

<template>
  <BasePopup>
    <BasePopupTitle>{{ $t("general.stickers") }}</BasePopupTitle>
    <FlagPicker
      class="picker"
      :selected="selected"
      :no-sticker-option="noStickerOption"
      @select="handleFlagChange"
    />
    <BasePopupFooter :disabled="!selected" @click="handleFlagChange(null)"
      >{{ $t("general.clear") }}
    </BasePopupFooter>
  </BasePopup>
</template>

<style lang="scss" scoped>
.picker {
  font-size: 10px;
  padding: 5px;
}
</style>
