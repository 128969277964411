<template>
  <base-modal :title="title" :fullscreen="fullscreen" :width="width">
    <template #header-content>
      <div v-if="showTabs" class="tabs-header">
        <span
          v-for="(title, index) in tabState.titles"
          :key="title"
          :class="{ 'tab-active': index === tabState.activeIndex }"
          @click="selectTab(index)"
        >
          {{ title }}
        </span>
      </div>
    </template>
    <div>
      <slot></slot>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { provide } from "vue";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { TAB_STATE } from "@/components/keys";
import BaseModal from "@/components/modal/BaseModal.vue";
import { TabState } from "@/components/tabbedModal";

@Options({ components: { BaseModal } })
export default class TabbedModal extends Vue {
  @Prop({ default: "Modal" }) readonly title!: string;
  @Prop({ default: "", type: String }) readonly width!: string;
  @Prop({ default: false, type: Boolean }) readonly fullscreen!: boolean;

  tabState: TabState = {
    activeIndex: -1,
    titles: [],
  };

  created() {
    provide(TAB_STATE, this.tabState);
  }

  @Watch("tabState.titles", { deep: true })
  tabAdded(titles: TabState["titles"]) {
    if (this.tabState.activeIndex < 0 && titles.length > 0) {
      this.tabState.activeIndex = 0;
    }
  }

  selectTab(i: number) {
    this.tabState.activeIndex = i;
  }

  get showTabs() {
    return this.tabState.titles.length > 1;
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/_colors" as *;

div.tabs-header {
  padding-left: 1rem;

  span {
    font-size: font.$size-small;
    display: inline-block;
    cursor: pointer;
    margin-right: 16px;
    padding-bottom: 8px;

    &.tab-active {
      font-weight: font.$weight-bold;
      border-bottom: solid 2px colors-old.$tab-border-color;
    }
  }
}
</style>
