<script setup lang="ts">
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { FieldChange } from "@/model";
import { useTeamStore } from "@/store/team";

defineProps<{ field: FieldChange }>();

function teamName(id?: string) {
  return id ? useTeamStore().findTeam({ id })!.name : "";
}
</script>

<template>
  <div class="change horizontal">
    <template v-if="field.old">
      <span class="change-value">
        <SvgIcon name="team" size="20" />
        {{ teamName(field.old) }}
      </span>
      <SvgIcon name="arrow/right-thin" size="20" />
    </template>
    <span class="change-value">
      <SvgIcon name="team" size="20" />
      {{ teamName(field.new) }}
    </span>
  </div>
</template>
