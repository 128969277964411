<script setup lang="ts">
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import { keyboardNavigation } from "@/directives/keyboardNavigation";

interface Item {
  id: string | number;
  name: string;
}

defineProps<{
  header?: string;
  modelValue?: Item | Item[];
  values: Item[];
  scrollable?: boolean;
  translate?: boolean;
}>();

const emit = defineEmits<{ "update:model-value": [value: Item | Item[]] }>();

const vKeyboardNav = keyboardNavigation();
</script>

<template>
  <SelectableList
    v-keyboard-nav
    :header="header"
    :model-value="modelValue"
    :values="values"
    :scrollable="scrollable"
    @update:model-value="emit('update:model-value', $event)"
  >
    <template #default="{ value }">
      {{ translate ? $t(value.name) : value.name }}
    </template>
  </SelectableList>
</template>
