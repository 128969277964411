<script setup lang="ts">
import { computed } from "vue";

import { getAlmType } from "@/backend/Backend";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import TypeDot from "@/components/TypeDot.vue";
import { FieldChange } from "@/model";
import { useCardStore } from "@/store/card";
import { Icon } from "@/types/icon";

const props = defineProps<{ field: FieldChange }>();

const linkedSticky = computed(() => {
  const stickyId = props.field?.old || props.field?.new;
  return stickyId ? useCardStore().cards[stickyId] : null;
});

const almIcon = computed(() => {
  return getAlmType() as Icon;
});
</script>

<template>
  <div class="change horizontal">
    <template v-if="linkedSticky">
      <span class="change-value">
        <TypeDot size="medium" :color="linkedSticky.type.color" />
        <span class="overflow-ellipsis"> {{ linkedSticky.text }}</span>
      </span>
      <span v-if="linkedSticky.almId && almIcon" class="change-value">
        <SvgIcon :name="almIcon" size="20" />
        {{ linkedSticky.almId }}
      </span>
    </template>
    <div v-else class="not-found">
      {{ $t("stickyChange.linkedStickyNotFound") }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.not-found {
  color: colors-old.$text-subtle-color;
}
</style>
