import { FlagIcon } from "@/model";

export default class CardFlag {
  icon: FlagIcon;
  colorIndex: number;

  constructor(icon: FlagIcon, colorIndex: number) {
    this.icon = icon;
    this.colorIndex = colorIndex;
  }

  public toString(): string {
    return `${this.icon}-${this.colorIndex}`;
  }

  static fromFlagString(flag?: string | null): CardFlag {
    if (flag) {
      const [icon, colorIndex] = flag.split("-");
      return new CardFlag(icon as FlagIcon, parseInt(colorIndex));
    } else {
      return CardFlag.emptyFlag();
    }
  }

  static emptyFlag(): CardFlag {
    return new CardFlag("none", 0);
  }

  equals(cardFlag: CardFlag) {
    return (
      this.icon === cardFlag.icon && this.colorIndex === cardFlag.colorIndex
    );
  }

  isEmpty(): boolean {
    return this.icon === "none" && this.colorIndex === 0;
  }
}
