<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import { getAlmType } from "@/backend/Backend";
import { readableAlmType } from "@/backend/serverModel";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import ListItem from "@/components/ListItem/ListItem.vue";
import ListItemSeparator from "@/components/ListItem/ListItemSeparator.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import SettingsModal from "@/components/modal/SettingsModal.vue";
import ShortcutsModal from "@/components/modal/ShortcutsModal.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import { useBoardStore } from "@/store/board";
import { useFlexStore } from "@/store/flex";
import { useLicenseStore } from "@/store/license";
import { useModalStore } from "@/store/modal";
import { useSessionStore } from "@/store/session";
import { useUserStore } from "@/store/user";

const props = defineProps<{ isUserOnBoard?: boolean }>();

const route = useRoute();

const setupUrl = computed(() =>
  useSessionStore().sessionAdminUrl(useBoardStore().board?.type),
);

const profileUrl = useSessionStore().profileAdminUrl();
const profileSyncUrl = computed(() =>
  useSessionStore().profileAdminUrl(route.fullPath),
);

const vKeyboardNav = keyboardNavigation();

const currentUser = computed(() => useUserStore().currentUser);
const canSetup = computed(() => useUserStore().isAllowed("setup"));
const canEdit = computed(() => useUserStore().isAllowed("edit"));
const infoLink = computed(() => {
  const board = useBoardStore().board;
  if (!board || board.type !== "flex") {
    return "";
  }
  return useFlexStore().infoLink(board.flexType);
});
const showUserSync = computed(() => useSessionStore().userSync === "unsynced");
const almType = computed(() => readableAlmType(getAlmType()));

const { t } = useI18n();
const expirationMessage = computed(() => {
  if (useLicenseStore().isExpired) {
    return t("license.expired");
  }
  const expiresIn = useLicenseStore().willExpireIn;
  if (expiresIn) {
    return t("license.expiredInDays", expiresIn);
  }
  return "";
});

function openSettings() {
  useModalStore().open(SettingsModal, {
    attrs: { isUserOnBoard: props.isUserOnBoard },
  });
}

function openShortcuts() {
  useModalStore().open(ShortcutsModal);
}
</script>

<template>
  <DropdownMenu v-if="currentUser" :offset="{ top: 12 }" class="user-actions">
    <template #trigger>
      <BaseTooltip position="bottom" class="tooltip">
        <div style="position: relative">
          <UserAvatar :user="currentUser" class="small" />
          <InfoMarker
            v-if="showUserSync || expirationMessage"
            level="error"
            :top="-2"
            :right="-2"
          />
        </div>
        <template #content>
          {{ $t("userAction.mainMenu") }}
        </template>
      </BaseTooltip>
    </template>
    <div v-keyboard-nav class="dropdown">
      <ListItem class="info" static>
        <div>
          <p v-if="currentUser.name">{{ currentUser.name }}</p>
          <p class="email">{{ currentUser.email }}</p>
        </div>
      </ListItem>
      <ListItem v-if="showUserSync" class="warning" disabled>
        <div>
          <SvgIcon name="message/warn" size="20" />
          <br />
          {{ $t("userAction.userNotSynced", { almType }) }}
          <br /><br />
          <a class="link" :href="profileSyncUrl">
            {{ $t("userAction.gotoProfileSync") }}
          </a>
        </div>
      </ListItem>
      <ListItem v-if="expirationMessage" class="warning" static>
        <div>
          <SvgIcon name="message/warn" size="20" />
          <br />
          {{ expirationMessage }}
        </div>
      </ListItem>
      <ListItemSeparator />
      <a v-if="canEdit" target="_blank" :href="profileUrl" class="list-item">
        <span>
          <SvgIcon name="user-action/profile" size="20" />
          {{ $t("userAction.profile") }}
        </span>
      </a>
      <a
        v-if="isUserOnBoard && canSetup"
        target="_blank"
        :href="setupUrl"
        class="list-item"
      >
        <span>
          <SvgIcon name="user-action/rte-cockpit" size="20" />
          {{ $t("userAction.rteCockpit") }}
        </span>
      </a>
      <a class="list-item" @click="openSettings">
        <span>
          <SvgIcon name="user-action/settings" size="20" />
          {{ $t("userAction.boardSettings") }}
        </span>
      </a>
      <a class="list-item" @click="openShortcuts">
        <span>
          <SvgIcon name="user-action/shortcuts" size="20" />
          {{ $t("userAction.keyboardShortcuts") }}
        </span>
      </a>
      <ListItemSeparator />
      <a v-if="infoLink" :href="infoLink" target="_blank" class="list-item">
        {{ $t("userAction.tips") }}
      </a>
      <a
        href="https://www.piplanning.io/releases"
        target="_blank"
        class="list-item"
      >
        {{ $t("userAction.releaseNotes") }}
      </a>
      <a
        href="https://www.piplanning.io/courses"
        target="_blank"
        class="list-item"
      >
        {{ $t("userAction.gettingStarted") }}
      </a>
      <a
        href="https://rentouch.atlassian.net/wiki/spaces/PD/overview"
        target="_blank"
        class="list-item"
      >
        {{ $t("userAction.documentation") }}
      </a>
      <ListItemSeparator />
      <router-link
        :to="{ path: '/logout', query: { reason: 'user-invoked' } }"
        class="list-item"
      >
        <span>
          <SvgIcon name="user-action/logout" size="20" />
          {{ $t("userAction.logOut") }}
        </span>
      </router-link>
    </div>
  </DropdownMenu>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/components/ListItem/listItem";

.user-actions {
  display: flex;
  height: 40px;

  .tooltip {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .info {
    font-weight: font.$weight-bold;

    p {
      margin: 0;
    }

    .email {
      font-weight: font.$weight-light;
      color: colors-old.$text-secondary-color;
      text-transform: none;
    }
  }

  .dropdown {
    padding: 8px 0;
  }

  a {
    text-decoration: none;
  }

  .warning {
    max-width: 20em;
    font-weight: font.$weight-normal;
    padding: 6px;
    color: colors-old.$text-primary-color;

    .link {
      color: colors-old.$primary-color;
    }

    & > div {
      background-color: colors-old.$warn-back-color;
      border-radius: 6px;
      padding: 12px;
    }
  }

  .list-item svg {
    color: colors-old.$text-secondary-color;
  }
}
</style>
