<template>
  <div
    :id="optionId"
    class="category item hoverable"
    :class="{ selected: current, all: !cat.id }"
  >
    <SvgIcon
      v-if="!readOnly"
      name="arrow/up-down"
      size="20"
      class="hover-icon"
    />
    <div class="text">
      <input-field
        :value="cat.name"
        :max-length="30"
        :read-only="readOnly || !cat.id || !editing"
        underline
        @change="setName"
        @blur="editing = false"
      />
      <SvgIcon v-show="!readOnly && editing" name="check" size="20" />
    </div>
    <SvgIcon
      v-if="!readOnly"
      name="pen"
      size="20"
      class="action hover-icon"
      @click.stop="editMode"
    />
    <SvgIcon
      v-if="!readOnly"
      name="action-menu/delete"
      size="20"
      class="action hover-icon"
      @click.stop="remove"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { categoryActions } from "@/action/categoryActions";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import InputField from "@/components/input/InputField.vue";
import { Category } from "@/model";

@Options({ components: { SvgIcon, InputField }, emits: ["delete"] })
export default class FlexCategoryElem extends Vue {
  @Prop(Object) readonly cat!: Category;
  @Prop(Boolean) readonly readOnly!: boolean;
  @Prop(Boolean) readonly current!: boolean;

  editing = false;

  mounted() {
    if (!this.readOnly && this.cat.fresh) {
      this.cat.fresh = false;
      this.editMode();
    }
  }

  remove() {
    this.$emit("delete", this.cat);
  }

  setName(name: string) {
    categoryActions.update("modal", this.cat.id, { name });
  }

  editMode() {
    if (this.editing) {
      this.editing = false;
    } else {
      this.editing = true;
      // let the input-field update its readOnly state before invoking click on it
      this.$nextTick(() => {
        const input = document.querySelector(
          "#" + this.optionId + " input",
        ) as HTMLInputElement;
        input.focus();
        input.click();
      });
    }
  }

  get optionId() {
    return "flexModal-category-" + this.cat.id;
  }
}
</script>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.category {
  height: 35px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 0.1em solid colors-old.$divider-color;

  .hover-icon {
    visibility: hidden;
  }

  &:hover:not(.drag-category-over, .all) .hover-icon {
    visibility: visible;
  }

  &.drag-board-over {
    border: 2px solid colors-old.$menu-color;
  }

  .text {
    display: flex;
    align-items: center;

    input {
      padding-right: 10px;
      border-bottom-style: none;
    }
  }

  & > :nth-child(2) {
    flex-grow: 1;
  }
}
</style>
