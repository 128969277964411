<script lang="ts" setup>
import { computed } from "vue";

import { boardKey, cardKey } from "@/components/card/injectKeys";
import { useBoardStore } from "@/store/board";
import { injectStrict } from "@/utils/context";

import StickyNoteRow from "../../../StickyNoteRow/StickyNoteRow.vue";
import MirrorStickyChip from "../MirrorStickyChip/MirrorStickyChip.vue";
import ROAMChip from "../ROAMChip/ROAMChip.vue";
import StickyLinksChip from "../StickyLinksChip/StickyLinksChip.vue";
import TeamSelectChip from "../TeamSelectChip/TeamSelectChip.vue";

const board = injectStrict(boardKey);
const card = injectStrict(cardKey);

const isSolutionPlanningBoard = computed(() => board.type === "solution");
const isSelected = computed(() => useBoardStore().activeCardId === card.id);
</script>

<template>
  <StickyNoteRow class="risk-footer">
    <TeamSelectChip v-if="isSolutionPlanningBoard" />
    <StickyLinksChip />
    <MirrorStickyChip v-if="isSelected" />
    <div class="roam"><ROAMChip /></div>
  </StickyNoteRow>
</template>

<style lang="scss" scoped>
@use "../../../../utils";

.risk-footer {
  .roam {
    margin-left: auto;
  }
}
</style>
