import { nextTick } from "vue";

import { updateStickyBatchSize } from "@/Settings";
import {
  mapEvents,
  mapObjective,
  mapPartialObjective,
} from "@/backend/BackendDataMapper";
import { BackendSession, Subscription } from "@/backend/BackendSession";
import { CancelError } from "@/backend/CancelError";
import { EventInfo } from "@/backend/EventInfo";
import { addSticky, changeSticky } from "@/backend/changeSticky";
import {
  ServerEvent,
  ServerIterationSubsState,
  ServerLink,
  ServerObjective,
  ServerStickiesUpdate,
  ServerStickiesUpdateCacheHit,
  ServerStickiesUpdateCacheMiss,
  ServerSticky,
} from "@/backend/serverModel";
import { i18n } from "@/i18n";
import { relativeCoord } from "@/math/coordinates";
import { Board, IdMap, Shape, TimerEvent } from "@/model";
import { addBreadcrumb, captureMessage } from "@/sentry";
import { isBackendUserId, loadUser } from "@/services/user.service";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useCardStore } from "@/store/card";
import { useConnectionStore } from "@/store/connection";
import { linkId, useLinkStore } from "@/store/link";
import { useObjectiveStore } from "@/store/objective";
import { usePointerStore } from "@/store/pointer";
import { useSessionStore } from "@/store/session";
import { useTimerStore } from "@/store/timer";
import { useUsersOnBoardStore } from "@/store/usersOnBoard";

export function sessionSubscriptions(backendSess: BackendSession) {
  return [
    backendSess.sessionSubscribe("link.add", (args) => {
      const [link] = args ?? [];
      sendAddLink(link);
    }),
    backendSess.sessionSubscribe("link.remove", (args) => {
      const [id] = args ?? [];
      useLinkStore().remove(id);
    }),
    backendSess.almSubscribe("running_state.update", (args) => {
      const [status] = args ?? [];
      useConnectionStore().alm = status === "running";
      useSessionStore().setSessionAlmStatus(status);
    }),
    !backendSess.getAlmType()
      ? null
      : backendSess.almSubscribe("iteration_sync.update", (args) => {
          const [iterState] = args ?? [];
          setIterationStatus(iterState);
        }),
    backendSess.sessionSubscribe("timer.handle_event", (args) => {
      const [events] = args ?? [];
      setTimers(events);
    }),
    backendSess.boardSubscribe(
      "",
      "sticky.start_update",
      "session",
      (args, _, event) => {
        const [id, data] = args ?? [];
        const board = getBoardFromEvent(event);
        if (!board) {
          return;
        }
        useCardStore().readOnly({ readOnly: true, id, userId: event.userId });
        changeSticky(board, id, data);
      },
    ),
    backendSess.boardSubscribe(
      "",
      "sticky.update",
      "session",
      (args, _, event) => {
        const [id, data] = args ?? [];
        const board = getBoardFromEvent(event);
        if (!board) {
          return;
        }
        changeSticky(board, id, data);
      },
    ),
    backendSess.boardSubscribe(
      "",
      "sticky.stop_update",
      "session",
      (args, _, event) => {
        const [id, data] = args ?? [];
        const board = getBoardFromEvent(event);
        if (!board) {
          return;
        }
        useCardStore().readOnly({ readOnly: false, id });
        changeSticky(board, id, data);
      },
    ),
    backendSess.boardSubscribe(
      "",
      "sticky.add",
      "session",
      (args, _, event) => {
        const [data] = args ?? [];
        const board = getBoardFromEvent(event);
        if (!board) {
          return;
        }
        addSticky(board, { ...data, shouldAnimate: true });
      },
    ),
    backendSess.boardSubscribe(
      "",
      "sticky.remove",
      "session",
      (args, _, event) => {
        const [id] = args ?? [];
        useCardStore().delete({ id, boardId: event.boardId });
      },
    ),
    backendSess.boardSubscribe(
      "",
      "sticky.scale",
      "session",
      (args, _, event) => {
        const [factor] = args ?? [];
        useBoardStore().setCardSize({ boardId: event.boardId, factor });
      },
    ),
    backendSess.boardSubscribe(
      "",
      "sticky.bring_to_front",
      "session",
      (args, _, event) => {
        const [id] = args ?? [];
        useBoardStore().cardToFront({ id, boardId: event.boardId });
      },
    ),
    backendSess.boardSubscribe<[Shape]>(
      "",
      "shape.add",
      "session",
      ([shape], _, event) => useBoardStore().addShape(event.boardId, shape),
    ),
    backendSess.boardSubscribe<[Shape]>(
      "",
      "shape.edit",
      "session",
      ([shape], _, event) => useBoardStore().editShape(event.boardId, shape),
    ),
    backendSess.boardSubscribe<[string]>(
      "",
      "shape.remove",
      "session",
      ([id], _, event) => useBoardStore().removeShape(event.boardId, id),
    ),
  ];
}

export function createBoardSubscriptions(
  backendSess: BackendSession,
  board: Board,
) {
  type Subscriptions = Array<Subscription | Promise<Subscription>>;

  return Promise.all([
    ...boardSubscriptions(),
    ...teamBoardSubscriptions(),
    ...objectivesBoardSubscriptions(),
  ]);

  function boardSubscriptions(): Subscriptions {
    return [
      backendSess.boardSubscribe<[{ x: number; y: number }]>(
        board.id,
        "pointer.position",
        "board",
        (args, _, event) => {
          const [coord] = args ?? [];
          usePointerStore().set({
            boardId: board.id,
            id: event.userId,
            pos: relativeCoord(coord.x, coord.y),
          });
        },
      ),
      backendSess.boardSubscribe<[string]>(
        board.id,
        "subscribers.join",
        "board",
        async (args) => {
          const [userId] = args ?? [];
          await handleUserJoined(userId);
        },
      ),
      backendSess.boardSubscribe<[string]>(
        board.id,
        "subscribers.leave",
        "board",
        async (args) => {
          const [userId] = args ?? [];
          handleUserLeft(userId);
        },
      ),
    ];

    async function handleUserJoined(userId: string) {
      if (!isBackendUserId(userId)) {
        const user = useUsersOnBoardStore().findUserOnBoard(userId);
        if (user) {
          user.boardVisitTimestamp = Date.now();
        } else {
          const user = await loadUser({ id: userId });
          const boardVisitTimestamp = Date.now();
          useUsersOnBoardStore().addUserOnBoard({
            ...user,
            boardVisitTimestamp,
          });
        }
      }
    }

    function handleUserLeft(userId: string) {
      if (!isBackendUserId(userId)) {
        useUsersOnBoardStore().removeUserFromBoard(userId);
      }
    }
  }

  function objectivesBoardSubscriptions(): Subscriptions {
    if (board.type !== "objectives") {
      return [];
    }
    return Object.values(useBoardStore().boards)
      .filter(
        (board) =>
          useArtStore().isCurrentArt(board.artId) &&
          ["team", "program"].includes(board.type),
      )
      .flatMap((board) => objectivesSubscriptions(board));
  }

  function teamBoardSubscriptions(): Subscriptions {
    if (board.type !== "team") {
      return [];
    }
    return [
      ...objectivesSubscriptions(board),
      backendSess.boardSubscribe<[number, number]>(
        board.id,
        "capacity.update",
        "board",
        (args) => {
          const [iteration, velocity] = args ?? [];
          useBoardStore().setVelocity({
            id: board.id,
            iteration,
            velocity: +velocity,
          });
        },
      ),
    ];
  }

  function objectivesSubscriptions(board: Board): Subscriptions {
    const objectives = useObjectiveStore();

    return [
      backendSess.boardSubscribe<[ServerObjective]>(
        board.id,
        "objective.add",
        "board",
        (args) => {
          const [objective] = args ?? [];
          objectives.add({ boardId: board.id, ...mapObjective(objective) });
        },
      ),
      backendSess.boardSubscribe<[string]>(
        board.id,
        "objective.remove",
        "board",
        (args) => {
          const [id] = args ?? [];
          objectives.remove({ boardId: board.id, id });
        },
      ),
      backendSess.boardSubscribe<[string, ServerObjective]>(
        board.id,
        "objective.update",
        "board",
        (args) => {
          const [id, objective] = args ?? [];
          objectives.update({
            boardId: board.id,
            ...mapPartialObjective(objective),
            id,
          });
        },
      ),
      backendSess.boardSubscribe<[string, boolean, number]>(
        board.id,
        "objective.move",
        "board",
        (args) => {
          const [id, stretch, rank] = args ?? [];
          objectives.move({ boardId: board.id, id, stretch, rank });
        },
      ),
    ];
  }
}

export function loadStickies(
  backendSess: BackendSession,
  board: Board,
): Promise<number> {
  type ServerStickyId = string;
  type ParsedServerStickyUpdates = {
    time: number;
    changes: ServerSticky[];
    removes?: ServerStickyId[];
  };
  const time = Date.now();
  const since = board.loaded;
  return backendSess
    .getBoardStickies(board.id, since)
    .then((stickiesOrUpdates) => {
      // user logged out / switched session while the stickies where loaded
      if (!useBoardStore().boards[board.id]) {
        return Promise.reject(new CancelError("Sticky loading cancelled"));
      }
      const stickies: ParsedServerStickyUpdates =
        parseStickies(stickiesOrUpdates);
      const updated = updateStickies(stickies.changes);
      deleteStickies(updated, stickies.removes);
      return stickies.time;
    });

  function isCacheHitServerStickiesUpdate(
    serverStickies: ServerSticky[] | ServerStickiesUpdate,
  ): serverStickies is ServerStickiesUpdateCacheHit {
    return !Array.isArray(serverStickies) && "updates" in serverStickies;
  }

  function isCacheMissServerStickiesUpdate(
    serverStickies: ServerSticky[] | ServerStickiesUpdate,
  ): serverStickies is ServerStickiesUpdateCacheMiss {
    return !Array.isArray(serverStickies) && "stickies" in serverStickies;
  }

  function parseStickies(
    serverStickies: ServerSticky[] | ServerStickiesUpdate,
  ): ParsedServerStickyUpdates {
    if (isCacheMissServerStickiesUpdate(serverStickies)) {
      const { stickies } = serverStickies;
      const stickyIds = new Set(
        stickies.map((sticky: ServerSticky) => sticky._id),
      );
      const removes: ServerStickyId[] = Object.keys(board.cards).filter(
        (cardId) => !stickyIds.has(cardId),
      );

      return {
        time: serverStickies.timestamp,
        changes: stickies,
        removes,
      };
    }

    if (isCacheHitServerStickiesUpdate(serverStickies)) {
      const { updates } = serverStickies;
      const changes: ServerSticky[] = updates
        .filter((update) => update.event === "alter_sticky")
        .map((update) => update.obj);
      const removes: ServerStickyId[] = updates
        .filter((update) => update.event === "delete_sticky")
        .map((update) => update.obj._id);

      return {
        time: serverStickies.timestamp,
        changes,
        removes,
      };
    }

    return { time, changes: serverStickies };
  }

  function updateStickies(changes: ServerSticky[]): IdMap<boolean> {
    const ids: IdMap<boolean> = {};

    const operations = changes.map((serverSticky: ServerSticky) => {
      ids[serverSticky._id] = true;
      return useCardStore().cards[serverSticky._id]
        ? () => {
            useCardStore().readOnly({ id: serverSticky._id, readOnly: false });
            changeSticky(board, serverSticky._id, serverSticky);
          }
        : () => addSticky(board, serverSticky);
    });

    addBreadcrumb("render", {
      message: "Updating stickies on the board",
      data: { count: operations.length, type: board.type, boardId: board.id },
    });
    setTimeout(() => processOperations(operations), 0);
    return ids;
  }

  function processOperations(operations: Array<() => void>) {
    operations
      .slice(0, updateStickyBatchSize)
      .forEach((operation) => operation());
    if (operations.length > updateStickyBatchSize) {
      setTimeout(
        () =>
          nextTick(() =>
            processOperations(operations.slice(updateStickyBatchSize)),
          ),
        1,
      );
    }
  }

  function deleteStickies(updateIds: IdMap<boolean>, deleteIds?: string[]) {
    if (deleteIds) {
      deleteIds.forEach((id) => {
        useCardStore().delete({ id, boardId: board.id });
      });
    } else {
      for (const c in board.cards) {
        if (!updateIds[c]) {
          useCardStore().delete({ id: c, boardId: board.id });
        }
      }
    }
  }
}

// links are updated by subscription of link.add / link.remove
// this is not always working (why?), so we explicitly update the links with this function
export async function updateLinks(backendSess: BackendSession) {
  interface LinkStates {
    [id: string]: "orphan" | "valid" | "existing";
  }

  const links = await backendSess.getLinks();
  await loadStickiesForHalfCompleteLinks(links);
  const ids = linkIds(links);
  deleteLinks(ids);
  createLinks(links, ids);

  // stickies from other ARTs might not be loaded
  // if we have links to them, load them now
  async function loadStickiesForHalfCompleteLinks(links: ServerLink[]) {
    const notLoadedStickies = new Array<string>();
    links.forEach((link) => {
      const hasFrom =
        useLinkStore().cardsByLink(link.from_sticky_id).length > 0;
      const hasTo = useLinkStore().cardsByLink(link.to_sticky_id).length > 0;
      if (hasFrom !== hasTo) {
        notLoadedStickies.push(
          hasFrom ? link.to_sticky_id : link.from_sticky_id,
        );
      }
    });
    const stickies = await backendSess.getStickies(notLoadedStickies);
    stickies.forEach((sticky) => {
      const board = useBoardStore().boards[sticky.board_id];
      addSticky(board, sticky);
    });
  }

  function linkIds(ls: ServerLink[]) {
    const ids: LinkStates = {};
    ls.forEach((l) => {
      ids[l.id] =
        useLinkStore().cardsByLink(l.from_sticky_id).length > 0 &&
        useLinkStore().cardsByLink(l.to_sticky_id).length > 0
          ? "valid"
          : "orphan";
    });
    return ids;
  }

  function deleteLinks(ids: LinkStates) {
    for (const link of useLinkStore().links) {
      if (!ids[link.id]) {
        // useLinkStore().remove(link.id);
      } else if (ids[link.id] === "valid") {
        ids[link.id] = "existing";
      }
    }
  }

  function createLinks(ls: ServerLink[], ids: LinkStates) {
    ls.forEach((l) => {
      if (ids[l.id] === "valid") {
        sendAddLink(l);
      }
    });
  }
}

function setIterationStatus(iterState: ServerIterationSubsState) {
  for (const id in useBoardStore().boards) {
    const board = useBoardStore().boards[id];
    if (board.type === "team" && board.team.id === "" + iterState.team_id) {
      const status =
        iterState.status === "syncing"
          ? "syncing"
          : iterState.error
          ? "fail"
          : "success";
      useBoardStore().setIterationStatus({
        boardId: id,
        id: iterState.pi_iteration_id,
        status,
        detail: iterState.error
          ? i18n.global.t("iterationSync.failedSync", {
              errorMessage: iterState.error,
            })
          : "",
      });
      break;
    }
  }
}

export async function setTimers(events: ServerEvent[]) {
  const timers = mapEvents(events);
  await loadTimerUsers(timers);
  useTimerStore().timers = timers;
}

function loadTimerUsers(timers: TimerEvent[]) {
  return Promise.all(
    timers.map(async (timer) => {
      timer.updatedByUser = await loadUser({ id: timer.updatedById });
    }),
  );
}

function sendAddLink(link: ServerLink) {
  const fromCard = useLinkStore().cardsByLink(link.from_sticky_id)[0];
  const toCard = useLinkStore().cardsByLink(link.to_sticky_id)[0];
  if (fromCard && toCard) {
    useLinkStore().add({
      id: link.id,
      from: linkId(fromCard),
      to: linkId(toCard),
      type: link.link_type_id,
      state: "default",
    });
  } else if (!useBoardStore().boardsLoading) {
    // TODO debugging for REN-8846
    captureMessage("Sticky to be linked not found", {
      info: {
        link,
        fromCard: fromCard?.id,
        fromGroup: useLinkStore().linkGroups[link.from_sticky_id],
        toCard: toCard?.id,
        toGroup: useLinkStore().linkGroups[link.to_sticky_id],
        total: Object.keys(useCardStore().cards).length,
      },
    });
  }
}

function getBoardFromEvent(event: EventInfo) {
  return useBoardStore().boards[event.boardId];
}
