import BoardChange from "@/components/ActivityPanel/components/BoardChange.vue";
import FlagChange from "@/components/ActivityPanel/components/FlagChange.vue";
import IterationChange from "@/components/ActivityPanel/components/IterationChange.vue";
import LinkChange from "@/components/ActivityPanel/components/LinkChange.vue";
import PointsChange from "@/components/ActivityPanel/components/PointsChange.vue";
import PriorityChange from "@/components/ActivityPanel/components/PriorityChange.vue";
import ReactionsChange from "@/components/ActivityPanel/components/ReactionsChange.vue";
import RiskChange from "@/components/ActivityPanel/components/RiskChange.vue";
import TeamChange from "@/components/ActivityPanel/components/TeamChange.vue";
import TextChange from "@/components/ActivityPanel/components/TextChange.vue";
import TypeChange from "@/components/ActivityPanel/components/TypeChange.vue";
import { FieldChange } from "@/model";

const fieldChanges: {
  [name in FieldChange["name"]]: {
    key: string;
    keyNew: string;
    component: any;
  };
} = {
  text: {
    key: /*$t*/ "stickyChange.summary",
    keyNew: /*$t*/ "stickyChange.summary.new",
    component: TextChange,
  },
  type: {
    key: /*$t*/ "stickyChange.stickyType",
    keyNew: /*$t*/ "stickyChange.stickyType.new",
    component: TypeChange,
  },
  points: {
    key: /*$t*/ "stickyChange.storyPoints",
    keyNew: /*$t*/ "stickyChange.storyPoints.new",
    component: PointsChange,
  },
  priority: {
    key: /*$t*/ "stickyChange.wsjf",
    keyNew: /*$t*/ "stickyChange.wsjf.new",
    component: PriorityChange,
  },
  risk: {
    key: /*$t*/ "stickyChange.risk",
    keyNew: /*$t*/ "stickyChange.risk.new",
    component: RiskChange,
  },
  iterationId: {
    key: /*$t*/ "stickyChange.iteration",
    keyNew: /*$t*/ "stickyChange.iteration.new",
    component: IterationChange,
  },
  teamId: {
    key: /*$t*/ "stickyChange.team",
    keyNew: /*$t*/ "stickyChange.team.new",
    component: TeamChange,
  },
  reactions: {
    key: "",
    keyNew: "",
    component: ReactionsChange,
  },
  flagType: {
    key: /*$t*/ "stickyChange.flag",
    keyNew: /*$t*/ "stickyChange.flag.new",
    component: FlagChange,
  },
  dependTeamId: {
    key: /*$t*/ "stickyChange.dependsOn",
    keyNew: /*$t*/ "stickyChange.dependsOn.new",
    component: TeamChange,
  },
} as const;

const otherStickyChanges: {
  [name in FieldChange["name"]]: {
    key: string;
    keyNew: string;
    component: any;
  };
} = {
  //
  mirrorBoard: {
    key: "",
    keyNew: "",
    component: BoardChange,
  },
  link: {
    key: "",
    keyNew: "",
    component: LinkChange,
  },
} as const;

export const stickyChanges = {
  ...fieldChanges,
  ...otherStickyChanges,
};
