<script setup lang="ts">
import { Modifier, createPopper, detectOverflow } from "@popperjs/core";
import { computed, nextTick, provide, ref, watch } from "vue";

import BasePopup, {
  Props as BasePopupProps,
} from "@/components-ng/BasePopup/BasePopup.vue";

import { dropdownKey } from "./injectKeys";

type BaseProps = {
  offset?: { left?: number; top?: number };
  noFlip?: boolean; // avoid flipping the placement from bottom to top, but instead just move the contents to fit inside the window
  disableTeleport?: boolean;
  width?: BasePopupProps["width"];
  disabled?: boolean; // disables user interactions on the trigger
};

type WithExternalOpenState = {
  isOpen: boolean; // manage the open state from outside
  stayOnClick?: never; // don't close the dropdown when the user clicks on the content container
  stayOnOutsideClick?: never; // don't close the dropdown when the user clicks outside the trigger and content container
};

type WithInternalOpenState = {
  isOpen?: never;
  stayOnClick?: boolean;
  stayOnOutsideClick?: boolean;
};

export type PropsWithExternalOpenState = BaseProps & WithExternalOpenState;
export type PropsWithInternalOpenState = BaseProps & WithInternalOpenState;
export type Props = PropsWithExternalOpenState | PropsWithInternalOpenState;

const props = withDefaults(defineProps<Props>(), {
  isOpen: undefined,
  offset: () => ({ left: 0, top: 5 }),
  noFlip: false,
  stayOnClick: false,
  stayOnOutsideClick: false,
  disableTeleport: false,
  width: undefined,
  disabled: false,
});
const emit = defineEmits<{ closed: [] }>();

const isOpenInternal = ref(false);
const triggerRef = ref<HTMLElement>();
const contentRef = ref<HTMLElement>();

provide(dropdownKey, {
  close: () => (isOpenInternal.value = false),
});

const move: Modifier<"move", Record<string, never>> = {
  name: "move",
  enabled: true,
  phase: "main",
  requiresIfExists: ["offset"],
  fn({ state }) {
    const overflow = detectOverflow(state, {});
    if (overflow.bottom > 0 && state.modifiersData.popperOffsets) {
      state.modifiersData.popperOffsets.y -= overflow.bottom;
    }
  },
};

const computedOpenState = computed(() => {
  return props.isOpen ?? isOpenInternal.value;
});

const handleKeyUp = (event: KeyboardEvent) => {
  if (isStateManagedExternally.value) return;

  if (event.key === "Escape") {
    document.removeEventListener("keyup", handleKeyUp);
    isOpenInternal.value = false;
  }
};

// only triggered when the component manges its own open state
const handleOutsideClick = (event: MouseEvent) => {
  const target = event.target as HTMLElement;

  if (
    !triggerRef.value?.contains(target) &&
    !contentRef.value?.contains(target)
  ) {
    document.removeEventListener("click", handleOutsideClick, true);
    isOpenInternal.value = false;
  }
};

const handleTriggerClick = () => {
  if (isStateManagedExternally.value) return;

  isOpenInternal.value = !isOpenInternal.value;
};

const handleContentClick = () => {
  if (isStateManagedExternally.value) return;

  if (!props.stayOnClick) {
    isOpenInternal.value = false;
  }
};

watch(isOpenInternal, () => {
  if (!isOpenInternal.value) {
    emit("closed");
  }
});

const isStateManagedExternally = computed(() => props.isOpen !== undefined);

// create a popper when the dropdown is opened
watch(computedOpenState, async (state) => {
  if (!state) return;
  await nextTick();

  if (!props.stayOnOutsideClick) {
    document.addEventListener("pointerdown", handleOutsideClick, true);
  }
  document.addEventListener("keyup", handleKeyUp);

  createPopper(
    triggerRef.value?.firstElementChild as HTMLElement,
    contentRef.value?.firstElementChild as HTMLElement,
    {
      placement: "bottom-start",
      modifiers: [
        {
          name: "offset",
          options: { offset: [props.offset.left, props.offset.top] },
        },
        ...(props.noFlip ? [{ name: "flip", enabled: false }, move] : []),
      ],
    },
  );
});
</script>

<template>
  <div class="dropdown-menu">
    <div
      ref="triggerRef"
      :class="['trigger', { disabled: props.disabled }]"
      @click="handleTriggerClick"
    >
      <slot name="trigger" :is-open="isOpenInternal" />
    </div>
    <Teleport v-if="computedOpenState" :disabled="disableTeleport" to="body">
      <div ref="contentRef" @click="handleContentClick">
        <BasePopup :width="width" class="content" :class="$attrs.class">
          <slot />
        </BasePopup>
      </div>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/z-index";

.dropdown-menu {
  display: inline-block;

  .trigger {
    cursor: pointer;
    position: relative;
  }

  .trigger.disabled {
    cursor: unset;
    pointer-events: none;
  }
}

.content {
  z-index: z-index.$popup-menu;

  // TODO: remove this when the list-item component is deleted
  &:deep(.list-item) {
    border-radius: 4px;
  }
}
</style>
