<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import { StatusClass, statusClassNames, statusClasses } from "@/baseTypes";
import { statusClassColors } from "@/colors";
import BaseList from "@/components-ng/BaseList/BaseList.vue";
import BaseListItem from "@/components-ng/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListItems from "@/components-ng/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components-ng/BaseList/components/BaseListTitle/BaseListTitle.vue";
import StatusDot from "@/components/StatusDot.vue";
import { boardKey, cardKey } from "@/components/card/injectKeys";
import { injectStrict } from "@/utils/context";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const statuses = computed(() => {
  return statusClasses
    .map((statusClass) => ({
      id: statusClass,
      name: statusClassNames[statusClass],
      color: statusClassColors[statusClass],
    }))
    .filter((status) => status.id !== "undefined");
});

const handleClick = (statusClass: StatusClass) => {
  const target = card?.status?.next.find(
    (targetStatus) => targetStatus.status.statusClass === statusClass,
  );

  if (!target) return;

  cardActions.setStatus(
    "card",
    board,
    card.id,
    target.status.name,
    target.transition.id,
  );
};
</script>

<template>
  <div class="sticky-note-status">
    <AttributeChip
      :dropdown-props="{ width: 'sm' }"
      text-placeholder="0"
      :text="card.status?.name"
    >
      <template #icon>
        <span class="status-dot">
          <StatusDot :status-class="card.status?.statusClass || 'undefined'" />
        </span>
      </template>
      <BaseList>
        <BaseListTitle>{{ $t("general.statusClasses") }}</BaseListTitle>
        <BaseListItems>
          <BaseListItem
            v-for="status in statuses"
            :key="status.id"
            @click="() => handleClick(status.id)"
          >
            <template #before>
              <span
                class="status-icon"
                :style="{ backgroundColor: status.color }"
              ></span>
            </template>
            {{ status.name }}
          </BaseListItem>
        </BaseListItems>
      </BaseList>
    </AttributeChip>
  </div>
</template>

<style lang="scss" scoped>
@use "../../../../utils";

.status-dot {
  height: utils.px-to-em(10px);
  width: utils.px-to-em(10px);
}

.status-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
</style>
