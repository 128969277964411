<script lang="ts" setup></script>

<template>
  <div class="sticky-note-row">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use "../../utils";

.sticky-note-row {
  display: flex;
  align-items: center;
  gap: utils.px-to-em(6px);
  height: utils.px-to-em(20px);
  box-sizing: content-box;

  > * {
    flex-shrink: 0;
  }
}
</style>
