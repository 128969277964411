<script setup lang="ts">
import { computed } from "vue";

import { BarChartData } from "@/components/StackedBarChart";

const props = defineProps<{ value: BarChartData<any, any> }>();

const isEmpty = computed(() => props.value.total === 0);
</script>

<template>
  <div class="stacked-bar-chart">
    <template v-if="!isEmpty">
      <div
        v-for="(item, index) in value.items"
        :key="index"
        class="progress-bar bordered"
        :style="{ backgroundColor: item.color, width: item.width + '%' }"
      />
    </template>
    <svg
      v-else
      class="empty-bar bordered"
      viewBox="0 0 500 40"
      preserveAspectRatio="none"
    >
      <defs>
        <pattern
          id="diagonalHatch"
          patternUnits="userSpaceOnUse"
          width="4"
          height="8"
          patternTransform="rotate(-45 2 2)"
        >
          <path d="M -1,2 l 6,0" stroke-width=".5" />
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill="url(#diagonalHatch)" />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.stacked-bar-chart {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;

  .bordered {
    border-radius: board.len(20px);
    border: board.len(2px) solid colors-old.$progress-border-color;
  }

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  svg.empty-bar {
    position: absolute;
    width: 100%;
    height: 100%;

    path {
      stroke: colors-old.$progress-empty-stroke-color;
    }
  }
}
</style>
