<template>
  <div
    class="objective-card"
    :data-testid="`objective-card-${objective.id}`"
    @mouseenter="setIsFocused(true)"
    @mouseleave="setIsFocused(false)"
  >
    <div
      class="objective-inner-container"
      :class="{ focused: isFocused && !isDragging, dragging: isDragging }"
    >
      <div class="objective-title">
        <div v-if="isFocused && !isDragging" class="objective-drag-icon">
          <SvgIcon name="arrow/up-down" size="20" />
        </div>
        <div v-else class="objective-index">{{ index }}</div>
        <max-length-textarea
          spellcheck="false"
          :placeholder="$t('objectives.title.placeholder')"
          :model-value="objective.text"
          :maxlength="260"
          bordered
          @update:model-value="setTitle"
        />
        <DropdownMenu v-if="isGPTAvailable">
          <template #trigger>
            <IconButton icon="vertical-more" />
          </template>
          <ListItem @click="openGPTPanel">
            {{ $t("action.showGPT") }}
          </ListItem>
        </DropdownMenu>
        <DropdownMenu>
          <template #trigger>
            <IconButton icon="vertical-more" />
          </template>
          <ListItem v-if="type === 'committed'" @click="markAsUncommitted">
            <span>
              <SvgIcon name="uncommitted" size="20" />
              <span>{{ $t("objective.markAsUncommitted") }}</span>
            </span>
          </ListItem>
          <ListItem v-else-if="type === 'uncommitted'" @click="markAsCommitted">
            <span>
              <SvgIcon name="committed" size="20" />
              <span> {{ $t("objective.markAsCommitted") }}</span>
            </span>
          </ListItem>
          <ListItem @click="remove()">
            <span>
              <SvgIcon name="action-menu/delete" size="20" />
              <span>{{ $t("general.delete") }}</span>
            </span>
          </ListItem>
        </DropdownMenu>
      </div>
      <div class="objective-description">
        <expandable-textarea
          :expand-description="expandDescriptions"
          :model-value="objective.description"
          spellcheck="false"
          @update:model-value="setDescription"
        />
      </div>

      <div class="objective-details">
        <div class="objective-values">
          <div class="objective-value-title">
            {{ $t("objectives.businessValue") }}
          </div>
          <div
            class="value-container"
            :class="{ focused: showBusinessValueSelector }"
            @click.stop="showBusinessValueSelector = !showBusinessValueSelector"
          >
            <span class="value">{{ objective.bv }}</span>
            <points-select
              :show="showBusinessValueSelector"
              :current="objective.bv"
              @input="setBusinessValue"
            />
          </div>
          <div class="objective-value-title actual-value">
            {{ $t("objectives.actualValue") }}
          </div>
          <div
            class="value-container"
            :class="{ focused: showActualValueSelector }"
            @click.stop="showActualValueSelector = !showActualValueSelector"
          >
            <span class="value">{{ actualValue }}</span>
            <points-select
              :show="showActualValueSelector"
              :current="objective.av"
              @input="setActualValue"
            />
          </div>
        </div>
      </div>
      <div class="objective-expanded-content">
        <div class="objective-content-header">
          <div
            class="linked-stickies-count"
            @click.stop="toggleExpandLinkedStickies"
          >
            <SvgIcon name="link" size="20" />
            <span style="margin-left: 5px">
              {{ $t("objectivesModal.linkedStickiesNumber", linkCount) }}
            </span>
            <SvgIcon
              v-if="linkedStickies.length > 0"
              name="arrow/up"
              size="20"
              class="arrow-icon"
              :class="{ expanded: isLinkedStickiesExpanded }"
            />
          </div>

          <status-distribution
            v-if="hasStatusDistribution"
            :value="getStatusDistribution()"
            source-item-type="objective"
          />
        </div>

        <RevealHeightTransition :wipe="true">
          <div
            v-if="isLinkedStickiesExpanded && isExpendable"
            class="reveal-container"
          >
            <div class="linked-stickies-container">
              <div
                v-for="sticky in linkedStickies"
                :id="'sticky-' + sticky.id"
                :key="sticky.id"
                :class="{ current: isStickyHighlighted(sticky.id) }"
                @mouseenter.stop="setHighlightedSticky(sticky.id)"
                @mouseleave.stop="setHighlightedSticky(null)"
              >
                <card-tree
                  :linked-card-ids="getLinkedCardIds(sticky.id)"
                  :card="sticky"
                  :board="board"
                  show-status
                >
                  <linkable-card-row
                    :linkable-card="sticky"
                    reset-button
                    show-status
                    show-actions
                    @set-linked-state="removeLink(sticky)"
                    @click.stop
                  />
                </card-tree>
              </div>
            </div>
          </div>
        </RevealHeightTransition>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { linkActions } from "@/action/linkActions";
import { objectiveActions } from "@/action/objectiveActions";
import { toggleActions } from "@/action/toggleActions";
import RevealHeightTransition from "@/components-ng/transitions/RevealHeightTransition.vue";
import CardTree from "@/components/CardTree.vue";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import LinkableCardRow from "@/components/LinkableCardRow.vue";
import ListItem from "@/components/ListItem/ListItem.vue";
import { objectiveStatusDistribution } from "@/components/StatusDistribution";
import StatusDistribution from "@/components/StatusDistribution.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import ExpandableTextarea from "@/components/input/ExpandableTextarea.vue";
import MaxLengthTextarea from "@/components/input/MaxLengthTextarea.vue";
import PointsSelect from "@/components/input/PointsSelect.vue";
import { LinkableCard } from "@/components/modal/stickyLinkStickies";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { isFeatureEnabled } from "@/feature";
import { Board, BoardWithObjectives, Card, Objective } from "@/model";
import { useBoardStore } from "@/store/board";
import { useGPTStore } from "@/store/gpt";
import { useLinkStore } from "@/store/link";
import {
  objectiveActualValueSet,
  objectiveBusinessValueSet,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { hasItemInSets } from "@/utils/general";

type ObjectiveType = "committed" | "uncommitted";

@Options({
  components: {
    SvgIcon,
    IconButton,
    StatusDistribution,
    PointsSelect,
    DropdownMenu,
    ListItem,
    LinkableCardRow,
    CardTree,
    MaxLengthTextarea,
    ExpandableTextarea,
    RevealHeightTransition,
  },
})
export default class ObjectiveCard extends Vue {
  @Prop({ type: Object, required: true }) readonly objective!: Objective;
  @Prop({ type: String, required: true }) readonly type!: ObjectiveType;
  @Prop({ type: Number, required: true }) readonly index!: number;
  @Prop({ type: Object, required: true }) readonly board!: BoardWithObjectives;
  @Prop({ type: Boolean, default: false }) readonly isDragging!: boolean;
  @Prop({ type: Boolean, default: false })
  readonly expandDescriptions!: boolean;
  @Prop({ type: Boolean, default: true })
  readonly isExpendable!: boolean;
  @Prop({ type: Boolean, default: true })
  readonly hasStatusDistribution!: boolean;

  highlightedStickyId: null | string = null;
  isFocused = false;
  isLinkedStickiesExpanded = false;
  showActualValueSelector = false;
  showBusinessValueSelector = false;

  get teamId() {
    return this.board.type === "team" ? this.board.team.id : undefined;
  }

  get teamObjectiveBacklogLinks() {
    return useLinkStore().teamObjectiveBacklogLinks(this.objective.id, {
      teamId: this.teamId,
    });
  }

  @Watch("isDragging")
  resetIsFocused() {
    this.setIsFocused(false);
  }

  getLinkedCardIds(cardId: Card["id"]) {
    return this.teamObjectiveBacklogLinks.has(cardId)
      ? this.teamObjectiveBacklogLinks.get(cardId)
      : new Set();
  }

  isStickyHighlighted(stickyId: string) {
    return !!this.highlightedStickyId && stickyId === this.highlightedStickyId;
  }

  setHighlightedSticky(stickyId: string | null) {
    this.highlightedStickyId = stickyId;
  }

  setTitle(title: string) {
    this.objective.text = title;
    objectiveActions.setTitle("mainMenu", this.board.id, this.objective);
  }

  setDescription(description: string) {
    this.objective.description = description;
    objectiveActions.setDescription("mainMenu", this.board.id, this.objective);
  }

  setIsFocused(isFocused: boolean) {
    this.isFocused = isFocused;
  }

  setActualValue(value?: number) {
    this.showActualValueSelector = false;
    if (value !== undefined) {
      trackEvent(
        objectiveActualValueSet({
          hadPreviousValue: this.objective.bv > 0,
          hasNewValue: value !== undefined,
        }),
      );

      this.objective.av = value;
      objectiveActions.setAv("mainMenu", this.board.id, this.objective);
    }
  }

  setBusinessValue(value?: number) {
    this.showBusinessValueSelector = false;
    if (value !== undefined) {
      trackEvent(
        objectiveBusinessValueSet({
          hadPreviousValue: this.objective.bv > 0,
          hasNewValue: value !== undefined,
          location: "objectives-modal",
        }),
      );

      this.objective.bv = value;
      objectiveActions.setBv("mainMenu", this.board.id, this.objective);
    }
  }

  toggleExpandLinkedStickies() {
    this.isLinkedStickiesExpanded = !this.isLinkedStickiesExpanded;
  }

  getStatusDistribution() {
    return objectiveStatusDistribution(
      this.teamObjectiveBacklogLinks,
      this.objective,
    );
  }

  markAsUncommitted() {
    objectiveActions.move("mainMenu", this.board.id, this.objective.id, {
      stretch: true,
      rank: this.board.stretchObjectives.length,
    });
  }

  markAsCommitted() {
    objectiveActions.move("mainMenu", this.board.id, this.objective.id, {
      stretch: false,
      rank: this.board.objectives.length,
    });
  }

  openGPTPanel() {
    useGPTStore().objective = this.objective;
    toggleActions.showGPT("mainMenu");
  }

  get isGPTAvailable() {
    return isFeatureEnabled(this.$route, "gpt");
  }

  get title() {
    return this.objective.text;
  }

  get actualValue() {
    return this.objective.av ? this.objective.av : 0;
  }

  get linkCount(): number {
    return useLinkStore().teamObjectiveLinkCount(this.objective.id, {
      teamId: this.teamId,
    });
  }

  remove() {
    objectiveActions.remove("mainMenu", this.board.id, this.objective.id);
  }

  get stickies(): Board["cards"] {
    return useBoardStore().teamVisibleStickies(this.teamId);
  }

  get linkedStickies(): LinkableCard[] {
    return this.objective.cards
      .filter(
        ({ isOrigin, id }) =>
          isOrigin &&
          !!this.stickies[id] &&
          !hasItemInSets(id, this.teamObjectiveBacklogLinks),
      )
      .map(({ id }) => {
        const boardSticky = this.stickies[id];
        return { ...boardSticky.data, linked: true };
      });
  }

  async removeLink(sticky: LinkableCard) {
    await linkActions.removeObjective(
      "board",
      sticky.id,
      this.board.id,
      this.objective.id,
    );
  }
}
</script>
<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/utils";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.objective-card {
  margin-bottom: 12px;
  background-color: colors-old.$back-color;
  border-radius: variables.$objective-card-border-radius;

  .objective-title {
    display: flex;
    margin-bottom: 4px;
    align-items: center;

    base-textarea {
      width: auto;
    }

    .objective-index {
      font-size: font.$size-normal;
      font-weight: font.$weight-semi-bold;
      margin-right: 4px;
    }

    .objective-drag-icon {
      margin: 0 0 0 -8px;
    }
  }

  .objective-inner-container {
    border: 1px solid colors-old.$objective-border-color;
    padding: 10px 16px 16px;
    border-radius: variables.$objective-card-border-radius;

    &.focused {
      border: 1px solid colors-old.$objective-border-color-hovered;
      cursor: pointer;
    }

    &.dragging {
      cursor: grabbing;
    }
  }

  .objective-details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .objective-description {
    padding-left: 0.8125rem;
    margin-bottom: 0.75rem;

    :deep(.description-textarea) {
      position: static;
      font-weight: font.$weight-normal;
      color: colors-old.$text-primary-color;
    }
  }

  .objective-values {
    display: flex;
    align-items: center;
    padding-left: 21px;

    .objective-value-title {
      font-size: font.$size-small;
      font-weight: font.$weight-normal;
      margin-right: 8px;

      &.actual-value {
        margin-left: 20px;
      }
    }

    .value-container {
      position: relative;
      border: 1px solid colors-old.$objective-border-color;
      border-radius: 6px;
      width: 28px;
      height: 28px;
      line-height: 28px;
      display: flex;
      justify-content: center;
      text-align: center;

      &:hover {
        background-color: colors-old.$background-hover-menu-color;
      }

      .value {
        font-size: font.$size-small;
      }

      &.focused {
        border: 1px solid colors-old.$menu-item-focus-color;
      }

      .points-select {
        top: 100%;
        left: 0;
        margin-top: 2px;
      }
    }
  }

  .list-item > * {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .objective-expanded-content {
    border-top: 1px solid colors-old.$objective-border-color;
    margin: 16px 21px 0;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 200px;

    .objective-content-header {
      display: flex;
      justify-content: space-between;

      .linked-stickies-count {
        font-size: font.$size-small;
        color: colors-old.$text-secondary-color;
        font-weight: font.$weight-semi-bold;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .arrow-icon {
          transform: rotate(180deg);
          transition: transform 0.4s ease;

          &.expanded {
            transform: none;
          }
        }
      }

      .status-distribution {
        height: 1rem;
        width: 30%;
      }
    }
  }

  .objective-content {
    @include utils.hide-scrollbar;
  }

  &.objective-drag-state > .objective-inner-container {
    visibility: hidden;
  }

  .linked-stickies-container {
    max-height: 160px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
</style>

<style lang="scss">
.objective-card {
  .status-distribution .bordered {
    border-width: 2px;
  }
}
</style>
