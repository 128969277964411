import "the-new-css-reset/css/reset.css";
import { createApp } from "vue";
import VueGtag from "vue-gtag";

import { registerGlobalActions } from "@/./globalActions";
import Base from "@/components/Base.vue";
import { showHelp } from "@/console";
import { registerCopyPaste } from "@/copyPaste";
import { focusTrap } from "@/directives/focusTrap";
import { scrollableOnBoard } from "@/directives/scrollableOnBoard";
import { handleError } from "@/errorHandler";
import { i18n, setLanguage } from "@/i18n";
import { goToLogout } from "@/router/navigation";
import { router } from "@/router/router";
import { captureException, initSentry } from "@/sentry";
import { setUnauthorizedHandler } from "@/services/api.config";
import { useBoardStore } from "@/store/board";
import { useCardStore } from "@/store/card";
import { useClientSettingsStore } from "@/store/clientSettings";
import pinia from "@/store/pinia";
import { useStatisticsStore } from "@/store/statistics";

import { setAmplitudeUserProperty } from "./utils/analytics/track";

const app = createApp(Base).use(router).use(pinia).use(i18n).use(VueGtag, {
  bootstrap: false,
  disableScriptLoad: true,
});

initSentry(app)
  .then(() => {
    window.onunhandledrejection = (event) => {
      event.preventDefault();
      handleError(event.reason);
    };
    window.onerror = (msg, url, line, col, error) => {
      captureException(error);
      return true;
    };
  })
  .finally(() => {
    app.directive("focus-trap", focusTrap());
    app.directive("scrollable-on-board", scrollableOnBoard());

    app.mount("#app").$watch("$route.query.lang", (lang) => {
      setLanguage(lang);
      setAmplitudeUserProperty("language", lang);
    });
  });

useClientSettingsStore().init();

const win = window as any;
win.stats = () => simpleObject(useStatisticsStore().current);
win.allStats = () => simpleObject(useStatisticsStore().statistics);

win.baseSize = () => {
  const child = window.open(location.href, "", "resizable=1")!;
  child.addEventListener("load", () => {
    const docElem = child.document.documentElement;
    const dw = 1920 - docElem.clientWidth;
    const dh = 1080 - docElem.clientHeight;
    child.resizeBy(dw, dh);
  });
};

if (win.Cypress) {
  win.state = {
    stores: () => ({ boardStore: useBoardStore(), cardStore: useCardStore() }),
  };
}
// real Intercom will be inited after login if enabled
// dummy init here, so we can just call Intercom without always checking if it's available or not
win.Intercom = () => {};

showHelp();
registerGlobalActions();
registerCopyPaste();
setUnauthorizedHandler(async () => {
  await goToLogout();
});

function simpleObject(obj: object) {
  return JSON.parse(JSON.stringify(obj));
}
