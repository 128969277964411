<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { cardActions } from "@/action/cardActions";
import UserAvatar from "@/components/UserAvatar.vue";
import ReactionIcon from "@/components/card/ReactionIcon.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import { BoardUser, Reaction } from "@/model";
import { useCardStore } from "@/store/card";
import { animateCopy } from "@/utils/animation";

const props = defineProps<{
  users: BoardUser[];
  type: Reaction;
  cardId: string;
  isDarkColor: boolean;
  readOnly?: boolean;
}>();

const iconRef = ref<ReactionIcon>();

const hasCurrentUserReaction = computed(() =>
  useCardStore().hasCurrentUserReaction(props.cardId, props.type),
);

function toggleReaction() {
  if (!props.readOnly) {
    cardActions.toggleReaction("card", props.cardId, props.type);
  }
}

watch(
  () => props.users,
  (newUsers, oldUsers) => {
    if (newUsers.length > oldUsers.length) {
      for (let i = 0; i < 5; i++) {
        window.setTimeout(
          () =>
            animateCopy(
              iconRef.value!.$el,
              "floating",
              (el) => (el.style.left = Math.random() * 1000 - 500 + "px"),
            ),
          Math.random() * 150,
        );
      }
    }
  },
);
</script>

<template>
  <BaseTooltip v-show="users.length > 0" position="bottom" interactive light>
    <div
      class="active-reaction"
      :class="{ active: hasCurrentUserReaction, 'is-dark-color': isDarkColor }"
      @click.stop="toggleReaction"
    >
      <ReactionIcon ref="iconRef" :reaction="type" />
      <span>{{ users.length }}</span>
    </div>
    <template #content>
      <div class="users-list">
        <i18n-t keypath="reaction.with" scope="global" tag="h3">
          <template #reaction>
            <reaction-icon :reaction="type" />
          </template>
        </i18n-t>
        <ul class="scrollable scrollable-relative">
          <li v-for="user in users" :key="user.id">
            <UserAvatar user-color :user="user" class="small" />
            {{ user.name || $t("boardUsers.unknown") }}
          </li>
        </ul>
      </div>
    </template>
  </BaseTooltip>
</template>

<style lang="scss" scoped>
@use "@/styles/tooltip-users-list";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

@keyframes float {
  100% {
    transform: translateX(board.len(100px));
  }
}

.active-reaction {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid transparent;
  border-radius: board.len(35px);
  padding: board.len(10px);
  cursor: pointer;
  background-color: colors-old.$light-reaction-color;
  height: board.len(65px);

  &:hover {
    background-color: colors-old.$light-hover-reaction;
  }

  &.active {
    border-color: colors-old.$light-active-reaction-border-color;
    background-color: colors-old.$light-reaction-color;

    &:hover {
      border-color: colors-old.$light-active-hover-reaction;
      background-color: colors-old.$light-hover-reaction;
    }
  }

  span {
    color: colors-old.$text-primary-color;
    margin-left: board.len(50px);
  }

  &.is-dark-color {
    background-color: colors-old.$dark-reaction-color;

    &:hover {
      background-color: colors-old.$dark-hover-reaction;
    }

    &.active {
      border-color: colors-old.$dark-active-reaction-border-color;
      background-color: colors-old.$dark-reaction-color;

      &:hover {
        border-color: colors-old.$dark-active-hover-reaction;
        background-color: colors-old.$dark-hover-reaction;
      }
    }

    span {
      color: colors-old.$back-color;
    }
  }

  img {
    position: absolute;
    top: board.len(12px);
    left: board.len(12px);
  }

  .floating {
    animation: float 0.6s -0.3s alternate infinite ease-in-out;
    transform: translateX(board.len(-50px));
    transition-property: top, left, opacity;
    transition-duration: 2s;
    transition-timing-function: linear;
    top: board.len(-1000px) !important;
    opacity: 0;
  }
}
</style>
