import { Counter } from "@/Counter";
import {
  AlmSourceId,
  BoardType,
  Color,
  Functionality,
  RiskType,
  StatusClass,
} from "@/baseTypes";
import CardFlag from "@/components/card/CardFlag";
import { Line, RelativeCoordinate, WindowCoordinate } from "@/math/coordinates";

import { Mode } from "./components/card/actions";
import { Icon } from "./types/icon";

export interface Id {
  id: string;
}

export interface BoardId {
  boardId: string;
}

export interface Tenant {
  id: string;
  name: string;
}

export interface SelectList<T> {
  list: T[];
  /** the item selected by the user */
  selected: T | null;
  /** the item that is effectively loaded */
  current: T;
}

export function emptySelectList<T>(): SelectList<T> {
  return {
    list: [],
    current: { id: "", name: "", startDate: null, archived: false } as T,
    selected: null,
  };
}

export type IdMap<T> = { [id: string]: T };

export interface State {
  cards: IdMap<Card>;
}

export interface License {
  daysTillExpiration: number | null;
  usable: boolean;
  plan: "trial" | "standard" | "premium" | "enterprise";
  tracking: boolean;
}

export interface Pointer {
  user: AuthUser;
  relativePos: RelativeCoordinate;
  windowPos: Array<{ pos: WindowCoordinate; index: number }>;
  timestamp: number;
}

export interface PersonalUser {
  id: string;
  name: string;
  email: string;
  imageUrl?: string;
  color: string;
  preferredLanguage: string;
  hash?: string;
}

export interface BackendUser extends PersonalUser {
  id: "backend";
  iconName: Icon; // Used for ALM "users" (eg. the 'jira' icon)
}

export type AuthUser = PersonalUser | BackendUser;

export type BoardUser = AuthUser & {
  boardVisitTimestamp: number;
};

export interface User {
  id: string;
  company: string;
  role: Role;
}

const roles = ["observer", "user", "admin", "planning_interval_admin"] as const;

export type Role = (typeof roles)[number];

export function asRole(s: string): Role {
  const r = s as Role;
  return roles.includes(r) ? r : "observer";
}

export interface Statistics {
  calls: Counter;
  publishes: Counter;
  sends: Counter;
  receives: Counter;
  subscriptions: number;
  reconnects: number;
}

export interface Size {
  top: number;
  left: number;
  height: number;
  width: number;
}

export interface Border {
  top: number;
  left: number;
  bottom: number;
  right: number;
}

export interface AppSize extends Size {
  zoom: number;
  padding: Border;
  margin: Border;
}

export interface Iteration {
  id: number;
  name: string;
  start: Date;
  end: Date;
}

export interface IterationState {
  status: IterationStatus | null;
  detail: string | null;
}

export type IterationStatus = "syncing" | "synced" | "success" | "fail";

export interface Link {
  id: string;
  from: string;
  to: string;
  type?: string;
  state: InfoLevel;
}

export interface LinkType {
  id: string;
  from: string;
  to: string;
}

export interface Linking {
  from: Card | null;
  to: LinkingTarget | null;
}

export interface LinkingTarget {
  id: Card["id"] | Objective["id"];
  type: "sticky" | "objective";
}

export interface Priority {
  value: number;
  text: string;
}

export interface StickyType {
  id: string;
  name: string;
  almType: string | null;
  color: Color;
  altColor: Color;
  priorities?: { [key: number]: Priority };
  functionality: Functionality;
  origin: BoardType; // the board type where the card was initially created
  flexOrigin: string | null; // the flex-board/colaboration-canvas where the card was initially created
  usable: BoardType[]; // where the sticky note can be mirrored too
  flexUsable: string[];
}

export type AlmItemTypeMap = {
  [boardKey: string]: {
    [sourceId: string]: AlmItemType;
  };
};

export interface AlmType {
  id: string;
  isMapped: boolean;
}

export interface AlmItemType {
  statuses: AlmItemStatus[];
  /** statuses that can be reached from every other status */
  globalTargetStatuses: TargetStatus[];
  /** statuses and transactions must be fetched on the fly for concrete stickies */
  dynamic: boolean;
}

export interface Status {
  id: string;
  name: string;
  statusClass: StatusClass;
  order: number;
}

export interface AlmItemStatus extends Status {
  initial: boolean;
  next: TargetStatus[];
  /** for jira sync we don't know next state, because it is calculated in fly (dynamic) */
  dynamic: boolean;
}

export interface TargetStatus {
  transition: { id: string; name: string };
  status: Status;
  fields: TransitionField[];
}

export interface TransitionField {
  id: string;
  name: string;
  required: boolean;
  type: string;
  itemType?: string;
  values?: TransitionFieldValue[];
}

export interface TransitionFieldValue {
  id: string;
  name: string;
}

interface BaseBoardData {
  id: string;
  type: BoardType;
  stickyTypes: StickyType[];
  almSources: AlmSource[];
  cardSize: RelativeCoordinate & { factor: number };
  cards: IdMap<BoardCard>;
  maxZ: number;
  loaded: number;
  artId?: string;
  userHeartbeatId?: number;
  selected: IdMap<boolean>;
  shapes: Shape[];
}

export interface Shape extends Line<RelativeCoordinate> {
  id: string;
  type: ShapeType;
  fixed: boolean;
}

export type ShapeType = "line";

export function fixLine(
  id: string,
  p0: RelativeCoordinate,
  p1: RelativeCoordinate,
): Shape {
  return { id, type: "line", p0, p1, fixed: true };
}

export type BoardData<T extends BoardType> = BaseBoardData &
  {
    backlog: { type: "backlog" };
    program: {
      type: "program";
      objectives: Objective[];
      stretchObjectives: Objective[];
    };
    solution_backlog: { type: "solution_backlog" };
    solution: { type: "solution" };
    risk: { type: "risk" };
    team: {
      type: "team";
      iterations: BoardIteration[];
      team: Team;
      objectives: Objective[];
      stretchObjectives: Objective[];
    };
    flex: {
      type: "flex";
      flexType: FlexType;
      name: string;
    };
    objectives: { type: "objectives" };
  }[T];

export type Board =
  | BoardData<"backlog">
  | BoardData<"program">
  | BoardData<"solution_backlog">
  | BoardData<"solution">
  | BoardData<"risk">
  | BoardData<"team">
  | BoardData<"flex">
  | BoardData<"objectives">;

export const mirrorableBoards = [
  "program",
  "solution",
  "risk",
  "team",
] as const;
export type MirrorableBoard = BoardData<(typeof mirrorableBoards)[number]>;

export type BoardWithObjectives = BoardData<"program"> | BoardData<"team">;

export function isBacklogBoard(type?: BoardType) {
  return type === "backlog" || type === "solution_backlog";
}

export function isPlanningBoard(type?: BoardType) {
  return type === "program" || type === "solution";
}

export function isSolutionBoard(type?: BoardType) {
  return type === "solution" || type === "solution_backlog";
}

export function isTeamBoard(type?: BoardType) {
  return type === "team";
}

/**
 * A frontend board is a board that exists only in the frontend,
 * i.e. no database representation exists for it.
 */
export function isFrontendBoard(type?: BoardType) {
  return type === "objectives";
}

export function boardTypeOrder(type: BoardType) {
  return {
    solution_backlog: 0,
    solution: 1,
    backlog: 2,
    program: 3,
    objectives: 4,
    risk: 5,
    team: 6,
    flex: 7,
  }[type];
}

export interface BoardCard {
  data: Card;
  meta: CardMeta;
}

export type DependencyTeamFilter =
  | "IncomingAndOutgoing"
  | "Incoming"
  | "Outgoing"
  | "Mutual";

export interface DependencyTeamItem {
  id: DependencyTeamFilter;
  name: string;
}

export interface CardMeta {
  pos: RelativeCoordinate;
  zIndex: number;
  mark: MarkMode;
  editing: boolean;
  dragging: boolean;
  isHighlighted: boolean;
  isRelatedToHighlighted: boolean;
  shouldAnimate?: boolean;
  dependencyTeamFilter: DependencyTeamFilter | null;
}

export type MarkMode =
  | "normal"
  | "highlight"
  | "fade-out"
  | "semi-fade-out"
  | "filter-out"
  | "hidden";

export function isFullyFaded(mode: MarkMode) {
  return mode === "fade-out" || mode === "filter-out";
}

export function isFaded(mode: MarkMode) {
  return isFullyFaded(mode) || mode === "semi-fade-out";
}

/**
 * True if the card is shown as 'low-light' (faded)
 */
export function isLowLight(mark: MarkMode, cardMode: Mode): boolean {
  return isFullyFaded(mark) && cardMode === "normal";
}

export interface BoardIteration {
  velocity: number;
  load: number;
  state: IterationState;
}

export interface StickyReference {
  id: string;
  isOrigin: boolean;
}

export interface Objective {
  id: string;
  text: string;
  description: string;
  bv: number;
  av: number | null;
  cards: StickyReference[];
}

export type ObjectiveUpdate = Omit<Objective, "cards"> &
  Partial<Pick<Objective, "cards">>;

export type ObjectiveType = "committed" | "uncommitted";

export interface LinkedObjective {
  id: Objective["id"];
  boardId: Board["id"];
}

/**
 * Model that represents a card component on the UI.
 */
export interface Card {
  id: string;
  groupId?: string | null;
  almId?: string; // the ID of the external ALM item
  almIssueUrl?: string;
  almSourceId: AlmSourceId | null;
  text: string;
  points: number;
  priority: number;
  teamId: string | null;
  precondTeam?: Team; // team that created the sticky
  dependTeam?: Team; // team that the sticky depends on
  iterationId: number | null;
  editor: AuthUser | null;
  links: Link[];
  type: StickyType;
  flagType: CardFlag;
  risk?: RiskType;
  status?: AlmItemStatus;
  transition?: string;
  objectives: LinkedObjective[];
  reactions?: Reactions;
  artId: string | null;
} //TODO subtypes depending on type.functionality

export function isDependency(card: Card) {
  return card.type.functionality === "dependency";
}

export function isWorkitem(card: Card) {
  return card.type.functionality === "workitem";
}

export function isNote(card: Card) {
  return card.type.functionality === "note";
}

export function isRisk(card: Card) {
  return card.type.functionality === "risk";
}

export type Reactions = { [reaction in Reaction]: AuthUser[] };

export type FlagIcon =
  | "none"
  | "flag"
  | "check"
  | "round"
  | "star"
  | "question"
  | "exclam";

export type FlagColor =
  | "red"
  | "orange"
  | "yellow"
  | "blue"
  | "purple"
  | "green";

export interface Session {
  id: string;
  name: string;
  startDate: Date | null;
  archived: boolean;
  almStatus: SessionAlmStatus | null;
}

export type SessionAlmStatus = "running" | "error" | "stopped" | "starting";

export interface AlmSource {
  id: AlmSourceId;
  name: string;
}

export interface Team {
  id: string;
  name: string;
  artId?: string;
}

export interface Art {
  id: string;
  name: string;
}

export interface FlexType {
  id: string;
  name: string;
  background: string;
}

export interface FlexBackground {
  id: string;
  name: string;
  infoLink: string;
}

export interface Category {
  id: string;
  name: string;
  boardIds: string[];
  fresh?: boolean;
}

export type EventType = "timer";

export interface Event<T> {
  id: string;
  boardId?: string;
  artId?: string;
  type: EventType;
  createdById: string;
  createdBy?: AuthUser;
  createdAt: number;
  updatedById: string;
  updatedByUser?: AuthUser;
  updatedAt: number;
  data: T;
}

export type TimerState = "running" | "paused" | "stopped";
export type ServerTime = number & { _type: "server" };

export interface TimerData {
  name: string;
  state: TimerState;
  duration: number;
  end: ServerTime;
}

export type TimerEvent = Event<TimerData>;

export type StickyLink = {
  type: "sticky";
  color: Card["type"]["color"];
  origin: Card["type"]["origin"];
} & Pick<Card, "text" | "iterationId" | "almId" | "teamId"> &
  Pick<Link, "id">;

export type ObjectiveLink = {
  id: string;
  type: "objective";
  text: Objective["text"];
  color: null;
  objectiveId: Objective["id"];
  boardId: Board["id"];
  origin: BoardType;
};

export type CardLink = StickyLink | ObjectiveLink;

export type Linkable = {
  linked: boolean;
};

export const reactions = ["hot", "love", "increment"] as const;

export type Reaction = (typeof reactions)[number];

export type WorkingMode = "planning" | "execution";

export type Group = Team | Art;

export type InfoLevel = "default" | "ok" | "warn" | "error";

export type StickyChangeKind =
  | "create"
  | "update"
  | "delete"
  | "mirror"
  | "unmirror"
  | "link"
  | "unlink";

export interface StickyChange {
  timestamp: Date;
  user: AuthUser;
  kind: StickyChangeKind;
  fields: FieldChange[];
}

// Sticky properties that we can render in the Activity Panel,
// with mappings from their server-key to local-key
const stickyProperties = {
  text: "text",
  type_id: "type",
  story_points: "points",
  WSJF_value: "priority",
  risk: "risk",
  iteration_number: "iterationId",
  team_id: "teamId",
  reactions: "reactions",
  flag_type: "flagType",
  depend_team_id: "dependTeamId",
} as const;

// Other changes that we can render in the Activity Panel
const otherStickyChanges = {
  mirror_board: "mirrorBoard",
  link: "link",
};

export const stickyActivityKeys = {
  ...stickyProperties,
  ...otherStickyChanges,
};

export interface FieldChange<T = any> {
  name: (typeof stickyActivityKeys)[keyof typeof stickyActivityKeys];
  old?: T;
  new?: T;
}

type SearchResultKind = "sticky";

export interface SearchResult {
  kind: SearchResultKind;
  boardId: string;
  id: string;
  text: string;
  typeId: string;
  teamId?: string;
  artId?: string;
  iterationId?: number;
  flag?: string | null;
  almId?: string;
}
