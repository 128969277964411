<template>
  <base-modal class="plan-readout-modal" fullscreen>
    <template #header-title>
      <div class="header-container">
        <div class="left-container">
          <svg-icon name="plan-readout" class="plan-readout-icon" />
          <h1 class="title">{{ $t("planReadout.title") }}</h1>
          <menu-item-delimiter />
          <div class="tab-container">
            <BaseButton
              v-for="tab in tabs"
              :key="tab.id"
              variant="ghost"
              color="grey"
              :activated="activeTab === tab.id"
              @click="setActiveTab(tab.id)"
            >
              <span class="tab-text">
                {{ tab.label }}
              </span>
            </BaseButton>
          </div>
        </div>
      </div>
      <team-selector
        :current-team="currentTeam"
        class="present-team-selector"
        @select="selectTeam"
      />
    </template>

    <div class="main-container">
      <transition-group name="faster-fade">
        <div v-if="activeTab === 'capacity'" class="metrics-modal-container">
          <metrics-modal-load-capacity-body :team="currentTeam" />
        </div>
        <risks-tab v-if="activeTab === 'risks'" :team="currentTeam" />
        <objectives-tab v-if="activeTab === 'objectives'" :team="currentTeam" />
      </transition-group>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import ObjectivesTab from "@/components/PlanReadout/components/ObjectivesTab.vue";
import RisksTab from "@/components/PlanReadout/components/RisksTab.vue";
import TeamSelector from "@/components/PlanReadout/components/TeamSelector.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import MenuItemDelimiter from "@/components/menu/components/MenuItemDelimiter.vue";
import BaseModal from "@/components/modal/BaseModal.vue";
import MetricsModalLoadCapacityBody from "@/components/modal/MetricsModalLoadCapacityBody.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { i18n } from "@/i18n";
import { Team } from "@/model";
import { useModalStore } from "@/store/modal";
import { useTeamStore } from "@/store/team";
import {
  planReadoutModalClosed,
  planReadoutModalSeen,
  planReadoutTabClicked,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

@Options({
  name: "PlanReadoutModal",
  components: {
    BaseModal,
    BaseButton,
    MenuItemDelimiter,
    ObjectivesTab,
    RisksTab,
    SvgIcon,
    MetricsModalLoadCapacityBody,
    TeamSelector,
  },
})
export default class PlanReadoutModal extends Vue {
  @Prop({ default: "objectives" }) readonly activeTab!: string;

  tabs = [
    { id: "objectives", label: i18n.global.t("general.objectives") },
    { id: "risks", label: i18n.global.t("planReadoutModal.risks") },
    { id: "capacity", label: i18n.global.t("general.capacity") },
  ];
  currentTeam: Team | undefined = useTeamStore().currentTeam;

  selectTeam(team: Team) {
    this.currentTeam = team;
  }

  tabButtonClass(tab: string): object {
    return { active: this.activeTab === tab };
  }

  setActiveTab(tabId: string) {
    trackEvent(planReadoutTabClicked(tabId, this.activeTab));
    useModalStore().changeAttrs({ activeTab: tabId });
  }

  mounted(): void {
    trackEvent(planReadoutModalSeen());
  }

  unmounted(): void {
    trackEvent(planReadoutModalClosed());
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/font";

.plan-readout-modal {
  .main-container {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    flex-grow: 1;

    .metrics-modal-container {
      width: 100%;
      height: 100%;
      padding: 0 8em 8em;

      :deep(> *) {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
  }

  .left-container {
    display: flex;
    align-items: center;
    gap: 16px;

    .plan-readout-icon {
      width: 18px;
      height: 18px;
    }

    .title {
      margin: 0;
      font-size: font.$size-large;
      font-weight: font.$weight-extra-bold;
    }

    .tab-container {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      .tab-text {
        font-weight: font.$weight-bold;
      }
    }
  }

  // Center the team selector, without overlapping it with the tab buttons on small screens
  .present-team-selector {
    position: absolute;
    left: max(600px, 50%);
    transform: translateX(-50%);
  }
}
</style>
