<template>
  <div :class="{ wrap: !isBoard }">
    <div class="loading-container">
      <div class="loading" :class="{ loadingboard: isBoard }"></div>
      <span v-if="!isBoard" class="loading-text">{{
        $t("loadComponent.loading")
      }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class LoadComponent extends Vue {
  @Prop(Boolean) readonly isBoard?: boolean;
}
</script>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

.wrap {
  height: 100vh;
  width: 100%;
}

.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);

  .loading-text {
    margin-top: 12px;
    font-size: 16px;
    font-weight: font.$weight-bold;
  }

  .loading {
    border: 8px solid colors-old.$board-background-color;
    border-top-color: colors-old.$primary-color;
    border-radius: 50%;
    animation: spin 2s linear infinite;
    width: 40px;
    height: 40px;
    margin: 5px;

    &.main {
      position: fixed;
      left: 50%;
      top: 50%;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .loadingboard {
    z-index: z-index.$links;
    position: absolute;
    height: variables.$fake-zoom * 40px;
    width: variables.$fake-zoom * 40px;
    border-width: 80px;
    top: 50%;
    left: 50%;
    margin-top: -1 * variables.$fake-zoom * 20px;
    margin-left: -1 * variables.$fake-zoom * 20px;
  }
}
</style>
