<template>
  <div>
    <div ref="committedHeader" class="objectives-header header-committed-group">
      <div
        class="collapsable-area"
        data-testid="collapsable-area"
        @click.stop="committedIsExpanded = !committedIsExpanded"
      >
        <SvgIcon v-if="committedIsExpanded" name="arrow/down" size="20" />
        <SvgIcon v-else name="arrow/right" size="20" />
        <div>
          <p class="objectives-header-title">
            {{ $t("objectives.committed") }}
          </p>
          <p class="objective-count">
            {{ $t("objectives.count", objectives.length) }}
          </p>
        </div>
      </div>
      <div class="objectives-actions">
        <BaseButton
          variant="ghost"
          color="grey"
          size="medium"
          icon-before="objective-description"
          @click="toggleGlobalDescriptions"
          >{{
            expandDescriptions
              ? $t("objectives.hideDescriptionButton")
              : $t("objectives.showDescriptionButton")
          }}
        </BaseButton>
        <menu-item-delimiter />
        <add-objective-button
          class="objective-btn"
          @click="
            addCommittedObjectiveIsActive = true;
            committedIsExpanded = true;
          "
        />
      </div>
    </div>
    <div
      v-if="committedIsExpanded"
      data-testid="committed-group-container"
      class="objectives-container"
    >
      <add-objective-card
        v-if="addCommittedObjectiveIsActive"
        type="committed"
        :board="board"
        @cancel="addCommittedObjectiveIsActive = false"
      />
      <draggable
        :list="objectives"
        item-key="id"
        ghost-class="objective-drag-state"
        class="committed-group"
        :group="group"
        :move="onMove"
        animation="100"
        @start="isDragging = true"
        @end="onEndDragging"
      >
        <template #item="{ index, element: objective }">
          <objective-card
            :index="index + 1"
            :objective="objective"
            :board="board"
            :is-dragging="isDragging"
            :expand-descriptions="expandDescriptions"
            :has-status-distribution="hasStatusDistribution"
            type="committed"
          />
        </template>
      </draggable>
      <div v-if="showNoObjectives" class="no-objective-placeholder">
        <p>{{ $t("objectives.none") }}</p>
      </div>
    </div>

    <div
      ref="uncommittedHeader"
      class="objectives-header header-uncommitted-group"
    >
      <div
        class="collapsable-area"
        data-testid="collapsable-area"
        @click.stop="uncommittedIsExpanded = !uncommittedIsExpanded"
      >
        <SvgIcon v-if="uncommittedIsExpanded" name="arrow/down" size="20" />
        <SvgIcon v-else name="arrow/right" size="20" />
        <div>
          <p class="objectives-header-title">
            {{ $t("objectives.uncommitted") }}
          </p>
          <p class="objective-count">
            {{ $t("objectives.count", stretchObjectives.length) }}
          </p>
        </div>
      </div>
      <add-objective-button
        class="objective-btn"
        @click="
          addUncommittedObjectiveIsActive = true;
          uncommittedIsExpanded = true;
        "
      />
    </div>
    <div
      v-if="uncommittedIsExpanded"
      data-testid="uncommitted-group-container"
      class="objectives-container"
    >
      <add-objective-card
        v-if="addUncommittedObjectiveIsActive"
        type="uncommitted"
        :board="board"
        @cancel="addUncommittedObjectiveIsActive = false"
      />

      <draggable
        :list="stretchObjectives"
        item-key="id"
        ghost-class="objective-drag-state"
        draggable=".objective-card"
        class="uncommitted-group"
        :group="group"
        :move="onMove"
        animation="100"
        @start="isDragging = true"
        @end="onEndDragging"
      >
        <template #item="{ index, element: objective }">
          <objective-card
            :index="index + 1"
            :objective="objective"
            :board="board"
            :is-dragging="isDragging"
            :expand-descriptions="expandDescriptions"
            :has-status-distribution="hasStatusDistribution"
            type="uncommitted"
          />
        </template>
      </draggable>
      <div v-if="showNoStretchObjectives" class="no-objective-placeholder">
        <p>{{ $t("objectives.none") }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import draggable from "vuedraggable";

import { objectiveActions } from "@/action/objectiveActions";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import MenuItemDelimiter from "@/components/menu/components/MenuItemDelimiter.vue";
import AddObjectiveButton from "@/components/objectives/AddObjectiveButton.vue";
import AddObjectiveCard from "@/components/objectives/AddObjectiveCard.vue";
import ObjectiveCard from "@/components/objectives/ObjectiveCard.vue";
import { i18n } from "@/i18n";
import ShortcutUser from "@/mixins/ShortcutUser";
import { BoardWithObjectives } from "@/model";

import BaseButton from "../ui/BaseButton/BaseButton.vue";

@Options({
  components: {
    SvgIcon,
    ObjectiveCard,
    AddObjectiveButton,
    AddObjectiveCard,
    draggable,
    MenuItemDelimiter,
    BaseButton,
  },
})
export default class ObjectivesList extends mixins(ShortcutUser) {
  @Prop({ required: true, type: Object }) readonly board!: BoardWithObjectives;
  @Prop({ type: Boolean, default: true })
  readonly addObjectiveByKeyBoard!: boolean;
  @Prop({ type: Boolean, default: true })
  readonly isExpendable!: boolean;
  @Prop({ type: Boolean, default: true })
  readonly hasStatusDistribution!: boolean;
  @Ref("committedHeader") readonly committedHeaderElm!: HTMLInputElement;
  @Ref("uncommittedHeader") readonly uncommittedHeaderElm!: HTMLInputElement;
  addCommittedObjectiveIsActive = false;
  addUncommittedObjectiveIsActive = false;
  isDragging = false;
  committedIsExpanded = true;
  uncommittedIsExpanded = true;
  @Prop({ type: String, default: "objectives" }) readonly group!: string;

  expandDescriptions = false;

  created() {
    if (this.addObjectiveByKeyBoard) {
      this.shortcut("KeyN", () => {
        this.addUncommittedObjectiveIsActive = true;
        this.uncommittedIsExpanded = true;
      });
    }
  }

  toggleGlobalDescriptions() {
    this.expandDescriptions = !this.expandDescriptions;
  }

  get title() {
    return this.board.type === "team"
      ? i18n.global.t("teamObjectivesModal.objectives", {
          teamName: this.board.team.name,
        })
      : i18n.global.t("objectives.programObjectives");
  }

  get objectives() {
    return this.board.objectives;
  }

  get stretchObjectives() {
    return this.board.stretchObjectives;
  }

  get showNoObjectives() {
    return this.objectives.length === 0;
  }

  get showNoStretchObjectives() {
    return this.stretchObjectives.length === 0;
  }

  addDragStateToCommittedHeader() {
    this.committedHeaderElm.classList.add("drag-state");
  }

  removeDragStateFromCommittedHeader() {
    this.committedHeaderElm.classList.remove("drag-state");
  }

  addDragStateToUncommittedHeader() {
    this.uncommittedHeaderElm.classList.add("drag-state");
  }

  removeDragStateFromUncommittedHeader() {
    this.uncommittedHeaderElm.classList.remove("drag-state");
  }

  hasDraggedToCommittedHeader(element: Element) {
    return element.classList
      ? element.classList.contains("header-committed-group")
      : false;
  }

  hasDraggedToUncommittedHeader(element: Element) {
    return element.classList
      ? element.classList.contains("header-uncommitted-group")
      : false;
  }

  onEndDragging(event: any) {
    /**
     * updates the commitment state for the objective that was dragged
     */
    this.isDragging = false;
    const hasDraggedBetweenUncommittedObjectives =
      event.to.classList.contains("uncommitted-group");

    const isStretch =
      hasDraggedBetweenUncommittedObjectives ||
      this.hasDraggedToUncommittedHeader(event.to);

    const hasDraggedToHeader =
      this.hasDraggedToCommittedHeader(event.to) ||
      this.hasDraggedToUncommittedHeader(event.to);

    let index = event.newIndex;

    if (hasDraggedToHeader) {
      index = isStretch ? 0 : this.objectives.length;
    }

    objectiveActions.move(
      "dragDrop",
      this.board.id,
      event.item.__draggable_context.element.id,
      {
        stretch: isStretch,
        rank: index ? index : 0,
      },
    );
    this.removeDragStateFromUncommittedHeader();
    this.removeDragStateFromCommittedHeader();
  }

  onMove(event: any, _originalEvent: any) {
    if (
      this.hasDraggedToCommittedHeader(event.to) &&
      !this.committedIsExpanded
    ) {
      this.addDragStateToCommittedHeader();
      this.removeDragStateFromUncommittedHeader();
    } else if (
      this.hasDraggedToUncommittedHeader(event.to) &&
      !this.uncommittedIsExpanded
    ) {
      this.addDragStateToUncommittedHeader();
      this.removeDragStateFromCommittedHeader();
    } else {
      this.removeDragStateFromCommittedHeader();
      this.removeDragStateFromUncommittedHeader();
    }
  }
}
</script>
<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

.header-committed-group,
.header-uncommitted-group {
  position: sticky;
  top: 0;
  background-color: colors-old.$modal-background-color;
  z-index: z-index.$low;
}

.objectives-container {
  margin: 0 20px;
}

.objectives-header {
  margin: 40px 0 20px;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-radius: 7px;
  vertical-align: center;

  .objectives-header-title {
    font-weight: font.$weight-extra-bold;
    font-size: font.$size-normal;
    margin: 0;
  }

  .objectives-actions {
    display: flex;
    align-items: center;
  }

  .collapsable-area {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.objective-drag-state {
  background: colors-old.$light-background-color;
  cursor: grabbing;
}

.drag-state {
  background: colors-old.$light-background-color;
  cursor: grabbing;

  & ~ .header-drag-state {
    display: none;
  }
}

.no-objective-placeholder {
  border: 1px solid colors-old.$objective-border-color;
  padding: 1.2rem;
  margin-bottom: 1.2rem;
  border-radius: variables.$objective-card-border-radius;

  p {
    color: colors-old.$placeholder-color;
    font-size: font.$size-small;
    text-align: center;
  }
}

.objective-count {
  font-size: font.$size-small;
  margin: 4px 0 0;
  color: colors-old.$text-secondary-color;
}
</style>
