<template>
  <div class="overlay teams scrollable no-wheel" @wheel="stopScrollPropagation">
    <div
      v-for="(team, index) in teamList"
      :id="optionId(team)"
      :key="team.id"
      @pointermove="selectIndex = index"
      @click.stop="select(team)"
      @pointerdown.stop
    >
      <span
        class="entry"
        :class="{
          selected: selected === team.id,
          current: index === selectIndex,
        }"
      >
        <img
          v-if="team.id === 'art'"
          class="icon"
          src="@/assets/arts.svg"
          data-testid="select-art"
        />
        {{ team.name }}</span
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { i18n } from "@/i18n";
import ScrollSupport from "@/mixins/ScrollSupport";
import SelectList from "@/mixins/SelectList";
import { Team } from "@/model";

type ArtSelectionItem = { id: "art"; name: string };

@Options({ emits: ["changeArt", "select"] })
export default class CardTeamsOverlay extends mixins(
  SelectList,
  ScrollSupport,
) {
  @Prop(Array) readonly teams!: Team[];
  @Prop(String) readonly selected?: string;
  @Prop(Boolean) readonly risk?: boolean;
  @Prop(Boolean) readonly showArts?: boolean;

  @Watch("teamList", { immediate: true })
  teamListChanged() {
    this.selectList = this.teamList;
  }

  mounted() {
    this.adjustScrollbarWidth();
  }

  select(val: Team | ArtSelectionItem | undefined) {
    // in case the user presses escape the input is undefined
    if (val?.id === "art") {
      this.$emit("changeArt");
    } else {
      this.$emit("select", val);
    }
  }

  get externalOptions() {
    if (this.risk) {
      return [{ id: "risk", name: i18n.global.t("board.risk") }];
    } else if (this.showArts) {
      return [{ id: "art", name: i18n.global.t("general.selectAnotherArt") }];
    }
    return [];
  }

  get teamList() {
    return [...this.externalOptions, ...this.teams];
  }

  optionId(team: Team) {
    return "teamsOverlay-" + team.id;
  }
}
</script>

<style lang="scss">
.entry .icon {
  width: 1.7em;
}
</style>
