<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";

import { cardActions } from "@/action/cardActions";
import { getBoardIdsOfGroupedStickies } from "@/backend/Backend";
import { boardIcon, boardTypeName } from "@/baseTypeI18n";
import BaseList from "@/components-ng/BaseList/BaseList.vue";
import BaseListItem from "@/components-ng/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListItems from "@/components-ng/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components-ng/BaseList/components/BaseListTitle/BaseListTitle.vue";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { cardKey, cardMethodsKey } from "@/components/card/injectKeys";
import type { MirrorableBoard } from "@/model";
import { useBoardStore } from "@/store/board";
import { injectStrict } from "@/utils/context";

const boardsStickyMirroredTo = ref<Array<string>>([]);

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);
const cardMethods = injectStrict(cardMethodsKey);

onMounted(async () => {
  boardsStickyMirroredTo.value = await getBoardIdsOfGroupedStickies(card.id);
});

const boards = computed(() => useBoardStore().mirrorTargetBoards([card]));

const boardName = (board: MirrorableBoard): string => {
  return board.type === "team" ? board.team.name : boardTypeName(board.type);
};

const mirrorToBoard = async (board: MirrorableBoard) => {
  if (isAlreadyMirrored(board.id)) {
    return;
  }
  if (!boardsStickyMirroredTo.value.includes(board.id)) {
    boardsStickyMirroredTo.value.push(board.id);
  }
  await cardActions.mirror("card", card.id, card.teamId, board);

  dropdown.close();
  cardMethods.animateCopy("mirroring");
};

const isAlreadyMirrored = (boardId: string) => {
  return boardsStickyMirroredTo.value.includes(boardId);
};
</script>

<template>
  <BaseList class="mirror-sticky no-wheel">
    <BaseListTitle>
      {{ $t("actionMenu.mirrorSticky.mirrorSticky") }}
    </BaseListTitle>
    <BaseListItems>
      <BaseListItem
        v-for="board in boards"
        :key="board.id"
        :data-testid="`mirror-sticky-list-item-${board.id}`"
        :class="{ disabled: isAlreadyMirrored(board.id) }"
        @click="mirrorToBoard(board)"
      >
        <template #before>
          <SvgIcon size="20" :name="boardIcon(board)" />
        </template>
        <span>{{ boardName(board) }}</span>
        <template #after>
          <template v-if="isAlreadyMirrored(board.id)">
            <SvgIcon size="20" name="check" />
            {{ $t("actionMenu.mirrorSticky.mirrored") }}
          </template>
          <template v-else>
            <span class="mirror">
              {{ $t("actionMenu.mirrorSticky.mirror") }}
            </span>
          </template>
        </template>
      </BaseListItem>
    </BaseListItems>
  </BaseList>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.mirror-sticky {
  width: 300px;
  max-height: 350px;

  .disabled {
    pointer-events: none;
    cursor: default;
  }

  .after {
    display: flex;
    align-items: center;
  }

  .mirror {
    color: colors-old.$primary-color;
  }
}
</style>
