<template>
  <div class="search-item" :class="{ active: isSearchActive }">
    <div class="search-item-grid">
      <div class="search-item-col">
        <IconButton
          v-if="isSearchActive"
          icon="close"
          :tooltip="$t('searchItem.closeSearch')"
          @click="toggle"
        />
        <search-bar
          v-model="text"
          class="search-item__search-bar"
          :is-active="isSearchActive"
          :any-filter-criteria="anyFilterCriteria"
          @toggle="toggle"
        />
        <div v-if="isSearchActive" class="search-item-actions">
          <StickyTypeItem />
          <template v-if="store.isDependencySearch">
            <DependencyLinkItem />
            <MenuItemDelimiter />
          </template>
          <StickersItem />
          <IterationItem />
          <TeamItem v-if="!isSolutionBoard" />
          <ArtItem v-else />
          <template v-if="showDependencyTeamFilter">
            <DependencyTeamItem />
            <MenuItemDelimiter />
          </template>
          <StatusClassItem />
          <MenuItemDelimiter />
          <RiskyLinksItem />
          <template v-if="anyFilterCriteria">
            <menu-item-delimiter />
            <BaseButton variant="ghost" @click="hide">
              {{ $t("searchItem.hideSearch") }}
            </BaseButton>
          </template>
        </div>
      </div>
      <div @click.stop>
        <transition name="slide-left">
          <search-results
            v-if="isSearchActive"
            :results="found"
            :selected="currentItem"
            @select="setCurrent"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Emit, Watch } from "vue-property-decorator";

import { Key, key } from "@/Shortcuts";
import { linkActions } from "@/action/linkActions";
import { toggleActions } from "@/action/toggleActions";
import MenuItemDelimiter from "@/components/menu/components/MenuItemDelimiter.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import EventBusUser, { sendCardAction } from "@/mixins/EventBusUser";
import SearchBase from "@/mixins/SearchBase";
import ShortcutUser from "@/mixins/ShortcutUser";
import TimeoutUser from "@/mixins/TimeoutUser";
import { isDependency, isSolutionBoard } from "@/model";
import { SearchQuery } from "@/router/types";
import { useBoardStore } from "@/store/board";
import { getLinkTargetId, useLinkStore } from "@/store/link";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useZoomStore } from "@/store/zoom";

import SearchBar from "./SearchBar.vue";
import SearchItemBase from "./SearchItemBase";
import { CardSearchResult, ItemKey, toSearchResult } from "./SearchResult";
import SearchResults from "./SearchResults.vue";
import ArtItem from "./dropdown/ArtItem.vue";
import DependencyLinkItem from "./dropdown/DependencyLinkItem.vue";
import DependencyTeamItem from "./dropdown/DependencyTeamItem.vue";
import IterationItem from "./dropdown/IterationItem.vue";
import RiskyLinksItem from "./dropdown/RiskyLinksItem.vue";
import StatusClassItem from "./dropdown/StatusClassItem.vue";
import StickersItem from "./dropdown/StickersItem.vue";
import StickyTypeItem from "./dropdown/StickyTypeItem.vue";
import TeamItem from "./dropdown/TeamItem.vue";

@Options({
  components: {
    BaseButton,
    DependencyTeamItem,
    DependencyLinkItem,
    SearchResults,
    SearchBar,
    StickyTypeItem,
    TeamItem,
    ArtItem,
    StatusClassItem,
    RiskyLinksItem,
    StickersItem,
    IterationItem,
    IconButton,
    MenuItemDelimiter,
  },
  emits: ["toggle"],
})
export default class SearchItem extends mixins(
  SearchItemBase,
  ShortcutUser,
  EventBusUser,
  SearchBase,
  TimeoutUser,
) {
  store = useSearchMenuStore();
  linkStore = useLinkStore();
  currentTeamId: string | null = null;

  setCurrent(e: ItemKey) {
    const index = this.found.findIndex((result) => result.id === e.id);
    if (index >= 0) {
      this.currentTeamId = e.teamId ?? null;
      this.setFound(index + 1 === this.foundIndex ? 0 : index + 1);
    }
  }

  updateSearch() {
    this.update();
    this.updateRoute(this.foundIndex);
  }

  update() {
    // TODO make the board responsible for marking search results? (like objectives board)
    this.search(this.cards, (card) => this.matches(this.currentBoard, card), {
      mapResult: toSearchResult,
      sortBy: (result) => -result.card!.meta.pos.y,
    });

    if (this.store.isDependencySearch) {
      this.highlightDependencyLinkedStickies();
    }
  }

  /** highlights the stickies which are related to a dependency that matches the search */
  highlightDependencyLinkedStickies() {
    // because generic mixins don't work (or how?)
    const found = this.found as CardSearchResult[];
    found.forEach((result) => {
      const card = result.card;
      if (!card || !isDependency(card.data)) {
        return;
      }
      card.data.links.forEach((link) => {
        const linkedCard = useLinkStore().boardCardByLink(
          getLinkTargetId(card.data, link),
          this.currentBoard,
        );
        if (linkedCard) {
          linkedCard.meta.mark = "normal";
        }
      });
    });
  }

  @Watch("foundIndex")
  foundIndexChanged() {
    this.updateRoute(this.foundIndex);
  }

  get showDependencyTeamFilter() {
    return this.store.showDependencyTeamFilter;
  }

  markFound(item: CardSearchResult, mark: boolean) {
    if (item?.card) {
      useZoomStore().zoomStickyNote(item.card.data.id);
      sendCardAction(item.card.data.id, {
        action: "zoom",
        zoom: mark,
        editMode: false,
      });
    }
  }

  get currentItem() {
    return {
      id: this.foundItem()?.id,
      teamId: this.currentTeamId ?? undefined,
    };
  }

  get isSearchActive() {
    return this.store.isSearchActive;
  }

  get anyFilterCriteria() {
    return this.store.anyFilterCriteria;
  }

  get isSolutionBoard() {
    return isSolutionBoard(useBoardStore().currentBoard().type);
  }

  bindSearchEvents() {
    this.onSearch((value: SearchQuery) => {
      if (this.applySearchQuery(value)) {
        this.store.expandSearch();
      }
    });
  }

  created() {
    this.defineKeybindings();
    this.bindSearchEvents();
  }

  mounted() {
    this.onBoardSwitch(this.update);
    this.update();
  }

  defineKeybindings() {
    this.globalActionShortcut(toggleActions.showSearch);
    // KeyY would be dependent on keyboard language
    this.globalShortcut(key("y", "altCtrl"), () => {
      this.store.expandSearch();
      this.store.openOverlay("stickyType");
    });
    this.globalShortcut(key("f", "altCtrl"), () => {
      this.store.expandSearch();
      this.store.openOverlay("sticker");
    });
    this.globalShortcut(key("l", "altCtrl"), () => {
      this.store.expandSearch();
      this.store.openOverlay("riskyLink");
    });
    this.openShortcut("Escape", () => {
      if (!this.store.anySearchOverlayActive) {
        this.toggle();
      } else {
        this.store.closeOverlay();
      }
    });
    this.openShortcut("Enter", () => {
      const found = this.foundItem();
      if (found && found.boardId !== this.currentBoard.id) {
        this.setCurrent({ id: found.id });
      }
    });
    this.openShortcut("ArrowUp", () => {
      if (!this.store.anySearchOverlayActive) {
        this.findNext(-1);
      }
    });
    this.openShortcut("ArrowDown", () => {
      if (!this.store.anySearchOverlayActive) {
        this.findNext(1);
      }
    });
  }

  openShortcut(k: Key, handler: () => void) {
    this.globalShortcut(k, () => {
      if (this.isSearchActive) {
        handler();
      }
    });
  }

  get currentBoard() {
    return useBoardStore().currentBoard();
  }

  get cards() {
    return this.currentBoard.cards;
  }

  get cardsData() {
    return Object.values(this.cards).map((card) => card.data);
  }

  get searchQuery() {
    return [
      this.text,
      this.store.id,
      this.types,
      this.teams,
      this.arts,
      this.iterations,
      this.selectedRiskyLinks,
      this.selectedStatusClasses,
      this.flags,
      this.selectedDependencyLink,
      this.selectedDependencyTeamFilter,
    ];
  }

  @Watch("searchQuery", { deep: true })
  searchChanged(value: any, oldValue: any) {
    // simple optimization: ignore search criteria "change" from empty array to empty array
    if (value?.length === 0 && oldValue?.length === 0) {
      return;
    }
    this.updateSearch();
  }

  @Watch("cardsData", { deep: true })
  cardsChanged() {
    if (this.store.anyFilterCriteria && !this.linkStore.isMarkingLinks) {
      this.updateSearch();
    }
  }

  @Watch("linkStore.isMarkingLinks")
  markingLinksChanged(marking: boolean) {
    if (this.store.anyFilterCriteria && !marking) {
      this.updateSearch();
    }
  }

  @Watch("store.anyFilterCriteria")
  anyFilterCriteriaChanged(filterCriteria: boolean) {
    if (!filterCriteria) {
      linkActions.updateCardLinkedMarks("internal");
    }
  }

  @Emit()
  toggle() {
    if (this.isSearchActive) {
      this.clearSearchCriteria();
      this.store.collapseSearch();
      this.resetSelectedCard();
    } else {
      this.store.expandSearch();
    }
  }

  hide() {
    this.store.collapseSearch();
  }

  resetSelectedCard() {
    this.setFound(0);
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/animations" as *;
@use "@/styles/z-index";

.search-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__search-bar {
    max-height: 40px;
    position: relative;
  }

  &.active {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: var(--back-color);
    height: 100%;
    z-index: z-index.$low;
  }

  &-grid {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-actions,
  &-col {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &-col {
    &:nth-child(2) {
      margin-left: auto;
    }
  }

  .search-item-actions {
    margin-left: 9px;
  }
}
</style>

<style lang="scss">
.search-select {
  &.dropdown-content {
    width: 200px;
  }
}
</style>
