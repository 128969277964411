<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { setSessionId } from "@/backend/Backend";
import HomeHeader from "@/components/HomeHeader.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import LoadComponent from "@/components/modal/components/LoadComponent.vue";
import { handleError } from "@/errorHandler";
import { Session } from "@/model";
import { navigateForward } from "@/router/navigation";
import { useLicenseStore } from "@/store/license";
import { useSessionStore } from "@/store/session";
import { useUserStore } from "@/store/user";
import { formatLongDate } from "@/utils/dateFormat";

const licenseStore = useLicenseStore();
const loading = ref(false);
const archived = ref(false);
const formatDate = formatLongDate;

const route = useRoute();

const filteredSessions = computed(() =>
  useSessionStore().filterArchived(archived.value),
);
const authid = computed(() => {
  const user = useUserStore().technicalUser;
  return user.company + "." + user.id;
});

watch(
  () => route.params.session,
  (sessionId: any) => {
    const session = useSessionStore().session.list.find(
      (session) => session.id === sessionId,
    );
    if (session) {
      select(session);
    }
  },
  { immediate: true },
);

async function select(session: Session) {
  if (loading.value) {
    return;
  }
  loading.value = true;
  try {
    await setSessionId(session.id);
    useSessionStore().selectSession(session);
    navigateForward();
  } catch (err: any) {
    handleError(err);
  }
}

function isStarting(session: Session) {
  const start = new Date();
  start.setHours(0, 0, 0, 0);

  const end = new Date(start.getTime());
  end.setDate(end.getDate() + 4);
  end.setHours(23, 59, 59, 999);

  const startDateMilis = session.startDate ? session.startDate.getTime() : 0;

  return startDateMilis > start.getTime() && startDateMilis < end.getTime();
}
</script>

<template>
  <div>
    <LoadComponent v-if="loading" />
    <div class="home-overview session-page">
      <HomeHeader>
        <template #title>
          <h1>{{ $t("sessionPage.piPlannings", { safe: "SAFe®" }) }}</h1>
        </template>
        <template #subtitle>
          <h2>{{ $t("sessionPage.select") }}</h2>
        </template>
      </HomeHeader>
      <div class="custom-checkbox">
        <input id="archived" v-model="archived" type="checkbox" />
        <label for="archived">{{ $t("sessionPage.showArchived") }}</label>
      </div>
      <div v-if="!licenseStore.license.usable" class="expired">
        {{ $t("sessionPage.licenseExpired") }}
        <div class="info">
          {{ $t("sessionPage.authid", { authid }) }}
        </div>
      </div>
      <hr class="seperator" />
      <div class="scrollable scrollable-relative scrollable-content">
        <table>
          <tbody>
            <tr
              v-for="session in filteredSessions"
              :key="session.id"
              class="item"
              :class="{
                starting: isStarting(session),
                archived: session.archived,
              }"
              @click="select(session)"
            >
              <td>
                <div class="item-element">
                  <div>
                    <h3>{{ session.name }}</h3>
                    <!-- Always render the next span with or without content, for proper spacing -->
                    <span>
                      {{
                        session.startDate ? formatDate(session.startDate) : null
                      }}
                      &nbsp;
                    </span>
                  </div>
                  <SvgIcon name="arrow/right" size="20" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/checkbox" as *;
@use "@/styles/animations" as *;

.session-page {
  position: relative;

  hr.seperator {
    margin-top: 30px;
  }

  .expired {
    margin-top: 2em;
    font-size: 150%;

    .info {
      margin-top: 1em;
      color: colors-old.$dark-divider-color;
    }
  }

  .scrollable-content {
    .item {
      display: table-row !important;
    }

    table {
      border-collapse: collapse;
      margin: 0 auto;
      width: 100%;

      td {
        .item-element {
          padding: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          border: 1px solid colors-old.$divider-color;
          border-radius: 6px;
          transition: 0.3s ease-in-out border-color;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;

          &:hover {
            border-color: colors-old.$primary-color;
          }

          h3 {
            font-size: 18px;
            font-weight: font.$weight-bold;
            color: colors-old.$text-primary-color;
            margin-bottom: 10px;
            margin-top: 0;
          }

          span {
            font-size: 14px;
            font-weight: font.$weight-normal;
            color: colors-old.$text-secondary-color;
          }
        }
      }

      tbody {
        tr {
          animation: opacity 0.3s forwards;
          opacity: 0;

          &.archived {
            td {
              .item-element {
                background-color: colors-old.$light-background-color;
              }
            }
          }
        }
      }
    }
  }
}
</style>
