<template>
  <div id="main-menu" class="navigation" data-no-screenshot>
    <top-menu-bar v-if="!drawMode" class="menu-header" />
    <main-menu v-if="!drawMode" />
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";

import { key } from "@/Shortcuts";
import { boardActions } from "@/action/boardActions";
import MainMenu from "@/components/menu/MainMenu.vue";
import TopMenuBar from "@/components/menu/TopMenuBar.vue";
import SettingsModal from "@/components/modal/SettingsModal.vue";
import ShortcutsModal from "@/components/modal/ShortcutsModal.vue";
import EventBusUser from "@/mixins/EventBusUser";
import ShortcutUser from "@/mixins/ShortcutUser";
import { useDrawStore } from "@/store/draw";
import { useModalStore } from "@/store/modal";

@Options({ components: { MainMenu, TopMenuBar } })
export default class Menus extends mixins(ShortcutUser, EventBusUser) {
  created() {
    this.globalActionShortcut(boardActions.screenshot);
    this.globalShortcut("?", () => useModalStore().open(ShortcutsModal));
    this.globalShortcut(key(",", "altCtrl"), () =>
      useModalStore().open(SettingsModal, { attrs: { isUserOnBoard: true } }),
    );
  }

  get drawMode() {
    return useDrawStore().active;
  }
}
</script>

<style lang="scss">
#main-menu.navigation {
  .tooltip-content {
    font-size: 12px;
  }

  .menu-item {
    &.show,
    &.current,
    &.choose {
      > .tooltip {
        .tooltip-content {
          opacity: 0;
          visibility: hidden;
        }
      }

      .popout {
        left: 55px;
        overflow: visible;

        img.icon {
          filter: none;
        }
      }
    }
  }
}
</style>
