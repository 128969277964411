<script lang="ts" setup>
import { cardKey } from "@/components/card/injectKeys";
import { isDependency, isNote, isRisk, isWorkitem } from "@/model";
import { injectStrict } from "@/utils/context";

import DependencyFooter from "./components/DependencyFooter/DependencyFooter.vue";
import NoteFooter from "./components/NoteFooter/NoteFooter.vue";
import RiskFooter from "./components/RiskFooter/RiskFooter.vue";
import WorkItemFooter from "./components/WorkItemFooter/WorkItemFooter.vue";

const card = injectStrict(cardKey);
</script>

<template>
  <WorkItemFooter v-if="isWorkitem(card)" class="sticky-note-footer" />
  <RiskFooter v-if="isRisk(card)" class="sticky-note-footer" />
  <NoteFooter v-if="isNote(card)" class="sticky-note-footer" />
  <DependencyFooter v-if="isDependency(card)" class="sticky-note-footer" />
</template>

<style lang="scss" scoped>
@use "../../utils";

.sticky-note-footer {
  padding: utils.px-to-em(8px);
  max-width: 100%;
  overflow: hidden;
}
</style>
