import { onBeforeUnmount } from "vue";

interface Listener {
  type: string;
  listener: (this: Document, ev: any) => any;
  options?: boolean | AddEventListenerOptions;
}

export function useNativeEvents() {
  const listeners = new Array<Listener>();

  onBeforeUnmount(() => {
    for (const listener of listeners) {
      document.removeEventListener(
        listener.type,
        listener.listener,
        listener.options,
      );
    }
  });

  return {
    addDocumentEventListener<K extends keyof DocumentEventMap>(
      type: K,
      listener: (this: Document, ev: DocumentEventMap[K]) => any,
      options?: boolean | AddEventListenerOptions,
    ) {
      listeners.push({ type, listener, options });
      document.addEventListener(type, listener, options);
    },
  };
}
