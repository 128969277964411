import { RouteLocation } from "vue-router";

import { featureToggles } from "@/featureToggles";

export type Feature = "gpt" | "sticky-note" | "track-events";

export function isFeatureEnabled(
  route: Pick<RouteLocation, "query">,
  feature: Feature,
): boolean {
  return (
    isFeatureEnabledInQuery(route, feature) || featureToggles.isEnabled(feature)
  );
}

function isFeatureEnabledInQuery(
  route: Pick<RouteLocation, "query">,
  feature: string,
): boolean {
  if (!route?.query?.feature) {
    return false;
  }
  if (Array.isArray(route.query.feature)) {
    return route.query.feature.includes(feature);
  }
  return route.query.feature === feature;
}
