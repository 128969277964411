<script setup lang="ts">
import { computed } from "vue";

import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import SelectStickyType from "@/components/ui/SelectStickyType/SelectStickyType.vue";
import { useBoardStore } from "@/store/board";
import { useSearchMenuStore } from "@/store/searchMenu";

import StickyTypeItemTooltip from "../tooltips/StickyTypeItemTooltip.vue";

const store = useSearchMenuStore();
const stickyTypes = useBoardStore().allStickyTypes;

const selection = computed({
  get: () => store.selectedStickyTypes,
  set: (values: Array<{ id: string }>) =>
    (store.selectedStickyTypeIds = new Set(values.map((val) => val.id))),
});
</script>

<template>
  <DropdownMenu
    class="search-select select-sticky-type"
    width="sm"
    stay-on-click
  >
    <template #trigger="{ isOpen }">
      <IconButton icon="sticky-type" :activated="isOpen">
        <template #tooltip>
          <StickyTypeItemTooltip :types="selection" />
        </template>
      </IconButton>
      <InfoMarker v-if="selection.length" :top="4" :right="4" />
    </template>
    <SelectStickyType v-model="selection" :sticky-types="stickyTypes" />
  </DropdownMenu>
</template>
