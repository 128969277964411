<template>
  <BaseInput
    ref="input"
    control-type="text"
    icon-before="search"
    :placeholder="$t('action.search')"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";

import BaseInput from "@/components/ui/BaseInput/BaseInput.vue";

/**
 * This component renders a text input with search icon
 * It also adds a listener that captures keypresses and focuses the input
 */
@Options({ components: { BaseInput }, emits: ["update:modelValue"] })
export default class SearchInputSelfFocusing extends Vue {
  @Prop({ type: String, required: true }) readonly modelValue!: string;
  @Ref("input") readonly inputElem!: HTMLInputElement;

  mounted() {
    document.addEventListener("keydown", this.keydown);
  }

  unmounted() {
    document.removeEventListener("keydown", this.keydown);
  }

  keydown(e: KeyboardEvent) {
    if (this.inputElem) {
      if (e.key === "Escape") {
        this.inputElem.blur();
        return;
      }

      if (this.isCharacter(e.key)) {
        this.inputElem.focus();
      }

      if (e.key === "Backspace" && this.modelValue.length > 0) {
        this.inputElem.focus();
      }
    }
  }

  isCharacter(keyValue: string) {
    return keyValue.length === 1;
  }
}
</script>
