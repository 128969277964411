<template>
  <div class="boards-item">
    <ActionButton
      v-if="hasSolutionBacklogBoard"
      :action="actions.goToSolutionBacklog"
      :activated="current === 'solution_backlog'"
      position="right"
    />
    <ActionButton
      v-if="hasSolutionBoard"
      :action="actions.goToSolution"
      :activated="current === 'solution'"
      position="right"
    />
    <menu-item-delimiter v-if="hasSolutionBoard" horizontal />
    <ActionButton
      :action="actions.goToBacklog"
      :activated="current === 'backlog'"
      position="right"
    />
    <ActionButton
      :action="actions.goToProgram"
      :activated="current === 'program'"
      position="right"
    />
    <ActionButton
      :action="actions.goToObjectives"
      :activated="current === 'objectives'"
      position="right"
    />
    <ActionButton
      :action="actions.goToRisk"
      :activated="current === 'risk'"
      position="right"
    />
    <ActionButton
      :action="actions.goToTeam"
      :activated="current === 'team'"
      position="right"
    />
    <ActionButton
      :action="actions.goToCanvas"
      :activated="current === 'flex'"
      position="right"
    />
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { RouteLocationNormalizedLoaded } from "vue-router";

import { navigationActions } from "@/action/navigationActions";
import MenuItemDelimiter from "@/components/menu/components/MenuItemDelimiter.vue";
import ActionButton from "@/components/ui/ActionButton/ActionButton.vue";
import EventBusUser from "@/mixins/EventBusUser";
import ShortcutUser from "@/mixins/ShortcutUser";
import { useBoardStore } from "@/store/board";

@Options({ components: { ActionButton, MenuItemDelimiter } })
export default class BoardsItem extends mixins(ShortcutUser, EventBusUser) {
  current: string | null = null;
  actions = navigationActions;

  @Watch("$route", { immediate: true })
  routeChanged(route: RouteLocationNormalizedLoaded) {
    //ok as board param is not repeatable
    this.current = route.params.board as string;
  }

  created() {
    Object.values(this.actions).forEach((action) =>
      this.globalActionShortcut(action),
    );
  }

  get hasSolutionBacklogBoard() {
    return useBoardStore().hasSolutionBacklogBoard;
  }

  get hasSolutionBoard() {
    return useBoardStore().hasSolutionBoard;
  }
}
</script>

<style lang="scss" scoped>
.boards-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  .menu-item {
    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &.solution-item {
      margin-bottom: 0;
    }
  }
}
</style>
