<script lang="ts">
import { Options, Vue } from "vue-class-component";

import {
  CapacityAllocation,
  calcCapacityAllocation,
} from "@/components/modal/metrics";
import { i18n } from "@/i18n";
import { StickyType } from "@/model";
import { useBoardStore } from "@/store/board";

import MetricsModalCapacityAllocationBody, {
  CapacityAllocationBodyItem,
} from "./MetricsModalCapacityAllocationBody.vue";

@Options({ mixins: [MetricsModalCapacityAllocationBody] })
export default class OverallMetricsModalCapacityAllocationBody extends Vue {
  title = i18n.global.t("metricsModal.allTeams");
  selected: StickyType | null = null;
  hoverSelected: StickyType | null = null;

  get items(): CapacityAllocationBodyItem[] {
    return this.boards.map((board) => {
      return {
        name: board.team.name,
      };
    });
  }

  get boards() {
    return useBoardStore().artTeamBoards();
  }

  iterData(iterationIndex?: number): CapacityAllocation {
    return calcCapacityAllocation(
      iterationIndex !== undefined
        ? [this.boards[iterationIndex]]
        : this.boards,
    );
  }
}
</script>
