import { bootstrap, set, setOptions } from "vue-gtag";
import { RouteLocation, RouteLocationRaw } from "vue-router";

import { gaKey, intercomApi, intercomId, isCloud } from "@/Environment";
import { openConnection } from "@/backend/Connection";
import { initFeatureToggles } from "@/featureToggles";
import { whenChildElementWithClass } from "@/lowlevel";
import { AuthUser, Role, User } from "@/model";
import {
  currentRoute,
  getTargetPageName,
  goToLogin,
  goToLogout,
} from "@/router/navigation";
import { resetUser, setUser } from "@/sentry";
import { clearUserCache } from "@/services/user.service";
import { useLicenseStore } from "@/store/license";
import { useMessengerStore } from "@/store/messenger";
import { useUserStore } from "@/store/user";

import { identifyUser } from "./utils/analytics/track";

export type Target = RouteLocation["query"] & { path: string };

export async function login(
  user: User,
  target: Target,
): Promise<RouteLocationRaw> {
  useUserStore().technicalUser = user;
  setUser(user.company, user.id);
  const authUser = await connect(user);
  initUserTracking(authUser, user, target);
  initIntercom(authUser, user);
  const query = { ...target } as RouteLocation["query"];
  delete query.session;
  delete query.board;
  delete query.team;
  delete query.path;
  return { path: target.path || "/page/session", query };
}

async function connect(user: User): Promise<AuthUser> {
  const connectionName = `${user.company}:${user.id}:${Math.floor(
    Date.now() / 1000,
  )}`;
  const [authUser] = await Promise.all([
    useUserStore().loadCurrentUser(),
    initFeatureToggles(user.company),
    openConnection(
      () => currentRoute().path,
      { login: goToLogin, logout: goToLogout },
      connectionName,
    ),
  ]);
  return authUser;
}

async function initUserTracking(
  authUser: AuthUser,
  user: User,
  target: Target,
) {
  if (isCloud && useLicenseStore().license.tracking) {
    identifyUser();
  }

  if (isCloud && gaKey && useLicenseStore().license.tracking) {
    setOptions({
      config: {
        id: gaKey,
        params: {
          page_title: getTargetPageName(target),
          user_id: authUser.id,
          language: authUser.preferredLanguage,
        },
      },
    });
    await bootstrap();
    set({ user_properties: { company: user.company, role: user.role } });
  }
}

function initIntercom(authUser: AuthUser, user: User) {
  const win = window as any;
  if (
    isCloud &&
    intercomId &&
    intercomApi &&
    useLicenseStore().license.tracking
  ) {
    win.Intercom = null; // delete existing intercom (dummy) instance
    win.loadIntercom(intercomId, intercomApi);
  }

  // show the custom intercom launcher only when the default launcher would be shown (it's in the DOM but made invisible by CSS)
  // the reason is that the default launcher can be configured to appear only in certain conditions (e.g. user role)
  // custom launcher cannot be configured like this.
  // Remove this code when we don't need this function anymore (because intercom launcher should always be shown)
  ifDefaultLauncherShown(() => (useMessengerStore().show = true));

  Intercom("boot", {
    custom_launcher_selector: ".messenger-item",
    email: authUser.email,
    name: authUser.name,
    user_id: authUser.id,
    user_hash: authUser.hash,
    role: intercomRoles[user.role],
    language_override: authUser.preferredLanguage,
    avatar: {
      type: "avatar",
      image_url: authUser.imageUrl || "",
    },
    company: {
      company_id: user.company,
      name: user.company,
      plan: useLicenseStore().license.plan,
    },
  });
}

function ifDefaultLauncherShown(action: () => void) {
  whenChildElementWithClass(document.body, "intercom-lightweight-app", (app) =>
    whenChildElementWithClass(app, "intercom-launcher", action),
  );
}

const intercomRoles: { [role in Role]: string } = {
  observer: "Observer",
  user: "Member",
  admin: "Admin",
  planning_interval_admin: "PI Admin",
};

export function clearUserData() {
  clearUserCache();
  useUserStore().resetUser();
  resetUser();
}
