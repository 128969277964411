<script setup lang="ts">
import DropdownMenu, {
  Props as DropdownMenuProps,
} from "@/components/DropdownMenu/DropdownMenu.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import { Icon } from "@/types/icon";

const props = defineProps<{
  icon?: Icon;
  tooltipText?: string;
  dropdownWidth?: DropdownMenuProps["width"];
}>();
</script>

<template>
  <div ref="rootRef" class="action-menu-item">
    <DropdownMenu stay-on-click disable-teleport :width="props.dropdownWidth">
      <template #trigger>
        <BaseTooltip :delay="[500, 0]" class="tooltip">
          <div class="content">
            <slot>
              <SvgIcon v-if="props.icon" :name="props.icon" size="20" />
            </slot>
          </div>
          <template v-if="tooltipText" #content>{{ tooltipText }}</template>
        </BaseTooltip>
      </template>

      <div v-if="$slots.dropdown">
        <slot name="dropdown" />
      </div>
    </DropdownMenu>

    <div v-if="$slots.dropdown" class="dropdown-icon">
      <svg
        width="6"
        height="6"
        viewBox="0 0 6 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.99993 1.06863e-10C5.55221 -7.98692e-06 5.99993 0.447701 5.99994 0.999986L6 4.99999C6 5.2652 5.89465 5.51956 5.70711 5.7071C5.51958 5.89464 5.26522 6 5 6H1C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H3.99999L3.99994 1.00001C3.99993 0.44773 4.44764 7.98713e-06 4.99993 1.06863e-10Z"
          fill="white"
          fill-opacity="0.5"
        />
      </svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
.action-menu-item {
  position: relative;
  height: 36px;
  width: 36px;

  .tooltip {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .content {
    padding: 8px;
    height: 100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .dropdown-icon {
    pointer-events: none;
    display: grid;
    place-items: center;
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
}
</style>
