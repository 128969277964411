<script setup lang="ts">
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import { Team } from "@/model";
import { NO_TEAM_ID, useTeamStore } from "@/store/team";
import { injectStrict } from "@/utils/context";

import TeamSelector from "../TeamSelector/TeamSelector.vue";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);

// Get the team the card is currently assigned to
const selectedTeam = computed(() => {
  const teamId = card.teamId;
  return teamId ? (useTeamStore().findTeam({ id: teamId }) as Team) : undefined;
});

// Assign the card to the given team
const handleTeamSelect = (team: Team | null) => {
  cardActions.setTeamAction("card", card.id, team?.id || NO_TEAM_ID);
  dropdown.close();
};
</script>

<template>
  <div class="team-dropdown">
    <TeamSelector
      :show-clear="true"
      :title="$t('action.team')"
      :show-current-team="true"
      :show-search="true"
      :show-other-arts="false"
      :selected="selectedTeam"
      @select="handleTeamSelect"
    />
  </div>
</template>

<style lang="scss" scoped>
.team-dropdown {
  width: 240px;
  height: 295px;
}
</style>
