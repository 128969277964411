import { FlagColor, FlagIcon } from "@/model";
import { StickersIcon } from "@/types/icon";

export const iconMap: Record<FlagIcon, StickersIcon> = {
  none: "stickers/none",
  flag: "stickers/flag",
  check: "stickers/checkmark",
  round: "stickers/dot",
  star: "stickers/star",
  question: "stickers/question-mark",
  exclam: "stickers/exclamation-mark",
};

export const colorMap: Record<number, FlagColor> = {
  0: "red",
  1: "orange",
  2: "yellow",
  3: "blue",
  4: "purple",
  5: "green",
};
