<template>
  <div class="sticky-link-stickies">
    <search-input-self-focusing
      v-model="query"
      wrapper-class="link-modal-search-input"
    />
    <div class="scrollable-list">
      <navigable-list :first-list="filteredCards" @select="toggleLink">
        <template #row="{ item }">
          <linkable-card-row
            :linkable-card="item"
            show-team-name
            show-actions
          />
        </template>
      </navigable-list>
    </div>
    <span v-if="!filteredCards.length" class="empty-placeholder">
      {{ $t("linkModal.noResult") }}
    </span>
  </div>
</template>
<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { linkActions } from "@/action/linkActions";
import LinkableCardRow from "@/components/LinkableCardRow.vue";
import NavigableList from "@/components/NavigableList.vue";
import SearchInputSelfFocusing from "@/components/SearchInputSelfFocusing.vue";
import SearchBase from "@/mixins/SearchBase";
import { Card } from "@/model";
import { useBoardStore } from "@/store/board";
import { useSelectionStore } from "@/store/selection";

import { LinkableCard, linkableCards } from "./stickyLinkStickies";

@Options({
  components: { NavigableList, LinkableCardRow, SearchInputSelfFocusing },
  emits: ["link-change"],
})
export default class StickyLinkStickiesTab extends mixins(SearchBase) {
  @Prop({ type: Array, required: true }) readonly cards!: Card[];
  query = "";

  get linkableCards(): LinkableCard[] {
    return linkableCards(this.cards);
  }

  toggleLink(targetCard: LinkableCard) {
    if (useBoardStore().areMultipleStickiesSelected) {
      useSelectionStore().addLinkingToHistory();
    }
    if (targetCard.linked) {
      targetCard.linked = false;
      this.cards.forEach((card) => {
        linkActions.remove("modal", {
          id: card.id,
          toId: targetCard.id,
        });
      });
    } else {
      targetCard.linked = true;
      this.cards.map((card) => {
        linkActions.add("modal", {
          id: card.id,
          toId: targetCard.id,
        });
      });
    }
    this.$emit("link-change");
  }

  get filteredCards(): LinkableCard[] {
    return this.query === "" ? this.linkableCards : this.found;
  }

  @Watch("query")
  queryChanged() {
    const query = this.query.toLowerCase();
    this.search(this.linkableCards, (linkableItem) =>
      isQueryMatch(linkableItem),
    );

    function isQueryMatch(linkableItem: LinkableCard) {
      return (
        query.length === 0 ||
        linkableItem.text.toLowerCase().includes(query) ||
        !!linkableItem.almId?.toLowerCase().includes(query)
      );
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/modal" as *;
@use "@/styles/utils";

.sticky-link-stickies {
  .link-modal-search-input {
    margin-bottom: 16px;
  }

  .empty-placeholder {
    @include empty-placeholder;
  }

  .scrollable-list {
    overflow-y: scroll;
    max-height: 45vh;

    @include utils.hide-scrollbar;
  }
}
</style>
