<script setup lang="ts">
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { InfoLevel } from "@/model";
import { Icon } from "@/types/icon";

defineProps<{ type: InfoLevel }>();

const icon: { [type in InfoLevel]: Icon } = {
  default: "iteration/default",
  ok: "iteration/ok",
  warn: "iteration/warn",
  error: "iteration/error",
};
</script>

<template>
  <SvgIcon :name="icon[type]" class="iteration-flag" :class="type" />
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.iteration-flag {
  &.default {
    color: colors-old.$iteration-default-color;
  }

  &.ok {
    color: colors-old.$iteration-success-color;
  }

  &.warn {
    color: colors-old.$iteration-warning-color;
  }

  &.error {
    color: colors-old.$iteration-error-color;
  }
}
</style>
