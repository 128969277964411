<script setup lang="ts">
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import CardFlag from "@/components/card/CardFlag";
import StickersSelector from "@/components/card/components/ActionMenu/components/StickersSelector/StickersSelector.vue";
import { cardKey } from "@/components/card/injectKeys";
import { injectStrict } from "@/utils/context";

import { colorMap, iconMap } from "./flagMap";

const card = injectStrict(cardKey);

const iconName = computed(
  () => iconMap[card.flagType.icon] || "stickynote/stickers",
);

const handleFlagChange = (flag: CardFlag | null) => {
  cardActions.setFlag("card", card.id, flag || CardFlag.emptyFlag());
};

const color = computed(() => colorMap[card.flagType.colorIndex]);

const triggerClass = computed(() => {
  const classes = ["trigger"];
  const isActive = !card.flagType.isEmpty();

  if (!card.flagType.isEmpty()) {
    classes.push("active");
  }

  if (color.value && isActive) {
    classes.push(color.value);
  }

  return classes;
});
</script>

<template>
  <div class="flag-chip">
    <DropdownMenu>
      <template #trigger>
        <div :class="triggerClass">
          <SvgIcon :name="iconName" />
        </div>
      </template>
      <StickersSelector
        :no-sticker-option="false"
        :selected="card.flagType"
        @select="handleFlagChange"
      />
    </DropdownMenu>
  </div>
</template>

<style scoped lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "../../../../utils";

.flag-chip {
  .trigger {
    display: grid;
    align-items: center;
    width: utils.px-to-em(20px);
    height: utils.px-to-em(20px);
    padding: utils.px-to-em(3px);
    color: rgba(colors-old.$text-primary-color, 0.3);
    border: utils.px-to-em(1px) dashed currentcolor;
    border-radius: 50%;
    cursor: pointer;

    &:hover:not(.active) {
      color: colors-old.$primary-color;
    }

    &.active {
      border: none;
      padding: 0;
    }

    $flag-colors: (
      blue: colors-old.$flag-color4,
      green: colors-old.$flag-color6,
      orange: colors-old.$flag-color2,
      purple: colors-old.$flag-color5,
      red: colors-old.$flag-color1,
      yellow: colors-old.$flag-color3,
    );

    @each $color-name, $color-value in $flag-colors {
      &.#{"#{$color-name}"} {
        color: $color-value;
      }
    }
  }
}
</style>
