import { defineStore } from "pinia";
import { ref } from "vue";

import { Card } from "@/model";

export const useZoomStore = defineStore("zoom", () => {
  const zoomedStickyNoteId = ref<Card["id"] | null>(null);
  const withFocus = ref(false);

  function zoomStickyNote(id: string, focus?: boolean) {
    zoomedStickyNoteId.value = id;
    withFocus.value = focus || false;
  }

  return { zoomedStickyNoteId, withFocus, zoomStickyNote };
});
