/**
 * Keeps track of the server time and its difference to the local user's time.
 * Publishes every second a "serverTick" event with the current server time.
 */
import { getServerInfo, loggedIn } from "@/backend/Backend";
import { sendServerTick } from "@/mixins/EventBusUser";
import { ServerTime } from "@/model";
import { isBrowserTabActive } from "@/utils/dom";

const maxTimeDiffBetweenTicks = 3000;
const maxDelayForSyncRequest = 2000;
const maxSyncRetries = 5;

// difference between server time and local time
let timeDiff = 0;
let lastTime = 0;
let shouldSync = false;
let timeout = 0;

export let serverTime: ServerTime = Date.now() as ServerTime;

export async function startServerTicks() {
  await syncServerTime();
  updateTime();
  nextTick();
}

export function stopServerTicks() {
  clearTimeout(timeout);
}

async function nextTick() {
  if (shouldSync || Math.abs(Date.now() - lastTime) > maxTimeDiffBetweenTicks) {
    if (loggedIn() && isBrowserTabActive()) {
      await syncServerTime();
      shouldSync = false;
    } else {
      shouldSync = true;
    }
  }
  updateTime();
  sendServerTick(serverTime);
  timeout = window.setTimeout(nextTick, 1000);
}

function updateTime() {
  lastTime = Date.now();
  serverTime = (lastTime + timeDiff) as ServerTime;
}

async function syncServerTime(retry = 0) {
  const before = Date.now();
  const info = await getServerInfo();
  const after = Date.now();
  if (after - before > maxDelayForSyncRequest && retry < maxSyncRetries) {
    await syncServerTime(retry + 1);
  } else {
    const estimatedArrivalAtServer = (after + before) / 2;
    timeDiff = info.time * 1000 - estimatedArrivalAtServer;
  }
}
