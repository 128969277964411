import { getCurrentInstance, onBeforeUnmount } from "vue";

import {
  Key,
  ShortcutOptions,
  accept,
  registerShortcut,
  unregisterShortcuts,
} from "@/Shortcuts";
import { Action } from "@/action/actions";
import { useModalStore } from "@/store/modal";

export function useShortcuts() {
  function globalShortcut(
    key: Key,
    handler: (e: KeyboardEvent) => void,
    options?: ShortcutOptions<any>,
  ) {
    registerShortcut(
      getCurrentInstance(),
      (e) => !useModalStore().isModalOpen() && accept(key)(e),
      handler,
      options,
    );
  }

  function globalActionShortcut(action: Action<any[]>) {
    if (action.data.shortcut) {
      globalShortcut(action.data.shortcut.key, () => action("keyboard"));
    }
  }

  onBeforeUnmount(() => {
    unregisterShortcuts(getCurrentInstance());
  });

  return { globalShortcut, globalActionShortcut };
}
