<template>
  <div class="card-flags-overlay overlay flags scrollable no-wheel" @click.stop>
    <flag-picker
      :selected="selected"
      :no-sticker-option="noStickerOption"
      @select="select"
    />
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

import CardFlag from "@/components/card/CardFlag";
import FlagPicker from "@/components/flags/FlagPicker.vue";
import ShortcutUser from "@/mixins/ShortcutUser";

@Options({ components: { FlagPicker }, emits: ["select"] })
export default class CardFlagsOverlay extends mixins(ShortcutUser) {
  @Prop([Array, Object]) readonly selected?: CardFlag[] | CardFlag;
  @Prop(Boolean) readonly noStickerOption: boolean | undefined;

  @Emit()
  select(_cardFlag: CardFlag) {}
}
</script>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.flag-picker {
  :deep(&__row) {
    border-bottom: 0.1em solid colors-old.$divider-color;
    cursor: pointer;
    font-size: 150%;
    margin-left: 1.7em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  :deep(.flag-icon::before) {
    content: none;
  }
}
</style>
