import { AxiosError } from "axios";

import { getConfig } from "@/Environment";
import { BackendError } from "@/backend/BackendError";
import { CancelError } from "@/backend/CancelError";
import { RpcBackend } from "@/backend/RpcBackend";
import { translateRPCPath } from "@/backend/natsTopicMapper";
import { createCallContext, createCallHeaders } from "@/backend/requestHelper";
import { handleResponse } from "@/backend/responseHelper";
import { ServerAlmType } from "@/backend/serverModel";
import { createApiClient } from "@/services/api.config";
import { useStatisticsStore } from "@/store/statistics";

export class HttpRpcBackend implements RpcBackend {
  private sessionId!: string;
  private almType?: ServerAlmType;
  private apiClient = createApiClient(getConfig().piserverAPIUrl);

  init(sessionId: string, almType?: ServerAlmType) {
    this.sessionId = sessionId;
    this.almType = almType;
  }

  async doCall(
    path: string,
    args: any[] = [],
    kwargs?: object,
    timeout?: number,
  ): Promise<any> {
    const context = createCallContext(path, args, kwargs, timeout, 0);
    try {
      const res = await this.apiClient.get(
        `ch/rentouch/${path}/${args.join("/")}`,
        {
          params: kwargs,
          headers: createCallHeaders(context),
          timeout: context.requestTimeout,
        },
      );
      useStatisticsStore().current.calls.addOk();
      return handleResponse(res.data, context, path);
    } catch (err) {
      useStatisticsStore().current.calls.addFail(err);
      if (err instanceof CancelError) {
        throw err;
      }
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_BAD_RESPONSE && err.response?.data) {
          return handleResponse(err.response.data, context, path);
        }
      }
      const message = (err as any).message || err;
      throw new BackendError(
        message,
        `HTTP Error while calling RPC method ${path}`,
        {
          request: context,
          response: { message },
        },
      );
    }
  }

  @translateRPCPath
  async sessionCall<T>(
    path: string,
    args: any[] = [],
    kwargs?: Record<any, any>,
  ) {
    const response = await this.doCall(
      `piplanning/6/room/session/${path}`,
      [this.sessionId, ...args],
      kwargs,
    );
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  @translateRPCPath
  async boardCall<T>(path: string, args: any[], kwargs?: Record<string, any>) {
    const response = await this.doCall(
      `piplanning/6/room/board/${path}`,
      args,
      kwargs,
    );
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  @translateRPCPath
  async flexBoardCall<T>(
    path: string,
    args: any[] = [],
    kwargs?: Record<any, any>,
  ) {
    const response = await this.doCall(
      `piplanning/6/room/session/${path}`,
      args,
      kwargs,
    );
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  @translateRPCPath
  async roomCall<T>(path: string, args: any[] = []) {
    const response = await this.doCall(`piplanning/6/room/${path}`, args);
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  @translateRPCPath
  async adminCall<T>(path: string, args: any[] = []) {
    const response = await this.doCall(`piplanning/6/room/${path}`, args);
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  @translateRPCPath
  async almCall<T>(path: string, args: any[] = []) {
    const response = await this.doCall(`piplanning/6/room/${path}`, args);
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  @translateRPCPath
  async almToolCall<T>(
    _path: string,
    _args: any[],
    _timeout?: number,
  ): Promise<T> {
    throw new Error("unsupported http call to almsync");
  }

  @translateRPCPath
  async staticSessionCall<T>(
    path: string,
    args: any[] = [],
    kwargs?: Record<any, any>,
  ) {
    const response = await this.doCall(
      `piplanning/6/room/session/${path}`,
      args,
      kwargs,
    );
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  @translateRPCPath
  async serverCall<T>(path: string) {
    const response = await this.doCall(`piplanning/6/room/server/${path}`);
    // FIXME: Casting break type checking. See below.
    return response as T;
  }

  async userCall<T>(_path: string): Promise<T> {
    throw new Error("unsupported http call to almsync");
  }
}
