import * as amplitude from "@amplitude/analytics-browser";

import {
  amplitudeApiKey,
  amplitudeDebugMode,
  getConfig,
  isCloud,
} from "@/Environment";
import { isFeatureEnabled } from "@/feature";
import { currentRoute } from "@/router/navigation";
import { useLicenseStore } from "@/store/license";
import { useUserStore } from "@/store/user";

import { AnalyticsEvent } from "./events";

type CustomAmplitudeUserProperty = "company" | "role" | "plan" | "language";

export const trackEvent = (params: AnalyticsEvent) => {
  if (!shouldTrack()) return;

  const { event, ...data } = params;

  amplitude.track(event, data);
};

export function identifyUser() {
  if (!amplitudeApiKey) return;

  amplitude.init(amplitudeApiKey, {
    defaultTracking: false,
    serverZone: "EU",
    trackingOptions: {
      ipAddress: false,
    },
  });
  amplitude.setUserId(useUserStore().technicalUser.id);

  const identifyEvent = new amplitude.Identify();
  identifyEvent.set("company", useUserStore().technicalUser.company);
  identifyEvent.set("role", useUserStore().technicalUser.role);
  identifyEvent.set("plan", useLicenseStore().license.plan);
  identifyEvent.set("build_hash", getConfig().build.hash);

  const lang = useUserStore().preferredLanguage;
  if (lang) {
    identifyEvent.set("language", lang);
  }

  amplitude.identify(identifyEvent);
}

/**
 * Set any custom amplitude user property
 * (the new value will be added to all future events)
 *
 * @param property Property name
 * @param value Property value
 */
export function setAmplitudeUserProperty(
  property: CustomAmplitudeUserProperty,
  value: amplitude.Types.ValidPropertyType,
) {
  const identifyEvent = new amplitude.Identify();
  identifyEvent.set(property, value);
  amplitude.identify(identifyEvent);

  // eslint-disable-next-line no-console
  if (amplitudeDebugMode) console.log("user property updated", property, value);
}

const shouldTrack = () => {
  return (
    isFeatureEnabled(currentRoute(), "track-events") &&
    isCloud &&
    useLicenseStore().license.tracking
  );
};
