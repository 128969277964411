import { Watch } from "vue-property-decorator";

import * as Gestures from "@/Gestures";
import { dragScroll } from "@/Gestures";
import { fakeZoom, longClick } from "@/Settings";
import { keys, registerShortcut } from "@/Shortcuts";
import { ActionSource } from "@/action/actions";
import { boardActions } from "@/action/boardActions";
import { cardActions } from "@/action/cardActions";
import BoardBase from "@/components/BoardBase";
import BoardContextMenu, {
  BoardContextMenuMode,
} from "@/components/menu/BoardContextMenu.vue";
import ConfirmArrangeModal from "@/components/modal/ConfirmArrangeModal.vue";
import { applyFluidBoardSize } from "@/lowlevel";
import { calcBoardSize } from "@/math/coordinate-systems";
import { RelativeCoordinate, clientCoord } from "@/math/coordinates";
import { BoardCard, Card, Size } from "@/model";
import { useAppSizeStore } from "@/store/appSize";
import { useBoardStore } from "@/store/board";
import { useContextMenuStore } from "@/store/contextMenu";
import { useDrawStore } from "@/store/draw";
import { useModalStore } from "@/store/modal";
import { useSelectionStore } from "@/store/selection";
import { useToastStore } from "@/store/toast";
import { useUserStore } from "@/store/user";

export interface ContextInfo extends BoardContextActions {
  selection?: SelectionContextActions;
  region?: { name: string } & RegionContextActions;
}

export const emptyContextInfo: ContextInfo = {
  syncProgramBacklog: false,
  draw: false,
};

export type ContextActions = BoardContextActions &
  SelectionContextActions &
  RegionContextActions;

export interface BoardContextActions {
  syncProgramBacklog: boolean;
  draw: boolean;
}

export interface SelectionContextActions {
  stickyMove: boolean;
  link: boolean;
  mirror: boolean;
  team: boolean;
}

export interface RegionContextActions {
  arrange: boolean;
  overview: boolean;
  sync: boolean;
  zoom: boolean;
}

export interface CardSize extends Size {
  id: string;
}

export interface ContextActionEvent {
  source: ActionSource;
}

export interface LocatedContextActionEvent extends ContextActionEvent {
  coord: RelativeCoordinate;
}

export interface CardComponent {
  card: Card;
  zoom: (zoom: boolean) => void;
  textEdit: boolean;
}

export default class FluidBoard extends BoardBase {
  zoomed = false;
  fixedFS = 0;
  zoomCard: CardComponent | null = null;
  pointerCard: CardComponent | null = null;
  boardSize = { width: 0, height: 0 };

  mounted() {
    const el = this.$el as HTMLElement;
    Gestures.onLongClick(el, longClick, (e) => {
      if (!useDrawStore().active) {
        cardActions.add("mouse", { pos: e.pos });
      }
    });
    // TODO this is done multiple times, avoid!
    dragScroll(el);
    el.addEventListener("contextmenu", (e) => {
      // don't open the context menu when clicking on the card > text input
      const target = e.target as HTMLElement;
      if (target.classList.contains("card-text-input")) return;

      useContextMenuStore().open(BoardContextMenu, {
        position: clientCoord(e),
        mode: "mouse" as BoardContextMenuMode,
      });
    });
    registerShortcut(
      this,
      keys("Shift"),
      () => {
        useSelectionStore().selecting = "hover";
        el.style.cursor = "copy";
        if (this.pointerCard) {
          boardActions.toggleCardSelection("mouse", this.pointerCard.card.id);
        }
        setTimeout(() => {
          if (useBoardStore().selectedStickyIds.length === 0) {
            useToastStore().show(/*$t*/ "message.selectStickyHint");
          }
        }, 1000);
      },
      {
        up() {
          useSelectionStore().selecting = "no";
          el.style.cursor = "auto";
        },
      },
    );
    registerShortcut(
      this,
      keys("Meta", "Control"),
      () => {
        useSelectionStore().singleCard = true;
        setTimeout(() => {
          if (
            useSelectionStore().singleCard &&
            useBoardStore().selectedStickies.length > 1
          ) {
            useToastStore().show(/*$t*/ "message.singleCardMode");
          }
        }, 1000);
      },
      {
        up() {
          useSelectionStore().singleCard = false;
        },
      },
    );
    registerShortcut(
      this,
      " ",
      () => {
        useBoardStore().magnifying = true;
        el.style.cursor = "zoom-in";
        this.zoomCard = this.pointerCard;
        this.pointerCard?.zoom(true);
        if (!this.pointerCard) {
          this.zoomed = false;
          setTimeout(() => {
            if (!this.zoomed) {
              useToastStore().show(/*$t*/ "message.keepBarPressed");
            }
          }, 1000);
        }
      },
      {
        up() {
          useBoardStore().magnifying = false;
          el.style.cursor = "auto";
          if (this.zoomCard) {
            this.zoomCard.textEdit = true;
          }
        },
      },
    );
  }

  activated() {
    window.addEventListener("resize", this.onWindowResize);
  }

  deactivated() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize() {
    this.applyBoardSize();
  }

  get appSize() {
    return useAppSizeStore().appSize;
  }

  get height() {
    return this.boardSize.height * fakeZoom;
  }

  get width() {
    return this.boardSize.width * fakeZoom;
  }

  get fixedFontSize() {
    if (this.active) {
      this.fixedFS = 100 / this.appSize.zoom;
    }
    return this.fixedFS;
  }

  get readOnly() {
    return !useUserStore().isAllowed("edit");
  }

  @Watch("appSize.padding.left")
  @Watch("appSize.padding.top")
  @Watch("appSize.margin.right")
  onSpaceChange() {
    if (useBoardStore().isCurrentBoardFluid) {
      this.applyBoardSize(true);
    }
  }

  @Watch("appSize.zoom")
  onZoom() {
    this.applyBoardSize();
  }

  applyBoardSize(smooth = false) {
    const newBoardSize = calcBoardSize();
    this.boardSize.height = newBoardSize.height;
    this.boardSize.width = newBoardSize.width;

    applyFluidBoardSize(newBoardSize, smooth);
    useAppSizeStore().setAppSize(newBoardSize);
  }

  setZoomCard(c: CardComponent | null) {
    this.zoomCard = c;
    if (c) {
      this.zoomed = true;
    }
  }

  getRelativeCardSizes(): CardSize[] {
    const res = [];
    for (const id in this.board.cards) {
      const card = this.board.cards[id];
      res.push(this.calcRelativeCardSize(card));
    }
    return res.sort((a, b) => a.order - b.order);
  }

  calcRelativeCardSize(card: BoardCard): CardSize & { order: number } {
    return {
      id: card.data.id,
      left: card.meta.pos.x - this.board.cardSize.x / 2,
      top: card.meta.pos.y - this.board.cardSize.y / 2,
      width: this.board.cardSize.x,
      height: this.board.cardSize.y,
      order: card.meta.zIndex,
    };
  }

  overview(_: any, _source: ActionSource) {
    throw Error("Implement in board");
  }

  contextActions(_c?: RelativeCoordinate): ContextInfo {
    return emptyContextInfo;
  }

  doContextAction(action: keyof ContextActions, coord: RelativeCoordinate) {
    const name = action + "Action";
    const target = (this as any)[name] as (
      event: LocatedContextActionEvent,
    ) => void;
    if (typeof target === "function") {
      return target({ coord, source: "contextMenu" });
    }
  }

  //default context action implementations
  arrangeAction(event: LocatedContextActionEvent) {
    const loc = useBoardStore().boardLocation(event.coord);
    useModalStore().open(ConfirmArrangeModal, {
      attrs: {
        boardId: this.board.id,
        location: loc.index(),
      },
    });
  }

  overviewAction(event: LocatedContextActionEvent) {
    this.overview(useBoardStore().boardLocation(event.coord), event.source);
  }

  zoomAction(event: LocatedContextActionEvent) {
    boardActions.zoomToRegion(
      event.source,
      useBoardStore().boardLocation(event.coord).bounds,
    );
  }
}

export function fluidBoard(board: BoardBase) {
  return (board as any).boardComponent?.() || board;
}
