<template>
  <canvas id="zoom-canvas" ref="canvas" width="9600" height="5400" />
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { Ref } from "vue-property-decorator";

import { editZoom } from "@/Settings";
import { ZoomLayer } from "@/ZoomLayer";
import { Color } from "@/baseTypes";
import BoardBase from "@/components/BoardBase";
import { fluidBoard } from "@/components/FluidBoard";
import { optimalFontSize } from "@/fontSizeOptimizer";
import { Board, Card, Size, isBacklogBoard, isFaded } from "@/model";
import { useBoardStore } from "@/store/board";
import color from "@/styles/color.module.scss";
import { contrastCssColor, shadeColor, toCssColor } from "@/utils/color";

interface Font {
  factor: number;
  family: string;
  weight: string;
  lineHeight: number;
}

export default class DefaultZoomLayer extends Vue implements ZoomLayer {
  @Ref("canvas") readonly canvasElem!: HTMLCanvasElement;
  ctx!: CanvasRenderingContext2D;

  mounted() {
    this.ctx = this.canvasElem.getContext("2d")!;
  }

  // paint a simplified version of a board's stickies
  async paintCards(component: BoardBase, board: Board) {
    this.ctx.clearRect(0, 0, this.canvasElem.width, this.canvasElem.height);
    this.show();
    let font: Font = null!;

    for (const relativeSize of fluidBoard(component).getRelativeCardSizes()) {
      const size = {
        left: relativeSize.left * this.canvasElem.width,
        top: relativeSize.top * this.canvasElem.height,
        width: relativeSize.width * this.canvasElem.width,
        height: relativeSize.height * this.canvasElem.height,
      };
      const cardElem = document.getElementById(relativeSize.id);
      // in some rare timing occasions, cardElem might be undefined
      if (cardElem) {
        let textElem = cardElem.querySelector(
          ".card-text-input",
        ) as HTMLElement;

        // new sticky note text input
        textElem ??= cardElem.querySelector(
          ".sticky-note .sticky-note-text-input",
        ) as HTMLTextAreaElement;

        font = font || this.calcFont(textElem);

        const card = board.cards[relativeSize.id];
        const cardColor = this.cardColor(
          card.data,
          useBoardStore().currentBoard(),
        );

        const isSelected = useBoardStore().isStickySelected(card.data);
        this.drawCard(size, cardColor, isSelected);

        const fontData = await optimalFontSize(textElem, card.data.text, false);
        this.drawText(
          size,
          cardColor,
          fontData.textLines,
          this.applyFont(font, textElem),
          font.lineHeight,
        );

        if (isFaded(card.meta.mark)) {
          this.ctx.fillStyle = color.lowlight;
          this.ctx.fillRect(size.left, size.top, size.width, size.height);
        }
      }
    }
  }

  cardColor(card: Card, board: Board) {
    return isBacklogBoard(board.type) && card.teamId
      ? card.type.altColor
      : card.type.color;
  }

  show() {
    this.canvasElem.style.display = "block";
  }

  hide() {
    this.canvasElem.style.display = "none";
  }

  calcFont(textElem: HTMLElement): Font {
    const style = window.getComputedStyle(textElem);
    const relativeCanvasSize =
      this.canvasElem.width / this.canvasElem.offsetWidth;
    const relativeFontSize =
      parseFloat(style.fontSize) / parseFloat(textElem.style.fontSize);
    return {
      factor: relativeCanvasSize * relativeFontSize,
      family: style.fontFamily,
      weight: style.fontWeight,
      lineHeight: parseFloat(style.lineHeight) / parseFloat(style.fontSize),
    };
  }

  applyFont(font: Font, textElem: HTMLElement): number {
    const fontSize =
      parseFloat(textElem.style.fontSize) *
      font.factor *
      (textElem.nodeName === "TEXTAREA" ? editZoom : 1);
    this.ctx.font = `${font.weight} ${fontSize}px ${font.family}`;
    return fontSize;
  }

  resetShadow() {
    this.ctx.shadowBlur = 0;
    this.ctx.shadowOffsetX = 0;
    this.ctx.shadowOffsetY = 0;
  }

  drawCard(size: Size, backgroundColor: Color, hasShadow: boolean) {
    this.ctx.fillStyle = toCssColor(backgroundColor);

    // Set shadow for card background
    if (hasShadow) {
      this.ctx.shadowColor = color.menu;
      this.ctx.shadowBlur = 100;
      this.ctx.shadowOffsetX = 50;
      this.ctx.shadowOffsetY = 50;
    } else {
      this.resetShadow();
    }
    this.ctx.fillRect(size.left, size.top, size.width, size.height);

    // Reset shadow for other elements
    this.resetShadow();

    const borderWidth = 4;
    this.ctx.strokeStyle = toCssColor(shadeColor(backgroundColor));
    this.ctx.lineWidth = borderWidth;
    this.ctx.strokeRect(
      size.left + borderWidth / 2,
      size.top + borderWidth / 2,
      size.width - borderWidth,
      size.height - borderWidth,
    );
  }

  drawText(
    size: Size,
    color: Color,
    lines: string[],
    fontSize: number,
    lineHeight: number,
  ) {
    this.ctx.fillStyle = contrastCssColor(color);
    const left = size.left + 0.037 * size.width;
    const top = size.top + 0.16 * size.height + 0.95 * fontSize;
    let y = 0;
    for (const line of lines) {
      this.ctx.fillText(line, left, top + y * lineHeight * fontSize);
      y++;
    }
  }
}
</script>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/z-index";

#zoom-canvas {
  display: none;
  pointer-events: none;
  position: absolute;
  width: 100% * variables.$fake-zoom;
  height: 100% * variables.$fake-zoom;
  z-index: z-index.$zoom;
}
</style>
