<template>
  <div
    :id="card.id"
    :ref="(el: any) => (cardRef = el)"
    data-type="sticky-note"
    class="card"
    :class="cssClass"
    :style="{
      fontSize: stickyFontSize + 'rem',
      zIndex,
      top: bodyTop + 'px',
      left: bodyLeft + 'px',
      width: bodyWidth + 'px',
      height: bodyHeight + 'px',
      color: backContrastColor,
      backgroundColor: backColor,
      borderColor,
      cursor,
    }"
    @pointerdown="pointerDown"
    @pointerup="pointerUp"
    @pointerenter="pointerEnter"
    @pointerleave="pointerLeave"
    @click="click"
    @animationend="meta.shouldAnimate = false"
  >
    <!--first this div to avoid destroy/create/mount card-text-input when levelOfDetail property toggles -->
    <div class="text">
      <card-text-input
        :edit="textEdit"
        :editable="editable()"
        :value="card.text"
        :max-length="textLen"
        :newlines="newlines"
        @input="selectText"
        @request-edit="requestTextEdit"
        @dblclick="zoom(true)"
      />
    </div>
    <div
      :ref="(el: any) => (cardHeaderRef = el)"
      class="card-header"
      :style="{
        top: -cornerHeight + 'px',
        height: cornerHeight + 3 + 'px',
        borderColor,
      }"
    >
      <div
        class="flag-container"
        :style="{ width: cornerWidth + 'px' }"
        @click.stop="setMode('flag')"
      >
        <base-flag v-if="hasFlag" :flag="card.flagType" />
        <flag-icon v-else-if="mode !== 'normal'" data-no-screenshot />
      </div>
      <template v-if="showStatus">
        <div class="status status-container">
          <div class="status-dot-container">
            <BaseTooltip position="right" :delay="[200, 0]">
              <status-dot
                :status-class="card.status?.statusClass"
                @click.stop="changeStatus"
              />
              <template #content>{{ statusName }}</template>
            </BaseTooltip>
          </div>
        </div>
      </template>
      <template v-if="levelOfDetail < 2">
        <div class="corner-corner" :style="{ width: cornerWidth + 'px' }">
          <svg viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M 100,0 L 100,100 L 0,100 z" :fill="borderColor" />
          </svg>
        </div>
        <div
          class="corner-top"
          :style="{ left: cornerWidth + 'px', backgroundColor: backColor }"
        >
          <div v-if="modeConfig.title(messageMode)" class="select">
            <div>
              {{ $t(modeConfig.title(messageMode) || "") }}
              <img src="@/assets/close-submenu.svg" @click="setMode('edit')" />
            </div>
          </div>
          <template v-else>
            <span
              class="type input"
              :class="{ disabled: modeConfig.isDisabled('type') }"
              @click.stop="setMode('type')"
              >{{ card.type.name }}</span
            >
            <span
              class="alm input no-help"
              :class="{
                disabled: !card.almIssueUrl,
                'alm--shift-left': showStatus,
              }"
            >
              <a target="_blank" :href="card.almIssueUrl" @click.stop>{{
                card.almId
              }}</a>
            </span>
          </template>
        </div>
      </template>
      <div
        v-else
        class="corner-simple"
        :style="{ backgroundColor: backColor }"
      />
      <div v-if="isPinned" class="pin">
        <img src="@/assets/card/pin.svg" @click.stop="removePin" />
      </div>
    </div>
    <div v-if="levelOfDetail < 1" class="team">
      <span
        v-if="hasAction('team')"
        class="input"
        @click.stop="setMode('team')"
      >
        {{ teamName }}
      </span>
      <span v-if="showTeam">
        {{ teamName }}
      </span>
      <span v-if="hasAction('art')" class="input" @click.stop="setMode('art')">
        {{ artName }}
      </span>
      <span v-if="showArt">
        {{ artName }}
      </span>
      <span v-if="hasAction('depend')">
        <span class="input disabled no-help">{{
          card.precondTeam && card.precondTeam.name
        }}</span>
        <img src="@/assets/card/arrow.svg" />
        <span class="input" @click.stop="setMode('depend')">{{
          $t("general.noTeam")
        }}</span>
      </span>
      <span v-if="card.dependTeam">
        <span class="input disabled no-help">{{
          card.precondTeam && card.precondTeam.name
        }}</span>
        <img src="@/assets/card/arrow.svg" />
        <span class="input disabled no-help">{{ card.dependTeam.name }}</span>
      </span>
    </div>
    <div v-if="levelOfDetail < 3" class="footer">
      <status-distribution
        v-if="showStatusDistribution"
        :value="currentStatusDistribution"
      />
      <template v-else>
        <span
          v-show="showLinkLabel"
          class="label input"
          :style="{ backgroundColor: shadeColor, color: shadeContrastColor }"
          @click.stop="setMode('linkLabel')"
        >
          {{ linkLabel }}
        </span>
        <div v-if="showReactions" class="reactions-toggle-button">
          <div v-if="cardReactions" class="reactions">
            <active-reaction
              v-for="reaction in reactions"
              :key="reaction"
              :is-dark-color="isDarkColor"
              :users="cardReactions[reaction]"
              :type="reaction"
              :card-id="card.id"
              :read-only="readOnly"
            />
          </div>
          <IconButton
            v-if="!cardHasAllReactions && !readOnly"
            icon="reaction/icon"
            :activated="mode === 'reaction'"
            :class="{ 'dark-color': isDarkColor }"
            @click.stop="toggleReactions"
          />
        </div>
        <div />
        <div
          v-if="hasAction('priority') && card.type.origin === 'backlog'"
          class="right"
          @click.stop="setMode('priority')"
        >
          <span
            class="input"
            :class="{ disabled: modeConfig.isDisabled('priority') }"
          >
            {{ priorityName }}
          </span>
        </div>
        <div v-if="showPoints" class="right" @click.stop="setMode('points')">
          <span class="input">
            {{ card.points }}
          </span>
        </div>
        <div
          v-if="hasAction('risk')"
          class="right"
          @click.stop="setMode('risk')"
        >
          <span
            class="input"
            :class="{
              roam: !card.risk,
              invert: !card.risk && isDarkColor,
            }"
          >
            {{ cardRisk }}
          </span>
        </div>
      </template>
    </div>
    <card-types-overlay
      v-if="mode === 'type'"
      :selected="card.type"
      :types="stickyTypes"
      @select="selectType"
    />
    <card-points-overlay
      v-if="mode === 'points'"
      :points="card.points"
      :point-values="pointValues"
      @select="selectPoints"
    />
    <card-priorities-overlay
      v-if="mode === 'priority'"
      :priorities="card.type.priorities"
      :priority="card.priority"
      @select="selectPriority"
    />
    <card-status-overlay
      v-if="mode === 'status'"
      :status="card.status"
      @select="selectStatus"
    />
    <card-arts-overlay
      v-if="mode === 'art'"
      :arts="arts"
      :selected="currentArt"
      @select="selectArt"
    />
    <card-teams-overlay
      v-if="mode === 'team' || mode === 'move' || mode === 'depend'"
      :teams="teams"
      :show-arts="showArts"
      :selected="card.teamId"
      :risk="card.type.origin && card.type.functionality === 'risk'"
      @select="selectTeam"
      @change-art="changeArt"
    />
    <card-risks-overlay
      v-if="mode === 'risk'"
      :risk="card.risk"
      @select="selectRisk"
    />
    <card-alm-sources-overlay
      v-if="mode === 'almSource'"
      :sources="almSources"
      :source="card.almSourceId"
      @select="selectAlmSource"
    />
    <card-labels-overlay
      v-if="mode === 'linkLabel'"
      :card="card"
      @select="setLinkLabel"
    />
    <card-boards-overlay
      v-if="mode === 'mirror' || mode === 'program'"
      :boards="boards"
      :team-id="card.teamId"
      @select="setBoard"
    />
    <card-message-overlay
      v-if="mode === 'message'"
      :message="modeConfig.message(messageMode)"
      @select="resetMode"
    />
    <card-flags-overlay
      v-if="mode === 'flag'"
      :selected="card.flagType"
      @select="selectFlag"
    />

    <Teleport v-if="isActionMenuOpen" to="body">
      <div :ref="(el: any) => (actionMenuRef = el)" class="action-menu-wrapper">
        <ActionMenu
          data-type="action-menu"
          @click.capture="handleActionMenuClick"
        />
      </div>
    </Teleport>

    <div
      v-if="isActionMenuOpen && linkColor"
      class="link-drag"
      data-no-animate
      @click="handleLinkDragClick"
    >
      <svg viewBox="0 0 30 100">
        <path :stroke="linkColor" stroke-width="10" d="M15 0v45" />
        <circle :fill="linkColor" cx="15" cy="40" r="15" />
      </svg>
    </div>

    <div v-if="showEditor" class="editor">
      <div class="name">
        <scaling-text
          :text="card?.editor?.name"
          :max-width="bodyWidth * 0.75"
          :min-scale="1.2"
          :max-scale="2.2"
        />
      </div>
      <user-avatar user-color :user="card.editor" />
    </div>

    <transition name="faster-fade">
      <add-reactions
        v-if="mode === 'reaction'"
        :card-id="card.id"
        :reactions="reactions"
        @input="setMode('normal')"
      />
    </transition>

    <div
      v-if="cssClass.lowlight || cssClass['semi-lowlight']"
      class="filter"
      :style="{ top: -cornerHeight - 1 + 'px' }"
    />

    <div
      v-if="meta.editing && hasAction('dragLink')"
      data-no-screenshot
      data-no-animate
    >
      <div
        v-for="link in cardLinks"
        :key="link.linkId"
        class="delete-link"
        :style="{ left: link.x + 'px', top: link.y + 'px' }"
        :title="$t('linkModal.removeLink')"
        @click.stop="deleteLink(link)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { createPopper } from "@popperjs/core";
import { Options, mixins } from "vue-class-component";
import { Prop, Provide, Watch } from "vue-property-decorator";

import { isDragging, isDraggingLink } from "@/Gestures";
import {
  cardZoomHeight,
  cornerFactor,
  fakeZoom,
  markLinkedTimeout,
  stickyFontFactor,
} from "@/Settings";
import { boardActions } from "@/action/boardActions";
import { cardActions } from "@/action/cardActions";
import { linkActions } from "@/action/linkActions";
import { almSync } from "@/backend/Backend";
import { riskTypeName } from "@/baseTypeI18n";
import { AlmSourceId, Color, RiskType } from "@/baseTypes";
import FluidBoard, { CardComponent } from "@/components/FluidBoard";
import {
  cardStatusDistribution,
  dependencyCardStatusDistribution,
} from "@/components/StatusDistribution";
import StatusDistribution from "@/components/StatusDistribution.vue";
import StatusDot from "@/components/StatusDot.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import AddReactions from "@/components/card/AddReactions.vue";
import BaseFlag from "@/components/card/BaseFlag.vue";
import CardAlmSourcesOverlay from "@/components/card/CardAlmSourcesOverlay.vue";
import CardArtsOverlay from "@/components/card/CardArtsOverlay.vue";
import CardBoardsOverlay from "@/components/card/CardBoardsOverlay.vue";
import CardFlag from "@/components/card/CardFlag";
import CardFlagsOverlay from "@/components/card/CardFlagsOverlay.vue";
import CardLabelsOverlay from "@/components/card/CardLabelsOverlay.vue";
import CardMessageOverlay from "@/components/card/CardMessageOverlay.vue";
import CardPointsOverlay from "@/components/card/CardPointsOverlay.vue";
import CardPrioritiesOverlay from "@/components/card/CardPrioritiesOverlay.vue";
import CardRisksOverlay from "@/components/card/CardRisksOverlay.vue";
import CardStatusOverlay from "@/components/card/CardStatusOverlay.vue";
import CardTeamsOverlay from "@/components/card/CardTeamsOverlay.vue";
import CardTypesOverlay from "@/components/card/CardTypesOverlay.vue";
import FlagIcon from "@/components/card/FlagIcon.vue";
import ScalingText from "@/components/card/ScalingText.vue";
import {
  ActionType,
  Mode,
  createModeConfig,
  hasAction,
} from "@/components/card/actions";
import { registerCardDrag } from "@/components/card/cardDragHandler";
import CardTextInput from "@/components/input/CardTextInput.vue";
import StickyLinkModal from "@/components/modal/StickyLinkModal.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import QuadraticSpline from "@/math/QuadraticSpline";
import {
  boardToRelativeSimple,
  cardInsideBoardAndViewport,
  relativeToBoard,
} from "@/math/coordinate-systems";
import {
  BoardCoordinate,
  RelativeCoordinate,
  boardCoord,
  minus,
} from "@/math/coordinates";
import EventBusUser, {
  CardActionOptions,
  onZoomEnd,
  onZoomStart,
} from "@/mixins/EventBusUser";
import {
  Art,
  Board,
  BoardData,
  CardLink,
  CardMeta,
  Card as CardModel,
  LinkingTarget,
  MarkMode,
  StickyType,
  TargetStatus,
  Team,
  isBacklogBoard,
  isDependency,
  isLowLight,
  isNote,
  isWorkitem,
  reactions,
} from "@/model";
import { isUnknownUser } from "@/services/user.service";
import { useAppSizeStore } from "@/store/appSize";
import { NO_ART_ID, useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useCardStore } from "@/store/card";
import { getLinkTargetId, linkBetween, useLinkStore } from "@/store/link";
import { useModalStore } from "@/store/modal";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useSelectionStore } from "@/store/selection";
import { useServerSettingsStore } from "@/store/serverSettings";
import { NO_TEAM_ID, useTeamStore } from "@/store/team";
import { useUserStore } from "@/store/user";
import { useWorkModeStore } from "@/store/workMode";
import color from "@/styles/color.module.scss";
import cssValue from "@/styles/variable.module.scss";
import { animateCopy } from "@/utils/animation";
import {
  contrastCssColor,
  isDarkColor,
  shadeColor,
  toCssColor,
} from "@/utils/color";

import ActiveReaction from "./ActiveReaction.vue";
import DelayedAction from "./DelayedAction";
import { ModeHistory } from "./ModeHistory";
import ActionMenu from "./components/ActionMenu/ActionMenu.vue";
import { boardKey, cardKey, cardMethodsKey } from "./injectKeys";

const menuFactor = 0.15; // how high is the menu relative to the whole card

// this must match with the CSS classes below, TODO a better way?
const animation = {
  mirroring: 1,
  deleting: 0.5,
  moving: 1,
  zooming: 0.15,
};

@Options({
  components: {
    BaseTooltip,
    StatusDistribution,
    UserAvatar,
    CardStatusOverlay,
    CardLabelsOverlay,
    CardArtsOverlay,
    CardTeamsOverlay,
    CardPrioritiesOverlay,
    CardPointsOverlay,
    CardTypesOverlay,
    CardFlagsOverlay,
    CardRisksOverlay,
    CardMessageOverlay,
    CardBoardsOverlay,
    CardAlmSourcesOverlay,
    CardTextInput,
    BaseFlag,
    FlagIcon,
    ScalingText,
    StatusDot,
    IconButton,
    AddReactions,
    ActiveReaction,
    ActionMenu,
  },
})
export default class Card
  extends mixins(EventBusUser)
  implements CardComponent
{
  cardRef!: HTMLDivElement;
  cardHeaderRef!: HTMLDivElement;
  actionMenuRef: HTMLDivElement | undefined;

  @Provide({ to: cardKey as symbol })
  @Prop(Object)
  readonly card!: CardModel;

  @Provide({ to: boardKey as symbol })
  @Prop(Object)
  readonly board!: Board;

  @Prop(Object) readonly meta!: CardMeta;
  @Prop(Array) readonly color!: Color;
  @Prop(String) readonly linkColor: string | undefined;
  @Prop(Array) readonly actions!: ActionType[];
  @Prop(Number) readonly boardWidth!: number;
  @Prop(Number) readonly boardHeight!: number;
  @Prop(Number) readonly width!: number;
  @Prop(Number) readonly height!: number;
  @Prop(Boolean) readonly draggable!: boolean;
  @Prop(Boolean) readonly readOnly: boolean | undefined;
  @Prop({ default: false, type: Boolean }) readonly fullDetails!: boolean;
  @Prop({
    default: "position",
    type: String,
    required: false,
    validator(placeBy: string) {
      return ["position", "order"].includes(placeBy);
    },
  })
  readonly placeBy!: "position" | "order";
  @Prop({ default: -1, type: Number, required: false }) readonly row!: number;
  @Prop({ default: -1, type: Number, required: false }) readonly col!: number;

  dragEnd: number | null = null;
  markAction = new DelayedAction(markLinkedTimeout);
  oldMark: MarkMode = "normal";
  textEdit = false;
  textEditStart = 0;
  zoomFactor = 1;
  offset = { x: 0, y: 0 };
  pointValues = [0, 0.5, 1, 2, 3, 5, 8, 13, 20, 40, 100];
  modeHistory: ModeHistory = new ModeHistory();
  messageMode: Mode = "normal";
  selectedArt: Art = useArtStore().currentArt;
  reactions = reactions;
  cards = useCardStore();
  animationClass = "";
  isBoardZooming = false;

  // Overrides for bodyTop and bodyLeft while dragging
  dragTop: number | null = null;
  dragLeft: number | null = null;

  boundHandleOutsideClick = this.handleOutsideClick.bind(this);

  // Used in the action menu
  @Provide({ to: cardMethodsKey as symbol })
  get cardMethods() {
    return {
      removePin: this.removePin,
      animate: this.animate,
      animateCopy: this.animateCopy,
    };
  }

  get modeConfig() {
    return createModeConfig(this);
  }

  get stickyFontSize() {
    return this.zoomFactor * this.width * stickyFontFactor;
  }

  get mode(): Mode {
    return this.modeHistory.peek();
  }

  set mode(value: Mode) {
    this.modeHistory.push(value);
  }

  get statusName() {
    return this.card.status?.name;
  }

  get hasFlag() {
    return !this.card.flagType.isEmpty();
  }

  get cardReactions() {
    return this.card.reactions;
  }

  get cardHasAllReactions(): boolean {
    if (!this.card.reactions) return false;

    return Object.values(this.card.reactions).every((arr) => arr.length > 0);
  }

  mounted() {
    this.initializeBusListeners();

    onZoomStart(() => (this.isBoardZooming = true));
    onZoomEnd(() => (this.isBoardZooming = false));
  }

  /**
   * Allows external components to trigger actions on this card
   * Listeners are cleaned up automatically by the mixin
   */
  initializeBusListeners() {
    this.onCardAction(this.card.id, (options: CardActionOptions) => {
      switch (options.action) {
        case "zoom":
          return this.zoom(options.zoom, options.editMode);
        case "mode":
          return this.setMode(options.mode);
        case "drag":
          return this.setDragPosition(options.x, options.y);
        case "dragEnd":
          return this.setDragPosition(null, null);
      }
    });
  }

  get almSources() {
    const cardOrigin = this.card.type.origin;
    return cardOrigin ? useBoardStore().boardByType(cardOrigin).almSources : [];
  }

  get left() {
    const x = this.placeBy === "position" ? this.meta.pos.x : this.col;
    return x * this.boardWidth - this.width / 2;
  }

  get top() {
    const y = this.placeBy === "position" ? this.meta.pos.y : this.row;
    return y * this.boardHeight - this.height / 2;
  }

  get levelOfDetail() {
    if (this.zoomFactor > 1 || this.fullDetails) {
      return 0;
    }
    const cardsInWindow =
      (window.innerWidth / (this.width * useAppSizeStore().appSize.zoom)) *
      fakeZoom;
    return Math.max(0, Math.floor((cardsInWindow - 25) / 5));
  }

  get showStatusDistribution() {
    return (
      (isDependency(this.card) || isBacklogBoard(this.card.type.origin)) &&
      this.isExecutionMode
    );
  }

  get isExecutionMode() {
    return (
      useWorkModeStore().showExecutionMode && useWorkModeStore().isExecutionMode
    );
  }

  get currentStatusDistribution() {
    return isDependency(this.card)
      ? dependencyCardStatusDistribution(this.card)
      : cardStatusDistribution(this.card);
  }

  get newlines(): boolean {
    return !almSync();
  }

  get textLen() {
    return almSync() ? 255 : 640;
  }

  get showTeam() {
    return this.hasAction("showTeam") && !this.hasAction("team");
  }

  get showArt() {
    return this.hasAction("showArt") && !this.hasAction("art");
  }

  get lines() {
    return this.card.text.split("\n");
  }

  get backColor() {
    return toCssColor(this.color);
  }

  get backContrastColor() {
    return contrastCssColor(this.color);
  }

  get isDarkColor() {
    return isDarkColor(this.color);
  }

  get shadeColor() {
    return toCssColor(shadeColor(this.color));
  }

  get shadeContrastColor() {
    return contrastCssColor(shadeColor(this.color));
  }

  @Watch("meta.dragging")
  onCardDrag() {
    const activeCardId = useBoardStore().activeCardId;
    if (activeCardId) {
      useBoardStore().setActiveCardId(null);
    }
  }

  @Watch("isActionMenuOpen")
  async updateActionMenuPosition() {
    await this.$nextTick();

    if (!this.actionMenuRef || !this.cardRef || !this.isActionMenuOpen) {
      return;
    }

    const { height: headerHeight } = this.cardHeaderRef.getBoundingClientRect();

    createPopper(this.cardRef, this.actionMenuRef, {
      placement: "top",
      modifiers: [
        { name: "offset", options: { offset: [0, 5 + headerHeight] } },
        { name: "flip", enabled: false },
      ],
    });
  }

  get borderColor() {
    return this.card.editor ? color.menu : this.shadeColor;
  }

  get bodyTop() {
    return this.dragTop ?? this.offset.y + this.top + this.cornerHeight;
  }

  get cornerHeight() {
    return this.zoomFactor * this.height * cornerFactor;
  }

  get bodyHeight() {
    return this.zoomFactor * this.height * (1 - cornerFactor);
  }

  get bodyLeft() {
    return this.dragLeft ?? this.offset.x + this.left;
  }

  get cornerWidth() {
    return this.zoomFactor * this.width * cornerFactor;
  }

  get bodyWidth() {
    return this.zoomFactor * this.width;
  }

  get allTeams(): Team[] {
    return [useTeamStore().noTeam, ...useTeamStore().teamsInCurrentArt];
  }

  get arts() {
    return this.hasAction("art")
      ? [useArtStore().noArt, ...useArtStore().arts]
      : useArtStore().arts;
  }

  get currentArt() {
    return this.hasAction("art") ? this.art : this.selectedArt;
  }

  get showArts() {
    return (
      this.board.type !== "backlog" &&
      this.mode !== "program" &&
      this.arts.length > 1
    );
  }

  get teams(): Team[] {
    if (this.mode === "team") {
      return this.allTeams;
    }
    if (this.mode === "move" && !useServerSettingsStore().moveBetweenTeams) {
      return [];
    }
    return useTeamStore()
      .teamsInArt(this.selectedArt.id)
      .filter((t) => t.id !== useTeamStore().currentTeam.id);
  }

  get teamName() {
    if (this.showTeam && !this.card.teamId) {
      return "";
    }
    return (
      this.allTeams.find((team) => team.id === (this.card.teamId || NO_TEAM_ID))
        ?.name ?? ""
    );
  }

  get artName() {
    if (this.showArt && !this.card.artId) {
      return "";
    }
    return this.art?.name ?? "";
  }

  get art() {
    return this.arts.find((art) => art.id === (this.card.artId || NO_ART_ID));
  }

  get priorityName() {
    const prios = this.card.type.priorities;
    return prios ? prios[this.card.priority]?.text || "-" : this.card.priority;
  }

  get boards(): Board[] {
    if (this.mode === "mirror") {
      return useBoardStore().mirrorTargetBoards([this.card]);
    }
    const sameArt =
      this.card.precondTeam?.artId === this.card.dependTeam?.artId;
    return useBoardStore().planningBoards(sameArt);
  }

  get stickyTypes() {
    return useBoardStore().originStickyTypes;
  }

  get showPoints() {
    return (
      this.hasAction("points") &&
      this.card.type.origin === "team" &&
      !isDependency(this.card) &&
      !isNote(this.card)
    );
  }

  get showStatus() {
    return isWorkitem(this.card);
  }

  get showEditor() {
    return this.levelOfDetail < 1 && !isUnknownUser(this.card.editor);
  }

  get linkLabel() {
    return useLinkStore().getCardLinkLabel(this.card);
  }

  get showLinkLabel() {
    return !isNote(this.card) && this.linkLabel !== undefined;
  }

  get showReactions() {
    return isNote(this.card);
  }

  get zIndex() {
    if (this.zoomFactor > 1) {
      return this.board.maxZ + 100;
    }
    return isLowLight(this.meta.mark, this.mode)
      ? cssValue.zIndexLinks
      : this.meta.zIndex;
  }

  get cursor() {
    if (useSelectionStore().selecting !== "no") {
      return "copy";
    }
    if (
      useSelectionStore().singleCard &&
      useBoardStore().selectedStickies.length > 1
    ) {
      return "move";
    }
    if (this.draggable) {
      return "grab";
    }
    return "auto";
  }

  get cardLinks() {
    if (this.zoomFactor > 1) {
      return [];
    }
    const size = boardToRelativeSimple(
      boardCoord(this.width / 2, (this.height * (1 + menuFactor)) / 2),
    );
    return this.card.links.flatMap((link) => {
      const matches = useSearchMenuStore().matchesLinkStates(link, true);
      if (matches) {
        const linkTargetId = getLinkTargetId(this.card, link);
        const linkedCard = useLinkStore().boardCardByLink(
          linkTargetId,
          this.board,
        );
        if (linkedCard) {
          const deleteIconPos = findDeleteIconPos(
            this.meta.pos,
            linkedCard.meta.pos,
          );
          if (deleteIconPos) {
            return { linkId: link.id, ...deleteIconPos, to: linkTargetId };
          }
        }
      }
      return [];
    });

    function findDeleteIconPos(
      from: RelativeCoordinate,
      to: RelativeCoordinate,
    ): BoardCoordinate | undefined {
      const spline = QuadraticSpline.forLink(from, to);
      let t = 0;
      let c: RelativeCoordinate;
      let tooNear;

      do {
        c = spline.interpolate(t);
        t += 0.005;
        tooNear =
          Math.abs(c.x - from.x) < 1.1 * size.x &&
          Math.abs(c.y - from.y) < 1.1 * size.y;
      } while (t < 0.8 && tooNear);

      if (!tooNear) {
        c.y += (menuFactor - 2 * cornerFactor) * size.y;
        return relativeToBoard(minus(c, from));
      }
    }
  }

  get cssClass() {
    return {
      "read-only": !!this.card.editor,
      editing: this.meta.editing,
      highlight: this.meta.mark === "highlight",
      lowlight: isLowLight(this.meta.mark, this.mode),
      "semi-lowlight":
        this.mode === "normal" && this.meta.mark === "semi-fade-out",
      appearing: this.meta.shouldAnimate,
      selected: useBoardStore().isStickySelected(this.card),
      [this.animationClass]: true,
    };
  }

  get activeCardId(): string | null {
    return useBoardStore().activeCardId;
  }

  get cardRisk() {
    return riskTypeName(this.card.risk || "");
  }

  get isPinned() {
    return useLinkStore().isMarkingLinkedCards(this.card.id);
  }

  @Watch("activeCardId")
  activeCardIdChanged() {
    if (this.mode !== "normal") {
      if (this.activeCardId !== this.card.id) {
        this.setMode("normal");
      }
    }
  }

  zoom(zoom: boolean, setEditMode = true) {
    if (!("setZoomCard" in this.$parent!)) {
      // we are on the overview modal -> avoid zooming
      return;
    }

    if (!this.editable()) return;

    const board = this.$parent as FluidBoard;
    board.setZoomCard(zoom ? this : null);
    if (!zoom) {
      board.pointerCard = null; // unzoom -> pointer is now probably outside of card
    }

    if (zoom && this.textEdit && !this.justTextEditStarted()) {
      return;
    }
    this.zoomFactor = 1;
    this.offset = { x: 0, y: 0 };
    this.animate("zooming", () => {
      if (zoom && setEditMode) {
        this.setMode("edit");
      }
    });
    if (zoom) {
      const zoomFactor =
        cardZoomHeight *
        ((fakeZoom * window.innerHeight) /
          this.height /
          useAppSizeStore().appSize.zoom);
      this.zoomFactor = zoomFactor > 1 ? zoomFactor : this.zoomFactor;
      const pos = boardCoord(
        this.left - ((this.zoomFactor - 1) * this.width) / 2,
        this.top - ((this.zoomFactor - 1) * this.height) / 2,
      );

      const size = boardCoord(
        this.zoomFactor * this.width,
        this.zoomFactor * this.height,
      );
      cardInsideBoardAndViewport(pos, size);
      this.offset = { x: pos.x - this.left, y: pos.y - this.top };
    }
  }

  pointerDown(event: PointerEvent) {
    // stop propagaging to avoid context menu open
    event.stopPropagation();

    if (!isDragging()) {
      this.markAction.start(() => {
        if (isDragging()) {
          return;
        }
        if (this.isPinned) {
          this.removePin();
        } else {
          linkActions.markCardLinkedCards(
            "mouse",
            this.board.cards[this.card.id],
          );
          this.markAction.executed();
        }
      });
    }

    const target = event.target as HTMLElement;
    const clickOnLinkDrag = target.classList.contains("link-drag");
    if (
      this.draggable &&
      this.editable() &&
      (this.zoomFactor <= 1 || clickOnLinkDrag)
    ) {
      registerCardDrag(this.card, this.onDragEnd.bind(this), event);
    }
  }

  pointerUp() {
    this.markAction.cancel();
  }

  pointerEnter() {
    this.zoomCard(this);
    this.setLinkingTarget(this.card.id);
    if (useSelectionStore().selecting === "hover") {
      boardActions.toggleCardSelection("mouse", this.card.id);
    }
  }

  pointerLeave() {
    this.markAction.cancel();
    this.zoomCard(null);
    useLinkStore().resetLinkingTarget();
    if (isDraggingLink()) {
      this.meta.mark = this.oldMark;
    }
  }

  click(e: Event) {
    if (!this.editable()) return;

    this.handleCardClick();

    if ((e.target as Element)?.classList?.contains("ignore-click")) {
      return;
    }
    if (useSelectionStore().selecting === "click") {
      if (!this.justDragEnded()) {
        boardActions.toggleCardSelection("board", this.card.id);
      }
    } else {
      if (["normal", "edit"].includes(this.mode)) {
        this.setMode("edit");
      } else {
        this.resetMode();
      }
    }
  }

  onDragEnd() {
    this.dragEnd = performance.now();
    if (!this.meta.editing) {
      cardActions.stopAlter("internal", this.card.id);
    }
    this.resetMode();
  }

  zoomCard(card: Card | null) {
    const board = this.$parent as FluidBoard;
    board.pointerCard = card;
    if (useBoardStore().magnifying) {
      this.zoom(!!card);
      if (!card) {
        this.setMode("normal");
      }
    }
  }

  setLinkingTarget(id: LinkingTarget["id"]) {
    const linkStore = useLinkStore();
    if (
      linkStore.linking.from &&
      !linkStore.isLinkedFrom(this.card.id) &&
      !linkBetween(this.card, linkStore.linking.from)
    ) {
      useLinkStore().setLinkingTarget({ id, type: "sticky" });
      this.oldMark = this.meta.mark;
      this.meta.mark = "highlight";
    }
  }

  removePin() {
    this.markAction.cancel();
    useLinkStore().removeAllMarks();
    useBoardStore().setActiveCardId(null);
  }

  hasAction(action: ActionType) {
    return hasAction(this.actions, action);
  }

  justMarkEnded() {
    return this.markAction.justExecuted(300);
  }

  justTextEditStarted() {
    return performance.now() < this.textEditStart + 300;
  }

  justDragEnded() {
    return this.dragEnd && performance.now() < this.dragEnd + 100;
  }

  requestTextEdit(e: boolean) {
    if (
      this.justMarkEnded() ||
      this.justDragEnded() ||
      useSelectionStore().selecting === "click" ||
      !this.editable()
    ) {
      return;
    }

    if (e) {
      this.setTextEdit(true);
    } else {
      this.setTextEdit(false);

      setTimeout(() => {
        // close only if not another menu point was selected
        if (
          !this.textEdit &&
          (this.mode === "edit" || !this.editable()) &&
          !isDraggingLink()
        ) {
          this.zoom(false);
          this.setMode("normal");
        }
      }, 200);
    }
  }

  setTextEdit(textEdit: boolean) {
    if (!this.textEdit && textEdit) {
      this.textEditStart = performance.now();
    }
    this.textEdit = textEdit;
  }

  resetMode() {
    /***
     * Change the mode to the one of edit or normal modes
     * according to the prior state from modeHistory
     */
    if (this.mode === "edit") {
      return this.setMode("normal");
    } else if (this.mode === "normal") {
      return;
    }
    const priorMode = this.modeHistory.peek(1);
    if (["edit", "normal"].includes(priorMode)) {
      return this.setMode(priorMode);
    } else {
      this.setMode("edit");
    }
  }

  toggleReactions() {
    if (!this.showReactions) {
      return;
    }
    return this.messageMode !== "reaction"
      ? this.setMode("reaction")
      : this.setMode("normal");
  }

  handleActionMenuClick() {
    if (this.mode !== "actionMenu") {
      this.setMode("actionMenu");
    }
  }

  // sets the active CardId in the store
  handleCardClick() {
    if (this.justDragEnded()) return;

    const boardStore = useBoardStore();
    if (this.card.id !== boardStore.activeCardId) {
      boardStore.setActiveCardId(this.card.id);
      document.addEventListener("click", this.boundHandleOutsideClick, true);
    }
  }

  handleOutsideClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const parent = target.closest("[data-type='sticky-note']");
    const parentId = parent?.getAttribute("id");

    // don't hide the action menu on remove link click
    if (
      target.classList.contains("delete-link") ||
      target.classList.contains("link-drag")
    ) {
      return;
    }

    // check if the clicks comes from the action menu
    if (target.closest("[data-type='action-menu']")) {
      return;
    }
    // Check if the click comes from the same card
    if (parentId === this.card.id) {
      return;
    }
    // don't hide the action menu when linkinig
    if (target.classList.contains("board-team")) {
      return;
    }
    // Don't hide when interacting with the Activity Panel
    if (target.closest(".activity-panel")) {
      return;
    }

    // If the click was in another card, remove the event listener
    if (parentId) {
      document.removeEventListener("click", this.boundHandleOutsideClick, true);
      return;
    }

    // Click was outside of any card component
    useBoardStore().setActiveCardId(null);
    document.removeEventListener("click", this.boundHandleOutsideClick, true);
  }

  setMode(mode: Mode) {
    if (!this.editable() || this.justMarkEnded()) {
      return;
    }
    // mouseover with space pressed should zoom/edit, but not activate text edit (only when releasing space)
    if (mode === "edit" && !useBoardStore().magnifying) {
      this.requestTextEdit(true);
    }

    if (mode !== this.mode) {
      if (this.mode === "normal") {
        this.meta.editing = true;
        cardActions.startAlter("internal", this.card.id);
        boardActions.cardToFront("card", this.card.id);
      }
      if (mode === "normal") {
        this.meta.editing = false;
        this.selectedArt = useArtStore().currentArt;
        this.zoom(false);
        cardActions.stopAlter("internal", this.card.id);
      }
    }

    if (this.modeConfig.isDisabled(mode)) {
      if (this.modeConfig.message(mode)) {
        this.messageMode = mode;
        this.mode = "message";
      }
    } else {
      if (mode === "delete" && !useServerSettingsStore().confirmDelete) {
        this.doDelete(true);
      } else {
        this.messageMode = mode;
        this.mode = mode;

        useBoardStore().setActiveCard({
          id: this.card.id,
          active: mode !== "normal",
        });
      }
    }
  }

  get isActionMenuOpen(): boolean {
    const isActiveCard = useBoardStore().activeCardId === this.card.id;
    const isEditable = this.editable();

    return (
      isActiveCard &&
      isEditable &&
      !this.meta.dragging &&
      !this.animationClass && // card is not in animation state
      !this.isBoardZooming &&
      !useBoardStore().magnifying &&
      !this.showEditor // someone else is not editing
    );
  }

  editable() {
    return (
      this.readOnly !== true &&
      useUserStore().isAllowed("edit") &&
      !this.card.editor &&
      !this.justDragEnded()
    );
  }

  handleLinkDragClick(event: MouseEvent) {
    event.stopPropagation();
    useModalStore().open(StickyLinkModal, {
      attrs: { cardIds: [this.card.id] },
    });
  }

  doDelete(del: boolean) {
    if (del) {
      this.meta.mark = "hidden"; // hide possible links immediately
      this.animate("deleting", () =>
        cardActions.delete("card", this.card.id, this.board.id),
      );
      if (this.isPinned) {
        useLinkStore().removeAllMarks();
      }
    } else {
      this.resetMode();
    }
  }

  deleteLink(link: (typeof this.cardLinks)[number]) {
    linkActions.remove("card", link);

    // we have to set the mode back to edit, or the other liks will dissapear
    this.setMode("edit");
  }

  selectPriority(e: number) {
    cardActions.setPriority("card", this.card.id, e);
    cardActions.stopAlter("internal", this.card.id);
    this.resetMode();
  }

  selectPoints(points: number | string) {
    cardActions.setPoints("card", this.card.id, +points);
    this.resetMode();
  }

  selectText(text: string) {
    cardActions.setText("card", this.card.id, text);
  }

  selectType(e: StickyType) {
    cardActions.setType("card", this.card.id, e.id);
    if (e.origin !== this.board.type) {
      this.animateCopy("mirroring");
    }
    this.resetMode();
  }

  changeArt() {
    this.mode = "art";
  }

  async changeStatus() {
    const status = this.card.status;
    if (status) {
      if (status.next.length === 0 && status.dynamic) {
        await this.cards.updateAlmItemType(this.card, this.board);
      }
      this.setMode("status");
    }
  }

  selectArt(selectedArt: Art) {
    if (this.hasAction("art")) {
      cardActions.setArt("card", this.card.id, selectedArt.id);
      this.resetMode();
    } else {
      this.selectedArt = selectedArt;
      this.modeHistory.pop();
    }
  }

  selectStatus(target?: TargetStatus) {
    if (target) {
      cardActions.setStatus(
        "card",
        this.board,
        this.card.id,
        target.status.name,
        target.transition.id,
      );
    }
    this.resetMode();
  }

  selectTeam(team: Team) {
    switch (this.mode) {
      case "team": {
        cardActions.setTeamAction("card", this.card.id, team.id);
        if (team.id !== NO_TEAM_ID) {
          this.setMode("normal");
          this.animateCopy("mirroring");
        }
        break;
      }
      case "move":
        if (!this.card.teamId && team.id !== "risk") {
          // this happens in demo sessions (and sometimes also in normal ones?)
          cardActions.setTeam(
            "card",
            this.card.id,
            (this.board as BoardData<"team">).team.id,
          );
        }
        this.animate("moving", () => {
          if (team.id === "risk") {
            cardActions.toRisk("card", this.card.id, this.card.teamId!);
            cardActions.delete("internal", this.card.id, this.board.id);
          } else {
            cardActions.move("card", this.card.id, team.id);
          }
        });
        break;
      case "depend":
        this.animateCopy("mirroring");
        cardActions.setDepend("card", this.card.id, team.id);
        break;
    }
    this.resetMode();
  }

  selectRisk(risk: RiskType) {
    cardActions.setRisk("card", this.card.id, risk);
    this.resetMode();
  }

  selectAlmSource(e: AlmSourceId) {
    cardActions.setAlmSource("card", this.card.id, e);
    this.resetMode();
  }

  setBoard(board: Board) {
    if (board) {
      this.animateCopy("mirroring");
      if (this.mode === "program") {
        if (board.type === "program") {
          cardActions.toProgram("card", this.card.id);
        } else if (board.type === "solution") {
          cardActions.toSolution("card", this.card.id);
        }
      } else {
        cardActions.mirror("card", this.card.id, this.card.teamId, board);
      }
    }
    this.resetMode();
  }

  selectFlag(flag: CardFlag) {
    const flagType = this.card.flagType.equals(flag)
      ? CardFlag.emptyFlag()
      : flag;
    cardActions.setFlag("card", this.card.id, flagType);
    this.resetMode();
  }

  async setLinkLabel(link?: CardLink) {
    if (link) {
      await linkActions.setCardLinkLabel("card", this.card, link);
    }
    this.resetMode();
  }

  animate(style: keyof typeof animation, action?: () => void) {
    this.animationClass = style;
    setTimeout(() => {
      action?.();
      setTimeout(() => {
        this.animationClass = "";
      }, 200);
    }, animation[style] * 1000);
  }

  animateCopy(style: keyof typeof animation) {
    animateCopy(this.$el, style);
  }

  /**
   * Set the position of the card while dragging
   * @param x replaces this.bodyLeft
   * @param y replaces this.bodyTop
   */
  setDragPosition(x: number | null, y: number | null) {
    this.dragTop = y;
    this.dragLeft = x;
  }
}
</script>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0.1);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.action-menu-wrapper {
  position: fixed;
  z-index: z-index.$pointer-trail;
}

.card {
  position: absolute;
  border: variables.$border-width solid;
  border-top-style: none;

  &.appearing {
    animation: appear 0.15s;
  }

  &.deleting {
    transition: all 0.5s;
    opacity: 0;
    transform: scale(0.1);
  }

  &.moving,
  &.mirroring {
    transition: all 1s;
    opacity: 0;
    transform: translateX(1000px);
  }

  &.zooming {
    transition: all 0.15s;
  }

  &.read-only {
    border: variables.$border-width solid colors-old.$menu-color;
  }

  &.highlight {
    filter: brightness(70%);
  }

  &.selected {
    filter: drop-shadow(2em 2em 2em colors-old.$menu-color);
  }

  .team {
    position: absolute;
    overflow: hidden;
    left: 0.5em;
    right: 0.5em;
    height: 1.4em;
    bottom: 2.7em;

    & > span {
      display: flex;
      word-break: break-all;

      & > span {
        flex-basis: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    img {
      height: 1em;
      margin: 0 0.5em;
    }
  }

  .pin {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    top: board.len(-30px);
    right: -10px;
    pointer-events: none;
    transform: rotate(30deg); /* added this line */

    img {
      pointer-events: auto;
      cursor: pointer;
      width: board.len(120px);
      padding: board.len(20px);
    }
  }

  .editor {
    position: absolute;
    bottom: 0;
    left: -1 * variables.$border-width;
    right: -1 * variables.$border-width;

    .name {
      position: absolute;
      left: 2em;
      right: 0;
      padding-left: 2.5em;
      padding-right: 0.5em;
      height: 3em;
      line-height: 3em;
      background-color: colors-old.$menu-color;

      span {
        color: colors-old.$back-color;
        overflow: hidden;
      }
    }

    .user-avatar {
      position: absolute;
      top: -1em;
      left: -1em;

      .avatar {
        border: 0.3em solid colors-old.$menu-color;

        span,
        img {
          width: 1.5em;
          height: 1.5em;
          font-size: 2.5em;
        }

        span {
          color: colors-old.$back-color;
        }
      }
    }
  }

  .filter {
    pointer-events: none;
    position: absolute;
    bottom: -2px;
    left: -2px;
    right: -2px;
    background-color: transparent;
  }

  &.lowlight .filter {
    background-color: colors-old.$lowlight-color;
    z-index: z-index.$board;
  }

  &.semi-lowlight .filter {
    background-color: colors-old.$semi-lowlight-color;
    z-index: z-index.$board;
  }

  .footer {
    position: absolute;
    left: 0;
    right: 0;
    height: 3em;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: colors-old.$back-color;
      height: 1.8em;
      line-height: 1.8em;
      border-radius: 0 1em 1em 0;
      padding: 0 0.5em;
      max-width: 80%;
    }

    .right {
      font-size: 180%;
      font-weight: font.$weight-extra-light;
      text-align: right;
      margin-right: 0.5em;

      .roam {
        display: inline-block;
        width: 1em;
        height: 1em;
        background: url("@/assets/card/roam.svg") center/contain no-repeat;
      }
    }

    .status-distribution {
      margin: 0 1em;
      width: 100%;
      height: board.len(40px);
    }
  }

  .delete-link {
    background: url("@/assets/card/delete-link.svg") center/contain no-repeat;
    position: absolute;
    width: 10%;
    height: 10%;
    margin-left: -5%;
    margin-top: -5%;
    cursor: pointer;
  }

  .text {
    position: absolute;
    inset: 0 0 4em;
    padding: 0.7em;
  }

  textarea {
    color: inherit;
  }

  .card-header {
    position: absolute;
    width: 100%;

    .status-container {
      height: 100%;
      width: 100%;
      position: absolute;

      .status-dot-container {
        position: absolute;
        right: 1em;
        top: 50%;
        transform: translate(50%, -50%);
        z-index: z-index.$board;
        width: 1.6em;
        height: 1.6em;
      }
    }
  }

  .flag-container {
    position: absolute;
    height: 100%;
    padding: 4px;
    z-index: z-index.$board;

    .flag-icon {
      padding: 0;
    }
  }

  .corner-simple {
    position: absolute;
    border-width: variables.$border-width;
    border-color: inherit;
    height: 100%;
    left: -1 * variables.$border-width;
    right: -1 * variables.$border-width;
    border-style: solid solid none;
  }

  .corner-top {
    position: absolute;
    border-width: variables.$border-width;
    border-style: solid solid none none;
    border-color: inherit;
    right: -1 * variables.$border-width;
    height: 100%;
    margin-left: -1 * variables.$border-width;
    font-weight: font.$weight-bold;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .select {
      align-items: flex-start;
      height: 100%;
      width: 100%;
      background-color: colors-old.$back-color;
      font-size: 50%;
      z-index: z-index.$board;
      text-transform: uppercase;

      div {
        display: flex;
        align-items: center;
        padding-left: 1em;
        padding-right: 2em;
        background-color: colors-old.$menu-color;
        color: colors-old.$back-color;
        overflow: hidden;
        height: 50%;
        width: 100%;
      }

      /* stylelint-disable-next-line */
      img {
        position: absolute;
        right: 0.5em;
        height: 1em;
        cursor: pointer;
      }
    }

    /* stylelint-disable-next-line */
    span {
      display: flex;
      align-items: center;
    }

    .type {
      filter: opacity(60%);
      padding-left: 0.3em;
    }

    .alm {
      margin-left: 0.3em;
      padding-right: 0.3em;

      &--shift-left {
        margin-right: 1.7em;
      }
    }
  }

  .corner-corner {
    display: block;
    position: absolute;
    left: -1 * variables.$border-width;
    background-origin: border-box;
    cursor: pointer;
  }

  & > .link-drag {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    top: 100%;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);

    svg {
      pointer-events: none;
      height: 80px;
    }
  }
}

.overlay {
  z-index: z-index.$board;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  overflow: scroll;
  color: colors-old.$text-primary-color;
  background-color: colors-old.$back-color;
}

.reactions-toggle-button,
.reactions {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.reactions-toggle-button {
  margin-left: 0.5em;

  .base-button {
    font-size: inherit !important;
    border-radius: 100% !important;
    width: board.len(65px) !important;
    height: board.len(65px) !important;
    padding: board.len(4px) !important;
    background-color: transparent;

    svg {
      width: 100% !important;
      height: 100% !important;
    }

    &:hover {
      background-color: colors-old.$light-reaction-color !important;
    }

    &.activated {
      background-color: transparent;

      svg {
        stroke: colors-old.$reaction-active-icon-color;
      }
    }

    &.dark-color {
      svg {
        stroke: colors-old.$dark-icon;
      }

      &:hover {
        background-color: rgba(
          colors-old.$back-color,
          colors-old.$reaction-color-transparency
        );
      }
    }
  }
}
</style>
