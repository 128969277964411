import { noop } from "lodash-es";

import {
  maxMoveSendPerPeriod,
  moveReceiveDelay,
  moveReceiveStep,
  moveSendPerPeriod,
  moveSendPeriod,
} from "@/Settings";
import { sender } from "@/backend/Sender";
import { Throttle } from "@/components/card/Throttle";
import { RelativeCoordinate, interpolate } from "@/math/coordinates";
import { BoardCard, IdMap } from "@/model";

export function sendCardMove(
  id: string,
  boardId: string,
  coord: RelativeCoordinate,
) {
  toSend.set(id, { boardId, coord });
}

export function receiveCardMove(
  card: BoardCard,
  coord: RelativeCoordinate,
  moveFn: (c: RelativeCoordinate) => void,
) {
  if (card) {
    toReceive[card.data.id] = {
      from: card.meta.pos,
      to: coord,
      moveFn,
      endFn: noop,
      t: 0,
    };
  }
}

export function receiveLinkMove(
  card: BoardCard,
  coord: RelativeCoordinate,
  moveFn: (c: RelativeCoordinate) => void,
  endFn: () => void,
) {
  toReceive[card.data.id] = {
    from: coord,
    to: { ...card.meta.pos },
    moveFn,
    endFn,
    t: 0,
  };
}

export function clear() {
  for (const id in toReceive) {
    delete toReceive[id];
  }
}

interface ToSend {
  boardId: string;
  coord: RelativeCoordinate;
}

interface ToReceive {
  from: RelativeCoordinate;
  to: RelativeCoordinate;
  moveFn: (c: RelativeCoordinate) => void;
  endFn: () => void;
  t: number;
}

const toSend: Map<string, ToSend> = new Map();
const toReceive: IdMap<ToReceive> = {};

new Throttle(
  moveSendPeriod,
  moveSendPerPeriod,
  maxMoveSendPerPeriod,
  sendMoves,
);
receiveMoves();

function sendMoves() {
  const sent = toSend.size;
  for (const [id, send] of toSend) {
    sender.alterCard(send.boardId, id, send.coord);
  }
  toSend.clear();
  return sent;
}

function receiveMoves() {
  for (const id in toReceive) {
    const d = toReceive[id];
    d.t += moveReceiveStep;
    d.moveFn(interpolate(d.t, d.from, d.to));
    if (d.t >= 1) {
      d.endFn();
      delete toReceive[id];
    }
  }
  setTimeout(() => receiveMoves(), moveReceiveDelay);
}
