import { i18n } from "@/i18n";
import { atDayStart, dayDiff, minuteDiff } from "@/utils/date";

type FormatterMap = Record<string, Intl.DateTimeFormat>;

const timeFormatters: { time: FormatterMap; offset: FormatterMap } = {
  time: {},
  offset: {},
};

/**
 * Get a time formatter for a specific time zone. If the time zone is invalid, UTC is used.
 */
export function getTimeFormatterForZone(zone?: string) {
  const z = zone && isValidTimeZone(zone) ? zone : "UTC";

  if (timeFormatters.time[z]) {
    return timeFormatters.time[z];
  }

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: z,
  } as const;
  timeFormatters.time[z] = new Intl.DateTimeFormat(
    navigator.languages as string[],
    options,
  );

  return timeFormatters.time[z];
}

/**
 * Format a Date object into a long date format (day, month, year)
 */
export function formatLongDate(date: Date) {
  return date.toLocaleString(navigator.languages, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

/**
 * Format a Date object into a short date format (month, day)
 */
export function formatShortDate(date: Date) {
  return date.toLocaleString(navigator.languages, {
    month: "short",
    day: "numeric",
  });
}

// Eg. "12/12/23, 02:15 PM" (for en-US)
export function formatTimestamp(date: Date) {
  return date.toLocaleString(navigator.languages, {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });
}

// Eg: "Tue, December 12, 2023 at 3:15 PM" (for en-US)
export function formatTimestampFriendly(date: Date) {
  return date.toLocaleString(navigator.languages, {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  });
}

export function formatTime(date: Date) {
  return date.toLocaleString(navigator.languages, {
    hour: "2-digit",
    minute: "2-digit",
  });
}

function isValidTimeZone(timeZone: string) {
  try {
    return !!Intl.DateTimeFormat(undefined, { timeZone: timeZone });
  } catch (e: unknown) {
    return false;
  }
}

const relativeDateName = [
  i18n.global.t("date.yesterday"),
  i18n.global.t("date.today"),
  i18n.global.t("date.tomorrow"),
];

export function relativeDate(d: Date) {
  const diff = dayDiff(atDayStart(new Date()), d) + 1;
  if (diff < 0 || diff >= 3) {
    return formatLongDate(d);
  }
  return relativeDateName[Math.floor(diff)];
}

export function relativeTime(d: Date) {
  const diff = minuteDiff(new Date(), d);
  const key = diff < 0 ? /*$t*/ "date.ago" : /*$t*/ "date.in";
  const value = Math.abs(diff);
  if (value >= 90) {
    return formatTime(d);
  }
  if (value >= 60) {
    return i18n.global.t(key, {
      time: i18n.global.t("date.hour", { count: Math.round(value / 60) }),
    });
  }
  if (value >= 1) {
    return i18n.global.t(key, {
      time: i18n.global.t("date.minute", { count: Math.round(value) }),
    });
  }
  return i18n.global.t("date.now");
}
