<script lang="ts">
import { markRaw } from "vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import OverallMetricsModalCapacityAllocationBody from "@/components/modal/OverallMetricsModalCapacityAllocationBody.vue";
import OverallMetricsModalLoadCapacityBody from "@/components/modal/OverallMetricsModalLoadCapacityBody.vue";
import { useArtStore } from "@/store/art";

import MetricsModal from "./MetricsModal.vue";

@Options({ name: "OverallMetricsModal", mixins: [MetricsModal] })
export default class OverallMetricsModal extends Vue {
  @Prop({ default: "allocation" }) readonly tab?: string;

  tabs = new Array<any>();

  created() {
    this.tabs = [
      {
        id: "allocation",
        content: this.$t("metricsModal.capacityAllocation"),
        subtitle: this.artName
          ? this.$t("general.namedArt", { name: this.artName })
          : "",
        component: markRaw(OverallMetricsModalCapacityAllocationBody),
      },
      {
        id: "load",
        content: this.$t("metricsModal.loadVsCapacity"),
        subtitle: this.artName
          ? this.$t("general.namedArt", { name: this.artName })
          : "",
        component: markRaw(OverallMetricsModalLoadCapacityBody),
      },
    ];
    (this as any).tabChanged(); // tabChanged is defined in the MetricsModal mixin
  }

  get artName() {
    return useArtStore().currentArt.name;
  }
}
</script>
