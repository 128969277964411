import { defineStore } from "pinia";

import { sendSetting } from "@/mixins/EventBusUser";
import { StickyType } from "@/model";
import { useBoardStore } from "@/store/board";
import { useSessionStore } from "@/store/session";

export interface ClientSettings {
  textScale: boolean;
  stickyFont: string;
  wheelZoom: boolean;
  overload: boolean;
  loadWarn: number;
  permanentTeamLinks: boolean;
  permanentProgramLinks: boolean;
  initialLineupDistance: number;
  showStats: number;
  overviewScale: number;
  defaultStickyTypes: { [key: string]: string };
}

export const useClientSettingsStore = defineStore("clientSettings", {
  state: () => loadSettings(),
  getters: {
    defaultStickyTypeId(state): string {
      return state.defaultStickyTypes[this.defaultStickyTypeKey];
    },
    defaultStickyTypeKey: () => {
      const board = useBoardStore().currentBoard();
      return (
        useSessionStore().session.current.id +
        "-" +
        board.type +
        "-" +
        (board.type === "flex" ? board.flexType : "")
      );
    },
  },
  actions: {
    init() {
      stickyFontCss(this.stickyFont, false);
      this.$subscribe((mutation, state) => {
        saveSettings(state);
      });
    },
    loadSettings() {
      this.$patch(loadSettings());
    },
    toggleShowStats(level: number) {
      this.showStats = this.showStats !== level ? level : 0;
    },
    setDefaultStickyType(type?: StickyType) {
      if (type) {
        this.defaultStickyTypes[this.defaultStickyTypeKey] = type?.id;
      } else {
        delete this.defaultStickyTypes[this.defaultStickyTypeKey];
      }
    },
  },
});

const settings: { [k in keyof ClientSettings]: (value?: any) => any } = {
  textScale(value?: boolean) {
    return getOrSet("textScale", value, true, "boolean");
  },
  stickyFont(value?: string) {
    if (value !== undefined) {
      stickyFontCss(value, true);
    }
    return getOrSet("stickyFont", value, "Mulish", "string");
  },
  wheelZoom(value?: boolean) {
    return getOrSet("wheelZoom", value, true, "boolean");
  },
  overload(value?: boolean) {
    return getOrSet("overload", value, true, "boolean");
  },
  loadWarn(value?: number) {
    return getOrSet("loadWarn", value, 100, "number");
  },
  permanentTeamLinks(value?: boolean) {
    return getOrSet("permanentTeamLinks", value, false, "boolean");
  },
  permanentProgramLinks(value?: boolean) {
    return getOrSet("permanentProgramLinks", value, true, "boolean");
  },
  initialLineupDistance(value?: number) {
    return !localStorage.getItem("initialLineupDistance") &&
      localStorage.getItem("lineupDistance") === "100"
      ? 110
      : getOrSet("initialLineupDistance", value, 110, "number");
  },
  showStats(value?: number) {
    return getOrSet("showStats", value, 0, "number");
  },
  overviewScale(value?: number) {
    return getOrSet("overviewScale", value, 6, "number");
  },
  defaultStickyTypes(value?: { [key: string]: string }) {
    return getOrSet("defaultStickyTypes", value, {});
  },
};

function loadSettings(): ClientSettings {
  const res = {} as any;
  for (const prop in settings) {
    const key = prop as keyof ClientSettings;
    res[prop] = settings[key]();
  }
  return res as ClientSettings;
}

function saveSettings(data: ClientSettings) {
  for (const prop in settings) {
    const key = prop as keyof ClientSettings;
    settings[key](data[key]);
  }
}

function getOrSet<T>(
  key: keyof ClientSettings,
  value: T,
  defaultVal: T,
  type?: "boolean" | "number" | "string",
): T {
  return value === undefined ? get() : set(value);

  function set(val: T) {
    localStorage.setItem(key, JSON.stringify(val));
    sendSetting(key, val);
    return val;
  }

  function get(): any {
    const raw = localStorage.getItem(key);
    if (raw === null) {
      return set(defaultVal);
    }
    try {
      const val = JSON.parse(raw);
      if (type && typeof val !== type) {
        return set(defaultVal);
      }
      return val;
    } catch (e) {
      return set(defaultVal);
    }
  }
}

function stickyFontCss(fontFamily: string, replace: boolean) {
  const css = document.styleSheets[0] as CSSStyleSheet;
  if (replace) {
    css.deleteRule(0);
  }
  css.insertRule(`:root { --card-font: ${fontFamily} }`, 0);
}
