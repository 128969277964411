import { Ref, computed } from "vue";

import { fakeZoom, stickyFontFactor } from "@/Settings";
import { Board, Card, CardMeta, isFullyFaded, isLowLight } from "@/model";
import { useBoardStore } from "@/store/board";
import cssValue from "@/styles/variable.module.scss";
import { toCssColor } from "@/utils/color";

type Args = {
  card: Card;
  cardMeta: CardMeta;
  board: Board;
  boardSize: { width: number; height: number };
  sizeOverride?: number;
  placeBy?: "order" | "position";
  col: number;
  row: number;
  zoomFactor: Ref<number>;
};

export const useStickyNoteConfig = ({
  card,
  board,
  boardSize,
  cardMeta,
  sizeOverride,
  placeBy,
  col,
  row,
  zoomFactor,
}: Args) => {
  // 0.0005 is a magic number that makes the font size a little bit bigger
  const newFontFactor = stickyFontFactor + 0.0005;

  const boardWidth = computed(() => boardSize.width * fakeZoom);
  const boardHeight = computed(() => boardSize.height * fakeZoom);

  const cardWidth = computed(() => {
    // when zoomFactor is not 1, we want to use the zoomed height and not the sizeOverride
    if (zoomFactor.value !== 1) {
      return zoomFactor.value * board.cardSize.x * boardSize.width * fakeZoom;
    }

    return sizeOverride || board.cardSize.x * boardSize.width * fakeZoom;
  });
  const cardHeight = computed(() => {
    // when zoomFactor is not 1, we want to use the zoomed height and not the sizeOverride
    if (zoomFactor.value !== 1) {
      return zoomFactor.value * board.cardSize.y * boardSize.height * fakeZoom;
    }

    return sizeOverride || board.cardSize.y * boardSize.height * fakeZoom;
  });

  const zIndex = computed(() => {
    if (zoomFactor.value > 1) {
      return board.maxZ + 100;
    }
    // TODO: take care or normal mode
    return isLowLight(cardMeta.mark, "normal")
      ? cssValue.zIndexLinks
      : cardMeta.zIndex;
  });

  const offset = computed(() => ({ x: 0, y: 0 }));
  const top = computed(() => {
    const y = placeBy === "position" ? cardMeta.pos.y : row;
    return y * boardHeight.value - cardHeight.value / 2;
  });
  const left = computed(() => {
    const x = placeBy === "position" ? cardMeta.pos.x : col;
    return x * boardWidth.value - cardWidth.value / 2;
  });
  const bodyTop = computed(() => offset.value.y + top.value);
  const bodyLeft = computed(() => offset.value.x + left.value);

  const backgroundColor = computed(() => toCssColor(card.type.color));

  const stickyFontSize = computed(() => {
    return cardWidth.value * newFontFactor;
  });

  const isStickyNoteActive = computed(
    () => card.id === useBoardStore().activeCardId,
  );

  const style = computed(() => ({
    fontSize: stickyFontSize.value + "rem",
    zIndex: zIndex.value,
    backgroundColor: backgroundColor.value,
    top: bodyTop.value + "px",
    left: bodyLeft.value + "px",
    width: cardWidth.value + "px",
    height: cardHeight.value + "px",
  }));

  const classes = computed(() => {
    return {
      faded: isFullyFaded(cardMeta.mark),
      active: isStickyNoteActive.value,
    };
  });

  return {
    zoomFactor,
    cardHeight,
    cardWidth,
    bodyLeft,
    bodyTop,
    backgroundColor,
    zIndex,
    style,
    classes,
  };
};
