<script lang="ts" setup></script>

<template>
  <div class="base-list-title">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.base-list-title {
  display: flex;
  align-items: center;
  font-weight: font.$weight-bold;
  font-size: 12px;
  border-bottom: 1px solid colors-old.$hover-input-border-color;
  color: colors-old.$text-secondary-color;
  padding: 12px;
}
</style>
