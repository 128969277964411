<script lang="ts" setup>
import { cardActions } from "@/action/cardActions";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { cardKey } from "@/components/card/injectKeys";
import { Reaction, reactions } from "@/model";
import { injectStrict } from "@/utils/context";

const card = injectStrict(cardKey);

const handlePointerEnter = (event: PointerEvent) => {
  const target = event.target as HTMLElement;
  const siblings = target.parentElement?.children || [];

  Array.from(siblings).forEach((sibling) => {
    if (sibling === target) {
      sibling.classList.add("active");
      return;
    }
    sibling.classList.add("transparent");
  });
};
const handlePointerLeave = (event: PointerEvent) => {
  const target = event.target as HTMLElement;
  const siblings = target.parentElement?.children || [];

  Array.from(siblings).forEach((sibling) => {
    sibling.classList.remove("active", "transparent");
  });
};

const handleToggleReaction = (reaction: Reaction) => {
  cardActions.toggleReaction("card", card.id, reaction);
};
</script>

<template>
  <div class="sticky-reactions">
    <span
      v-for="reaction in reactions"
      :key="reaction"
      :class="['reaction']"
      @pointerenter="handlePointerEnter"
      @pointerleave="handlePointerLeave"
      @pointerup="handleToggleReaction(reaction)"
    >
      <SvgIcon :name="`misc/${reaction}`" size="20" />
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use "sass:color";
@use "@/styles/colors";

.sticky-reactions {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 8px;
  border-radius: 30px;
  overflow: hidden;

  .reaction {
    position: relative;
    cursor: pointer;

    &.active {
      display: inline-block;
      transform: scale(1.4);
    }

    &.transparent::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: color.change(colors.$back-color, $alpha: 0.3);
    }

    transform: scale(1.2);
  }
}
</style>
