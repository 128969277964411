import {
  Line,
  RelativeCoordinate,
  minus,
  relativeCoord,
  times,
} from "@/math/coordinates";

export function useSvgShapes(width: number, height: number) {
  function rectAround(pos: RelativeCoordinate, size: number) {
    return {
      ...minus(times(pos, width, height), relativeCoord(size / 2, size / 2)),
      width: size,
      height: size,
    };
  }

  function rectAlong(line: Line<RelativeCoordinate>, size: number) {
    const x =
      line.p0.x < line.p1.x ? [line.p0.x, line.p1.x] : [line.p1.x, line.p0.x];
    const y =
      line.p0.y < line.p1.y ? [line.p0.y, line.p1.y] : [line.p1.y, line.p0.y];
    return {
      x: x[0] * width - size / 2,
      y: y[0] * height - size / 2,
      width: (x[1] - x[0]) * width + size,
      height: (y[1] - y[0]) * height + size,
    };
  }

  function line(line: Line<RelativeCoordinate>) {
    return {
      x1: line.p0.x * width,
      y1: line.p0.y * height,
      x2: line.p1.x * width,
      y2: line.p1.y * height,
    };
  }

  return {
    line,
    rectAroundPoint(size: number) {
      return (pos: RelativeCoordinate) => rectAround(pos, size);
    },
    rectAlongLine(size: number) {
      return (line: Line<RelativeCoordinate>) => rectAlong(line, size);
    },
  };
}
