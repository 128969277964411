<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import { cardKey } from "@/components/card/injectKeys";
import { Reaction } from "@/model";
import { useBoardStore } from "@/store/board";
import { injectStrict } from "@/utils/context";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";
import StickyNoteRow from "../../../StickyNoteRow/StickyNoteRow.vue";
import StickyLinksChip from "../StickyLinksChip/StickyLinksChip.vue";
import StickyReactions from "../StickyReactions/StickyReactions.vue";

const card = injectStrict(cardKey);

const stickyHasAllReactions = computed(() => {
  if (!card.reactions) return false;
  return Object.values(card.reactions).every((arr) => arr.length > 0);
});

const handleToggleReaction = (reaction: Reaction) => {
  cardActions.toggleReaction("card", card.id, reaction);
};

const isSelected = computed(() => {
  return card.id === useBoardStore().activeCardId;
});
</script>

<template>
  <StickyNoteRow class="note-footer">
    <AttributeChip v-if="!stickyHasAllReactions" icon="thin/emoji">
      <StickyReactions />
    </AttributeChip>
    <template v-for="(val, key) in card.reactions">
      <AttributeChip
        v-if="val.length > 0"
        :key="key"
        :icon="`misc/${key}`"
        :text="val.length.toString()"
        @click="handleToggleReaction(key)"
      />
    </template>
    <StickyLinksChip v-if="isSelected" />
  </StickyNoteRow>
</template>
