<script lang="ts" setup>
import { createPopper } from "@popperjs/core";
import {
  ComponentPublicInstance,
  computed,
  nextTick,
  onMounted,
  provide,
  ref,
  watch,
} from "vue";

import { boardActions } from "@/action/boardActions";
import { cardActions } from "@/action/cardActions";
import { linkActions } from "@/action/linkActions";
import StickyNoteFilter from "@/components-ng/StickyNote/components/StickyNoteFilter/StickyNoteFilter.vue";
import { registerCardDrag } from "@/components/card/cardDragHandler";
import ActionMenu from "@/components/card/components/ActionMenu/ActionMenu.vue";
import {
  boardKey,
  cardKey,
  cardMethodsKey,
} from "@/components/card/injectKeys";
import { useAnimation } from "@/composables/useAnimation";
import { useDelayedAction } from "@/composables/utils/useDelayedAction";
import { onZoomEnd, onZoomStart } from "@/mixins/EventBusUser";
import { Board, Card, CardMeta } from "@/model";
import { useBoardStore } from "@/store/board";
import { useDraggingStore } from "@/store/dragging";
import { useLinkStore } from "@/store/link";
import { useUserStore } from "@/store/user";

import StickyNoteFooter from "./components/StickyNoteFooter/StickyNoteFooter.vue";
import StickyNoteHeader from "./components/StickyNoteHeader/StickyNoteHeader.vue";
import StickyNotePin from "./components/StickyNotePin/StickyNotePin.vue";
import StickyNoteProgress from "./components/StickyNoteProgress/StickyNoteProgress.vue";
import StickyNoteTextInput from "./components/StickyNoteTextInput/StickyNoteTextInput.vue";
import { useStickyNoteConfig } from "./composables/useStickyNoteConfig";
import { useStickyNoteZoom } from "./composables/useStickyNoteZoom";

type Props = Readonly<{
  board: Board;
  card: Card;
  meta: CardMeta;
  boardSize: { width: number; height: number };
  sizeOverride?: number; // used to set the card size on backlog boards
  placeBy?: "order" | "position";
  col?: number;
  row?: number;
  draggable?: boolean;
}>;

const props = withDefaults(defineProps<Props>(), {
  col: -1,
  row: -1,
  placeBy: "position",
  draggable: true,
});

// provide / inject
provide(cardKey, props.card);
provide(boardKey, props.board);

// to be updated later on
provide(cardMethodsKey, {
  animate(_style, action) {
    if (action) action();
  },
  animateCopy(_style) {},
  removePin() {},
});

const stickyWasDragged = ref(false);
const isBoardZoomInProgress = ref(false);
const isReadOnly = computed(() => !useUserStore().isAllowed("edit"));
const isZoomedIn = computed(() => zoomFactor.value > 1);
const zoomFactor = ref(1); // used to calculate the width and height of the sticky note

const stickyNoteRef = ref<HTMLDivElement>();
const textInputRef = ref<ComponentPublicInstance<typeof StickyNoteTextInput>>();
const actionMenuRef = ref<HTMLDivElement>();

// computed
const isStickyNoteActive = computed(
  () => props.card.id === useBoardStore().activeCardId,
);

const isActionMenuOpen = computed(() => {
  return (
    isStickyNoteActive.value &&
    !props.meta.dragging &&
    !isReadOnly.value &&
    !isBoardZoomInProgress.value
  );
});

const isPinned = computed(() =>
  useLinkStore().isMarkingLinkedCards(props.card.id),
);

// composables
const { animateOn } = useAnimation(stickyNoteRef);
animateOn("transition-zooming", zoomFactor);

const { zoomOut } = useStickyNoteZoom({
  el: stickyNoteRef,
  card: props.card,
  zoomFactor,
});

const config = useStickyNoteConfig({
  card: props.card,
  cardMeta: props.meta,
  board: props.board,
  boardSize: props.boardSize,
  sizeOverride: props.sizeOverride,
  col: props.col,
  row: props.row,
  placeBy: props.placeBy,
  zoomFactor,
});

// event handlers
const handleStickyNotePointerDown = (event: PointerEvent) => {
  if (isPinned.value) {
    delayedUnpinStickyNote();
  } else {
    delayedPinStickyNote();
  }

  if (!props.draggable || isZoomedIn.value) return;
  registerCardDrag(props.card, () => {}, event);
};

const handleStickyNoteClick = (event: MouseEvent) => {
  const target = event.target as HTMLElement;

  cancelPinStickyNote();
  cancelUnpinStickyNote();

  // prevents the card from being selected when the user drags the card
  if (stickyWasDragged.value) {
    event.stopPropagation();
    stickyWasDragged.value = false;
    return;
  }

  // don't set the sticky note as active if the user clicks on [data-ignore-click] element
  if (target.closest("[data-ignore-click]")) {
    return;
  }

  textInputRef.value?.focus();
  cardActions.startAlter("internal", props.card.id);
  boardActions.cardToFront("card", props.card.id);

  useBoardStore().setActiveCardId(props.card.id);
  document.addEventListener("pointerdown", handleOutsideClick, true);
};

/**
 * Set the activeCardId to null when the user clicks outside of the card
 * @param event
 */
const handleOutsideClick = (event: MouseEvent) => {
  const target = event.target as HTMLElement;

  // the click came from the same sticky note
  if (stickyNoteRef.value?.contains(target)) {
    return;
  }

  // we ignore clicks from any html element that has data-ignore-click attribute so the sticky note
  // does not get deselected when we slick on an item which was teleported to the body
  if (target.closest("[data-ignore-click]")) {
    return;
  }

  // if the sticky note was zoomed in we zoom out
  if (isZoomedIn.value) {
    zoomOut();
  }

  // the click was outside the current sticky note
  useBoardStore().setActiveCardId(null);
  cardActions.stopAlter("internal", props.card.id);
  document.removeEventListener("pointerdown", handleOutsideClick, true);
};

const pinStickyNote = () => {
  if (isPinned.value || useDraggingStore().isLinkDragging) return;
  linkActions.markCardLinkedCards("mouse", props.board.cards[props.card.id]);
};

const unpinStickyNote = () => {
  if (!isPinned.value || useDraggingStore().isLinkDragging) return;
  useLinkStore().removeAllMarks();
};

const [delayedPinStickyNote, cancelPinStickyNote] = useDelayedAction(() => {
  pinStickyNote();
}, 500);

const [delayedUnpinStickyNote, cancelUnpinStickyNote] = useDelayedAction(
  () => unpinStickyNote(),
  500,
);

// show the action menu
watch(isActionMenuOpen, async () => {
  await nextTick();

  if (!actionMenuRef.value || !stickyNoteRef.value || !isActionMenuOpen.value) {
    return;
  }

  // this can be moved to the action menu
  createPopper(stickyNoteRef.value, actionMenuRef.value, {
    placement: "top",
    modifiers: [
      { name: "offset", options: { offset: [0, 5] } },
      { name: "flip", enabled: false },
    ],
  });
});

// Used to check of the sticky was dragged before the mouse button release
watch(
  () => props.meta.dragging,
  (dragging) => {
    if (dragging && !stickyWasDragged.value) {
      stickyWasDragged.value = true;
    }
    //cancel the delayed actions if the sticky was dragged
    if (dragging) {
      cancelPinStickyNote();
      cancelUnpinStickyNote();
    }
  },
);

// life hooks
onMounted(() => {
  onZoomStart(() => {
    if (isStickyNoteActive.value) {
      isBoardZoomInProgress.value = true;
    }
  });
  onZoomEnd(() => {
    if (isStickyNoteActive.value) {
      isBoardZoomInProgress.value = false;
    }
  });
});
</script>

<template>
  <div
    :id="card.id"
    ref="stickyNoteRef"
    :class="['sticky-note', { ...config.classes.value }]"
    :style="config.style.value"
    @pointerdown="handleStickyNotePointerDown"
    @click.capture="handleStickyNoteClick"
  >
    <StickyNotePin v-if="isPinned" @click="unpinStickyNote" />
    <StickyNoteHeader />
    <StickyNoteTextInput ref="textInputRef" />
    <StickyNoteFooter />
    <StickyNoteProgress v-if="isStickyNoteActive" />
    <StickyNoteFilter :mark="meta.mark" />
  </div>

  <Teleport v-if="isActionMenuOpen" to="body">
    <div :ref="(el: any) => (actionMenuRef = el)">
      <ActionMenu />
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "./utils";

.sticky-note {
  position: absolute;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  font-size: 12px;
  line-height: 1.25;
  outline: utils.px-to-em(1px) solid colors-old.$shortcut-color;

  &.faded {
    background-color: colors-old.$lowlight-color;
  }

  &.active {
    outline: utils.px-to-em(2px) solid colors-old.$primary-color;
  }

  &.transition-zooming {
    transition: all 0.15s;
  }
}
</style>
