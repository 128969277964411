<script setup lang="ts">
import { cardActions } from "@/action/cardActions";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import type { Team } from "@/model";
import { NO_TEAM_ID } from "@/store/team";
import { injectStrict } from "@/utils/context";

import TeamSelector from "../TeamSelector/TeamSelector.vue";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);

const handleChangeDependantOn = (team: Team | null) => {
  cardActions.setDepend("card", card.id, team?.id || NO_TEAM_ID);
  dropdown.close();
};
</script>

<template>
  <div v-scrollable-on-board class="dependon-sticky">
    <TeamSelector
      show-search
      show-other-arts
      :show-clear="false"
      :show-current-team="false"
      :title="$t('actionMenu.dependentOn')"
      @select="handleChangeDependantOn"
    />
  </div>
</template>

<style lang="scss" scoped>
.dependon-sticky {
  max-height: 350px;
}
</style>
