// iconLoader.ts
/// <reference types="vite-svg-loader" />
import { ComponentPublicInstance, defineAsyncComponent } from "vue";

import SvgLoadingPlaceholder from "@/components/SvgIcon/SvgLoadingPlaceholder.vue";
import PlaceHolderIcon from "@/icons/placeholder.svg?component";
import { captureException } from "@/sentry";
import { Icon } from "@/types/icon";

const modules = import.meta.glob("/src/icons/**/*.svg", { as: "component" });

export const getIconComponent = (name: Icon) => {
  return defineAsyncComponent({
    loader: async () => {
      try {
        return (await modules[
          `/src/icons/${name}.svg`
        ]()) as ComponentPublicInstance;
      } catch (err) {
        captureException(err);
        return PlaceHolderIcon;
      }
    },
    // Render an empty div while the icon is loading so there's
    // no layout shift when the icon is finally loaded
    loadingComponent: SvgLoadingPlaceholder,
    delay: 0,
  });
};

// Preload icons in batches to avoid blocking the main thread
export const preloadIcons = async (
  icons: Icon[] | "all",
  batchSize: number,
) => {
  const batches: Icon[][] = [];

  if (icons === "all") {
    const keys = Object.keys(modules).map((path) => {
      const match = path.match(/\/src\/icons\/(.*)\.svg/);
      return match ? match[1] : "";
    }) as Icon[];

    for (let i = 0; i < keys.length; i += batchSize) {
      batches.push(keys.slice(i, i + batchSize));
    }
  } else {
    for (let i = 0; i < icons.length; i += batchSize) {
      batches.push(icons.slice(i, i + batchSize));
    }
  }

  for (const batch of batches) {
    const promises = batch.map(async (name) => {
      try {
        await modules[`/src/icons/${name}.svg`]();
      } catch (err) {
        captureException(err);
      }
    });

    // Wait for current batch to preload
    await Promise.all(promises);
  }
};
