<template>
  <search-results-item
    class="search-results-item"
    :item="cardContent"
    :show-status="showStatus"
  />
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

import { SearchResultGroupItem } from "@/components/Search/SearchResult";
import SearchResultsItem from "@/components/Search/SearchResultsItem.vue";
import { Card } from "@/model";
import { useTeamStore } from "@/store/team";

@Component({ components: { SearchResultsItem } })
export default class CardRow extends Vue {
  @Prop({ type: Object, required: true }) readonly card!: Card;
  @Prop({ default: false, type: Boolean }) readonly showTeamName!: boolean;
  @Prop({ default: false, type: Boolean }) readonly showStatus!: boolean;

  get cardContent(): SearchResultGroupItem {
    const teamName = !this.showTeamName
      ? ""
      : this.card.teamId
      ? useTeamStore().findTeam({ id: this.card.teamId })?.name
      : this.$t("general.noTeam");
    return {
      id: this.card.id,
      text: this.card.text,
      color: this.card.type.color,
      type: this.card.type.name,
      flag: this.card.flagType.isEmpty() ? null : this.card.flagType.toString(),
      almId: this.card.almId,
      status: this.card.status,
      teamName,
    };
  }
}
</script>

<style lang="scss" scoped>
.search-results-item {
  padding: 0.625rem 0 !important;
}
</style>
