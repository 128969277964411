<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import BasePopup from "@/components-ng/BasePopup/BasePopup.vue";
import { dropdownKey } from "@/components/DropdownMenu/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import type { Art } from "@/model";
import { NO_ART_ID, useArtStore } from "@/store/art";
import { injectStrict } from "@/utils/context";

import ArtSelector from "../ArtSelector/ArtSelector.vue";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);

const arts = computed(() => useArtStore().arts);
const selected = computed(() => {
  const found = arts.value.find((art) => art.id === card.artId);
  return found || null;
});

const handleArtChange = (art: Art | null) => {
  cardActions.setArt("card", card.id, art?.id || NO_ART_ID);
  dropdown.close();
};
</script>

<template>
  <BasePopup class="change-art">
    <ArtSelector :selected="selected" :arts="arts" @select="handleArtChange" />
  </BasePopup>
</template>

<style lang="scss" scoped>
.change-art {
  width: 200px;
  max-height: 350px;
}
</style>
