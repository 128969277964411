<template>
  <BaseButton
    class="objective-btn"
    variant="ghost"
    color="primary"
    size="medium"
    icon-before="plus"
  >
    {{ $t("objectives.add") }}
  </BaseButton>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import BaseButton from "../ui/BaseButton/BaseButton.vue";

@Options({ components: { BaseButton } })
export default class AddObjectiveButton extends Vue {}
</script>
