/*
 * Convert a window coordinate into a board coordinate.
 * @param e: The window coordinate to be converted.
 * @param limitCoords: If the coordinate should be checked and adjusted if needed
 *    - "input": Check the window coordinate parameter to be inside the window
 *    - "output": Check the resulting board coordinate to be inside the board
 * @param additionalOffsets: A list of additional offsets to the given coordinate that should also be checked.
 */
import { clamp } from "lodash-es";

import { boardAspect, cornerFactor, fakeZoom } from "@/Settings";
import { isFeatureEnabled } from "@/feature";
import {
  BoardCoordinate,
  Rectangle,
  RelativeCoordinate,
  WindowCoordinate,
  boardCoord,
  clientCoord,
  divided,
  minus,
  plus,
  relativeCoord,
  times,
  windowCoord,
} from "@/math/coordinates";
import { AppSize } from "@/model";
import { getRouter } from "@/router";
import { useActivityStore } from "@/store/activity";
import { useAppSizeStore } from "@/store/appSize";
import { useBoardStore } from "@/store/board";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useTimerStore } from "@/store/timer";

export function windowToBoard(
  e: WindowCoordinate,
  limitCoords: "input" | "output" | "none" = "output",
  additionalOffsets: Array<{ offset: WindowCoordinate }> = [],
  appSize: AppSize = useAppSizeStore().appSize,
): BoardCoordinate {
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  const limited = limit(e);
  for (const additional of additionalOffsets) {
    combineDeltas(
      limited,
      limit(plus(e, times(additional.offset, appSize.zoom))),
    );
  }
  return times(plus(limited, limited.delta), fakeZoom);

  function limit(e: WindowCoordinate) {
    const inp = limitCoords === "input" ? insideWindow(e) : e;
    const c = boardCoord(
      (inp.x - appSize.left) / appSize.zoom - xHalf * appSize.width,
      (inp.y - appSize.top) / appSize.zoom - yHalf * appSize.height,
    );
    return {
      ...c,
      delta:
        limitCoords === "output" ? minus(insideBoard(c), c) : boardCoord(0, 0),
    };
  }

  function combineDeltas(
    target: { delta: BoardCoordinate },
    combine: { delta: BoardCoordinate },
  ) {
    if (Math.abs(combine.delta.x) > Math.abs(target.delta.x)) {
      target.delta.x = combine.delta.x;
    }
    if (Math.abs(combine.delta.y) > Math.abs(target.delta.y)) {
      target.delta.y = combine.delta.y;
    }
  }
}

function insideWindow(e: WindowCoordinate): WindowCoordinate {
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  const appSize = useAppSizeStore().appSize;
  return windowCoord(
    clamp(
      e.x,
      xHalf * appSize.width + appSize.padding.left,
      window.innerWidth - xHalf * appSize.width,
    ),
    clamp(
      e.y,
      yHalf * appSize.height + appSize.padding.top,
      window.innerHeight - yHalf * appSize.height,
    ),
  );
}

function insideBoard(e: BoardCoordinate): BoardCoordinate {
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  const h = useAppSizeStore().appSize.height;
  return boardCoord(
    clamp(e.x, 0, (1 - 2 * xHalf) * useAppSizeStore().appSize.width),
    clamp(
      e.y,
      yHalf * 2 * getCornerFactor() * h,
      (1 - yHalf * 2 * (1 - getCornerFactor())) * h,
    ),
  );
}

export function windowToRelative(
  e: WindowCoordinate,
  appSize: AppSize = useAppSizeStore().appSize,
): RelativeCoordinate {
  const xBase = (e.x - appSize.left + window.scrollX) / appSize.zoom;
  const yBase = (e.y - appSize.top + window.scrollY) / appSize.zoom;
  return relativeCoord(xBase / appSize.width, yBase / appSize.height);
}

export function relativeToWindow(
  e: RelativeCoordinate,
  appSize: AppSize = useAppSizeStore().appSize,
): WindowCoordinate {
  return windowCoord(
    appSize.zoom * (e.x * appSize.width) + appSize.left,
    appSize.zoom * (e.y * appSize.height) + appSize.top,
  );
}

export function boardSize(): Rectangle<WindowCoordinate> {
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  const h = useAppSizeStore().appSize.height;
  const p0 = boardToWindow(
    boardCoord(0, fakeZoom * yHalf * 2 * getCornerFactor() * h),
  );
  const p1 = boardToWindow(
    boardCoord(
      fakeZoom * (1 - 2 * xHalf) * useAppSizeStore().appSize.width,
      fakeZoom * (1 - yHalf * 2 * (1 - getCornerFactor())) * h,
    ),
  );
  return {
    p0: windowCoord(Math.floor(p0.x), Math.floor(p0.y)),
    p1: windowCoord(Math.ceil(p1.x), Math.ceil(p1.y)),
  };
}

export function boardToWindow(
  e: BoardCoordinate,
  appSize: AppSize = useAppSizeStore().appSize,
): WindowCoordinate {
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  return windowCoord(
    (e.x / fakeZoom + xHalf * appSize.width) * appSize.zoom + appSize.left,
    (e.y / fakeZoom + yHalf * appSize.height) * appSize.zoom + appSize.top,
  );
}

export function boardToWindowSimple(c: BoardCoordinate): WindowCoordinate {
  return divided(c, fakeZoom) as unknown as WindowCoordinate;
}

export function windowToBoardSimple(c: WindowCoordinate): BoardCoordinate {
  return times(c, fakeZoom) as unknown as BoardCoordinate;
}

export function boardToRelative(
  coordinates: Partial<BoardCoordinate>,
): RelativeCoordinate {
  if (coordinates.x === undefined || coordinates.y === undefined) {
    return relativeCoord(0.5, 0.5);
  }
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  return relativeCoord(
    coordinates.x / (useAppSizeStore().appSize.width * fakeZoom) + xHalf,
    coordinates.y / (useAppSizeStore().appSize.height * fakeZoom) +
      yHalf * (1 - 2 * getCornerFactor()),
  );
}

export function boardToRelativeSimple(e: BoardCoordinate): RelativeCoordinate {
  return relativeCoord(
    e.x / (useAppSizeStore().appSize.width * fakeZoom),
    e.y / (useAppSizeStore().appSize.height * fakeZoom),
  );
}

export function relativeToBoard(e: RelativeCoordinate): BoardCoordinate {
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  return boardCoord(
    (e.x + xHalf) * useAppSizeStore().appSize.width * fakeZoom,
    (e.y + yHalf) * useAppSizeStore().appSize.height * fakeZoom,
  );
}

export function cardInsideBoardAndViewport(
  a: BoardCoordinate,
  size: BoardCoordinate,
) {
  const appSize = useAppSizeStore().appSize;
  // Adjust viewport size for any open side panels
  const panelWidth = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue(
      "--side-panel-width",
    ) || "0",
  );
  const leftPanelSize = useSearchMenuStore().isSearchActive ? panelWidth : 0;
  const rightPanelSize =
    useTimerStore().active || useActivityStore().active ? panelWidth : 0;
  // card inside board
  if (a.x < 0) {
    a.x = 0;
  }
  let x = a.x + size.x - appSize.width * fakeZoom;
  if (x > 0) {
    a.x -= x;
  }
  if (a.y < 0) {
    a.y = 0;
  }
  let y = a.y + size.y - appSize.height * fakeZoom;
  if (y > 0) {
    a.y -= y;
  }
  // card inside view port (and not hidden by side panels)
  const viewportLeft = window.scrollX - appSize.left + leftPanelSize;
  const viewportRight =
    viewportLeft + window.innerWidth - leftPanelSize - rightPanelSize;
  const viewportTop = window.scrollY - appSize.top;

  x = a.x * appSize.zoom - viewportLeft * fakeZoom;
  if (x < 0) {
    a.x -= x / appSize.zoom;
  }
  x = (a.x + size.x) * appSize.zoom - viewportRight * fakeZoom;
  if (x > 0) {
    a.x -= x / appSize.zoom;
  }
  y = a.y * appSize.zoom - viewportTop * fakeZoom;
  if (y < 0) {
    a.y -= y / appSize.zoom;
  }
  y =
    (a.y + size.y) * appSize.zoom -
    (viewportTop + window.innerHeight) * fakeZoom;
  if (y > 0) {
    a.y -= y / appSize.zoom;
  }
}

export function calcBoardSize(
  zoom: number = useAppSizeStore().appSize.zoom,
): AppSize {
  const size = useAppSizeStore().appSize;
  // use window.innerWidth because it does NOT change when a scrollbar is shown/hidden
  // thus results in smoother zooming
  const cw = size.margin.right
    ? window.innerWidth - size.margin.right
    : window.innerWidth - size.margin.left;

  const ch = window.innerHeight - size.margin.top;
  const ratio = cw / ch;
  const top = size.padding.top + size.margin.top;
  const left = size.margin.right ? 0 : size.padding.left + size.margin.left;
  if (ratio < boardAspect) {
    const h = cw / boardAspect;
    return {
      top: top + Math.max(0, (ch - h * zoom) / 2),
      left: left + Math.max(0, (cw * (1 - zoom)) / 2),
      height: h,
      width: cw,
      zoom,
      padding: { ...size.padding },
      margin: { ...size.margin },
    };
  }
  const w = ch * boardAspect;
  return {
    top: top + Math.max(0, (ch * (1 - zoom)) / 2),
    left: left + Math.max(0, (cw - w * zoom) / 2),
    height: ch,
    width: w,
    zoom,
    padding: { ...size.padding },
    margin: { ...size.margin },
  };
}

// Temporal solution to disable cornerFactor for the new implementation sticky notes
const getCornerFactor = () => {
  const router = getRouter();
  if (!router) {
    return cornerFactor;
  }

  if (isFeatureEnabled(router.currentRoute.value, "sticky-note")) {
    return 0;
  }

  return cornerFactor;
};

export function relativeClientCoord(elem: MouseEvent): RelativeCoordinate {
  return windowToRelative(clientCoord(elem));
}
