<template>
  <base-board-input
    v-model="inputValue"
    class="board-number-input"
    type="text"
    :maxlength="maxlength"
    :no-borders="noBorders"
    @keypress="keyPressed"
    @keydown.esc.stop="unfocus"
  />
</template>

<script lang="ts">
import { Options, Prop, Vue } from "vue-property-decorator";

import BaseBoardInput from "@/components/input/BaseBoardInput.vue";

const Component = Options;

@Component({ components: { BaseBoardInput }, emits: ["input"] })
export default class BoardNumberInput extends Vue {
  @Prop([String, Number]) readonly value!: string | number;
  @Prop([String, Number]) readonly maxlength!: string | number;
  @Prop({ type: Boolean, default: false }) readonly noBorders!: boolean;
  @Prop({ type: Boolean, default: false }) readonly allowFloat!: boolean;

  allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
  numbers = this.allowFloat
    ? this.allowedChars
    : this.allowedChars.slice(0, -1);

  get inputValue() {
    return "" + this.value;
  }

  set inputValue(newValue) {
    this.$emit("input", +newValue);
  }

  keyPressed(event: KeyboardEvent) {
    if (!this.numbers.includes(event.key)) {
      event.preventDefault();
    }
  }

  unfocus() {
    (this.$el as HTMLElement).blur();
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.board-number-input {
  &:read-only {
    border: none;
    cursor: default;
    background-color: colors-old.$input-readonly-background-color;
  }
}
</style>
