<script setup lang="ts">
import { computed } from "vue";

import { linkActions } from "@/action/linkActions";
import CardTree from "@/components/CardTree.vue";
import LinkableCardRow from "@/components/LinkableCardRow.vue";
import NavigableList from "@/components/NavigableList.vue";
import BaseModal from "@/components/modal/BaseModal.vue";
import { BoardData, Card } from "@/model";
import { useBoardStore } from "@/store/board";
import { useLinkStore } from "@/store/link";
import { useModalStore } from "@/store/modal";
import { useObjectiveStore } from "@/store/objective";
import { hasItemInSets } from "@/utils/general";

import { LinkableCard } from "./stickyLinkStickies";

const props = defineProps<{ boardId: string; objectiveId: string }>();
const modals = useModalStore();

function getLinkedCardIds(cardId: Card["id"]) {
  return teamObjectiveBacklogLinks.value.has(cardId)
    ? teamObjectiveBacklogLinks.value.get(cardId)
    : new Set();
}

const board = computed(
  () => useBoardStore().boardById(props.boardId) as BoardData<"team">,
);
const objective = computed(
  () =>
    useObjectiveStore().teamObjectiveById(props.objectiveId, {
      teamId: board.value.team.id,
    })!,
);
const stickies = computed(() => useBoardStore().teamVisibleStickies());
const teamObjectiveBacklogLinks = computed(() =>
  useLinkStore().teamObjectiveBacklogLinks(objective.value.id),
);
const linkedStickies = computed(() => {
  const originCards = objective.value.cards
    .filter(({ isOrigin, id }) => isOrigin && Boolean(stickies.value[id]))
    .map(({ id }) => stickies.value[id].data);

  return originCards
    .filter(({ id, type }) => {
      if (type.origin === "backlog") {
        return true;
      }

      const isChildOfAnyBacklogItem = hasItemInSets(
        id,
        teamObjectiveBacklogLinks.value,
      );
      return !isChildOfAnyBacklogItem;
    })
    .map((card) => {
      return {
        ...card,
        linked: true,
      };
    });
});

async function removeLink(sticky: LinkableCard) {
  await linkActions.removeObjective(
    "modal",
    sticky.id,
    board.value.id,
    objective.value.id,
  );
  modals.hide();
}
</script>

<template>
  <BaseModal
    :title="$t('objectiveLinkModal.title')"
    class="objective-link-modal"
  >
    <div class="scrollable-list">
      <NavigableList :first-list="linkedStickies" @select="removeLink">
        <template #row="{ item }">
          <CardTree
            :linked-card-ids="getLinkedCardIds(item.id)"
            :card="item"
            :board="board"
          >
            <LinkableCardRow
              :linkable-card="item"
              show-team-name
              show-actions
            />
          </CardTree>
        </template>
      </NavigableList>
    </div>
    <span v-if="!linkedStickies.length" class="empty">
      {{ $t("general.noLinkedStickies") }}
    </span>
  </BaseModal>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.objective-link-modal {
  .empty {
    display: inline-flex;
    padding: 3rem 0;
    color: colors-old.$text-secondary-color;
    justify-content: center;
    width: 100%;
    margin: auto;
  }
}
</style>
