<script lang="ts" setup>
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import DependOnSticky from "@/components/card/components/ActionMenu/components/DependOnSticky/DependOnSticky.vue";
import { cardKey } from "@/components/card/injectKeys";
import { injectStrict } from "@/utils/context";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";
import MirrorStickyChip from "../MirrorStickyChip/MirrorStickyChip.vue";
import StickyLinksChip from "../StickyLinksChip/StickyLinksChip.vue";

const card = injectStrict(cardKey);
</script>

<template>
  <div class="dependency-footer">
    <div class="row">
      <StickyLinksChip />
    </div>
    <div class="row">
      <div class="team precond">{{ card.dependTeam?.name }}</div>
      <SvgIcon class="icon" name="arrow/right-thin" />
      <div v-if="card.dependTeam" class="team depend">
        {{ card.dependTeam.name }}
      </div>
      <AttributeChip v-else :text-placeholder="$t('actionMenu.dependentOn')">
        <DependOnSticky />
      </AttributeChip>

      <SvgIcon class="icon" name="arrow/right-thin" />
      <MirrorStickyChip class="mirror" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "../../../../utils";

.dependency-footer {
  display: flex;
  flex-direction: column;
  row-gap: utils.px-to-em(8px);
  max-width: 100%;

  .row {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: utils.px-to-em(20px);
    box-sizing: content-box;
    gap: utils.px-to-em(6px);

    > * {
      flex-shrink: 0;
    }

    .team {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.precond {
        flex-shrink: 2;
      }

      &.depend {
        flex-shrink: 1;
      }
    }

    .mirror {
      flex-shrink: 0;
    }

    & > .icon {
      width: utils.px-to-em(25px);
      flex-shrink: 0;
    }
  }
}
</style>
