<script setup lang="ts">
import { toggleActions } from "@/action/toggleActions";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import PlanReadoutModal from "@/components/PlanReadoutModal.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { useModalStore } from "@/store/modal";

const openPlanReadoutModal = () => {
  useModalStore().open(PlanReadoutModal);
};
</script>

<template>
  <DropdownMenu :offset="{ top: 14 }">
    <template #trigger="{ isOpen }">
      <IconButton
        icon="facilitation"
        :activated="isOpen"
        :tooltip="$t('action.facilitation')"
        data-testid="facilitation-button"
      />
    </template>
    <BaseButton
      variant="ghost"
      color="grey"
      icon-before="plan-readout"
      class="plan-readout-button"
      full-width
      @click="openPlanReadoutModal"
    >
      {{ $t("planReadout.title") }}
    </BaseButton>
    <BaseButton
      variant="ghost"
      color="grey"
      :icon-before="toggleActions.showTimer.data.icon"
      class="plan-readout-button"
      full-width
      @click="toggleActions.showTimer"
    >
      {{ $t(toggleActions.showTimer.data.name) }}
    </BaseButton>
  </DropdownMenu>
</template>

<style lang="scss" scoped>
@use "@/styles/font";

.plan-readout-button {
  justify-content: flex-start;
  font-weight: font.$weight-normal;
}

.tooltip {
  display: flex;
}
</style>
