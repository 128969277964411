<script setup lang="ts">
import { computed } from "vue";

import { Color } from "@/baseTypes";
import { toCssColor } from "@/utils/color";

const props = defineProps<{
  color: Color | string;
  size?: "small" | "medium" | "large";
}>();
const cssColor = computed(() =>
  Array.isArray(props.color) ? toCssColor(props.color) : props.color,
);
</script>

<template>
  <span
    class="type-dot"
    :class="size || 'small'"
    :style="{ backgroundColor: cssColor }"
  />
</template>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.type-dot {
  display: inline-block;
  flex-shrink: 0;
  border: 1px solid colors-old.$icon-border-color;

  &.small {
    width: 0.6em;
    height: 0.6em;
    border-radius: 0.6em;
  }

  &.medium {
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }

  &.large {
    width: 34px;
    height: 34px;
    border-radius: 4px;
  }

  &.absolute {
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    margin-left: 0.7em;
  }
}
</style>
