import { zoomState } from "@/Gestures";
import { ZoomLayer } from "@/ZoomLayer";
import BoardBase from "@/components/BoardBase";
import { applyFluidBoardSize } from "@/lowlevel";
import { calcBoardSize } from "@/math/coordinate-systems";
import {
  offZoomEnd,
  offZoomStart,
  onZoomEnd,
  onZoomStart,
} from "@/mixins/EventBusUser";
import { pushZoomState } from "@/router/navigation";
import { useAppSizeStore } from "@/store/appSize";
import { useBoardStore } from "@/store/board";
import { ElementState, addElements, removeElements } from "@/utils/dom";

export function zoomHandler(
  zoomLayer: ZoomLayer,
  currentBoard: () => BoardBase,
) {
  let zoomFactor = 0;
  let cardNodes: ElementState | null = null;
  let checkZoomTimeout = 0;

  onZoomStart(startZoom);
  onZoomEnd(endZoom);

  return {
    unregister() {
      offZoomStart(startZoom);
      offZoomEnd(endZoom);
    },
  };

  async function startZoom() {
    if (useBoardStore().isCurrentBoardFluid) {
      zoomFactor = useAppSizeStore().appSize.zoom;
      await zoomLayer.paintCards(
        currentBoard(),
        useBoardStore().currentBoard(),
      );
      // removing and adding cards directly using DOM (.1ms, .75ms) is around 2x faster that using vue.js (.2ms, 1.5ms)
      cardNodes = removeElements(document.querySelectorAll(".card"));
      window.setTimeout(checkZoom, 1);
    }
  }

  function endZoom() {
    if (useBoardStore().isCurrentBoardFluid) {
      useAppSizeStore().setAppZoom(zoomFactor);
      pushZoomState();
      if (cardNodes) {
        addElements(cardNodes);
        cardNodes = null;
      }
      window.clearTimeout(checkZoomTimeout);
      zoomLayer.hide();
    }
  }

  function checkZoom() {
    if (zoomFactor !== zoomState.factor) {
      zoomFactor = zoomState.factor;
      const size = calcBoardSize(zoomFactor);
      applyFluidBoardSize(size);
      zoomState.scrollToCenter(size);
    }
    checkZoomTimeout = window.setTimeout(checkZoom, 1);
  }
}
