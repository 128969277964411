<script setup lang="ts">
import { computed } from "vue";

import { Action } from "@/action/actions";
import { boardActions } from "@/action/boardActions";
import { cardActions } from "@/action/cardActions";
import { selectionActions } from "@/action/selectionActions";
import { toggleActions } from "@/action/toggleActions";
import { ExecutionMode } from "@/baseTypes";
import FluidBoard, { ContextActions } from "@/components/FluidBoard";
import ListItem from "@/components/ListItem/ListItem.vue";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import { windowToRelative } from "@/math/coordinate-systems";
import { WindowCoordinate } from "@/math/coordinates";
import { useBoardStore } from "@/store/board";
import { useDrawStore } from "@/store/draw";
import { useUserStore } from "@/store/user";

export type BoardContextMenuMode = "iteration" | "backlog" | "mouse";

const props = defineProps<{
  board: FluidBoard;
  position: WindowCoordinate;
  mode: BoardContextMenuMode;
}>();

defineEmits<{ dummy: [] }>();

const vKeyboardNav = keyboardNavigation();

const selectedCount = computed(() => useBoardStore().selectedStickyIds.length);
const editable = computed(() => useUserStore().isAllowed("edit"));
const relativePos = computed(() => windowToRelative(props.position));
const context = computed(() => props.board.contextActions(relativePos.value));
const general = computed(() => ({
  newSticky: props.mode === "mouse" && editable.value,
  screenshot: props.mode === "mouse" || props.mode === "backlog",
  syncBacklog: editable.value && context.value.syncProgramBacklog,
  draw: editable.value && context.value.draw,
}));
const anyGeneral = computed(() => Object.values(general).some((value) => value));

function newSticky() {
  cardActions.add("contextMenu", { pos: props.position });
}

function screenshot() {
  boardActions.screenshot("contextMenu");
}

function execute(action: keyof ContextActions | Action<[ExecutionMode]>) {
  if (typeof action === "function") {
    action("contextMenu", "normal");
  } else {
    props.board.doContextAction(action, relativePos.value);
  }
}

const boardEdit = computed(() => useUserStore().isAllowed("editBoard"));
</script>

<template>
  <div style="width: 300px" v-keyboard-nav>
    <template v-if="anyGeneral">
      <ListItem static class="title">
        {{ $t("contextMenu.general") }}
      </ListItem>
      <ListItem v-if="general.newSticky" @click="newSticky">
        <span>{{ $t("contextMenu.createNew") }}</span>
        <span class="btn">{{ cardActions.add.data.shortcut?.name() }}</span>
      </ListItem>
      <ListItem v-if="general.screenshot" @click="screenshot">
        <span>{{ $t("contextMenu.saveBoardImage") }}</span>
        <span class="btn">
          {{ boardActions.screenshot.data.shortcut?.name() }}
        </span>
      </ListItem>
      <ListItem v-if="general.syncBacklog" @click="execute('syncProgramBacklog')">
        {{ $t("contextMenu.synchronizeBacklog") }}
      </ListItem>
      <ListItem v-if="boardEdit && general.draw" @click="toggleActions.showDraw('contextMenu')">
        {{ useDrawStore().active ? $t("contextMenu.drawModeOff") : $t("contextMenu.drawModeOn") }}
      </ListItem>
    </template>

    <template v-if="editable && selectedCount > 1 && context.selection">
      <ListItem static class="title">
        {{ $t("contextMenu.selectedStickyCount", { selectedCount }) }}
      </ListItem>
      <ListItem v-if="context.selection.stickyMove" @click="execute(selectionActions.alignHorizontal)">
        {{ $t(selectionActions.alignHorizontal.data.name) }}
      </ListItem>
      <ListItem v-if="context.selection.stickyMove" @click="execute(selectionActions.distributeHorizontal)">
        {{ $t(selectionActions.distributeHorizontal.data.name) }}
      </ListItem>
      <ListItem v-if="context.selection.stickyMove" @click="execute(selectionActions.alignVertical)">
        {{ $t(selectionActions.alignVertical.data.name) }}
      </ListItem>
      <ListItem v-if="context.selection.stickyMove" @click="execute(selectionActions.distributeVertical)">
        {{ $t(selectionActions.distributeVertical.data.name) }}
      </ListItem>
      <ListItem v-if="context.selection.stickyMove" @click="execute(selectionActions.lineup)">
        {{ $t(selectionActions.lineup.data.name) }}
      </ListItem>
      <ListItem v-if="context.selection.link" @click="execute(selectionActions.link)">
        {{ $t(selectionActions.link.data.name) }}
      </ListItem>
    </template>

    <template v-if="context.region">
      <ListItem static class="title">
        {{ context.region.name }}
      </ListItem>
      <ListItem v-if="context.region.overview" @click="execute('overview')">
        <span>{{ $t(toggleActions.showOverview.data.name) }}</span>
        <span class="btn">
          {{ toggleActions.showOverview.data.shortcut?.name() }}
        </span>
      </ListItem>
      <ListItem v-if="context.region.zoom" @click="execute('zoom')">
        {{ $t("contextMenu.zoomToRegion") }}
      </ListItem>
      <ListItem v-if="editable && context.region.sync" @click="execute('sync')">
        {{ $t("contextMenu.synchronizeIteration") }}
      </ListItem>
      <ListItem v-if="editable && context.region.arrange" @click="execute('arrange')">
        {{ $t("boardBase.arrangeStickies") }}
      </ListItem>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins" as *;

.list-item {
  &.title {
    font-weight: font.$weight-extra-bold;
  }

  .btn {
    border: 1px solid colors-old.$shortcut-border-color;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: font.$size-smaller;

    @include shortcut-button-shadow;
  }
}
</style>
