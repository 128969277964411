import { Directive } from "vue";

/**
 * Makes the element on the board scrollable by preventing the scroll event from bubbling up.
 *
 */
export function scrollableOnBoard(): Directive<HTMLElement> {
  const handleScroll = (event: Event) => {
    event.stopPropagation();
  };

  return {
    mounted(el) {
      el.addEventListener("scroll", handleScroll, { passive: true });
    },
    unmounted(el) {
      el.removeEventListener("scroll", handleScroll);
    },
  };
}
