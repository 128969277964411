<template>
  <div class="team-objectives" @keydown.esc="removeObjective">
    <div class="header" @click="editObjectives">
      <h3 class="header-title">
        <SvgIcon name="board/objectives" size="1.25em" />
        <span>{{ $t("teamObjectives.teamObjectives") }}</span>
      </h3>
      <IconButton icon="expand" />
    </div>
    <div class="scrollable" @wheel="stopScrollPropagation" @scroll="onScroll">
      <div>
        <div class="objective-group-header">
          <span>
            <h3>{{ $t("objectives.committed") }}</h3>
          </span>
          <span>
            <h3 class="link">{{ $t("general.links") }}</h3>
            <h3 :title="$t('objectives.businessValue')">
              {{ $t("objectives.bv") }}
            </h3>
          </span>
        </div>
        <div
          v-for="(objective, index) in board.objectives"
          :key="objective.id"
          class="objective"
          tabindex="0"
          @click="toggleObjective(objective.id)"
          @blur="blurObjective"
        >
          <team-objective-row
            :index="index + 1"
            :objective="objective"
            :selected="isSelected(objective.id)"
          />
        </div>
        <div />
        <div class="objective-group-header">
          <span>
            <h3>{{ $t("objectives.uncommitted") }}</h3>
          </span>
          <span>
            <h3 class="link">{{ $t("general.links") }}</h3>
            <h3 :title="$t('objectives.businessValue')">
              {{ $t("objectives.bv") }}
            </h3>
          </span>
        </div>
        <div
          v-for="(stretchObjective, index) in board.stretchObjectives"
          :key="stretchObjective.id"
          class="objective"
          tabindex="0"
          @click="toggleObjective(stretchObjective.id)"
          @blur="blurObjective"
        >
          <team-objective-row
            :index="index + 1"
            :objective="stretchObjective"
            :selected="isSelected(stretchObjective.id)"
          />
        </div>
        <div />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import { cancelLongClick } from "@/Gestures";
import { toggleActions } from "@/action/toggleActions";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import TeamObjectivesModal from "@/components/TeamObjectivesModal.vue";
import TeamObjectiveRow from "@/components/objectives/TeamObjectiveRow.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import ScrollSupport from "@/mixins/ScrollSupport";
import ShortcutUser from "@/mixins/ShortcutUser";
import { BoardCard, IdMap, Objective } from "@/model";
import { useBoardStore } from "@/store/board";
import { showCardLinkedObjectives, useLinkStore } from "@/store/link";
import { useModalStore } from "@/store/modal";

@Options({
  components: { SvgIcon, IconButton, TeamObjectiveRow },
})
export default class TeamObjectives extends mixins(
  ScrollSupport,
  ShortcutUser,
) {
  selectedObjectiveId = "";
  highlightedObjectives: string[] = [];

  created() {
    this.globalActionShortcut(toggleActions.showTeamObjectives);
  }

  mounted() {
    this.adjustScrollbarWidth();
  }

  get cards(): IdMap<BoardCard> {
    return this.board.cards;
  }

  @Watch("cards", { deep: true })
  cardsChanged() {
    this.highlightedObjectives = [];
    Object.values(this.cards).forEach((boardCard) => {
      if (showCardLinkedObjectives(boardCard)) {
        boardCard.data.objectives.forEach((objective) =>
          this.highlightedObjectives.push(objective.id),
        );
      }
    });
  }

  get board() {
    return useBoardStore().boardByType("team");
  }

  onScroll() {
    cancelLongClick();
  }

  isSelected(objectiveId: Objective["id"]) {
    if (this.selectedObjectiveId === objectiveId) {
      return true;
    }
    return this.highlightedObjectives.includes(objectiveId);
  }

  editObjectives() {
    useModalStore().open(TeamObjectivesModal, {
      attrs: { boardId: this.board.id },
    });
  }

  toggleObjective(objectiveId: Objective["id"]) {
    if (this.selectedObjectiveId === objectiveId) {
      this.removeObjective();
    } else {
      this.selectedObjectiveId = objectiveId;
      const linkStore = useLinkStore();
      linkStore.markObjectiveLinkedCards(objectiveId);
    }
  }

  blurObjective(event: FocusEvent) {
    if ((event.relatedTarget as Element)?.classList.contains("objective")) {
      return;
    }
    this.removeObjective();
  }

  removeObjective() {
    if (this.selectedObjectiveId !== "") {
      this.selectedObjectiveId = "";
      const linkStore = useLinkStore();
      linkStore.removeAllMarks();
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/board";

.team-objectives {
  .header-title {
    font-size: 60% !important;
    display: inline-flex;
    align-items: center;
    gap: board.len(15px);
  }
}
</style>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.board-team {
  .team-objectives {
    padding: 0;
    display: flex;
    flex-direction: column;

    :focus {
      outline: none;
    }

    .objective-group-header {
      padding: board.len(12px) board.len(24px) 0 board.len(24px);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        color: colors-old.$text-primary-color;
        font-size: board.len(14px);
        font-weight: font.$weight-extra-bold;
      }

      .link {
        margin-right: board.len(38px);
      }
    }

    .header {
      width: 100%;
      color: colors-old.$text-primary-color;
      background-color: colors-old.$back-color;
      padding-left: board.len(24px);
      padding-right: board.len(12px);
      border-bottom: board.len(3px) solid colors-old.$divider-color;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .base-button {
        font-size: inherit;
        padding: board.len(16px);

        svg {
          width: board.len(32px);
          height: board.len(32px);
        }
      }
    }

    .scrollable {
      position: relative;
      padding-bottom: board.len(12px);
    }

    .objective {
      cursor: pointer;
    }
  }
}
</style>
