import { defineStore } from "pinia";

import { adminUrl } from "@/Environment";
import { getAlmConnectionId, getAlmType } from "@/backend/Backend";
import { DataError } from "@/backend/DataError";
import { BoardType } from "@/baseTypes";
import {
  Art,
  Id,
  SelectList,
  Session,
  SessionAlmStatus,
  Team,
  emptySelectList,
} from "@/model";
import { goToLogout } from "@/router/navigation";
import { addBreadcrumb, captureException } from "@/sentry";
import { useArtStore } from "@/store/art";
import { useTeamStore } from "@/store/team";

const boardTypeSetupPath: { [type in BoardType]?: string } = {
  backlog: "programBacklogBoard",
  program: "programBoard",
  solution: "solutionPlanningBoard",
  solution_backlog: "solutionBacklogBoard",
  risk: "riskBoard",
  team: "teamBoards",
  flex: "flexBoards",
};

export const useSessionStore = defineStore("session", {
  state: () => {
    return {
      isSessionLoading: false,
      session: emptySelectList<Session>(),
      userSync: "unsyncedSession" satisfies
        | "unsyncedSession"
        | "unsynced"
        | "synced",
    };
  },
  getters: {
    sessionAdminUrl:
      (state) =>
      (boardType?: BoardType): string => {
        const sessionId = state.session.selected?.id;
        const path = boardTypeSetupPath[boardType!] || "generalInformation";
        return sessionId
          ? `${adminUrl}/edit-session?id=${sessionId}&path=${path}`
          : `${adminUrl}/safepiplannings`;
      },
    profileAdminUrl:
      (state) =>
      (path?: string): string => {
        const sessionId = state.session.selected?.id;
        path = encodeURIComponent(path || "");
        return sessionId && path
          ? `${adminUrl}/profile?mapping=${sessionId}&path=${path}`
          : `${adminUrl}/profile`;
      },
    connectionAdminUrl: (_) => {
      const almConnectionId = getAlmConnectionId() || "";
      return `${adminUrl}/alm-connections/${getAlmType()}/${almConnectionId}`;
    },
    uniqueSessionName: (state) => (session?: Session) => {
      if (!session) {
        session = state.session.current;
      }
      let index = 1;
      for (const ss of state.session.list) {
        if (ss.id === session.id) {
          break;
        }
        if (ss.name === session.name) {
          index++;
        }
      }
      return (index > 1 ? `_${index}_` : "") + session.name;
    },
    findByNameOrId: (state) => (query: string) =>
      state.session.list.find(
        (session) => session.name === query || session.id === query,
      ),
    findByIndexedName: (state) => (sessionName: string) => {
      const indexed = /_(\d+)_(.+)/.exec(sessionName || "");
      if (indexed) {
        return state.session.list.filter(
          (session) => session.name === indexed[2],
        )[+indexed[1] - 1];
      }
    },
    filterArchived: (state) => (archived: boolean) =>
      archived
        ? state.session.list
        : state.session.list.filter((session) => !session.archived),
    currentlyDifferentSession() {
      return (sessionName: string) =>
        !this.session.current ||
        (sessionName !== this.uniqueSessionName() &&
          sessionName !== this.session.current.id);
    },
  },
  actions: {
    setSessions(sessions: Session[]) {
      const select: SelectList<Session> = {
        list: sessions,
        current: emptySelectList<Session>().current,
        selected: null,
      };
      // if we are in a session, try to find it in the list
      // set current/selected if session is found or logout if not
      if (this.session.current.id) {
        const currentSession = sessions.find(
          (session) => session.id === this.session.current.id,
        );
        if (!currentSession) {
          goToLogout();
        } else {
          select.current = select.selected = currentSession;
        }
      }
      this.session = select;
    },
    selectSession(e: Session) {
      this.session.current = emptySelectList<Session>().current;
      this.session.selected = e;
      useArtStore().art.current = emptySelectList<Art>().current;
      useArtStore().art.selected = null;
      useTeamStore().team.current = emptySelectList<Team>().current;
      useTeamStore().team.selected = null;
    },
    setSessionAlmStatus(status: SessionAlmStatus) {
      this.session.current.almStatus = status;
    },
    setSession({ id: sessionId }: Id, load: Promise<void>) {
      addBreadcrumb("store", {
        message: `Setting current and selected session to ${sessionId}`,
        data: { sessionId },
      });
      this.session.selected =
        this.session.list.find((session) => session.id === sessionId) || null;
      if (!this.session.selected) {
        const sessionIds = this.session.list.map(({ id }) => id);
        captureException(
          new DataError(
            "Could not find the Session ID in the list of active sessions",
            { sessionId, availableSessions: sessionIds },
          ),
        );
      }
      this.session.current =
        this.session.selected || emptySelectList<Session>().current;
      this.isSessionLoading = true;
      load.finally(() => {
        this.isSessionLoading = false;
      });
    },
  },
});
