<template>
  <modal-legacy class="confirm-modal" :width="500" :height="height">
    <modal-header-legacy :title="question" />
    <p>{{ subtext }}</p>
    <div class="image" :class="[state.status]" />
    <p class="error">{{ state.error || state.detail }}</p>
    <div class="bottom">
      <button class="reset" @click="action">
        {{ actionLabel }}
      </button>
      <button class="reset" @click="cancel">
        {{ cancelLabel || $t("general.cancel") }}
      </button>
    </div>
  </modal-legacy>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import ModalHeaderLegacy from "@/components/modal/ModalHeaderLegacy.vue";
import ModalLegacy from "@/components/modal/ModalLegacy.vue";
import { useModalStore } from "@/store/modal";

interface ConfirmState {
  status: string | "success" | "syncing";
  error?: string;
  detail?: string;
}

@Options({ components: { ModalHeaderLegacy, ModalLegacy } })
export default class ConfirmModal extends Vue {
  @Prop({ default: 380, type: Number }) readonly height!: number;
  @Prop({ required: true, type: String }) readonly question!: string;
  @Prop({ default: "" }) readonly subtext!: string;
  @Prop({ required: true, type: String }) readonly actionLabel!: string;
  @Prop({ required: false, type: String }) readonly cancelLabel!: string;
  @Prop({ required: true, type: Function })
  readonly action!: () => Promise<unknown>;
  @Prop({ default: () => useModalStore().hide(), type: Function })
  readonly cancel!: () => void;
  @Prop({ default: () => ({ status: "idle" }) }) readonly state!: ConfirmState;
}
</script>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/reset" as *;

.confirm-modal {
  .bottom {
    display: flex;

    & > button {
      width: 100%;
      border-bottom: 0.5em solid colors-old.$menu-color;
      border-top: 0.5em solid colors-old.$menu-color;
      color: inherit;
      display: inline-block;
      font-weight: inherit;
      height: auto;
      line-height: inherit;
      margin: 0;
      padding: 0 1em;
      position: relative;
      text-align: center;
      text-transform: uppercase;

      &:not(:last-of-type)::after {
        border-right: 1px solid colors-old.$back-color;
        content: "";
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .error {
    max-height: 60px;
    overflow: auto;
    color: colors-old.$error-color;
  }

  .image {
    width: 2em;
    height: 0;
    margin: 1em auto;

    &.success {
      height: 2em;
      background: url("@/assets/check.svg") center/contain no-repeat;
      filter: invert(1);
    }

    &.syncing {
      height: 2em;
      background: url("@/assets/sync.svg") center/contain no-repeat;
      animation: rotate 3s infinite;
      animation-timing-function: linear;
    }

    &.arrange {
      width: 8em;
      height: 8em;
      background: url("@/assets/arrange.gif") center/contain no-repeat;
    }
  }
}
</style>
