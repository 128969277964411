import { onUnmounted } from "vue";

type DelayedAction<T extends any[]> = (...args: T) => void;

export function useDelayedAction<T extends any[]>(
  action: DelayedAction<T>,
  delay: number,
): [DelayedAction<T>, () => void] {
  let timeoutId: number | null = null;

  const clear = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  // Cleanup on unmount
  onUnmounted(clear);

  // Start the delayed action
  const startAction: DelayedAction<T> = (...args: T) => {
    clear(); // Clear any existing timeouts
    timeoutId = window.setTimeout(() => action(...args), delay);
  };

  // Cancel the delayed action
  const cancelAction = () => {
    clear();
  };

  return [startAction, cancelAction];
}
