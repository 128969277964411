<template>
  <div class="points-select action" tabindex="-1" @blur="select()">
    <div v-if="show" ref="popup" class="items">
      <div
        v-for="point in points"
        :key="point"
        :class="{ current: point === current }"
        @click.stop="select(point)"
      >
        {{ point }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";

@Component({ emits: ["input"] })
export default class PointsSelect extends Vue {
  @Prop({ type: Boolean }) readonly show!: boolean;
  @Prop({ type: Array, default: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] })
  readonly points?: number[];
  @Prop({ type: Number }) readonly current?: number;

  @Watch("show")
  showChanged(value: boolean) {
    if (value) {
      (this.$el as HTMLElement).focus();
    }
  }

  select(i?: number) {
    this.$emit("input", i);
  }
}
</script>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "sass:color";
@use "@/styles/z-index";

.points-select {
  z-index: z-index.$low + 1;
  position: absolute;
  text-align: right;
  display: inline-block;
  font-size: font.$size-small;

  &:focus {
    outline: none;
  }

  .items {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    background-color: colors-old.$modal-background-color;
    width: 102px;
    padding: 2px;
    border-radius: 6px;
    border: 1px solid colors-old.$border-menu-color;

    div {
      flex-grow: 1;
      background-color: colors-old.$light-background-color;
      border-radius: 4px;
      margin: 2px;
      height: 1.75rem;
      line-height: 1.75rem;
      width: 1.75rem;
      max-width: 1.75rem;
      vertical-align: middle;

      &:focus,
      &:focus-within,
      &:hover {
        background-color: color.scale(colors-old.$back-color, $lightness: -15%);
      }

      &.current {
        color: colors-old.$primary-color;
        background-color: rgba(
          colors-old.$primary-color,
          colors-old.$background-color-transparency
        );
      }
    }
  }
}
</style>
