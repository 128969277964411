import { clamp } from "lodash-es";
import { defineStore } from "pinia";

import { zoomFactor } from "@/Settings";
import { removeBoardSubscriptions } from "@/backend/Backend";
import { BoardType, Functionality } from "@/baseTypes";
import { BoardLocation, fullBoardLocation } from "@/components/BoardLocation";
import { PlanningBoardLocation } from "@/components/PlanningBoardLocation";
import { RiskBoardLocation } from "@/components/RiskBoardLocation";
import { TeamBoardLocation } from "@/components/TeamBoardLocation";
import { receiveCardMove } from "@/components/card/animator";
import { i18n } from "@/i18n";
import { RelativeCoordinate, times } from "@/math/coordinates";
import { sendCardAction } from "@/mixins/EventBusUser";
import {
  Art,
  Board,
  BoardCard,
  BoardData,
  BoardId,
  Card,
  FlexType,
  Group,
  Id,
  IterationStatus,
  MarkMode,
  MirrorableBoard,
  Shape,
  StickyType,
  Team,
  isSolutionBoard,
  mirrorableBoards,
} from "@/model";
import { captureException, captureMessage } from "@/sentry";
import {
  startUserHeartbeat,
  stopUserHeartbeat,
} from "@/services/heartbeat.service";
import { useArtStore } from "@/store/art";
import { CardEvent } from "@/store/card";
import { useFlexStore } from "@/store/flex";
import { usePointerStore } from "@/store/pointer";
import { useStickyTypeStore } from "@/store/stickyType";
import { useTeamStore } from "@/store/team";
import { useUserStore } from "@/store/user";
import { useUsersOnBoardStore } from "@/store/usersOnBoard";
import { generateId } from "@/utils/general";

class BoardNotFoundError extends Error {}

type BoardMap = Record<Board["id"], Board>;

export const useBoardStore = defineStore("board", {
  state: () => {
    return {
      boards: {} as BoardMap,
      board: undefined as Board | undefined,
      activeCardId: null as string | null,
      magnifying: false,
    };
  },
  getters: {
    findIterations: (state) => {
      return (cardId: keyof Board["cards"]) => {
        for (const board of Object.values(state.boards)) {
          if (board.cards[cardId] && "iterations" in board) {
            return board.iterations;
          }
        }
      };
    },
    hasCurrentBoard: (state) => {
      return typeof state.board !== "undefined";
    },
    boardById: (state) => {
      return (boardId: Board["id"]): Board => {
        const board = state.boards[boardId];
        if (!board) {
          captureMessage("Could not find board", { info: { boardId } });
          return { id: boardId } as Board;
        }
        return board;
      };
    },
    boardByType: (state) => {
      return <T extends BoardType>(
        type: T,
        { teamId, artId }: { teamId?: string; artId?: string } = {},
      ): BoardData<T> => {
        const board = Object.values(state.boards).find((board) => {
          const isTypeMatch = board.type === type;
          const isTeamMatch = boardBelongsToTeam(
            board,
            teamId || useTeamStore().currentTeam.id,
          );

          const isArtMatch = boardBelongsToArt(
            board,
            artId || useArtStore().currentArt.id,
          );
          const isFlexMatch = boardIsCurrentFlex(
            board,
            useFlexStore().currentFlexBoard,
          );
          return isTypeMatch && isTeamMatch && isArtMatch && isFlexMatch;
        });

        if (board) {
          return board as BoardData<T>;
        }

        captureException(new Error(`board type '${type}' not found`), {
          data: {
            info: {
              type,
              teamId,
              artId,
              boards: Object.values(state.boards).map(({ id, type }) => ({
                id,
                type,
              })),
              team: useTeamStore().team.current,
              art: useArtStore().art.current,
            },
          },
        });

        // NOTE: Loading of boards may take a few seconds, especially if the board is large.
        // It's okay to return an empty object here, as it will be reactively updated once the missing board is loaded.
        // TODO not sure this is true
        return {} as BoardData<T>;
      };
    },
    planningBoards() {
      return (artLevelOnly = false) => [
        this.boardByType("program"),
        ...(!artLevelOnly && this.hasSolutionBoard
          ? [this.boardByType("solution")]
          : []),
      ];
    },
    artBoardsToLoad() {
      return (force = false) =>
        [...this.currentArtBoards, ...this.solutionBoards]
          .filter((board) => {
            const wasLoaded = board.loaded && !force;
            return board !== this.currentBoard() && !wasLoaded;
          })
          .sort((boardA, boardB) => order(boardA) - order(boardB));

      function order(board: Board) {
        return board.type === "backlog" ? 0 : board.type === "team" ? 1 : 2;
      }
    },
    artTeamBoards: (state) => {
      const teamBoards = Object.values(state.boards).filter(
        (board): board is BoardData<"team"> => board.type === "team",
      );
      return () => {
        return teamBoards.filter((board) =>
          useArtStore().isCurrentArt(board.team.artId),
        );
      };
    },
    currentArtBoards: (state) =>
      Object.values(state.boards).filter(
        (board) => board.artId === useArtStore().currentArt.id,
      ),
    solutionBoards: (state) =>
      Object.values(state.boards).filter((board) =>
        isSolutionBoard(board.type),
      ),
    boardsInited: (state) =>
      Object.keys(state.boards).some((boardId) => boardId[0] !== "$"),
    boardsLoading: (state) =>
      Object.values(state.boards).some((board) => board.loaded === 0),
    currentBoard: (state) => (): Board => {
      if (state.board) {
        return state.board;
      }
      throw new BoardNotFoundError("No current board is set");
    },
    boardId() {
      return () => this.currentBoard().id;
    },
    isCurrentBoardFluid: (state) => {
      return state.board?.type !== "objectives";
    },
    boardLocationFromIndex() {
      return (index: string[]): BoardLocation =>
        this.boardLocation(parseInt(index[0], 10), parseInt(index[1], 10));
    },
    boardLocation() {
      return (
        pos: RelativeCoordinate | number,
        top?: number,
        board?: Board,
      ): BoardLocation => {
        board = board || this.currentBoard();
        switch (board.type) {
          case "program":
            return PlanningBoardLocation.ofTeams(
              this.boardGroups(board),
              pos,
              top,
            );
          case "solution":
            return PlanningBoardLocation.ofArts(
              this.boardGroups(board),
              pos,
              top,
            );
          case "risk":
            return RiskBoardLocation.of(pos);
          case "team":
            return TeamBoardLocation.of(board.iterations, pos);
          default:
            return fullBoardLocation;
        }
      };
    },
    allStickyTypes: (state) => {
      return state.board?.stickyTypes || [];
    },
    stickyTypesByFunctionality(_state) {
      return (functionality: Functionality) => {
        return Object.values(this.allStickyTypes).filter((stickyType) => {
          return stickyType.functionality === functionality;
        });
      };
    },
    originStickyTypes(): StickyType[] {
      const board = this.currentBoard();
      return board.stickyTypes
        .filter((type) => type.origin === board.type)
        .filter(
          (type) =>
            board.type !== "solution" || type.functionality !== "dependency",
        );
    },
    // fetch origin sticky types & sticky types, which can be mirrored to the current board
    creatableStickyTypes(): StickyType[] {
      const board = this.currentBoard();
      // we can’t select a team on the risk board, hence we should not allow creating team board stickies on the risk board
      if (board.type === "risk") {
        return board.stickyTypes.filter((type) => type.origin !== "team");
      }
      // we can't select a team on the solution-planning board, hence we should not allow creating team board stickies (dependencies)
      // also we should not allow creating art backlog stickies on the solution-planning board, because we do not automatically create
      // the origin on the art backlog board (not implemented in the backend)
      if (board.type === "solution") {
        return board.stickyTypes.filter(
          (type) => type.origin !== "team" && type.origin !== "backlog",
        );
      }
      return board.stickyTypes;
    },
    selectedStickyIds: (state) => {
      return Object.keys(state.board?.selected || {});
    },
    selectedStickies(): BoardCard[] {
      return this.selectedStickyIds.map((id) => this.currentBoard().cards[id]);
    },
    selectedOrActiveCards(): BoardCard[] {
      if (this.selectedStickyIds.length > 0) {
        return this.selectedStickies;
      }
      const active = this.activeCard;
      return active ? [active] : [];
    },
    isStickySelected: (state) => {
      return (card: Card) => card.id in (state.board?.selected || {});
    },
    areMultipleStickiesSelected() {
      return this.selectedStickyIds?.length > 1;
    },
    teamVisibleStickies() {
      return (teamId?: Team["id"]): Board["cards"] => {
        return {
          ...this.boardByType("team", { teamId }).cards,
          ...this.boardByType("backlog").cards,
          ...this.boardByType("risk").cards,
          ...this.boardByType("program").cards,
        };
      };
    },
    backlogStickies(): BoardCard[] {
      return Object.values({
        ...this.boardByType("backlog").cards,
        ...(this.hasSolutionBacklogBoard
          ? this.boardByType("solution_backlog").cards
          : {}),
      });
    },
    boardGroups() {
      return (board?: Board): Group[] => {
        const theBoard = board || this.board;
        switch (theBoard?.type) {
          case "program":
            return programBoardTeams(theBoard.artId || "");
          case "solution":
            return solutionBoardArts();
          default:
            return [];
        }
      };

      function programBoardTeams(artId: string): Team[] {
        const milestoneEvents = {
          id: "",
          name: i18n.global.t("programBoard.milestoneEvents"),
        };
        const otherArts = useArtStore().isMultiArt
          ? [{ id: "", name: i18n.global.t("programBoard.otherArts") }]
          : [];
        const emptyTeam = { id: "", name: "" };

        return [
          milestoneEvents,
          ...useTeamStore().teamsInArt(artId),
          ...otherArts,
          emptyTeam,
        ];
      }

      function solutionBoardArts(): Art[] {
        const milestoneEvents = {
          id: "",
          name: i18n.global.t("programBoard.milestoneEvents"),
        };
        const emptyArt = { id: "", name: "" };
        const arts = useArtStore().arts;
        return [milestoneEvents, ...arts, emptyArt];
      }
    },
    hasSolutionBacklogBoard: (state) =>
      Object.values(state.boards).some(
        (board) => board.type === "solution_backlog",
      ),
    hasSolutionBoard: (state) =>
      Object.values(state.boards).some((board) => board.type === "solution"),
    mirrorTargetBoards: (state) => {
      return (cards: Card[]) => {
        const values = Object.values(state.boards).filter(
          (board): board is MirrorableBoard => {
            return mirrorableBoards.includes(board.type as any);
          },
        );
        return values.filter((board) => {
          const isCurrentBoard = board.id === state.board?.id;
          const isArtMatching =
            !board.artId || board.artId === useTeamStore().currentTeam.artId;
          const isEveryCardMirrorable = cards.every((card) =>
            card.type.usable.some(
              (boardType) =>
                boardType === board.type &&
                (card.teamId || boardType !== "program") &&
                (boardType !== "solution" ||
                  displaySolutionBoardForMirroring(card)),
            ),
          );
          // if every card has its origin on this board type, it makes no sense to mirror the cards to this board
          const isSomeCardNotOrigin = cards.some(
            (card) => board.type !== card.type.origin,
          );
          const hasEveryCardMatchingTeam =
            board.type !== "team" ||
            cards.every(
              (card) => !card.teamId || board.team.id === card.teamId,
            );
          return (
            !isCurrentBoard &&
            isArtMatching &&
            isEveryCardMirrorable &&
            isSomeCardNotOrigin &&
            hasEveryCardMatchingTeam
          );
        });
      };
    },
    activeCard(): BoardCard | null {
      const activeCardId = this.activeCardId;
      return activeCardId ? this.currentBoard().cards[activeCardId] : null;
    },
  },
  actions: {
    addBoards(boards: BoardMap) {
      this.boards = {
        ...this.boards,
        ...boards,
      };
    },
    addBoard<T extends BoardType>(board: BoardData<T>): BoardData<T> {
      this.boards = { ...this.boards, [board.id]: board };
      return board;
    },
    addFlexBoard(id: string, name: string, flexType: FlexType) {
      return this.addBoard<"flex">({
        ...frontendBoard(),
        id,
        type: "flex",
        name,
        flexType,
        stickyTypes: useStickyTypeStore().boardStickyTypes(
          "flex",
          flexType.id,
          useStickyTypeStore().stickyTypes,
        ),
      });
    },
    updateObjectiveBoardId() {
      Object.values(this.boards).forEach((board) => {
        if (board.type === "objectives") {
          board.id = objectivesBoardId();
        }
      });
    },
    resetBoards() {
      this.boards = {
        objectives: objectivesBoard(),
      };
    },
    switchBoard(board?: Board) {
      const newBoard =
        board || (this.board && this.boardByType(this.board.type));
      const res = { board: this.board, cards: new Array<BoardCard>() };
      if (newBoard && newBoard.id !== this.board?.id) {
        if (this.hasCurrentBoard) {
          res.cards = this.leaveBoard().cards;
        }
        this.board = newBoard;
        startUserHeartbeat();
      }
      return res;
    },
    leaveBoard() {
      const board = this.currentBoard();
      this.activeCardId = null;
      const cards = [];
      for (const id in board.cards) {
        const card = board.cards[id];
        if (card.meta.editing) {
          cards.push(card);
          card.meta.editing = false;
        }
      }
      stopUserHeartbeat();
      usePointerStore().reset();
      useUsersOnBoardStore().usersOnBoard = [];
      removeBoardSubscriptions();
      return { board, cards };
    },
    selectCard(cardId: string) {
      this.currentBoard().selected[cardId] = true;
    },
    unselectCard(cardId: string) {
      delete this.currentBoard().selected[cardId];
    },
    initCard(card: CardEvent) {
      const board = this.currentBoard();
      switch (board.type) {
        case "backlog":
          card.priority = 0;
          break;
        case "program": {
          const loc = this.boardLocation(card.pos) as PlanningBoardLocation;
          card.iterationId = loc.iterationId;
          card.teamId = loc.groupId;
          break;
        }
        case "solution": {
          const loc = this.boardLocation(card.pos) as PlanningBoardLocation;
          card.iterationId = loc.iterationId;
          card.artId = loc.groupId;
          break;
        }
        case "team": {
          const loc = this.boardLocation(card.pos) as TeamBoardLocation;
          card.iterationId = loc.iterationId;
          card.teamId = board.team.id;
          break;
        }
      }
    },
    updateBoards<T extends BoardType>(
      type: T,
      boards: BoardData<T>[],
      update: (exist: BoardData<T>, board: BoardData<T>) => void,
    ) {
      const bs = this.boards;
      for (const id in bs) {
        if (
          bs[id].type === type &&
          !boards.find((board) => board.id === bs[id].id)
        ) {
          delete this.boards[id];
        }
      }
      boards.forEach((board) => {
        const found = this.boards[board.id] as BoardData<T>;
        if (found) {
          update(found, board);
        } else {
          this.boards[board.id] = board;
        }
      });
    },
    setActiveCardId(cardId: string | null) {
      this.activeCardId = cardId;
    },

    setActiveCard(e: Id & { active: boolean }) {
      // don't clear active card if a different one is active
      if (!e.active && this.activeCardId !== e.id) {
        return;
      }
      this.activeCardId = e.active ? e.id : null;
    },
    normalMode() {
      for (const id in this.currentBoard().cards) {
        sendCardAction(id, { action: "mode", mode: "normal" });
      }
    },
    setVelocity(e: { id: string; velocity: number; iteration: number }) {
      const board = this.boards[e.id] as BoardData<"team">;
      board.iterations[e.iteration] = {
        velocity: e.velocity,
        load: board.iterations[e.iteration].load,
        state: board.iterations[e.iteration].state,
      };
    },
    setCardSize(e: BoardId & { factor: number }) {
      if (
        !useUserStore().isAllowed("edit") ||
        !useUserStore().isNonTeamZoomAllowed(this.currentBoard())
      ) {
        return false;
      }
      if (!(e.boardId in this.boards)) {
        // Board is not present: it could be a flex board, which is not loaded
        // until it is visited for the first time. However, we could receive an
        // update from someone else on the board. Since the flex board content
        // will be loaded the first time we access it, it's safe to ignore the
        // event.
        return false;
      }
      this.boards[e.boardId].cardSize = {
        factor: clamp(e.factor, 1, 8),
        ...times(zoomFactor, e.factor),
      };
      return true;
    },
    setIterationStatus(e: {
      boardId?: string;
      id: number;
      status: IterationStatus;
      detail?: string;
    }) {
      const boardId = e.boardId || this.currentBoard().id;
      const board = this.boards[boardId] as BoardData<"team">;
      const iter = board.iterations[e.id];
      if (iter) {
        iter.state.status = e.status;
        iter.state.detail = e.detail || null;
        if (e.status === "success") {
          setTimeout(
            () =>
              this.setIterationStatus({ boardId, id: e.id, status: "synced" }),
            5000,
          );
        }
      }
    },
    cardToFront(e: Id & Partial<BoardId>) {
      const board = e.boardId ? this.boards[e.boardId] : this.board;
      if (!board) {
        // Board is not present: it could be a flex board, which is not loaded
        // until it is visited for the first time. However, we could receive an
        // update from someone else on the board. Since the flex board content
        // will be loaded the first time we access it, it's safe to ignore the
        // event.
        return false;
      }
      const boardCard = board.cards[e.id];
      if (boardCard) {
        // card might just have been deleted
        boardCard.meta.zIndex = ++board.maxZ;
        return true;
      }
    },
    setCardPos(e: Id & BoardId & RelativeCoordinate) {
      const boardCard = this.boards[e.boardId].cards[e.id];
      receiveCardMove(boardCard, e, (pos) => {
        boardCard.meta.pos.x = pos.x;
        boardCard.meta.pos.y = pos.y;
      });
    },
    addShape(boardId: string, shape: Shape) {
      const { shapes, index } = this.findShape(boardId, shape.id);
      if (index >= 0) {
        return shapes[index];
      }
      shapes.push(shape);
      return shape;
    },
    editShape(boardId: string, shape: Shape) {
      const { shapes, index } = this.findShape(boardId, shape.id);
      if (index >= 0) {
        shapes[index] = shape;
      }
    },
    removeShape(boardId: string, id: string) {
      const { shapes, index } = this.findShape(boardId, id);
      if (index >= 0) {
        shapes.splice(index, 1);
      }
    },
    findShape(boardId: string, id: string) {
      const shapes = this.boards[boardId]?.shapes;
      return { shapes, index: shapes.findIndex((s) => s.id === id) };
    },
    markCard(id: string, mark: MarkMode) {
      this.currentBoard().cards[id].meta.mark = mark;
    },
  },
});

function objectivesBoard(): BoardData<"objectives"> {
  return {
    id: objectivesBoardId(),
    type: "objectives",
    ...frontendBoard(),
  };
}

function frontendBoard() {
  return {
    stickyTypes: [],
    almSources: [],
    cards: {},
    maxZ: 0,
    loaded: 1,
    cardSize: { factor: 3, ...times(zoomFactor, 3) },
    selected: {},
    shapes: [],
  };
}

function objectivesBoardId() {
  return generateId("1", useArtStore().currentArt.id);
}

function boardBelongsToTeam(board: Board, teamId?: string | null) {
  return board.type !== "team" || !teamId || board.team.id === teamId;
}

function boardBelongsToArt(board: Board, artId?: string) {
  return (
    ["team", "flex", "objectives"].includes(board.type) ||
    !board.artId ||
    !artId ||
    useArtStore().arts.length <= 1 ||
    board.artId === artId
  );
}

function boardIsCurrentFlex(board: Board, flexBoard: BoardData<"flex"> | null) {
  return board.type !== "flex" || !flexBoard || board.id === flexBoard.id;
}

// We can always mirror to the solution board if card has artId, otherwise card functionality should
// be dependency and should depend on the team from another ART
function displaySolutionBoardForMirroring(card: Card): boolean {
  if (card.artId) {
    return true;
  }
  if (card.type.functionality === "dependency") {
    return card.precondTeam?.artId !== card.dependTeam?.artId;
  }
  return false;
}
