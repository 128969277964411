<script setup lang="ts">
import { computed } from "vue";

import { selectionActions } from "@/action/selectionActions";
import { toggleActions } from "@/action/toggleActions";
import BoardBase from "@/components/BoardBase";
import SelectionMenu from "@/components/SelectionMenu.vue";
import AddItem from "@/components/menu/AddItem.vue";
import CardZoom from "@/components/menu/CardZoom.vue";
import DrawActions from "@/components/menu/DrawActions.vue";
import MessengerItem from "@/components/menu/MessengerItem.vue";
import ActionButton from "@/components/ui/ActionButton/ActionButton.vue";
import { useBoardStore } from "@/store/board";
import { useDrawStore } from "@/store/draw";
import { useMessengerStore } from "@/store/messenger";
import { usePointerStore } from "@/store/pointer";
import { useSelectionStore } from "@/store/selection";

defineProps<{ board?: BoardBase }>();

const showSelection = computed(
  () => useBoardStore().areMultipleStickiesSelected,
);
const showDrawActions = computed(() => useDrawStore().active);
const showStickyActions = computed(() => {
  const board = useBoardStore().currentBoard();
  return board.stickyTypes.some((t) => t.origin === board.type);
});
const showMessenger = computed(() => useMessengerStore().show);
</script>

<template>
  <div class="board-actions" data-no-screenshot>
    <div class="position center">
      <div v-if="showSelection" class="menu-group">
        <SelectionMenu :board="board" />
      </div>
    </div>

    <div class="position right">
      <div v-if="showDrawActions" class="menu-group">
        <DrawActions />
      </div>
      <template v-else>
        <div v-if="showStickyActions" class="menu-group" style="padding: 0">
          <AddItem />
        </div>
        <div class="menu-group">
          <ActionButton
            v-if="showStickyActions"
            :action="selectionActions.select"
            :activated="useSelectionStore().selecting === 'click'"
          />
          <CardZoom v-if="showStickyActions" />
          <ActionButton
            :action="toggleActions.showPointer"
            :activated="usePointerStore().isPointerActive"
          />
        </div>
      </template>

      <div v-if="showMessenger" class="menu-group">
        <MessengerItem />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";
@use "@/styles/mixins" as *;

.board-actions {
  z-index: z-index.$menu;
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;

  .position {
    position: absolute;
    bottom: 0;
    display: flex;
    gap: 8px;

    &.center {
      left: 0;
      right: 0;
      margin: auto;
      width: fit-content;
    }

    &.right {
      right: 0;
    }
  }

  .menu-group {
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 2px;
    height: 44px;
    background-color: colors-old.$back-color;
    border-radius: variables.$common-border-radius;
    width: fit-content;

    @include default-shadow;
  }
}
</style>
