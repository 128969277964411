<template>
  <div :id="board.id" class="board board-risk">
    <load-component v-if="!board.loaded" is-board />
    <link-layer :board="board" :color="linkColor" />
    <div class="backdrop" @dblclick="overview(eventLocation($event))">
      <div class="field" style="top: 6%; left: 4%">
        <h3>{{ location(0).name }}</h3>
      </div>
      <div class="field right" style="top: 6%; right: 4%">
        <h3>{{ location(1).name }}</h3>
      </div>
      <div class="field" style="top: 56%; left: 4%">
        <h3>{{ location(2).name }}</h3>
      </div>
      <div class="field right" style="top: 56%; right: 4%">
        <h3>{{ location(3).name }}</h3>
      </div>
      <div class="field" style="top: 87%; left: 4%">
        <h3>{{ location(4).name }}</h3>
      </div>
    </div>
    <template v-if="isNewStickyNoteEnabled">
      <StickyNote
        v-for="card in board.cards"
        :key="card.data.id"
        :card="card.data"
        :board="board"
        :meta="card.meta"
        :board-size="boardSize"
      />
    </template>

    <template v-else>
      <card
        v-for="card in board.cards"
        :key="card.data.id"
        :draggable="!readOnly"
        :card="card.data"
        :meta="card.meta"
        :color="card.data.type.color"
        :link-color="linkColor"
        :height="board.cardSize.y * height"
        :width="board.cardSize.x * width"
        :board="board"
        :board-width="width"
        :board-height="height"
        :actions="actions"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";

import { ActionSource } from "@/action/actions";
import { toggleActions } from "@/action/toggleActions";
import { normalLinkColors } from "@/colors";
import StickyNote from "@/components-ng/StickyNote/StickyNote.vue";
import FluidBoard, { ContextInfo } from "@/components/FluidBoard";
import LinkLayer from "@/components/LinkLayer.vue";
import { RiskBoardLocation } from "@/components/RiskBoardLocation";
import { ActionType } from "@/components/card/actions";
import LoadComponent from "@/components/modal/components/LoadComponent.vue";
import { isFeatureEnabled } from "@/feature";
import { relativeClientCoord } from "@/math/coordinate-systems";
import { RelativeCoordinate } from "@/math/coordinates";
import { useBoardStore } from "@/store/board";

@Options({ components: { LinkLayer, LoadComponent, StickyNote } })
export default class RiskBoard extends mixins(FluidBoard) {
  linkColor = normalLinkColors.risk;
  actions: ActionType[] = [
    "delete",
    "close",
    "almSource",
    "mirror",
    "link",
    "dragLink",
    "showTeam",
  ];

  get board() {
    return useBoardStore().boardByType("risk");
  }

  location(c: RelativeCoordinate | number) {
    return RiskBoardLocation.of(c);
  }

  eventLocation(e: MouseEvent) {
    return this.location(relativeClientCoord(e));
  }

  get isNewStickyNoteEnabled() {
    return isFeatureEnabled(this.$route, "sticky-note");
  }

  contextActions(c?: RelativeCoordinate): ContextInfo {
    const actions: ContextInfo = {
      syncProgramBacklog: false,
      draw: true,
      selection: {
        stickyMove: true,
        link: true,
        mirror: false,
        team: false,
      },
    };
    if (c) {
      const loc = this.location(c);
      actions.region = {
        name: loc.name,
        arrange: true,
        overview: true,
        sync: false,
        zoom: true,
      };
    }
    return actions;
  }

  overview(loc: RiskBoardLocation, source: ActionSource = "mouse") {
    const attrs = {
      boardId: this.board.id,
      location: loc.index(),
    };
    toggleActions.showOverview(source, attrs);
  }
}
</script>
