import { defineStore } from "pinia";

import { WorkingMode, isSolutionBoard } from "@/model";
import { useBoardStore } from "@/store/board";

interface State {
  workingMode: WorkingMode;
}

export const useWorkModeStore = defineStore("workMode", {
  state: (): State => ({
    workingMode: "planning",
  }),
  actions: {
    toggleWorkingMode(): void {
      this.workingMode =
        this.workingMode === "planning" ? "execution" : "planning";
    },
  },
  getters: {
    isExecutionMode(): boolean {
      return this.workingMode === "execution";
    },
    isPlanningMode(): boolean {
      return this.workingMode === "planning";
    },
    showExecutionMode(): boolean {
      return !isSolutionBoard(useBoardStore().board?.type);
    },
  },
});
