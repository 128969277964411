<template>
  <div v-show="isActive" class="tab">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { inject } from "vue";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { TAB_STATE } from "@/components/keys";

@Options({ name: "Tab" })
export default class ModalTab extends Vue {
  @Prop({ required: true }) readonly title!: string;
  tabState = inject(TAB_STATE);

  mounted() {
    this.tabState?.titles.push(this.title);
  }

  get isActive() {
    return this.tabState?.titles[this.tabState?.activeIndex] === this.title;
  }
}
</script>
