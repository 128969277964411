import { Lines } from "@/lineBreaker";

export interface PaintFunctions {
  canvasFontSize: number;
  canvasFont: string;
  maxFontSize: number;
  lineHeight: number;
  widthInCanvas: number;
  heightInCanvas: number;

  setCanvasFont(size: number): void;

  setElementFontSize(size: number): void;

  stringWidth(s: string): number;

  charWidth(char1: string, char2?: string): number;

  // display text lines on the canvas (for debugging reasons only)
  displayLines(x: number, y: number, text: string, lines: Lines): void;
}

export interface PaintLayer {
  init(el: SVGElement | HTMLElement): PaintFunctions;
}

export let thePaintLayer: PaintLayer;

export function registerPaintLayer(paintLayer: PaintLayer) {
  thePaintLayer = paintLayer;
}
