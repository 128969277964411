<script lang="ts" setup>
import { onMounted, ref } from "vue";

import { getBoardIdsOfGroupedStickies } from "@/backend/Backend";
import SetWsjf from "@/components/card/components/SetWsjf/SetWsjf.vue";
import { cardKey } from "@/components/card/injectKeys";
import { injectStrict } from "@/utils/context";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";

const card = injectStrict(cardKey);

// TODO: this will need to be cached
const boardsStickyMirroredTo = ref<Array<string>>([]);
onMounted(async () => {
  boardsStickyMirroredTo.value = await getBoardIdsOfGroupedStickies(card.id);
});
</script>

<template>
  <AttributeChip :text="card.priority.toString()">
    <SetWsjf />
  </AttributeChip>
</template>
