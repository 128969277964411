<script lang="ts" setup></script>

<template>
  <div class="base-popup-title">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.base-popup-title {
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid colors-old.$light-background-color;
  font-weight: font.$weight-bold;
  font-size: 12px;
  line-height: 15px;
  color: colors-old.$text-secondary-color;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
