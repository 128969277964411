<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import FlashIcon from "@/components/FlashIcon.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import { InfoLevel } from "@/model";
import { useBoardStore } from "@/store/board";
import { useLinkStore } from "@/store/link";
import { useSearchMenuStore } from "@/store/searchMenu";

import RiskyLinksItemTooltip from "../tooltips/RiskyLinksItemTooltip.vue";

const vKeyboardNav = keyboardNavigation();

const store = useSearchMenuStore();

const { t } = useI18n();

const riskyLinks = computed(() => {
  const board = useBoardStore().currentBoard();
  const { critical, risky } = useLinkStore().riskyLinkCount(board);
  return [
    {
      state: "error",
      label: t("linkState.criticalLink.count", critical),
      id: "critical-links",
    },
    {
      state: "warn",
      label: t("linkState.riskyLink.count", risky),
      id: "risky-links",
    },
  ] as Array<{ state: InfoLevel; label: string; id: string }>;
});

const selection = computed({
  get: () =>
    riskyLinks.value.filter((item) => store.selectedLinkStates.has(item.state)),
  set: (values: Array<{ state: InfoLevel }>) => {
    store.selectedLinkStates = new Set(values.map((val) => val.state));
  },
});
</script>

<template>
  <DropdownMenu class="search-select" width="sm" stay-on-click>
    <template #trigger="{ isOpen }">
      <BaseTooltip
        position="bottom"
        class="flash-icons"
        :class="{ active: isOpen }"
      >
        <FlashIcon
          v-if="store.selectedRiskyLinks.length === 0"
          link-state="default"
        />
        <div
          v-else
          class="flash-icons-wrapper"
          :class="{
            'flash-icons-multiple': store.selectedRiskyLinks.length > 1,
          }"
        >
          <FlashIcon
            v-for="state in store.selectedRiskyLinks"
            :key="state"
            :link-state="state"
          />
        </div>
        <InfoMarker
          v-if="store.selectedRiskyLinks.length"
          :top="4"
          :right="4"
        />
        <template #content>
          <RiskyLinksItemTooltip :states="store.selectedRiskyLinks" />
        </template>
      </BaseTooltip>
    </template>
    <SelectableList
      v-model="selection"
      v-keyboard-nav
      :values="riskyLinks"
      :header="$t('linkState.riskyLinks')"
    >
      <template #default="{ value }">
        <span>
          <FlashIcon :link-state="value.state" />
          {{ value.label }}
        </span>
      </template>
    </SelectableList>
  </DropdownMenu>
</template>

<style lang="scss" scoped>
@use "@/styles/variables";
@use "@/styles/z-index";

.flash-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: var(--light-background-color);
  }

  &.active {
    background-color: var(--active-background-color);
  }
}

.flash-icons-wrapper {
  height: 40px;
  display: flex;
  align-items: center;

  .flash-icon {
    &:first-child {
      position: relative;
    }

    &:nth-child(2) {
      margin: 0 -0.7em;
    }
  }
}

.flash-icons-multiple {
  text-align: left;
  width: 30px;
}
</style>
