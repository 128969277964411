<template>
  <div :id="board.id" class="board board-flex">
    <load-component v-if="!board.loaded" is-board />
    <link-layer :board="board" :color="linkColor" />
    <FlexBackground :type="background" class="background" />
    <card
      v-for="card in board.cards"
      :key="card.data.id"
      :draggable="!readOnly"
      :card="card.data"
      :meta="card.meta"
      :color="card.data.type.color"
      :link-color="linkColor"
      :height="board.cardSize.y * height"
      :width="board.cardSize.x * width"
      :board="board"
      :board-width="width"
      :board-height="height"
      :actions="actions"
    />
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";

import { normalLinkColors } from "@/colors";
import FluidBoard, { ContextInfo } from "@/components/FluidBoard";
import LinkLayer from "@/components/LinkLayer.vue";
import FlexBackground from "@/components/background/FlexBackground.vue";
import { ActionType } from "@/components/card/actions";
import LoadComponent from "@/components/modal/components/LoadComponent.vue";
import { RelativeCoordinate } from "@/math/coordinates";
import { useBoardStore } from "@/store/board";

@Options({ components: { LinkLayer, FlexBackground, LoadComponent } })
export default class FlexBoard extends mixins(FluidBoard) {
  linkColor = normalLinkColors.flex;
  actions: ActionType[] = ["delete", "link", "dragLink", "close"];

  contextActions(_c?: RelativeCoordinate): ContextInfo {
    return {
      syncProgramBacklog: false,
      draw: true,
      selection: {
        stickyMove: true,
        link: false,
        mirror: false,
        team: false,
      },
    };
  }

  get board() {
    return useBoardStore().boardByType("flex");
  }

  get background() {
    return this.board.flexType?.background;
  }
}
</script>

<style lang="scss">
.board-flex {
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
