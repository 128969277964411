export class RingBuffer<T> {
  private data = new Array<T>();
  private start = 0;
  private len = 0;

  constructor(private size: number) {}

  add(value: T) {
    if (this.len < this.size) {
      this.len++;
    }
    this.data[this.start] = value;
    this.start = (this.start + 1) % this.size;
  }

  get() {
    const res = [
      ...this.data.slice(this.start),
      ...this.data.slice(0, this.start),
    ];
    res.splice(this.len);
    return res;
  }
}
