<template>
  <div class="overlay almSources scrollable no-wheel">
    <div v-for="s in sources" :key="s.id" @click.stop="emit(s.id)">
      <span class="entry hoverable" :class="{ selected: s.id === source }">{{
        s.name
      }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { AlmSourceId } from "@/baseTypes";
import { AlmSource } from "@/model";

@Options({ emits: ["select"] })
export default class CardAlmSourcesOverlay extends Vue {
  @Prop(Array) readonly sources!: AlmSource[];
  @Prop([Number, String]) readonly source!: AlmSourceId;

  emit(val: AlmSourceId) {
    this.$emit("select", val);
  }
}
</script>
