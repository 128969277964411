<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { AuthUser, BackendUser } from "@/model";
import Avatar from "@/pi-components/avatar/AvatarComponent.vue";
import colors from "@/styles/color.module.scss";

import SvgIcon from "./SvgIcon/SvgIcon.vue";

const props = defineProps<{ user?: AuthUser; userColor?: boolean }>();

const isImageUrlValid = ref(true);

const { t } = useI18n();
const imageUrl = computed(() => props.user?.imageUrl);
const name = computed(() => props.user?.name || t("boardUsers.unknown"));
const letter = computed(() => name.value.substring(0, 1).toUpperCase());
const color = computed(() =>
  !props.userColor || !props.user?.color ? colors.avatar : props.user.color,
);

// Name of the icon representing an ALM backend user (eg. 'jira')
const svgIconName = computed(() => (props.user as BackendUser)?.iconName);
</script>

<template>
  <div class="user-avatar">
    <avatar :text="letter" :color="color">
      <img
        v-if="isImageUrlValid && imageUrl"
        data-testid="avatar-image"
        :src="imageUrl"
        :alt="name"
        @error="isImageUrlValid = false"
      />
      <SvgIcon
        v-if="svgIconName"
        :name="svgIconName"
        size="20"
        class="alm-icon"
      />
    </avatar>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";

.user-avatar {
  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }

  &.small {
    .avatar {
      border: 0;

      span {
        font-size: 1rem;
        font-weight: font.$weight-normal;
        background-color: inherit;
      }

      span,
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }

  &.smaller {
    .avatar {
      border: 0;

      span {
        font-size: font.$size-smaller;
        font-weight: font.$weight-semi-bold;
        background-color: inherit;
      }

      span,
      img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
  }
}
</style>
