export interface ElementState {
  elements: Array<{
    parent: HTMLElement;
    element: HTMLElement;
  }>;
  activeElement: Element | null;
}

export function removeElements(
  elementList: NodeListOf<HTMLElement>,
): ElementState {
  const state: ElementState = {
    elements: [],
    activeElement: document.activeElement,
  };
  elementList.forEach((e) => {
    const parent = e.parentElement!;
    const element = parent.removeChild(e);
    // moving and deleting stickies are scheduled to be removed from the board, so don't save them
    if (!e.classList.contains("moving") && !e.classList.contains("deleting")) {
      state.elements.push({ parent, element });
    }
  });
  return state;
}

export function addElements(state: ElementState) {
  state.elements.forEach((e) => {
    e.parent.appendChild(e.element);
  });
  (state.activeElement as HTMLElement)?.focus?.();
}

export function isBrowserTabActive() {
  return document.visibilityState !== "hidden";
}

export function createTspan(text: string, x: number, y: number) {
  const tspan = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
  tspan.appendChild(document.createTextNode(text));
  tspan.setAttribute("x", "" + x);
  tspan.setAttribute("y", "" + y);
  return tspan;
}

export async function fontsLoaded() {
  const fonts = document.fonts;
  return fonts ? fonts.ready : Promise.resolve();
}

export function terminateEvent(event: Event) {
  event.stopPropagation();
  event.preventDefault();
}
