<template>
  <div
    v-if="cardZoomable || canNonTeamZoom"
    class="card-zoom"
    :class="{ current: cardZooming, disabled: !cardZoomable }"
    tabindex="0"
    @blur="close"
    @click="toggleCardZooming"
  >
    <IconButton
      icon="card-zoom"
      :activated="cardZooming"
      :disabled="!cardZoomable"
      :tooltip="cardZooming ? '' : $t('action.scaleStickies')"
      :shortcut="shortcuts()"
    />
    <div v-if="cardZooming" class="popout caret caret-bottom" @click.stop>
      <IconButton
        icon="plus"
        size="small"
        @pointerdown="cardZoom(actions.biggerCardSize)"
        @pointerout="cardZoom()"
        @pointerup="cardZoom()"
      />
      <IconButton
        icon="minus"
        size="small"
        @pointerdown="cardZoom(actions.smallerCardSize)"
        @pointerout="cardZoom()"
        @pointerup="cardZoom()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import { Action } from "@/action/actions";
import { boardActions } from "@/action/boardActions";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import ShortcutUser from "@/mixins/ShortcutUser";
import { isBacklogBoard, isFrontendBoard } from "@/model";
import { useBoardStore } from "@/store/board";
import { useToastStore } from "@/store/toast";
import { useUserStore } from "@/store/user";

@Options({ components: { IconButton } })
export default class CardZoom extends mixins(ShortcutUser) {
  actions = boardActions;
  cardZoomTimeout: number | undefined;
  cardZooming = false;
  lastCardZoom = 0;

  @Watch("$route", { immediate: true })
  routeChanged() {
    this.cardZooming = false;
  }

  created() {
    this.globalActionShortcut(boardActions.smallerCardSize, { repeat: true });
    this.globalActionShortcut(boardActions.biggerCardSize, { repeat: true });
  }

  cardZoom(action?: Action, rep?: boolean) {
    if (action) {
      const delay = rep
        ? Math.max(0, 75 + this.lastCardZoom - performance.now())
        : 200;
      this.lastCardZoom = performance.now();
      action("mainMenu");
      this.cardZoomTimeout = window.setTimeout(
        () => this.cardZoom(action, true),
        delay,
      );
    } else {
      clearTimeout(this.cardZoomTimeout);
    }
  }

  get canNonTeamZoom() {
    return useUserStore().isAllowed("nonTeamZoom");
  }

  get cardZoomable() {
    const board = useBoardStore().board;
    return (
      board &&
      !isBacklogBoard(board.type) &&
      !isFrontendBoard(board.type) &&
      useUserStore().isAllowed("edit") &&
      useUserStore().isNonTeamZoomAllowed(board)
    );
  }

  toggleCardZooming() {
    if (this.cardZoomable) {
      this.cardZooming = !this.cardZooming;
    } else if (
      !useUserStore().isNonTeamZoomAllowed(useBoardStore().currentBoard())
    ) {
      useToastStore().show(/*$t*/ "message.onlyRTEAdminAllowed");
    }
  }

  shortcuts() {
    return (
      boardActions.smallerCardSize.data.shortcut!.name() +
      " " +
      boardActions.biggerCardSize.data.shortcut!.name()
    );
  }

  close() {
    this.cardZooming = false;
  }
}
</script>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins" as *;

.card-zoom {
  position: relative;
  display: flex;

  &.current .popout {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    width: 72px !important;
    height: 40px;
    background-color: colors-old.$back-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: variables.$common-border-radius;

    @include default-shadow;

    &::after {
      border-bottom-color: colors-old.$back-color;
    }
  }
}
</style>
