<script setup lang="ts">
import { boardIcon, boardTitle } from "@/baseTypeI18n";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { FieldChange } from "@/model";
import { useBoardStore } from "@/store/board";

defineProps<{ field: FieldChange }>();

function boardName(id: string) {
  return boardTitle(useBoardStore().boardById(id));
}

function iconName(id: string) {
  return boardIcon(useBoardStore().boardById(id));
}
</script>

<template>
  <div class="change horizontal">
    <span v-if="field.old" class="change-value">
      <SvgIcon :name="iconName(field.old)" size="20" />
      {{ boardName(field.old) }}
    </span>
    <SvgIcon v-if="field.old && field.new" name="arrow/right-thin" size="20" />
    <span v-if="field.new" class="change-value">
      <SvgIcon :name="iconName(field.new)" size="20" />
      {{ boardName(field.new) }}
    </span>
  </div>
</template>
