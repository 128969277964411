import { defineStore } from "pinia";

import { Size } from "@/model";
import { useBoardStore } from "@/store/board";

export const useAppSizeStore = defineStore("appSize", {
  state: () => ({
    appSize: {
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      zoom: 1,
      padding: {
        top: 0,
        left: 68,
        bottom: 60,
        right: 68,
      },
      margin: {
        top: 48,
        left: 0,
        bottom: 0,
        right: 0,
      },
    },
  }),
  getters: {},
  actions: {
    setAppSize(e: Size) {
      this.appSize = {
        top: e.top,
        left: e.left,
        width: e.width,
        height: e.height,
        zoom: this.appSize.zoom,
        padding: { ...this.appSize.padding },
        margin: { ...this.appSize.margin },
      };
    },
    setAppZoom(zoom: number) {
      this.appSize.zoom = Math.round(100 * zoom) / 100;
    },
    updateAppZoom(zoom: number) {
      if (zoom !== this.appSize.zoom) {
        this.setAppZoom(zoom);
        return true;
      }
    },
    setLeft(space: number, margin: number) {
      if (useBoardStore().isCurrentBoardFluid) {
        this.appSize.padding.left = space;
        this.appSize.margin.left = margin;
      }
    },
    setRightMargin(margin: number) {
      this.appSize.margin.right = margin;
    },
  },
});
