<template>
  <base-backlog-board ref="board" :backlog-board="board" :actions="actions" />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import BaseBacklogBoard from "@/components/BaseBacklogBoard.vue";
import { ActionType } from "@/components/card/actions";
import { useBoardStore } from "@/store/board";

@Options({ components: { BaseBacklogBoard } })
export default class SolutionBacklogBoard extends Vue {
  actions: ActionType[] = [
    "delete",
    "close",
    "almSource",
    "mirror",
    "priority",
    "art",
    "showArt",
    "link",
  ];

  get board() {
    return useBoardStore().boardByType("solution_backlog");
  }

  boardComponent() {
    return this.$refs.board;
  }
}
</script>
