import { userHeartbeatInterval } from "@/Settings";
import { sender } from "@/backend/Sender";
import { useBoardStore } from "@/store/board";
import { useUserStore } from "@/store/user";
import { useUsersOnBoardStore } from "@/store/usersOnBoard";

const heartbeatWorker = new Worker("/workers/heartbeat.js");

heartbeatWorker.addEventListener("message", () => {
  _onHeartbeatTick();
});

export function startUserHeartbeat() {
  heartbeatWorker.postMessage({
    action: "start",
    interval: userHeartbeatInterval,
  });
}

export function stopUserHeartbeat() {
  heartbeatWorker.postMessage({ action: "stop" });
}

function _onHeartbeatTick() {
  sender.addUserOnBoard(
    useBoardStore().boardId(),
    useUserStore().technicalUser.id,
  );
  useUsersOnBoardStore().expireUsersOnBoard();
}
