<template>
  <div class="overlay statuses scrollable no-wheel">
    <div
      v-for="(status, index) in selectList"
      :key="status.id"
      class="container"
      @pointermove="selectIndex = index"
      @click.stop="status.selected ? undefined : select(status)"
    >
      <div class="status-dot-container">
        <status-dot :status-class="status.statusClass" />
      </div>
      <span class="entry" :class="{ current: index === selectIndex }">
        <div class="name" :class="{ selected: status.selected }">
          {{ status.name }}
        </div>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import StatusDot from "@/components/StatusDot.vue";
import SelectList from "@/mixins/SelectList";
import { AlmItemStatus, Status } from "@/model";

@Options({ components: { StatusDot }, emits: ["select"] })
export default class CardStatusOverlay extends mixins(SelectList) {
  @Prop(Object) readonly status!: AlmItemStatus;

  @Watch("statuses", { immediate: true })
  statusesChanged() {
    this.selectList = this.statuses;
  }

  select(status: Status | undefined) {
    this.$emit(
      "select",
      this.status.next.find(
        (targetStatus) => targetStatus.status.id === status?.id,
      ),
    );
  }

  get statuses() {
    const statuses = [
      { ...this.status, selected: true },
      ...this.status.next.map((next) => next.status),
    ];
    return statuses.sort((a, b) => a.order - b.order);
  }
}
</script>

<style lang="scss">
@use "@/styles/font";

.overlay.statuses {
  .container {
    display: flex;
    align-items: center;

    .status-dot-container {
      width: 1.6em;
      height: 1.6em;
      margin: 0 1em;
    }

    .entry {
      padding: 0;
      flex: 1;
      margin: 0;
    }

    .name {
      vertical-align: middle;
      margin-left: 0.75em;

      &.selected {
        font-weight: font.$weight-bold;
        cursor: initial;
      }
    }
  }
}
</style>
