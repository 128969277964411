<template>
  <DropdownMenu :offset="{ top: 15 }" class="settings-font-dropdown">
    <template #trigger>
      <DropdownTrigger
        :value="modelValue"
        :style="{ fontFamily: modelValue }"
      />
    </template>
    <ListItem
      v-for="font in fonts"
      :key="font"
      :style="{ fontFamily: font }"
      @click="select(font)"
    >
      {{ font }}
    </ListItem>
  </DropdownMenu>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import DropdownTrigger from "@/components/DropdownMenu/DropdownTrigger.vue";
import ListItem from "@/components/ListItem/ListItem.vue";

@Options({
  components: { DropdownTrigger, ListItem, DropdownMenu },
  emits: ["update:modelValue"],
})
export default class SettingsFontDropdown extends Vue {
  @Prop(String) readonly modelValue!: string;

  fonts = ["Mulish", "Caveat", "Natural", "Child", "Lefthand", "Bedtime"];

  select(font: string) {
    this.$emit("update:modelValue", font);
  }
}
</script>

<style lang="scss" scoped>
.settings-font-dropdown {
  .dropdown-trigger > :deep(span) {
    width: 100px;
    font-family: inherit;
  }
}
</style>
