<script lang="ts">
import { Options, Vue } from "vue-class-component";

import { i18n } from "@/i18n";
import { useBoardStore } from "@/store/board";

import MetricsModalLoadCapacityBody, {
  LoadCapacityItem,
  toLoadCapacityItem,
} from "./MetricsModalLoadCapacityBody.vue";

@Options({ mixins: [MetricsModalLoadCapacityBody] })
export default class OverallMetricsModalLoadCapacityBody extends Vue {
  sumTitle = i18n.global.t("metricsModal.allTeams");

  get items(): LoadCapacityItem[] {
    return useBoardStore()
      .artTeamBoards()
      .map(({ team, iterations }) => {
        const teamIterations = toLoadCapacityItem(iterations).reduce(
          (acc, curr) => {
            return {
              load: acc.load + curr.load,
              capacity: acc.capacity + curr.capacity,
            };
          },
          { load: 0, capacity: 0 },
        );
        return { id: team.id, title: team.name, ...teamIterations };
      });
  }
}
</script>
