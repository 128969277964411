<script lang="ts" setup>
import { cardKey } from "@/components/card/injectKeys";
import { injectStrict } from "@/utils/context";

import AttributeChip from "../AttributeChip/AttributeChip.vue";
import StickyNoteRow from "../StickyNoteRow/StickyNoteRow.vue";
import FlagChip from "./components/FlagChip/FlagChip.vue";

const card = injectStrict(cardKey);
</script>

<template>
  <div class="sticky-note-header">
    <StickyNoteRow>
      <div class="type">{{ card.type.name }}</div>
      <AttributeChip
        v-if="card.almId"
        as="a"
        icon="jira"
        target="_blank"
        :href="card.almIssueUrl"
        :text="card.almId"
      />
      <FlagChip data-ignore-click />
    </StickyNoteRow>
  </div>
</template>

<style lang="scss" scoped>
@use "../../utils";

.sticky-note-header {
  padding: utils.px-to-em(8px) utils.px-to-em(8px) 0;

  .type {
    margin-right: auto;
  }
}
</style>
