import { defineStore } from "pinia";

export const useGlobalErrorStore = defineStore("globalError", {
  state: () => ({
    globalError: null as { error: Error; timestamp: number } | null,
  }),
  actions: {
    setGlobalError(error: Error) {
      this.globalError = { error, timestamp: Date.now() };
    },
  },
});
