import { action, defineActions } from "@/action/actions";
import { sender } from "@/backend/Sender";
import { useCategoryStore } from "@/store/category";

export const categoryActions = defineActions("category", {
  add: action((name: string) => {
    sender.addCategory(name).then((id) => useCategoryStore().add(id, name));
  }),
  update: action((id: string, data: { name?: string; position?: number }) => {
    sender.updateCategory(id, data);
    useCategoryStore().update({ id, ...data });
  }),
  remove: action((id: string) => {
    sender.deleteCategory(id);
    useCategoryStore().remove({ id });
  }),
  addBoard: action((boardId: string, categoryId: string) => {
    if (categoryId) {
      sender.addBoardToCategory(boardId, categoryId);
      useCategoryStore().addBoard({ boardId, categoryId });
    }
  }),
  removeBoard: action((boardId: string, categoryId: string) => {
    if (categoryId) {
      sender.removeBoardFromCategory(boardId, categoryId);
      useCategoryStore().removeBoard({ boardId, categoryId });
    }
  }),
});
