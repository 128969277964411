import { defineStore } from "pinia";

import { setLanguage } from "@/i18n";
import {
  AuthUser,
  Board,
  Role,
  Tenant,
  asRole,
  isPlanningBoard,
} from "@/model";
import { loadUser } from "@/services/user.service";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      loginData: { email: "", tenants: [] as Tenant[] },
      technicalUser: emptyUser(),
      currentUser: null as AuthUser | null,
    };
  },
  getters: {
    preferredLanguage(): string | undefined {
      return this.currentUser?.preferredLanguage;
    },
    isAllowed: (state) => (permission: Permission) =>
      permissions[state.technicalUser.role].includes(permission),
    isNonTeamZoomAllowed() {
      return (board: Board): boolean =>
        (!isPlanningBoard(board.type) && board.type !== "risk") ||
        this.isAllowed("nonTeamZoom");
    },
  },
  actions: {
    async loadCurrentUser() {
      this.currentUser = await loadUser(
        { id: this.technicalUser.id },
        { useCache: false },
      );
      if (this.preferredLanguage) {
        await setLanguage(this.preferredLanguage);
      }
      return this.currentUser;
    },
    resetUser() {
      this.technicalUser = emptyUser();
    },
  },
});

function emptyUser() {
  return {
    id: "",
    company: "",
    role: asRole(""),
  };
}

type Permission =
  | "edit"
  | "nonTeamZoom"
  | "deleteBoard"
  | "setup"
  | "useWithoutLicense"
  | "editBoard";

const permissions: { [role in Role]: Permission[] } = {
  observer: [],
  user: ["edit"],
  admin: [
    "edit",
    "nonTeamZoom",
    "deleteBoard",
    "setup",
    "useWithoutLicense",
    "editBoard",
  ],
  planning_interval_admin: [
    "edit",
    "nonTeamZoom",
    "deleteBoard",
    "setup",
    "editBoard",
  ],
};
