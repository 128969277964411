import { thePaintLayer } from "@/PaintLayer";
import { Lines, breakLines } from "@/lineBreaker";

export interface TextMeasure {
  height: number;
  lines: Lines;
}

export default class TextMeasurer {
  paintFunctions;

  constructor(elem: SVGElement | HTMLElement) {
    this.paintFunctions = thePaintLayer.init(elem);
  }

  setFontSize(size: number) {
    this.paintFunctions.setElementFontSize(size);
  }

  maxFontSize() {
    return this.paintFunctions.maxFontSize;
  }

  maxFontSizeForText(): (s: string) => number {
    this.paintFunctions.setCanvasFont(this.maxFontSize());
    return (s) =>
      this.paintFunctions.widthInCanvas / this.paintFunctions.stringWidth(s);
  }

  measureLines(size: number, text: string): TextMeasure {
    this.paintFunctions.setCanvasFont(size);
    const lines = breakLines(
      text,
      this.paintFunctions.widthInCanvas,
      (chars, from, to) => {
        let width = 0;
        // widths of single characters
        for (let i = from; i < to; i++) {
          width += this.paintFunctions.charWidth(chars[i]);
        }
        // plus width diff of character pairs (e.g. 'AV' takes less space than a 'A' and a 'V')
        for (let i = from; i < to - 1; i++) {
          width += this.paintFunctions.charWidth(chars[i], chars[i + 1]);
        }
        return width;
      },
    );
    return {
      height: lines.length * this.paintFunctions.lineHeight,
      lines,
    };
  }

  displayLines(x: number, y: number, size: number, text: string) {
    this.paintFunctions.displayLines(
      x,
      y,
      text,
      this.measureLines(size, text).lines,
    );
  }
}
