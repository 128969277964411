<template>
  <div>
    <h1>{{ title }}</h1>
    <h2 v-if="$slots.default">
      <slot></slot>
    </h2>
    <div v-if="$slots.search" class="header-search">
      <slot name="search"></slot>
    </div>
    <div v-if="$slots.action" class="header-action">
      <slot name="action"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

/** @deprecated Will be removed in the future.
 * Only kept for backwards compatibility with legacy modals.
 */
export default class ModalHeaderLegacy extends Vue {
  @Prop(String) readonly title!: string;
}
</script>

<style lang="scss">
.header-search {
  position: absolute;
  top: 20px;
}

.header-action {
  position: absolute;
  top: 1em;
  right: 1em;
}
</style>
