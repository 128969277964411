<script setup lang="ts">
import { getAlmType } from "@/backend/Backend";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import TypeDot from "@/components/TypeDot.vue";
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import { StickyType } from "@/model";
import { useStickyTypeStore } from "@/store/stickyType";

export type StickyTypeItem = Pick<
  StickyType,
  "id" | "name" | "color" | "almType"
>;

const props = defineProps<{
  header?: string;
  modelValue?: StickyTypeItem | StickyTypeItem[];
  stickyTypes: StickyTypeItem[];
  disabled?: boolean;
}>();

const emit = defineEmits<{
  "update:model-value": [value: StickyTypeItem | StickyTypeItem[]];
}>();

const vKeyboardNav = keyboardNavigation();
const almType = getAlmType()!;

function isAlm(type: StickyTypeItem) {
  return (
    almType &&
    "almType" in type &&
    useStickyTypeStore().almTypeOf(type)?.isMapped
  );
}
</script>

<template>
  <SelectableList
    v-keyboard-nav
    :header="props.header || $t('general.stickyTypes')"
    :model-value="props.modelValue"
    :values="props.stickyTypes"
    :disabled="disabled"
    @update:model-value="emit('update:model-value', $event)"
  >
    <template #default="{ value }">
      <span>
        <TypeDot size="medium" :color="value.color" />
        {{ value.name }}
        <SvgIcon v-if="isAlm(value)" :name="almType" size="15" />
      </span>
    </template>
  </SelectableList>
</template>
