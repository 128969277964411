<script setup lang="ts">
import { computed } from "vue";

import { boardTitle } from "@/baseTypeI18n";
import NavigableList from "@/components/NavigableList.vue";
import BaseModal from "@/components/modal/BaseModal.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { Board } from "@/model";
import { useModalStore } from "@/store/modal";
import { useSelectionStore } from "@/store/selection";

const boards = computed(() => useSelectionStore().mirrorTargets);

function name(board: Board) {
  return boardTitle(board, false);
}

function mirror(board: Board) {
  useSelectionStore().mirror(board);
  useModalStore().hide();
}
</script>

<template>
  <BaseModal class="mirror-modal">
    <template #header-title>{{ $t("mirrorModal.title") }}</template>
    <div class="scrollable-list">
      <NavigableList :first-list="boards" @select="mirror">
        <template #row="{ item }">
          <div class="item">
            <span>{{ name(item) }}</span>
            <IconButton icon="mirror" />
          </div>
        </template>
      </NavigableList>
    </div>
  </BaseModal>
</template>

<style lang="scss" scoped>
.mirror-modal {
  .item {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
