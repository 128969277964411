import type { PiVueRouter } from "./types";

let router: PiVueRouter;

export function setRouter(routerImpl: PiVueRouter) {
  router = routerImpl;
}

export function getRouter() {
  return router;
}
