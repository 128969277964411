<script setup lang="ts">
import { computed, ref } from "vue";

import HomeHeader from "@/components/HomeHeader.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import LoadComponent from "@/components/modal/components/LoadComponent.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { Art } from "@/model";
import { navigateBack, navigateForward } from "@/router/navigation";
import { useArtStore } from "@/store/art";
import { useSessionStore } from "@/store/session";

const loading = ref(false);

const selectedSessionName = computed(
  () => useSessionStore().session.selected?.name,
);
const artsList = computed(() => useArtStore().arts);

function back() {
  navigateBack();
}

function select(art: Art) {
  loading.value = true;
  useArtStore().setArt(art);
  navigateForward();
}

function isSelectedArt(art: Art) {
  return useArtStore().art.selected?.id === art.id;
}
</script>

<template>
  <div>
    <LoadComponent v-if="loading" />
    <div class="home-overview art-page">
      <HomeHeader>
        <template #back>
          <BaseButton
            variant="ghost"
            color="grey"
            icon-before="arrow/left"
            @click="back"
          >
            {{ $t("page.backToSessions") }}
          </BaseButton>
        </template>
        <template #title>
          <h1>{{ selectedSessionName }}</h1>
        </template>
        <template #subtitle>
          <h2>{{ $t("artPage.select") }}</h2>
        </template>
      </HomeHeader>

      <hr class="seperator" />
      <div class="scrollable scrollable-relative scrollable-content">
        <div
          v-for="art in artsList"
          :key="art.id"
          class="item"
          :class="{ selected: isSelectedArt(art) }"
          @click="select(art)"
        >
          <div class="name-wrapper">
            <h3 class="name">{{ art.name }}</h3>
          </div>
          <SvgIcon name="arrow/right" size="20" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/team-art-list" as *;
@use "@/styles/mixins" as *;

.art-page {
  @include two-line-ellipsis-text;

  h1 {
    margin-top: 21px;
  }
}
</style>
