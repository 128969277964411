<template>
  <div class="">
    <slot />
    <div class="left-indent" @click.stop>
      <div>
        <card-row
          v-for="linkedCard in linkedCards"
          :key="linkedCard.id"
          :card="linkedCard"
          show-team-name
          :show-status="showStatus"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

import CardRow from "@/components/CardRow.vue";
import { LinkableCard } from "@/components/modal/stickyLinkStickies";
import { Board, BoardCard, Card, IdMap } from "@/model";
import { useBoardStore } from "@/store/board";

@Component({ components: { CardRow } })
export default class CardTree extends Vue {
  @Prop({ type: Set, required: true }) readonly linkedCardIds!: Set<Card["id"]>;
  @Prop({ type: Object, required: true }) readonly card!: LinkableCard;
  @Prop({ default: false, type: Boolean }) readonly showStatus!: boolean;
  @Prop({ type: Object, required: true }) readonly board!: Board;

  get stickies(): IdMap<BoardCard> {
    return useBoardStore().teamVisibleStickies(
      this.board.type === "team" ? this.board.team.id : undefined,
    );
  }

  get linkedCards(): Card[] {
    return [...this.linkedCardIds]
      .filter((cardId) => cardId in this.stickies)
      .map((cardId) => this.stickies[cardId].data);
  }
}
</script>

<style lang="scss" scoped>
.left-indent {
  margin-left: 42px;
}
</style>
