<template>
  <div class="overlay boards scrollable no-wheel">
    <div
      v-for="(board, index) in boards"
      :key="board.id"
      @pointermove="selectIndex = index"
      @click.stop="select(board)"
    >
      <span class="entry" :class="{ current: index === selectIndex }">
        <span>{{ name(board) }}</span>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { boardTitle } from "@/baseTypeI18n";
import SelectList from "@/mixins/SelectList";
import { Board } from "@/model";

@Options({ emits: ["select"] })
export default class CardBoardsOverlay extends mixins(SelectList) {
  @Prop(Array) readonly boards!: Board[];
  @Prop(String) readonly teamId!: string | null;

  @Watch("boards", { immediate: true })
  boardsChanged() {
    this.selectList = this.boards;
  }

  select(board: Board) {
    this.$emit("select", board);
  }

  name(board: Board) {
    return boardTitle(board, false);
  }
}
</script>
