<script setup lang="ts">
import { computed } from "vue";

import BaseList from "@/components-ng/BaseList/BaseList.vue";
import BaseListTitle from "@/components-ng/BaseList/components/BaseListTitle/BaseListTitle.vue";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import ListItem from "@/components/ListItem/ListItem.vue";
import ListItemSeparator from "@/components/ListItem/ListItemSeparator.vue";
import BaseFlag from "@/components/card/BaseFlag.vue";
import FlagPicker from "@/components/flags/FlagPicker.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import { useSearchMenuStore } from "@/store/searchMenu";

import StickersItemTooltip from "../tooltips/StickersItemTooltip.vue";

const store = useSearchMenuStore();
const anySelected = computed(() => store.selectedFlagStrings.size > 0);
</script>

<template>
  <DropdownMenu class="search-select" width="sm" stay-on-click>
    <template #trigger="{ isOpen }">
      <IconButton icon="stickers" :activated="isOpen">
        <template #tooltip>
          <StickersItemTooltip :stickers="store.flags" />
        </template>
      </IconButton>
      <InfoMarker v-if="anySelected" :top="4" :right="4" />
    </template>
    <BaseList>
      <BaseListTitle>{{ $t("general.stickers") }}</BaseListTitle>
    </BaseList>
    <ListItem static class="picker">
      <FlagPicker
        no-sticker-option
        :selected="store.flags"
        @select="store.toggleFlag"
      />
    </ListItem>
    <template v-if="anySelected">
      <ListItemSeparator />
      <ListItem static class="footer">
        <div class="selected-flags-wrapper" @click="store.clearFlags">
          <div class="header">
            <span>
              {{ $t("stickersItem.selected") }}
            </span>
            <span class="active">
              {{ $t("general.clear") }}
            </span>
          </div>
          <div class="flags">
            <BaseFlag
              v-for="selectedFlag in store.flags"
              :key="selectedFlag.toString()"
              :flag="selectedFlag"
              @click.stop="store.toggleFlag(selectedFlag)"
            />
          </div>
        </div>
      </ListItem>
    </template>
  </DropdownMenu>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.picker {
  font-size: 10px;
}

.footer {
  font-size: 12px;

  .selected-flags-wrapper {
    width: 100%;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .active {
    color: colors-old.$primary-color;
    cursor: pointer;
  }

  .flags {
    display: flex;
    height: 2.8em;

    .flag-icon {
      width: auto;
      cursor: pointer;
    }
  }
}
</style>
