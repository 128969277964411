import { Vue } from "vue-class-component";

import { useAppSizeStore } from "@/store/appSize";

export default class RightSidePanel extends Vue {
  created() {
    useAppSizeStore().setRightMargin(301);
  }

  unmounted() {
    useAppSizeStore().setRightMargin(0);
  }
}
