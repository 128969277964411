import { captureException } from "@sentry/vue";
import {
  RouteLocation,
  RouteLocationNamedRaw,
  isNavigationFailure,
} from "vue-router";

import { zoomState } from "@/Gestures";
import { internalActions } from "@/action/internalActions";
import { BoardType } from "@/baseTypes";
import { useAppSizeStore } from "@/store/appSize";
import { useArtStore } from "@/store/art";
import { useSessionStore } from "@/store/session";
import { useTeamStore } from "@/store/team";

import { getRouter } from ".";
import { BoardTarget, LogoutReason, Query, QueryImpl } from "./types";

export function navigateForward() {
  switch (currentRoute().name) {
    case "session":
      return goTo(useArtStore().isMultiArt ? "art" : "team");
    case "art":
      return goTo("team");
    case "team":
    case "app":
      return goToApp();
  }
}

export function navigateBack() {
  switch (currentRoute().name) {
    case "settings":
      return goTo("team");
    case "app":
      internalActions.leaveBoard();
      return goTo("team");
    case "team":
      return goTo(useArtStore().arts.length > 1 ? "art" : "session");
    case "art":
      return goTo("session");
    case "session":
      return goTo("logout");
  }
}

export async function goToLogin() {
  try {
    return await getRouter().syncPush(() => ({ name: "login" }));
  } catch (fail) {
    return handleRouterFail(fail);
  }
}

export function goToLogout(reason: LogoutReason = "unknown") {
  return goTo("logout", { reason });
}

export async function replaceQuery(query: Query) {
  return getRouter()
    .syncReplace(() => {
      const current = currentRoute().query as QueryImpl;
      const newQuery: QueryImpl = {
        zoom: query.zoom || current.zoom,
        scrollX: query.scrollX || current.scrollX,
        scrollY: query.scrollY || current.scrollY,
        searchText:
          query.search?.text !== undefined
            ? query.search.text // keep empty search text to keep search dialog open
            : current.searchText,
        searchId: query.search?.id !== undefined ? query.search.id : undefined,
        searchFlag:
          query.search?.flag !== undefined
            ? query.search.flag || undefined
            : current.searchFlag,
        searchTeam:
          query.search?.team !== undefined
            ? query.search.team || undefined
            : current.searchTeam,
        searchArt:
          query.search?.art !== undefined
            ? query.search.art || undefined
            : current.searchArt,
        searchIteration:
          query.search?.iteration !== undefined
            ? query.search.iteration || undefined
            : current.searchIteration,
        searchType:
          query.search?.type !== undefined
            ? query.search.type || undefined
            : current.searchType,
        searchDepLink:
          query.search?.depLink !== undefined
            ? query.search.depLink || undefined
            : current.searchDepLink,
        searchStatusClass:
          query.search?.statusClass !== undefined
            ? query.search.statusClass || undefined
            : current.searchStatusClass,
        searchLink:
          query.search?.link !== undefined
            ? query.search.link || undefined
            : current.searchLink,
        searchPos: query.search
          ? query.search.pos || undefined
          : current.searchPos,
        feature: current.feature,
        category: "category" in query ? query.category : current.category,
      };

      return {
        query: {
          ...current,
          ...(newQuery as Record<string, string | undefined>),
        },
      };
    })
    .catch(handleRouterFail);
}

export function pushZoomState() {
  if (zoomState.zooming) {
    return;
  }
  replaceQuery({
    zoom: "" + useAppSizeStore().appSize.zoom,
    scrollX: "" + window.scrollX,
    scrollY: "" + window.scrollY,
  });
}

export function goToBoard(board: BoardTarget, query?: QueryImpl) {
  getRouter()
    .syncPush(() => mergeParams(boardParams(board), query))
    .catch(handleRouterFail);
}

export function boardUrl(
  session: string | undefined,
  board: BoardType,
  spec?: string,
) {
  const params = boardParams({
    type: board,
    team: board === "team" && spec ? { id: "", name: spec } : undefined,
    name: board === "flex" && spec ? spec : undefined,
  });
  // if team is not explicitly set, use _ meaning current team
  if (!params.team) {
    params.team = "_";
  }
  if (session) {
    params.session = session;
  }
  const router = getRouter();

  return router.resolve(mergeParams(params));
}

export function currentRoute(): RouteLocation {
  return getRouter()?.currentRoute.value;
}

export function currentQuery() {
  return getRouter().navigatingTo || currentRoute();
}

export function getTargetPageName(target: { path: string }) {
  const route = getRouter().resolve(target);
  if (route.name !== "app") {
    return route.name;
  }
  return route.params.board || "unknown";
}

function handleRouterFail(fail?: any) {
  if (!isNavigationFailure(fail)) {
    return Promise.reject(fail);
  }
}

async function goToApp() {
  const selectedSession = useSessionStore().session.selected;
  if (!selectedSession) {
    captureException(new Error("No selected session when opening app"));
    return goTo("session");
  }
  if (!useTeamStore().selectedTeam) {
    captureException(new Error("No selected team when opening app"));
    return goTo("team");
  }
  try {
    const team = useTeamStore().selectedTeam?.name;
    const session = useSessionStore().uniqueSessionName(selectedSession);

    return await getRouter().syncPush(() => ({
      name: "app",
      params: { session, board: "team", team },
      query: { feature: currentRoute().query.feature, searchText: "" },
    }));
  } catch (fail) {
    return handleRouterFail(fail);
  }
}

async function goTo(name: string, query?: Record<string, string>) {
  try {
    return await getRouter().syncPush(() => {
      return {
        name,
        query: { feature: currentRoute().query.feature, ...query },
      };
    });
  } catch (fail) {
    return handleRouterFail(fail);
  }
}

function mergeParams(
  params: Record<string, string>,
  query?: QueryImpl,
): RouteLocationNamedRaw {
  return {
    params: {
      ...currentRoute().params,
      ...{ name: undefined },
      ...params,
    } as unknown as Record<string, string>, // the typing of vue-router is wrong: params can be undefined to remove them from the route
    query: { ...currentRoute().query, zoom: "1", ...query },
  };
}

function boardParams(target: BoardTarget): Record<string, string> {
  if (typeof target === "string") {
    return { board: target };
  }
  switch (target.type) {
    case "team": {
      const team = target.team?.name;
      return { board: "team", ...(team && { team }) };
    }
    case "flex": {
      const name = target.name;
      return { board: "flex", ...(name && { name }) };
    }
    default: {
      const team =
        target.artId &&
        useTeamStore().teams.find(({ artId }) => artId === target.artId)?.name;
      return { board: target.type, ...(team && { team }) };
    }
  }
}
