import { defineStore } from "pinia";

import { BoardType } from "@/baseTypes";
import { i18n } from "@/i18n";
import { AlmType, IdMap, StickyType } from "@/model";

export function zombieStickyType(): StickyType {
  return {
    id: "0",
    name: i18n.global.t("stickyType.unknown"),
    almType: null,
    color: [0.5, 0.5, 0.5, 0.8],
    altColor: [0.5, 0.5, 0.5, 0.8],
    origin: "risk",
    flexOrigin: null,
    usable: [],
    flexUsable: [],
    functionality: "note",
  };
}

export function isZombieType(type: StickyType) {
  return type.id === "0";
}

export const useStickyTypeStore = defineStore("stickyType", {
  state: () => ({
    stickyTypes: [] as StickyType[],
    almTypes: {} as IdMap<AlmType>,
  }),
  getters: {
    boardStickyTypes:
      () =>
      (
        type: BoardType,
        flexType: string | undefined,
        stickyTypes: StickyType[],
      ) => {
        const mirrorTypes = stickyTypes.filter(
          (s) =>
            s.origin !== type &&
            s.usable.find((a) => a === type) &&
            (type !== "flex" ||
              (s.flexUsable || []).find((a) => a === flexType)),
        );
        mirrorTypes.sort((a, b) => a.name.localeCompare(b.name));
        const originTypes = stickyTypes.filter(
          (s) =>
            s.origin === type && (type !== "flex" || s.flexOrigin === flexType),
        );
        return originTypes.concat(mirrorTypes);
      },
    findStickyType:
      () =>
      (stickyTypes: StickyType[], id: string): StickyType => {
        const type = stickyTypes.find((t) => t.id === id);
        if (type) {
          return type;
        }
        return zombieStickyType();
      },
    almTypeOf: (state) => (stickyType: Pick<StickyType, "almType">) => {
      return state.almTypes[stickyType.almType || ""];
    },
  },
  actions: {
    setStickyTypes(stickyTypes: StickyType[]) {
      this.stickyTypes = stickyTypes;
    },
  },
});
