<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { StatusClass, statusClassNames } from "@/baseTypes";
import { statusClassColors } from "@/colors";
import { BarChartData, createBarChartData } from "@/components/StackedBarChart";
import StackedBarChart from "@/components/StackedBarChart.vue";
import type {
  StatusDistributionData,
  StatusDistributionSource,
} from "@/components/StatusDistribution";
import StatusDot from "@/components/StatusDot.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import { Iteration } from "@/model";
import { useIterationStore } from "@/store/iteration";
import { formatShortDate } from "@/utils/dateFormat";

const sourceKeys: { [source in StatusDistributionSource]: string } = {
  iteration: /*$t*/ "statusDistribution.emptyIteration",
  objective: /*$t*/ "statusDistribution.emptyObjective",
  sticky: /*$t*/ "statusDistribution.emptySticky",
};

const props = defineProps<{
  value: StatusDistributionData;
  sourceItemType?: StatusDistributionSource;
  iteration?: Iteration;
}>();

const { t } = useI18n();

const chartData = computed(() => {
  return {
    ...createBarChartData(props.value, (key, value) => ({
      name: statusClassNames[key],
      points: value.points,
      status: key,
      color: statusClassColors[key],
    })),
    totalPoints: [...props.value.values()].reduce(
      (sum, item) => sum + item.points,
      0,
    ),
  } as BarChartData<StatusClass, any> & { totalPoints: number };
});

const items = computed(() => [...chartData.value.items]);
const emptyMessage = computed(() =>
  t(sourceKeys[props.sourceItemType || "sticky"]),
);
const today = computed(() => formatShortDate(new Date()));
const iterationNow = computed(() =>
  useIterationStore().iterationProgress(new Date(), props.iteration),
);
const iterationState = computed(() => {
  const passed = iterationNow.value.iterationsPassed;
  if (passed <= 0) {
    return t("statusDistribution.iteration.notStarted");
  }
  if (passed >= 1) {
    return t("statusDistribution.iteration.ended");
  }
  return t("statusDistribution.iteration", {
    day: Math.ceil(iterationNow.value.daysPassedInIteration),
    total: iterationNow.value.daysInIteration,
  });
});
</script>

<template>
  <div class="status-distribution" data-testid="status-distribution">
    <BaseTooltip class="status-tooltip" position="bottom">
      <template #content>
        <div class="status-distribution-content">
          <div v-if="iteration" class="iteration">
            {{ $t("statusDistribution.today", { today }) }}
            <br />
            {{ iterationState }}
          </div>
          <div v-if="chartData.total === 0" class="empty">
            {{ emptyMessage }}
          </div>
          <table v-else>
            <tr>
              <th>{{ $t("statusDistribution.status") }}</th>
              <th>{{ $t("statusDistribution.storyPoints") }}</th>
              <th>{{ $t("statusDistribution.workItems") }}</th>
            </tr>
            <tr v-for="(item, index) in items" :key="index">
              <td>
                <div>
                  <div class="status-dot-container">
                    <StatusDot radar-type :status-class="item.status" />
                  </div>
                  <span>{{ item.name }}</span>
                </div>
              </td>
              <td>
                {{
                  $t("general.partOfTotal", {
                    part: item.points,
                    total: chartData.totalPoints,
                  })
                }}
              </td>
              <td>
                {{
                  $t("general.partOfTotal", {
                    part: item.value,
                    total: chartData.total,
                  })
                }}
              </td>
            </tr>
          </table>
        </div>
      </template>
      <div class="bar-chart-wrap">
        <StackedBarChart :value="chartData" />
      </div>
    </BaseTooltip>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.status-distribution {
  display: flex;

  .status-tooltip {
    display: inline-flex;
    width: 100%;
  }

  .bar-chart-wrap {
    display: inline-flex;
    width: 100%;
  }

  table {
    column-gap: 40px !important;
  }

  &-content {
    .iteration {
      white-space: pre-line;
      border-bottom: 1px solid colors-old.$status-distribution-header-text-color;
      padding-bottom: 0.5em;
    }

    .status-dot-container {
      width: 1.5em;
      height: 1.5em;
    }

    th {
      font-size: font.$size-small;
      font-weight: font.$weight-bold;
      color: colors-old.$status-distribution-header-text-color;
      padding-bottom: 8px;
    }

    td {
      font-size: font.$size-normal;
      padding-bottom: 4px;
    }

    .empty {
      padding: 0.3em 1em;
      white-space: nowrap;
    }

    th:first-child {
      text-align: left;
    }

    td:not(:first-child) {
      text-align: right;
    }

    td:first-child {
      & > div {
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }

    td:not(:last-child),
    th:not(:last-child) {
      padding-right: 10px;
    }
  }
}
</style>
