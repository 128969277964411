<template>
  <base-planning-board
    ref="board"
    :planning-board="board"
    :group-iterations="groupIterations"
    :cards-for-distribution="cardsForDistribution"
    :field-class="fieldClass"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import BasePlanningBoard from "@/components/BasePlanningBoard.vue";
import { BoardIteration, Card, Group } from "@/model";
import { useBoardStore } from "@/store/board";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useTeamStore } from "@/store/team";

@Options({ components: { BasePlanningBoard } })
export default class ProgramBoard extends Vue {
  get board() {
    return useBoardStore().boardByType("program");
  }

  get groupIterations() {
    return Object.fromEntries(
      useBoardStore()
        .artTeamBoards()
        .map((board) => [board.team.id, board.iterations]),
    );
  }

  fieldClass(group: Group, iter?: BoardIteration) {
    const teamColor =
      group.name === useTeamStore().currentTeam.name ? "group-current" : "";
    if (!iter || !useClientSettingsStore().overload) {
      return teamColor;
    }
    const v = iter.velocity;
    if (!v) {
      return teamColor;
    }
    const r = iter.load / v;
    return r < useClientSettingsStore().loadWarn / 100
      ? teamColor
      : r >= 1
      ? "overload"
      : "warn";
  }

  get cardsForDistribution() {
    return useBoardStore()
      .artTeamBoards()
      .reduce(
        (acc, board) => [
          ...acc,
          ...Object.values(board.cards).map((card) => card.data),
        ],
        [] as Card[],
      );
  }

  boardComponent() {
    return this.$refs.board;
  }
}
</script>
