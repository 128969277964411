<template>
  <img :src="image[reaction]" />
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import hot from "@/assets/reactions/reaction-hot.svg";
import increment from "@/assets/reactions/reaction-increment.svg";
import love from "@/assets/reactions/reaction-love.svg";
import { Reaction } from "@/model";

export default class ReactionIcon extends Vue {
  @Prop(String) readonly reaction!: Reaction;

  image: { [reaction in Reaction]: string } = { hot, increment, love };
}
</script>

<style lang="scss" scoped>
@use "@/styles/board";

img {
  height: board.len(40px);
}
</style>
