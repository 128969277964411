<script setup lang="ts">
import { computed } from "vue";

import BaseModal from "@/components/modal/BaseModal.vue";
import SettingsContent from "@/components/modal/SettingsContent.vue";

const props = defineProps<{ isUserOnBoard: string }>();
const isBoard = computed(() => props.isUserOnBoard === "true");
</script>

<template>
  <BaseModal
    width="40%"
    :fullscreen="!isBoard"
    class="settings-modal"
    :title="$t('settings.settings')"
  >
    <div :class="{ 'settings-wrap': !isBoard }">
      <SettingsContent />
    </div>
  </BaseModal>
</template>

<style lang="scss" scoped>
.settings-wrap {
  margin: 1rem auto;
  min-width: 50%;
}
</style>
