<template>
  <div
    :id="board.id"
    :style="{ width: widthPercentage }"
    class="board program-objectives-board"
  >
    <div class="objectives-board">
      <div class="column program-objectives">
        <div class="column-header">
          <div class="team-info">
            <h3>{{ programObjectivesTitle }}</h3>
            <div class="objectives-count">
              {{ $t("objectives.count", programObjectivesCount) }}
            </div>
          </div>
        </div>
        <div class="scrollable">
          <ObjectivesList
            group="program-objectives"
            :board="programBoard"
            :is-expendable="false"
            :has-status-distribution="false"
          />
        </div>
      </div>
      <div class="column">
        <div class="column-header">
          <div class="team-info">
            <DropdownMenu :style="{ maxHeight: '50vh' }" class="select-team">
              <template #trigger>
                <BaseButton
                  variant="ghost"
                  color="grey"
                  icon-after="arrow/down"
                  style="margin-left: -10px"
                >
                  {{ teamObjectivesTitle }}
                </BaseButton>
              </template>
              <ListItem
                v-for="team in teams"
                :key="team.id"
                :active="team.id === currentTeam.id"
                @click="select(team)"
              >
                <div>
                  <div class="team-title">{{ team.name }}</div>
                  <div class="team-objectives-count">
                    {{ $t("objectives.count", getTeamObjectivesCount(team)) }}
                  </div>
                </div>
              </ListItem>
            </DropdownMenu>
            <div class="objectives-count">
              {{ $t("objectives.count", teamObjectivesCount) }}
            </div>
          </div>

          <span class="arrows-container">
            <IconButton
              variant="outlined"
              size="small"
              icon="arrow/left"
              :tooltip="$t('action.previous')"
              :disabled="isPrevDisabled"
              @click="shiftTeam(-1)"
            />
            <IconButton
              variant="outlined"
              size="small"
              icon="arrow/right"
              :tooltip="$t('action.next')"
              :disabled="isNextDisabled"
              @click="shiftTeam(1)"
            />
          </span>
        </div>
        <div class="scrollable">
          <transition name="faster-fade" mode="out-in">
            <ObjectivesList
              :key="selectedTeamBoard.id"
              :board="selectedTeamBoard"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import { boardActions } from "@/action/boardActions";
import BoardBase from "@/components/BoardBase";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import ListItem from "@/components/ListItem/ListItem.vue";
import ObjectivesList from "@/components/objectives/ObjectivesList.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { Team } from "@/model";
import { useAppSizeStore } from "@/store/appSize";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useTeamStore } from "@/store/team";

@Options({
  name: "ProgramObjectivesBoard",
  components: {
    BaseButton,
    ObjectivesList,
    IconButton,
    DropdownMenu,
    ListItem,
  },
})
export default class ProgramObjectivesBoard extends mixins(BoardBase) {
  width = 100;

  @Watch("appSize.margin.right")
  onSpaceChange() {
    const rightMargin = this.appSize.margin.right;
    const cw = document.documentElement.clientWidth;
    this.width = ((cw - rightMargin) * 100) / cw;
  }

  get appSize() {
    return useAppSizeStore().appSize;
  }

  get widthPercentage() {
    return this.width.toString() + "%";
  }

  get board() {
    return useBoardStore().boardByType("objectives");
  }

  get programBoard() {
    return useBoardStore().boardByType("program");
  }

  get selectedTeamBoard() {
    return this.getTeamBoard(this.currentTeam);
  }

  get currentTeam() {
    return useTeamStore().currentTeam;
  }

  get teams() {
    return useTeamStore().teamsInCurrentArt;
  }

  get programObjectivesCount() {
    const committedCount = this.programBoard.objectives.length;
    const uncommittedCount = this.programBoard.stretchObjectives.length;
    return committedCount + uncommittedCount;
  }

  get programObjectivesTitle() {
    const name = useArtStore().currentArt.name;
    return this.$t("objectivesBoard.objectives", { name });
  }

  get teamObjectivesCount() {
    return this.getTeamObjectivesCount(this.currentTeam);
  }

  get teamObjectivesTitle() {
    const name = this.currentTeam.name;
    return this.$t("objectivesBoard.objectives", { name });
  }

  getTeamObjectivesCount(team: Team) {
    const board = this.getTeamBoard(team);
    const committedCount = board.objectives.length;
    const uncommittedCount = board.stretchObjectives.length;
    return committedCount + uncommittedCount;
  }

  getTeamBoard(team: Team) {
    return useBoardStore().boardByType("team", { teamId: team.id });
  }

  select(team: Team) {
    boardActions.switchTeam("board", team);
  }

  get currentTeamIndex() {
    return this.teams.findIndex((t) => t.id === this.currentTeam.id);
  }

  shiftTeam(diff: number) {
    const newIndex = this.currentTeamIndex + diff;
    if (newIndex >= 0 && newIndex < this.teams.length) {
      boardActions.switchTeam("board", this.teams[newIndex]);
    }
  }

  get isNextDisabled() {
    return this.currentTeamIndex === this.teams.length - 1;
  }

  get isPrevDisabled() {
    return this.currentTeamIndex === 0;
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.program-objectives-board {
  background: colors-old.$board-background-color;
  transform: scale(10);
  transform-origin: top left;
  height: 100%;
  padding-top: 48px;
  font-size: 100%;
}

.objectives-board {
  display: flex;
  width: 100%;
  height: 100%;

  .column {
    display: flex;
    flex-direction: column;
    width: 50vw;

    &.program-objectives {
      border-right: 1px solid colors-old.$divider-color;
    }

    .column-header {
      border-bottom: 1px solid colors-old.$divider-color;
      padding: 1rem 5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        font-size: font.$size-normal;
        font-weight: font.$weight-bold;
      }

      .objectives-count {
        font-size: font.$size-small;
        font-weight: font.$weight-semi-bold;
        color: colors-old.$text-secondary-color;
      }

      .arrows-container {
        display: flex;
        gap: 0.4rem;
      }
    }

    .scrollable {
      position: relative;
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
}

.select-team {
  .team-title {
    margin-bottom: 0.25rem;
  }

  .team-objectives-count {
    font-size: font.$size-small;
    font-weight: font.$weight-normal;
    color: colors-old.$text-secondary-color;
  }
}
</style>
