<template>
  <div>
    <label class="switch">
      <input :checked="modelValue" type="checkbox" @click="select" />
      <span class="slider round"></span>
    </label>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({ emits: ["update:modelValue"] })
export default class BaseSwitch extends Vue {
  @Prop(Boolean) readonly modelValue!: boolean;

  select(e: Event) {
    this.$emit("update:modelValue", (e.target as HTMLInputElement).checked);
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: colors-old.$dark-divider-color;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 6px;
  background-color: colors-old.$back-color;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: colors-old.$checkbox-color;
}

input:checked + .slider::before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round::before {
  border-radius: 50%;
}
</style>
