<script setup lang="ts">
import { computed } from "vue";

import { navigationActions } from "@/action/navigationActions";
import { boardTypeName } from "@/baseTypeI18n";
import GroupDropdown from "@/components/GroupDropdown.vue";
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue";
import { Art, Team, isSolutionBoard } from "@/model";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useTeamStore } from "@/store/team";
import { teamChange } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

const teams = computed(() => useTeamStore().teamsInCurrentArt);
const arts = computed(() => useArtStore().arts);
const boardType = computed(() => useBoardStore().currentBoard().type);
const boardName = computed(() => boardTypeName(boardType.value));
const flexBoard = computed(() => useBoardStore().boardByType("flex"));
const currentArt = computed(() => useArtStore().currentArt);
const currentTeam = computed(() => useTeamStore().currentTeam);
const solutionBoard = computed(() => isSolutionBoard(boardType.value));

function selectArt(art: Art) {
  navigationActions.changeArt("mainMenu", boardType.value, art);
}

function selectTeam(team: Team) {
  trackEvent(teamChange("menu-top"));
  navigationActions.changeTeam("mainMenu", team);
}
</script>

<template>
  <div class="board-name">
    <div v-if="boardType === 'team'">
      <span class="team">
        <GroupDropdown
          type="team"
          :title="$t('general.teams')"
          :groups="teams"
          :value="currentTeam"
          @change="selectTeam"
        />
        <SvgIcon name="arrow/right" size="20" class="arrow-icon" />
        {{ $t("board.team") }}
      </span>
    </div>
    <div v-else-if="boardType !== 'flex'">
      <span v-if="currentArt.name && !solutionBoard" class="art">
        <GroupDropdown
          type="art"
          :title="$t('general.arts')"
          :groups="arts"
          :value="currentArt"
          @change="selectArt"
        />
        <SvgIcon name="arrow/right" size="20" class="arrow-icon" />
        {{ boardName }}
      </span>
      <span v-else>{{ boardName }}</span>
    </div>
    <div v-else>
      <span>{{ flexBoard.name }}</span>
    </div>
  </div>
</template>

<style lang="scss">
.board-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: var(--text-secondary-color);

  .team,
  .art {
    display: flex;
    align-items: center;
  }

  .arrow-icon {
    margin-right: 8px;
  }
}
</style>
