export function firstProperty(
  obj: Record<string, unknown>,
): string | undefined {
  for (const prop in obj) {
    return prop;
  }
}

export function mapObjectValues<S, T>(
  obj: { [id: string]: S },
  mapper: (value: S) => T,
): { [id: string]: T } {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, mapper(value)]),
  );
}

export function firstValue(obj: { [key: string]: any }): any {
  return obj[firstProperty(obj)!];
}

export function isEmpty(obj: Record<string, unknown>): boolean {
  return !firstProperty(obj);
}

export function toHex(value: number, len: number): string {
  return pad(value.toString(16), len);
}

export function pad(s: string | number, len: number): string {
  let r = s.toString();
  while (r.length < len) {
    r = "0" + r;
  }
  return r;
}

export function generateId(type: string, data?: string) {
  let res = type;
  const end = data || "";
  while (res.length + end.length < 24) {
    res += "0";
  }
  return res + end;
}

export function hash(s: string) {
  let h = 3496329;
  for (let i = 0; i < s.length; i++) {
    // eslint-disable-next-line no-bitwise
    h = ((h << 5) - h + s.charCodeAt(i)) | 0;
  }
  return h;
}

export function flattenObject(obj: any, level = 2, path = ""): any {
  const flattenable = ["[object Object]", "[object Array]"].includes(
    Object.prototype.toString.call(obj),
  );
  const keys = Object.keys(obj || "");
  if (level === 0 || !flattenable || !keys.length) {
    return path.length ? { [path.substring(1)]: obj } : obj;
  }
  return keys.reduce(
    (accu, prop) => ({
      ...accu,
      ...flattenObject(obj[prop], level - 1, path + "." + prop),
    }),
    {},
  );
}

/**
 * Removes the non-printing character from a string
 * https://en.wikipedia.org/wiki/Control_character#In_Unicode
 */
export function removeNonPrintable(value: string) {
  // eslint-disable-next-line no-control-regex
  return value.replace(/[\u0000-\u001F\u007F-\u009F]/g, " ");
}

/**
 * Checks whether the provided `item` exists in any of the sets in the `map`.
 *
 * @template T - The type of the set items.
 * @template S - The type of the map keys.
 *
 * @param item - The item to search for in the sets.
 * @param map - The map containing sets to check.
 *
 * @returns {boolean} - `true` if the item is found in any of the sets, otherwise `false`.
 */
export function hasItemInSets<T, S>(item: T, map: Map<S, Set<T>>): boolean {
  for (const [_, children] of map) {
    if (children.has(item)) {
      return true;
    }
  }
  return false;
}

/**
 * Aggregates the values of a map into a single set.
 *
 * @template K - The type of the map keys.
 * @template V - The type of the map values.
 *
 * @param map - The map to aggregate.
 *
 * @returns - The set containing all the values from the map.
 */
export function aggregateMapValues<K, V>(map: Map<K, Set<V>>): Set<V> {
  const result = new Set<V>();
  for (const set of map.values()) {
    for (const value of set) {
      result.add(value);
    }
  }
  return result;
}
